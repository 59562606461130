import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./aa02.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";

import TabBottom from "../../TabBottom/Tab";

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 


const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    // ['link', 'image', 'video'],
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]


// <div className={``}></div>
//  'page000/1/register',
const pageCode1 ='page005/3/setting-tutorial-add'
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(2);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("");
    const [textInput4 , setTextInput4] = useState("in");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    const [quillValue1, setQuillValue1] = useState();

    const [boolPopup1, setBoolPopup1 ] = useState(false);

  const [objectUserData, setObjectUserData] = useState(null);
  const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
  const [objectArrayFolderData1, setObjectArrayFolderData1] = useState([]);
  const [objectArrayGameData1, setObjectArrayGameData1] = useState([]);

  const [objectPopupData1, setObjectPopupData1] = useState(null);

  const [currentFolderKeyname, setCurrentFolderKeyname] =  useState(null);
  const [currentFolderData, setCurrentFolderData] =  useState(null);
  const [currentCardsData, setCurrentCardsData] =  useState([]);
  const [keyname, setKeyname] = useState("");



    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }




    function handleCreate() {
      // console.log("[CHECKING] 00001 ::::::::: handleCreate - objectUserData", objectUserData)
      var currentFolderItem = window.localStorage.getItem("currentFolderItem")
      // console.log("currentFolderKeyname", currentFolderKeyname)
      // console.log("[CHECKING] 00002 ::::::::: handleCreate - currentFolderItem", currentFolderItem)
      // console.log("[CHECKING] 00003 ::::::::: handleCreate - currentFolderItem", quillValue1)

      fireDb.database().ref()
      .child("tutorial")
      .push(
        {
            keyname : keyname,
            id : keyname + moment().format("YYYYMMDD_HHmmss"),
            type : "",  
            title :  textInput2,
            description: quillValue1,
            nickname : objectUserData.nickname,
            lock : false,
            share : textInput4 == "in" ? true : false,
            number : 0,
            checked : false, // 백엔드에 저장하는 항목은 아님.
          
          // account : textInput1, 
          // email : textInput1, 
          // password : textInput2,
        }
        , (err) => {
        if (err) {
          console.log(err);
          setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>현재 생성이 불가합니다.</div>)
        } else {

          console.log("[CHECKING] 00004 ::::::::: handleCreate - currentFolderItem",)
          setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}>데이터 생성되었습니다.</div>)

          setScreenMode1('page005/2/tutorial');

          
        }
      });
    
    }

    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }


      if (viewScreenMode.mode !== pageCode1) {
        return;
      }

    
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")
    var arrayCardData1 = [];
    var arrayFolderData1 = [];
    var arrayGameData1 = [];
    if (true && true) {
      const parseAuthStore1 = JSON.parse(authStore1)
      console.log("parseAuthStore1", parseAuthStore1);

      var currentFolderItem = window.localStorage.getItem("currentFolderItem")
      Firebase.database().ref()
          .child("accounts")
          .orderByChild("username")
          .equalTo(authStoreUserName)
          .once("value", (snapshot) => {
              if (snapshot.val()) {
                  const data = snapshot.val();

                  const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                  setKeyname(keyName);
                  if (keyName) {
                      // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                      // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                      
                        // window.localStorage.setItem("authStore",  JSON.stringify(data[keyName]));
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                      setObjectUserData(data[keyName]);
                        props && props.handleUser(keyName, data[keyName]);
                      // setObjectArrayCardData1
                      // setObjectArrayFolderData1
                      // setObjectArrayGameData1
                      // if (data[keyName].cards) {
                      //   setObjectArrayCardData1([...data[keyName].cards])
                      // } else {
                      //   setObjectArrayCardData1([])
                      // }
                      if (data[keyName].folders) {
                        
                        console.log("data[keyName].folders - Object.values()",Object.values( data[keyName].folders));
                        if (Object.values( data[keyName].folders) && Object.values( data[keyName].folders).length > 0)
                        setObjectArrayFolderData1(Object.values( data[keyName].folders))

                        var folders = Object.values( data[keyName].folders) ;
                        if (folders && folders.length > 0 ) {
                          const findIndex1 = folders.findIndex((findItem, findIndex)=> {
                            if (findItem.id == currentFolderItem) {

                              return (
                                findItem
                              )
                            }
                          })

                          if (findIndex1 > -1 ) {
                            setCurrentFolderKeyname(Object.keys(data[keyName].folders)[findIndex1]);
                            setCurrentFolderData(folders[findIndex1])
                            props && props.handleFolder(Object.keys(data[keyName].folders)[findIndex1], folders[findIndex1], Object.keys(data[keyName].folders)[findIndex1].id);

                            if (folders[findIndex1] && folders[findIndex1].cards && folders[findIndex1].cards.length > 0) {
                              console.log(" == folders[findIndex1].cards - Object.values()",Object.values( folders[findIndex1].cards));
                              
                              var cards = Object.values( folders[findIndex1].cards);
                              setCurrentCardsData(cards);

                            }

                          }else {
                            // 없음
                          }
                        }
                        
                      }else {
                        setObjectArrayFolderData1([])
                      }


                      if (data[keyName].games) {
                        setObjectArrayGameData1([...data[keyName].games])
                      }else {
                        setObjectArrayGameData1([])
                      }
                      // if (data[keyName].status == "YES") {
                      // } else {
                      // }
                  } else {

                  }

              }  else {
                  // alert("존재하지 않는 회원입니다.");
              }
          });



    }


    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
      {objectPopupData1}
        <div className={`aa02_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />

          <div className="" style={{ marginTop: 120 }}></div>
          <div className="step_content_container0 step_content_container2">

            {/* <div className="input_label0 input_label1" >
            &nbsp;&nbsp;폴더 선택
            </div>
            <div className="tab2_wrapper0 tab_wrapper1">
              <div className="tab_item0 tab_item1"
                style={{ 
                  color : textInput1 == "in" ?  `#FFFFFF` : `#212529`,
                  fontWeight : textInput1 == "in" ?  `700` : `400`,
                  background : textInput1 == "in" ?  `#181818` : `#FFFFFF`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextInput1("in")
                }}
              >MY 폴더선택</div>
              <div className="tab_item0 tab_item2"
                style={{ 
                  color : textInput1 == "out" ?  `#FFFFFF` : `#212529`,
                  fontWeight : textInput1 == "out" ?  `700` : `400`,
                  background : textInput1 == "out" ?  `#181818` : `#FFFFFF`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextInput1("out")
                }}
                >신규폴더생성등록</div>
            </div> */}


            <div className="input_label0 input_label2">
            &nbsp;&nbsp;제목
            </div>
            <div 
            className="textinput_form_container0 textinput_form_container1"
              style={{ background : `#fff`}}
            >
              <input 
                value={textInput2}
                onChange={e=> {
                  // e.preventDefault();
                  // e.stopPropagation();
                  setTextInput2(e.target.value);
                }}
                placeholder={`타이틀을 입력하세요(20자 이내로)`}
              />
              {
                textInput2.length > 0 &&
                <img src={appImages._000_2} 
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextInput2("");
                }}
                />
              }
            </div>
            <div className="input_label0 input_label2">
            &nbsp;&nbsp;설명
            </div>
            <QuillWrapper
              style={{
                maxWidth : `calc(100vw - 32px)`,
                width :`calc(100vw - 32px)`,
                marginBottom : `80px`,
                
              }}
              theme={'snow'}
              id={'description'}
              placeholder={'설명을 입력해주세요'}
              value={quillValue1}
              modules={modules}
              formats={formats}
              onChange={(event) => setQuillValue1(event)}
          /> 

            
            {/* <div className="tab2_wrapper0 tab_wrapper1">
              <div className="tab_item0 tab_item1"
                style={{ 
                  color : textInput4 == "in" ?  `#FFFFFF` : `#212529`,
                  fontWeight : textInput4 == "in" ?  `700` : `400`,
                  background : textInput4 == "in" ?  `#181818` : `#FFFFFF`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  setBoolPopup1(true);
                }}
              >공식입력</div>
              <div className="tab_item0 tab_item2"
                style={{ 
                  color : textInput4 == "out" ?  `#FFFFFF` : `#212529`,
                  fontWeight : textInput4 == "out" ?  `700` : `400`,
                  background : textInput4 == "out" ?  `#181818` : `#FFFFFF`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  // setTextInput4("out")
                }}
                >영어사전</div>
            </div> */}

              
            {/* <___Editor/> */}

            <div className="submit_button0 submit_button1"
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();

                if (textInput2 == "") {
                  return ;
                }


                handleCreate();
                
              }}
            >
              완료
            </div>

            <div className="submit_button0 submit_button2"
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              취소
            </div>



          </div>
        {
            boolPopup1 == true &&
            <>
                <div className="popup_background0" 
                onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    // setBoolPopup1(false);
                }}
                >

                <div className="popupcontent0 math_bottom_popup1">
                    <div className="titlerow0 titlerow1">
                      <div className="title0 ">공식입력</div>
                      <img className="close0" src={appImages._000_2}
                onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolPopup1(false);
                }} />
                    </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
                style={{ marginTop: 20, marginBottom: 40 }}

              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>

                      <input 
                    value={textInput3}
                    onChange={e=> {
                      // e.preventDefault();
                      // e.stopPropagation();
                      setTextInput3(e.target.value);
                    }}
                    placeholder={`검색어를 입력하세요`}
                  />
                  {
                    textInput3.length > 0 &&
                    <img src={appImages._000_2} 
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTextInput3("");
                    }}
                    />
                  }
                </div>
                <div className="button0" 
                onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolPopup1(false);
                }}>취소하기</div>
            </div>
                  
                  {
                    Array.from(["", "", "", "", ]).map((mapItem, mapIndex)=> {
                      return (

                    <div className="button0 button2"
                    onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        // setBoolPopup1(false);
                    }}
                    >
                    <div className="label0 label1">원의 겉넓이 : 4πr2</div>
                    <img className="img0 img1" src={`/static/icons/061/icon_check0.png`}  />
                </div>
            

                      )
                    })
                  }
                </div>

                </div>
            </>
        }
































        </div>
      </>
      
    );
}
