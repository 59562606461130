import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";;

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 = 'page005/2/push';
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(1);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("");
    const [textInput4 , setTextInput4] = useState("");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    

    const [boolPopup1, setBoolPopup1] =  useState(false);
    const [boolCheck1 , setBoolCheck1] = useState(false);
    const [boolCheck2 , setBoolCheck2] = useState(false);
    const [boolCheck3 , setBoolCheck3] = useState(false);
    

    const menuArr1 = [
      { label : "마이페이지",  code : 'page005/2/setting-mypage' },
      { label : "알람", code : 'page005/2/setting-notification', },
      { label : "수신동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "자주묻는질문", code : 'page005/2/setting-faq', },
      { label : "1:1문의", code : 'page005/2/setting-questions', },
      { label : "공지사항", code : 'page005/2/setting-notice', },
      { label : "튜토리얼", code : 'page005/2/tutorial', },
      { label : "이용약관", code : 'page005/2/term', },
      { label : "개인정보처리방침",  code : 'page005/2/term', },

    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',


    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`a04_c`} style={{ background : '#fff'}}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
        
            <div className="" style={{ marginTop: `72px`}}></div>
            <div className="infoRow0 infoRow1">
                <div className="text0 text1">시스템 알림</div>
                <div className="text0 text2">
                    <div className="taggOn">On</div>
                </div>
            </div>

            <div className="graybox0 graybox1" style={{ marginTop: `0px`, marginBottom : `20px`}}>
                <div className="row0 text1" >
                    {`알림 수신 해제는 기기 설정 > 알림 > 쏙닥쏙닥에서 가능하며, 알림을 해제하면 커뮤니티 서비스 이용이 어려울 수 있어요.`}
                </div>
            </div>

            <div className="hr0"></div>

            <div className="infoRow0 infoRow1">
                <div className="text0 text1">커뮤니티 메시지/친구추가 알림</div>
                <div className="text0 text2"
                style={{ marginRight: `20px`}}>
                    
                <img 
                    className="icon0 icon2" 
                    src={
                        boolCheck1 === true ?
                        `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                        :
                        `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                    alt=""
                    style={{
                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                    // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolCheck1(!boolCheck1)

                    }}
                />
                </div>
            </div>

            <div className="graybox0 graybox1" style={{ marginTop: `0px`, background : '#fff', }}>
                <div className="row0 text1" style={{ width : `100%`, marginLeft : 0, marginRight : 0 }}>
                    {`내 대화친구가 생겼을 때, 새로운 대화친구가 메세지를 남겼을 때 등 알림메세지로 알려드려요`}
                </div>
            </div>

            <div className="infoRow0 infoRow1">
                <div className="text0 text1">마음다이어리 작성 알림</div>
                <div className="text0 text2"
                style={{ marginRight: `20px`}}>
                    
                <img 
                    className="icon0 icon2" 
                    src={
                        boolCheck2 === true ?
                        `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                        :
                        `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                    alt=""
                    style={{
                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                    // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolCheck2(!boolCheck2)

                    }}
                />
                </div>
            </div>
            <div className="graybox0 graybox1" style={{ marginTop: `0px`, background : '#fff'}}>
                <div className="row0 text1"  style={{ width : `100%`, marginLeft : 0, marginRight : 0 }}>
                    {`마음다이어리 작성`}
                </div>
            </div>
            
            <div className="infoRow0 infoRow1">
                <div className="text0 text1">혜택/이벤트 알림</div>
                <div className="text0 text2" 
                style={{ marginRight: `20px`}}
                >
                    
                <img 
                    className="icon0 icon2" 
                    src={
                        boolCheck3 === true ?
                        `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                        :
                        `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                    alt=""
                    style={{
                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                    // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolCheck3(!boolCheck3)

                    }}
                />
                </div>
            </div>



            <style>{`

                .hr0 {
                    width : 100vw;
                    height: 20px;
                    background : #f2f2f2;
                }


            .taggOn {

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

width: 46px;
height: 28px;

background: #FFF4F4;
border-radius: 30px;

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */
text-align: center;
letter-spacing: -0.02em;
text-transform: capitalize;

/* Main/Pink */
color: #FF7A7A;

margin-right : 20px;

            }

                .graybox0 {  
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center; 
                        
                        width: calc(100vw - 40px);
                        /* Gray/00 */
                        background: #F9F9F9;
                        border-radius: 12px;
                        margin-left: auto;
                        margin-right : auto;
                }
                .graybox0 .text1 {
                    width: calc(100vw - 40px); 
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                    /* or 21px */

                    /* Gray/07 */
                    color: #666666;
                    margin-left: 20px;
                    margin-right : 20px;
                    margin-top: 16px;
                    margin-bottom : 16px;
                    text-align: left;
                }
            `}</style>





        </div>
      </>
      
    );
}
