
// IMAGES
const splash0 =  '/static/images/splash0.png';
const logo0 =  '/static/images/logo0.png';
const logo1 =  '/static/images/logo1.png';
const logo2 =  '/static/images/logo2.png';
const logo3 =  '/static/images/logo3.png';
const logo4 =  '/static/images/logo4.png';
const back_layer_001 =  '/static/images/back_layer_001.png';
const back_layer_002 =  '/static/images/back_layer_002.png';
const back_layer_003 =  '/static/images/back_layer_003.png';
const badge_normal_001 =  '/static/images/badge_normal_001.png';
const badge_normal_002 =  '/static/images/badge_normal_002.png';
const badge_normal_003 =  '/static/images/badge_normal_003.png';
const badge_normal_004 =  '/static/images/badge_normal_004.png';
const badge_normal_005 =  '/static/images/badge_normal_005.png';
const badge_normal_006 =  '/static/images/badge_normal_006.png';
const badge_normal_007 =  '/static/images/badge_normal_007.png';
const badge_round001 =  '/static/images/badge_round001.png';
const badge_round002 =  '/static/images/badge_round002.png';
const badge_round003 =  '/static/images/badge_round003.png';
const badge_round004 =  '/static/images/badge_round004.png';
const badge_round005 =  '/static/images/badge_round005.png';
const badge_round006 =  '/static/images/badge_round006.png';
const badge_round007 =  '/static/images/badge_round007.png';

const _010_1 =  '/static/icons/010/1.png'
const _010_2 =  '/static/icons/010/2.png'
const _010_3 =  '/static/icons/010/3.png'
const _010_4 =  '/static/icons/010/4.png'
const _010_5 =  '/static/icons/010/5.png'
const _010_6 =  '/static/icons/010/6.png'
const _010_7 =  '/static/icons/010/7.png'
const _010_8 =  '/static/icons/010/8.png'

const _020_1 =  '/static/icons/020/1.png'
const _020_2 =  '/static/icons/020/2.png'
const _020_3 =  '/static/icons/020/3.png'
const _020_4 =  '/static/icons/020/4.png'
const _020_5 =  '/static/icons/020/5.png'
const _020_6 =  '/static/icons/020/6.png'
const _020_7 =  '/static/icons/020/7.png'
const _020_8 =  '/static/icons/020/8.png'
const _020_9 =  '/static/icons/020/9.png'
const _020_10 =  '/static/icons/020/10.png'

const _021_1 =  '/static/icons/021/1.png'
const _021_2 =  '/static/icons/021/2.png'
const _021_3 =  '/static/icons/021/3.png'
const _021_4 =  '/static/icons/021/4.png'
const _021_5 =  '/static/icons/021/5.png'
const _021_6 =  '/static/icons/021/6.png'
const _021_7 =  '/static/icons/021/7.png'
const _021_8 =  '/static/icons/021/8.png'
const _021_9 =  '/static/icons/021/9.png'
const _021_10 =  '/static/icons/021/10.png'

const _030_1 =  '/static/icons/030/1.png'
const _030_2 =  '/static/icons/030/2.png'
const _030_3 =  '/static/icons/030/3.png'
const _030_4 =  '/static/icons/030/4.png'
const _030_5 =  '/static/icons/030/5.png'
const _030_6 =  '/static/icons/030/6.png'
const _030_7 =  '/static/icons/030/7.png'
const _030_8 =  '/static/icons/030/8.png'

const _040_1 =  '/static/icons/040/1.png'
const _040_2 =  '/static/icons/040/2.png'
const _040_3 =  '/static/icons/040/3.png'
const _040_4 =  '/static/icons/040/4.png'

const _000_1 = '/static/icons/000/icon_arrow.png'
const _000_2 = '/static/icons/000/icon_cancel.png'
const _000_3 = '/static/icons/000/icon_magnifier.png'
const _000_4 = '/static/icons/000/icon_more.png'

const _tabA1 =  '/static/icons/022/tab01.png'
const _tabA2 =  '/static/icons/022/tab02.png'
const _tabA3 =  '/static/icons/022/tab03.png'
const _tabA4 =  '/static/icons/022/tab04.png'
const _tabA5 =  '/static/icons/022/tab05.png'

const _tabB001 = '/static/icons/tab/tab_feed0.png'
const _tabB002 = '/static/icons/tab/tab_region0.png'
const _tabB003 = '/static/icons/tab/tab_sale0.png'
const _tabB004 = '/static/icons/tab/tab_chat0.png'
const _tabB005 = '/static/icons/tab/tab_prof0.png'

const _tabB011 = '/static/icons/tab/tab_feed1.png'
const _tabB012 = '/static/icons/tab/tab_region1.png'
const _tabB013 = '/static/icons/tab/tab_sale1.png'
const _tabB014 = '/static/icons/tab/tab_chat1.png'
const _tabB015 = '/static/icons/tab/tab_prof1.png'


const _tabC001 = '/static/images/ssokdak/Tab/Property 1=Home, Active=no.svg';
const _tabC011 = '/static/images/ssokdak/Tab/Property 1=Home, Active=yes.svg';
const _tabC002 = '/static/images/ssokdak/Tab/Property 1=Diary, Active=no.svg';
const _tabC012 = '/static/images/ssokdak/Tab/Property 1=Diary, Active=yes.svg';
const _tabC003 = '/static/images/ssokdak/Tab/Property 1=Report, Active=no.svg';
const _tabC013 = '/static/images/ssokdak/Tab/Property 1=Report, Active=yes.svg';
const _tabC004 = '/static/images/ssokdak/Tab/Property 1=Community, Active=no.svg';
const _tabC014 = '/static/images/ssokdak/Tab/Property 1=Community, Active=yes.svg';
const _tabC005 = '/static/images/ssokdak/Tab/Property 1=My page, Active=no.svg';
const _tabC015 = '/static/images/ssokdak/Tab/Property 1=My page, Active=yes.svg';


const EmotionsCard01 = '/static/images/ssokdak/EmotionsCard/State=Angry.svg';
const EmotionsCard02 = '/static/images/ssokdak/EmotionsCard/State=Annoyed.svg';
const EmotionsCard03 = '/static/images/ssokdak/EmotionsCard/State=Anxious.svg';
const EmotionsCard04 = '/static/images/ssokdak/EmotionsCard/State=Bored.svg';
const EmotionsCard05 = '/static/images/ssokdak/EmotionsCard/State=Comfy.svg';
const EmotionsCard06 = '/static/images/ssokdak/EmotionsCard/State=Depress.svg';
const EmotionsCard07 = '/static/images/ssokdak/EmotionsCard/State=Excited.svg';
const EmotionsCard08 = '/static/images/ssokdak/EmotionsCard/State=Happy.svg';
const EmotionsCard09 = '/static/images/ssokdak/EmotionsCard/State=Lonely.svg';
const EmotionsCard10 = '/static/images/ssokdak/EmotionsCard/State=Love.svg';
const EmotionsCard11 = '/static/images/ssokdak/EmotionsCard/State=Sad.svg';
const EmotionsCard12 = '/static/images/ssokdak/EmotionsCard/State=Scared.svg';
const EmotionsCard13 = '/static/images/ssokdak/EmotionsCard/State=Soso.svg';
const EmotionsCard14 = '/static/images/ssokdak/EmotionsCard/State=Surprised.svg';
const EmotionsCard15 = '/static/images/ssokdak/EmotionsCard/State=Tired.svg';




export default {
    splash0,
    logo0,
    logo1,
    logo2,
    logo3,
    logo4,
    back_layer_001,
    back_layer_002,
    back_layer_003,
    badge_normal_001,
    badge_normal_002,
    badge_normal_003,
    badge_normal_004,
    badge_normal_006,
    badge_normal_005,
    badge_normal_007,
    badge_round001,
    badge_round002,
    badge_round003,
    badge_round004,
    badge_round005,
    badge_round006,
    badge_round007,
    _010_1,
    _010_2,
    _010_3,
    _010_4,
    _010_5,
    _010_6,
    _010_7,
    _010_8,
    _020_1,
    _020_2,
    _020_3,
    _020_4,
    _020_5,
    _020_6,
    _020_7,
    _020_8,
    _020_9,
    _020_10,
    _021_1,
    _021_2,
    _021_3,
    _021_4,
    _021_5,
    _021_6,
    _021_7,
    _021_8,
    _021_9,
    _021_10,
    _030_1,
    _030_2,
    _030_3,
    _030_4,
    _030_5,
    _030_6,
    _030_7,
    _030_8,
    _040_1,
    _040_2,
    _040_3,
    _040_4,
    
    _000_1,
    _000_2,
    _000_3,
    _000_4,

    _tabA1,
    _tabA2,
    _tabA3,
    _tabA4,
    _tabA5,

    _tabB001,
    _tabB002,
    _tabB003,
    _tabB004,
    _tabB005,
    _tabB011,
    _tabB012,
    _tabB013,
    _tabB014,
    _tabB015,

    _tabC001,
_tabC011,
_tabC002,
_tabC012,
_tabC003,
_tabC013,
_tabC004,
_tabC014,
_tabC005,
_tabC015,

EmotionsCard01,
EmotionsCard02,
EmotionsCard03,
EmotionsCard04,
EmotionsCard05,
EmotionsCard06,
EmotionsCard07,
EmotionsCard08,
EmotionsCard09,
EmotionsCard10,
EmotionsCard11,
EmotionsCard12,
EmotionsCard13,
EmotionsCard14,
EmotionsCard15,


}