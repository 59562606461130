import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";
import TabBottom from "../../TabBottom/Tab";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const pageCode1 =   'page005/2/setting-notification';
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);

    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    const [boolPopup1, setBoolPopup1] =  useState(false);
    
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    // console.log("selectedPageObject", selectedPageObject)

    const menuArr1 = [
      { label : "마이페이지",  code : 'page005/2/setting-mypage' },
      { label : "알람", code : 'page005/2/setting-notification', },
      { label : "수신동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "자주묻는질문", code : 'page005/2/setting-faq', },
      { label : "1:1문의", code : 'page005/2/setting-questions', },
      { label : "공지사항", code : 'page005/2/setting-notice', },
      { label : "튜토리얼", code : 'page005/2/tutorial', },
      { label : "이용약관", code : 'page005/2/term', },
      { label : "개인정보처리방침",  code : 'page005/2/term', },

    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',

    // 071/btn_toggle0.png
    // 071/btn_toggle1.png

    return (
      <>
        <div className={`aa01_c`} style={{ background : ` #F8F8F8`}}> 
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className=" " style={{ height : `95px`}}></div>
          <div className="menu_container0 menu_container1">

          {
                true && 
                // boolMoreInfoOpen1 == true &&
                <div className="term_textarea0 term_textarea1">
                {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                </div>
                }
                <div className="hl"></div>
                <div className="textinput_form_check_row0">
                  <div className="col100">
                    <img 
                    src={`/static/icons/000/icon_check0.png`}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                      }}
                    />
                    <div 
                      className="text1 "
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >(필수) 개인정보 수집 및 이용동의
                    </div>
                    
                    <div className="more_wrapper0 more_wrapper1"
                      style={{
                        color : boolMoreInfoOpen2 == true ? `#181818` : `#545963`
                      }}
                    >
                      더보기
                      <img 
                      src={
                        boolMoreInfoOpen2 == true ?
                        `/static/icons/000/icon_more_arrow1.png`
                        :
                        `/static/icons/000/icon_more_arrow0.png`
                      }
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolMoreInfoOpen2(!boolMoreInfoOpen2)
                        }}
                      />
                    </div>
                      
                  </div>
  
                </div>
  
                {
                true && 
                  // boolMoreInfoOpen2 == true &&
                  <div className="term_textarea0 term_textarea1">
                  {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                  </div>
                  }
          {
            menuArr1.map((mapItem, mapIndex) => {
              return (
                <div className={"map_item0 map_item" + (mapIndex + 1)}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setScreenMode1(mapItem.code);
                  }}
                >
                  {mapItem.label}
                <img src={`/static/icons/071/btn_toggle0.png`} style={{width : `60px`, height: `30px` }}/>
                </div>
              )
            })
          }

          </div>
          <div className=" " style={{ marginBottom: `200px`, height : `200px`}}></div>
          <TabBottom/>
        </div>
        <style>{`
          .menu_container0 {
            width: calc(100vw - 40px);
            height: 540px;

            background: #FFFFFF;
            border-radius: 10px;

            display: flex;
            flex-direction: column;

            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: auto;

          }
          .map_item0 {

            width: calc(100% - 40px);
            height: 60px;


            color: #FF7A7A;

            border-top: 1px solid rgba(46, 52, 66, 0.05);

            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 60px;

            margin-left: auto;
            margin-right: auto;
            position: relative;

            display: flex;
            align-items: center;
          }
          .map_item1 {
            border-top: none;
          }
          .map_item0 > img {
            width : 8px;
            height: 8px;
            object-fit : contain;
            margin-left: auto;
          }
          
        `}</style>
      </>
      
    );
}
