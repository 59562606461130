import React, { Component, useEffect, useState, useContext, useRef } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../../redux/authRedux/auth.reducer";
import { setAuth } from "../../../redux/authRedux/auth.actions";
import { category_KEY } from "../../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../../redux/filterRedux/filter.actions";

import uuid from 'react-uuid';
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import TabBottom from "../../TabBottom/Tab";

import DropDownClickable from "../../../components/DropdownClickable";

import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';
import "./aa01.css";

import STT from "./STT";

const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    // [
    //   { list: 'ordered' },
    //   { list: 'bullet' },
    //   { indent: '-1' },
    //   { indent: '+1' },
    // ],
    // // ['link', 'image', 'video'],
    // // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]

const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]


const pageCode1 =   'page002/1/mind-diary-checkin/stt';

// <div className={``}></div>
export default function ThisComponent(props) {
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);
    const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
    const [objectArrayFeedData1, setObjectArrayFeedData1] = useState([]);

    const [selectedFilter1, setSelectedFilter1] = useState(
      {
        label : "전체", 
        index: 1,
      },);
    const [boolTapped1, setBoolTapped1] = useState(false);

    const [currentCardsData, setCurrentCardsData] = useState([]);
    const [data, setData] = useState('No result');
    const [qrMode, setQrMode] = useState("false");
    const [url, setUrl] = useState("");

  const [arraySelected, setArraySelected] = useState([]);

  const [clickedIndex, setClickedIndex] = useState(0); // 임시설정값
  const [pageStep, setPageStep] = useState(6);

  const [textValue1, setTextValue1] = useState([]);
  const [textValue2, setTextValue2] = useState([]);
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const [boolPopup1, setBoolPopup1] = useState(false);
  const [boolPopup2, setBoolPopup2] = useState(false);
  const [boolPopup3, setBoolPopup3] = useState(false);

  const [quillValue1, setQuillValue1] = useState();

  const [sttType, setSttType] = useState("text1");
  const [sttAction, setSttAction] = useState("stop");

  const [textTranscript, setTextTranscript] = useState("");



  const resizeTextArea = () => {
    if (textAreaRef1 && textAreaRef1.current && textAreaRef1.current.style) {

      textAreaRef1.current.style.height = "auto";
      textAreaRef1.current.style.height = textAreaRef1.current.scrollHeight + "px";
  
    }
    if (textAreaRef2 && textAreaRef2.current && textAreaRef2.current.style) {

      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + "px";
    }
  };

    const downloadQRCode = (e) => {
      e.preventDefault();
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    const qrcode = (
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={50}
        bgColor={"#fff"}
        level={"H"}
      />
    );

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {

      setTextValue1("");
      setTextValue2("");
      setBoolPopup1(false);
      setQuillValue1();

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore")
      if (true && true) {
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        Firebase.database().ref()
        .child("behaviors/posts")
        // .orderByChild("random_id16")
        // .equalTo(currentCardItem)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/posts --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setObjectArrayCardData1(Object.values(data))

              const indexArr1  = Object.values(data);
              setCurrentCardsData(indexArr1);
            }
          }
        });


        Firebase.database().ref()
        .child("behaviors/feeds")
        // .orderByChild("random_id16")
        // .equalTo(currentCardItem)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/feeds --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setObjectArrayFeedData1(Object.values(data))

              const indexArr1  = Object.values(data);
              setCurrentCardsData(indexArr1);
            }
          }
        });

        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        
                        setUrl("everappy@nickname:" + data[keyName].nickname)

                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                     
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });



      }

    }, [useSelector(state => state)])



  
    function handleCreate() {


      console.log("handleCreate - 0", )

      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore")

      setBoolPopup2(true);
      
      fireDb.database().ref().child("behaviors/diary-checkins").push(
        {
          reason : textValue1,
          did : textValue2,
          contents : quillValue1 ? quillValue1 : '',
          pre_contents: quillValue1 ? quillValue1.replace(/<[^>]+>/g, '') : '',
          username : authStoreUserName,
          emotionKrName : emoCardArray[clickedIndex].krName,
          emotionObject : emoCardArray[clickedIndex],
          created_at : moment().format("YYYY-MM-DD HH:mm:ss"),
          created_day : moment().format("YYYY-MM-DD"),
          created_time : moment().format('hh:mm A')
        }
        , (err) => {
        if (err) {
          // toast.error(err);
          console.log(err);
        } else {
          // toast.success("Signup  Successfully");

          // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

          setTimeout(()=> {
              setBoolPopup2(false);
              setTextValue1("")
              setTextValue2("")
              setPageStep(1);
              setQuillValue1();

              const pageCodeGo1 =  'page002/0/mind-diary';
              setScreenMode1(pageCodeGo1)
          }, 400)

          // alert("")
          
        }
      });

    
    }


    useEffect(()=> {

      if (pageStep === 6) {
        resizeTextArea();
      }

    }, [pageStep])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }
    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`}> 
            {
                pageStep === 1 &&
                (
                
                <div className="checkInPageWrapper">

                <div className="checkInTopWrapper" >
                    <div className="checkInTopTopBar">
                        <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setScreenMode1( 'page002/0/mind-diary')
                            }}
                        />
                        <img className="checkInTopTopIcon2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                        
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            setScreenMode1( 'page002/0/mind-diary')
                        }}
                        />
                    </div>
                  </div>
        
                  <div className="checkInLabelWrapper">
                    🔑 <br/>
                    오늘의 기분을<br/>
                    Check-In 해주세요 
                  </div>
                  <div className="checkInLabelEmoWrapper">
                    <div className="wrapDiv1">
                    {
                        Array.from(emoCardArray)
                        // .sort(function(a, b)  {
                        //   return a.index - b.index;
                        // })
                        .map((mapItem, mapIndex)=> {
                          return (
                            <div className="cardItem " 
                            >
                              {
                                 (mapIndex === clickedIndex )&&

                                 <div className="selectImg0">
                                 <img className="closeBtn" 
                                 src={`/static/images/ssokdak/Tab2/editSave1.svg`} alt=""
                                 style={{ marginLeft : 'auto'}}
                                 onClick={e=> {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     setScreenMode1( 'page002/0/mind-diary')
                                 }}
                                 />
                               </div>
                              }
                              <div className="cardImg0">
                                <img  src={mapItem.iconCardPathName} alt="" 
                                  style={{
                                    border : (mapIndex === clickedIndex )?  `1px solid #FF7A7A` : `1px solid #ffffff`,
                                    borderRadius : `10px`,
                                  }}
                                  onClick={e=> {
                                    setClickedIndex(mapIndex);
                                    setPageStep(2);
                                }}/>
                              </div>
                            </div>)
                        })
                      }
                    </div>
                   
                  </div>
                  </div>

                )
            }

            {
                pageStep >= 2 &&
                (
                    <div className="checkInPageWrapper">

                        <div className="checkInTopWrapper" >
                            <div className="checkInTopTopBar">
                                <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
                                    onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // setScreenMode1( 'page002/0/mind-diary')
                                        setPageStep(1);
                                    }}
                                />
                                <div className="checkInTopTopLabel1">
                                    {moment().format("YYYY년 MM월 DD일")}
                                </div>
                                <img className="checkInTopTopIcon2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                                
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setScreenMode1( 'page002/0/mind-diary')
                                }}
                                />
                                
                            </div>
                        </div>
                        <div className="checkInEmoPrevWrapper"
                          style={{
                            height : (pageStep >= 3 && pageStep <= 5) ? `0px` :`192px`
                          }}
                        >
                            {
                                clickedIndex > -1 &&
                                emoCardArray[clickedIndex] &&
                                <div className="iconWrapper1">
                                  <img className="icon1" src={emoCardArray[clickedIndex].iconOnlyPathName} alt=""/>
                                  <div className="row0 row1">
                                    <div className="iconKrName1">{`#`}{emoCardArray[clickedIndex].krName}</div>
                                    <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/editPencil.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setBoolPopup1(true);
                                        }}
                                    />
                                  </div>
                                </div>
                            }
                            <div></div>
                        </div>
                        <div className="checkInEmoWriteWrapper" 
                        
                        style={{
                          height : (pageStep >= 3 && pageStep <= 5) ? `calc(100vh - 50px)` :`calc(100vh - 250px)`,
                          borderRadius : (pageStep >= 3 && pageStep <= 5) ? `0px 0px 0px 0px` : `30px 30px 0px 0px`,
                        }}
                        >

                            <div className="editButtonRow">
                              {/* {
                                pageStep === 2 && 
                                <div className="editButtonDiv1">

                                    <img className="icon0 icon1" src={`/static/images/ssokdak/Tab2/edit1.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />
                                    <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/edit2.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />

                                    <img className="icon0 icon3" src={`/static/images/ssokdak/Tab2/edit3.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />
                                </div>
                              } */}
                                <div className="editStepDiv1">
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 2 || pageStep === 3) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 4 || pageStep === 5) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 6 || pageStep === 7) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                </div>
                            </div>
                            {
                              pageStep === 2 &&

                              <div className="editContentRow" 
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setPageStep(pageStep + 1);
                              }}
                              >
                              <div className="editRowStep1Wrapper">
                              {`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                               </div> 
                           </div>
                            }
                            {
                              pageStep === 3 &&
                              <div className="editContentRow">
                                <div className="editRowStep2Wrapper">
                                  <textarea 
                                    className="input0"
                                    value={textValue1}
                                    onChange={e=> {
                                      setTextValue1(e.target.value);
                                    }}
                                    placeholder={`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                                  />
                                </div>
                              </div>
                            }
                            {
                              pageStep === 4 &&

                              <div className="editContentRow" 
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setPageStep(pageStep + 1);
                              }}
                              >
                              <div className="editRowStep1Wrapper">
                              {`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                               </div> 
                           </div>
                            }
                            {
                              pageStep === 5 &&
                              <div className="editContentRow">
                                <div className="editRowStep2Wrapper">
                                  <textarea 
                                    className="input0"
                                    value={textValue2}
                                    onChange={e=> {
                                      setTextValue2(e.target.value);
                                    }}
                                    placeholder={`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                                  />
                                </div>
                              </div>
                            }

                            { 
                              pageStep === 6 &&
                              <div className="editContentRow">
                                <div className="editRowStep3Wrapper">

                                    <STT 
                                    type={sttType}
                                    action={sttAction}
                                    
                                    callBack={e=> {
                                        console.log("stt::: e", e)

                                        setTextTranscript(e.transcript);
                                        // if (e.type === "text1") {
                                        //     setTextValue1(textValue1 + e.transcript)
                                        // }
                                        // if (e.type === "text2") {
                                        //     setTextValue2(textValue2 + e.transcript)
                                        // }
                                        // if (e.type === "text3") {
                                        //     setQuillValue1(quillValue1 + e.transcript)
                                        // }
                                    }}/>

                                  <div className="text0 text1">
                                  <textarea 
                                    ref={textAreaRef1}
                                    className="input0"
                                    value={textValue1}
                                    onChange={e=> {
                                      setTextValue1(e.target.value);
                                    }}
                                    onFocus={e=> {
                                        setSttType("text1")
                                        // setSttAction("start")
                                    }}
                                    onBlur={e=> {

                                        // setSttType("")
                                        // setSttAction("stop")
                                    }}
                                    placeholder={`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                                    // disabled={true}
                                  />
                                  </div>
                                  <div className="text0 text2">
                                  <textarea 
                                    ref={textAreaRef2}
                                    className="input0 input1"
                                    value={textValue2}
                                    onChange={e=> {
                                      setTextValue2(e.target.value);
                                    }}
                                    onFocus={e=> {
                                        setSttType("text2")
                                        // setSttAction("start")
                                    }}
                                    onBlur={e=> {

                                        // setSttType("")
                                        // setSttAction("stop")
                                    }}
                                    placeholder= {`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                                    // disabled={true}
                                  /></div>

                                  <QuillWrapper
                                    style={{
                                      maxWidth : `calc(100vw - 80px)`,
                                      width :`calc(100vw - 80px)`,
                                      minHeight: `400px`,
                                      maxHeight: `calc(100vh - 200px)`,
                                      marginBottom : `80px`,
                                      color : `#000`,
                                    }}
                                    theme={'snow'}
                                    id={'description'}
                                    placeholder={'추가설명을 입력해주세요'}
                                    value={quillValue1}
                                    modules={modules}
                                    formats={formats}
                                    onChange={(event) => setQuillValue1(event)}
                                    
                                    onFocus={e=> {
                                        setSttType("text3")
                                        // setSttAction("start")
                                    }}
                                    onBlur={e=> {

                                        // setSttType("")
                                        // setSttAction("stop")
                                    }}
                                  /> 

                                  
                                </div>
                              </div>
                            }
                        {
                          pageStep > 2 &&
                          
                          <div className="editButtonRow">
                            {
                              pageStep === 6 && <div className="editButtonDiv1">

                              {/* <img className="icon0 icon1" src={`/static/images/ssokdak/Tab2/edit1.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              />
                              <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/edit2.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              />

                              <img className="icon0 icon3" src={`/static/images/ssokdak/Tab2/edit3.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              /> */}
                          </div>
                          }

                            <div className="editButtonDiv2">
                                    {
                                      (pageStep === 6 || 
                                      pageStep === 7) ?

                                    <div className="text0 text1" 
                                    onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // setScreenMode1( 'page002/0/mind-diary')
                                        // setPageStep(pageStep + 1);
                                        // if (textValue1 === "") {
                                        //   return;
                                        // }
                                        // if (textValue2 === "") {
                                        //   return;
                                        // }
                                        // if (quillValue1 == null) {
                                        //   return;
                                        // }
                                        handleCreate();
                                        
                                    }}
                                  >완료</div>
                                  :
                                  <>

                                    <div className="text0 text1" 
                                      onClick={e=> {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          // setScreenMode1( 'page002/0/mind-diary')
                                          setPageStep(pageStep + 1);
                                      }}
                                    >
                                      다음
                                    </div>

                                  </>
                                    }
                                </div>
                            {/*  */}
                        </div>
                        }
                        <div className="fabStt0 fabStt1">
                        <img className="image0 image1" 
                          src={`/static/images/ssokdak/Tab2/sttRecord01.png`} 
                          alt=""
                          onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation(); 

                              if (sttAction === "start") {

                                setSttAction("stop");
                                if (sttType === "text1") { 
                                  setTextValue1(textValue1 + textTranscript)
                                  setSttType("");
                                  setTextTranscript("")
                                }
                                if (sttType === "text2") { 
                                  setTextValue2(textValue2 + textTranscript)
                                  setSttType("");
                                  setTextTranscript("")
                                }
                                if (sttType === "text3") { 
                                  setQuillValue1(quillValue1 ? quillValue1 + textTranscript : textTranscript)
                                  setSttType("");
                                  setTextTranscript("")
                                }

                              } else {

                                setSttAction("start");
                              }
                          }}
                      />

                            <div className="text0 text1" style={{ marginLeft : 8}}>음성인식 {sttAction === "start" ? "ON" : "OFF"}</div>
                        </div>
                        </div>

                        {
                          boolPopup2 === true &&
                          <div 
                          className="bottomSheet0 bottomSheetForEmotionCard" 
                          style={{
                            height: boolPopup2 === false ? 0 : `100vh`,
                            transition: `all 0.5s ease-out`
                          }}
                          > 
                            <div className="blurView"></div>
                          </div>
                        }


                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: boolPopup1 === false ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          <div className="contentView"> 
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  // setScreenMode1( 'page002/0/mind-diary')
                                  setBoolPopup1(false)

                              }}
                              />
                            </div>
                            {
                              boolPopup1 === true &&
                              
                            <div className="bottomSheetEmoWrapper">
                            <div className="wrapDiv1">
                            {
                                Array.from(emoCardArray)
                                .sort(function(a, b)  {
                                  return a.index - b.index;
                                })
                                .map((mapItem, mapIndex)=> {
                                  return (
                                    <div className="cardItem " 
                                    >
                                      {
                                         (mapIndex === clickedIndex )&&

                                         <div className="selectImg0">
                                         <img className="closeBtn" 
                                         src={`/static/images/ssokdak/Tab2/editSave1.svg`} alt=""
                                         style={{ marginLeft : 'auto'}}
                                         onClick={e=> {
                                             e.preventDefault();
                                             e.stopPropagation();
                                            //  setScreenMode1( 'page002/0/mind-diary')
                                         }}
                                         />
                                       </div>
                                      }
                                      <div className="cardImg0">
                                        <img  src={mapItem.iconCardPathName} alt="" 
                                          style={{
                                            border : (mapIndex === clickedIndex )?  `1px solid #FF7A7A` : `1px solid #ffffff`,
                                            borderRadius : `10px`,
                                          }}
                                          onClick={e=> {
                                            setClickedIndex(mapIndex);
                                            setPageStep(2);
                                        }}/>
                                      </div>
                                    </div>)
                                })
                              }
                            </div>
                          </div>
                            }
                          </div>
                        </div>



                    </div>

                )
            }
            

          {/* <div className=" " style={{ marginBottom: `200px`, height : `40vh`}}></div> */}
        </div>
        {/* <TabBottom/> */}
        </>
      }
      </>
      
    );
}
