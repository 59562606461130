import React, { Component, useEffect, useState, useContext, useRef } from "react";
import parse from "html-react-parser";
import "./index.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";

import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";

const pageCode1 =  'page000/0/splash';
export default function ThisComponent() {
  const [objectPopupData1, setObjectPopupData1] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [imageUrl, setImageUrl] = useState('/static/images/ssokdak/Splash/splashMove001.svg');
    const [animationMode1, setAnimationMode1] = useState('center');

    const vidRef = useRef(null);
    const handlePlayVideo = () => {
      vidRef.current.play();
    }

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {

      const authStoreUserName = window.localStorage.getItem("authStoreUserName");
      const authStorePassword = window.localStorage.getItem("authStorePassword");

      if (authStoreUserName && authStorePassword ) {
        handleLogin(authStoreUserName, authStorePassword);
      }
    }, [])


    function handleLogin(paramUsername, paramPassword) {

      // var email =  paramUsername
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }

      console.log("paramUsername - 0", paramUsername)

      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo( paramUsername)
            .once("value", (snapshot) => {

                  console.log("paramUsername - 1", snapshot)

                if (snapshot.val()) {

                  console.log("paramUsername - 2", snapshot.val())
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    console.log("paramUsername - 3", keyName)

                    if (keyName) {

                        console.log("paramUsername - 4", data[keyName])

                        
                        Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"auto_login" : moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                        if (data[keyName].password == paramPassword) {
                          console.log("paramUsername - 5",data[keyName].password )
                            //비밀번호와 일치하는지 여부
                            // toast.success("인증되었습니다. ");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}>환영합니다.</div>)
                           

                            // console.log("data[keyName]", data[keyName])

                            window.localStorage.setItem("authStoreKeyName",  keyName)
                            window.localStorage.setItem("authStoreKeyName",  keyName);
                            
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                            // window.localStorage.setItem()
                            //지금 수정중
                            setTimeout(()=> {
                              setScreenMode1( 'page001/0/home');
                            }, 1000 )

                        } else {
                          // console.log("paramUsername - 6", paramPassword )
                            // toast.error("인증실패하였습니다. 비밀번호를 확인해주세요.");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다. 비밀번호를 확인해주세요.</div>)
                        }
                    } else {
                        //조건 분기 필요
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                    }

                }  else {
                  setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                  // 테스트 코드
                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : paramUsername, 
                  //     email : paramUsername, 
                  //     password : paramPassword,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    }



    useEffect(()=> {
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }


    return (
      <>
      {objectPopupData1}
        <div className={`splash_screen_c`} 
          style={{
				    transitionProperty: `all`,
				    // transitionDuration: `4s`,
            transitionDelay : `1s`,
          }}
        >
        {/* {
          animationMode1 === 'center' &&
          <img 
            src={imageUrl} 
            className={``} 
            style={{ maxWidth : `200px`}} 
            alt=""
            />
        }
        {
          animationMode1 === 'top/door' &&
          <img 
            src={imageUrl} 
            className={``} 
            style={{ maxWidth : `200px`}} 
            alt=""
            />
        } */}

        <img 
            src={"/static/images/ssokdak/Splash/splash001.gif"} 
            className={`splash_gif`} 
            alt=""
            />


        {/* <video ref={vidRef} controls={false} autoPlay={true} loop={true} 
        style={{ width : `calc(100vw - 40px)`, marginLeft :'auto', marginRight : 'auto'}}
        >
          <source src="/static/images/ssokdak/Splash/splash001.mov" />
        </video> */}

        {viewScreenMode.mode === pageCode1 && <>

          <div className="start_button0 start_button1"
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
              if (true) {
                setScreenMode1('page000/0/first')
              } else {
                setScreenMode1('page001/0/home')
              }
          }}
        >
          이메일로 가입하기
        </div>
        <div className="start_button0 start_button2"
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            setScreenMode1('page000/0/login')
          }}
        >
          
          이미 계정이 있으신가요?
        </div>
        </>}


          
        </div>
      </>
      
    );
}
