import {SET_category} from './category.actionTypes'


export const category_KEY   = 'categoryStore'

let initialState = {
    mode: ''
}

let categoryReducer = (state = initialState, action) =>{
    let {type, payload} = action;
    console.log("[categoryReducer] ::: ", payload);

    switch(type){
        case SET_category:
            return{
                mode : payload
            }
        default: return {
            mode: initialState.mode
        }
    }

}

export {categoryReducer}