import {SET_filter} from './filter.actionTypes'


export const filter_KEY   = 'filterStore'

let initialState = {
    mode: ''
}

let filterReducer = (state = initialState, action) =>{
    let {type, payload} = action;
    console.log("[filterReducer] ::: ", payload);

    switch(type){
        case SET_filter:
            return {
                mode : payload
            }
        default: return {
            mode: initialState.mode
        }
    }

}

export {filterReducer}