import {SET_AUTH} from './auth.actionTypes'


let setAuth = (param) =>{
    return{
        type: SET_AUTH,
        payload: param
    }
}

export {setAuth}