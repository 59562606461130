import {SET_AUTH} from './auth.actionTypes'


export const AUTH_KEY   = 'authStore'

let initialState = {
    username : '',
    password : '',
    token : '',
    more : {}
}

let authReducer = (state = initialState, action) =>{
    let {type, payload} = action;
    console.log("[authReducer] ::: ", payload);

    switch(type){
        case SET_AUTH:
            return{
                username:  payload.username,
                password:  payload.password,
                token:  payload.token,
                more:  payload.more,

            }
        default: return {
            username:  initialState.username,
            password:  initialState.password,
            token:  initialState.token,
            more:  initialState.more,
        }
    }

}

export {authReducer}