import React, { Component, useEffect, useState, useContext } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a01.css";
import "./a02.css";
import "./a03.css";
import "./a04.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../redux/authRedux/auth.reducer";
import { setAuth } from "../../redux/authRedux/auth.actions";
import { category_KEY } from "../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../redux/filterRedux/filter.actions";

import uuid from 'react-uuid';
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import TabBottom from "../TabBottom/Tab";

import DropDownClickable from "../../components/DropdownClickable";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]


const pageCode1 =   'page001/0/home';

// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [selectedFilter1, setSelectedFilter1] = useState(
      {
        label : "전체", 
        index: 1,
      },);
    const [selectedFilter2, setSelectedFilter2] = useState(null);
    const [currentCardsData, setCurrentCardsData] = useState([]);
    const [data, setData] = useState('No result');
    const [qrMode, setQrMode] = useState("false");
    const [url, setUrl] = useState("");
    const [arraySelected, setArraySelected] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [objectUserData, setObjectUserData] = useState(null); 


    const downloadQRCode = (e) => {
      e.preventDefault();
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }
 
    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore") 


      Firebase.database().ref()
          .child("accounts")
          .orderByChild("username")
          .equalTo(authStoreUserName)
          .once("value", (snapshot) => {
              if (snapshot.val()) {
                  const data = snapshot.val();

                  // console.log("[테스트] >> snapshot.val()", )
                  // console.log("[테스트] >> data", data)
                  const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0] 
                  if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                      setObjectUserData(data[keyName]);
                        props && props.handleUser(keyName, data[keyName]); 
                  } else {

                  } 
              }  else {
                  // alert("존재하지 않는 회원입니다.");
              }
          });


    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    // console.log("selectedPageObject", selectedPageObject)
    // console.log("authStore1", authStore1)

    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`}> 
          <div className="welcomeWrapper">
            <div className="welcomeTopBar">
              <div className="welcomeNotificationMenu">
                {/* <img className="image0 image1" src={`/static/images/ssokdak/Tab1/welcomeBell1.svg`} alt=""/> */}
              </div>
            </div>
            <div className="welcomeTextWrapper">
              <div className="text1 ">반가워요 {objectUserData && objectUserData.nickname ?  objectUserData.nickname : "쏙닥러"}님 :)</div>
            </div>
          </div>
          <div className="bannerWrapper">
            <div className="cardWrapper">
              {
                Array.from([
                  {
                    tags : [{ title : "커뮤니티" , color :"#FF7A7A" }],
                    title: "나와 통하는 대화<br/>친구가 필요한가요?",
                    path : `/static/images/ssokdak/Tab1/welcomeDummy1.png`,
                    link : 'page004/998/chat-list',
                  },
                  {
                    tags : [{ title : "마음 다이어리" , color : "#9F8FFF"}],
                    title: "오늘, 나의 마음은<br/>안녕한가요?",
                    path : `/static/images/ssokdak/Tab1/welcomeDummy0.png`,
                    link :  'page002/0/mind-diary',
                  },
                ]).map((mapItem, mapIndex)=> {
                  return (
                    <div className="cardItem " 
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        
                      window.localStorage.setItem("selectedTab4", 1);
                        setScreenMode1(mapItem.link);

                      }}
                    >
                      <div className="tagWrapper" >
                        {
                          Array.from(mapItem.tags).map((tagItem, tagIndex)=> {
                            return (
                              <div className="tag1" style={{ color : tagItem.color, border : `1px solid ${tagItem.color}` }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{tagItem.title}&nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="text1">{parse(mapItem.title)}</div>
                      <div className="image1">
                        <img  src={mapItem.path} alt=""/>
                      </div>
                    </div>)
                })
              }
              </div>
          </div>
          <div className="checkInWrapper">
            <div className="checkInTextWrapper">
              <div className="text1 " >오늘의 기분 체크인 🔑</div>
            </div>
            <div className="cardWrapper">
            {
                Array.from(emoCardArray).map((mapItem, mapIndex)=> {
                  return (
                    <div className="cardItem " onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();

                      setScreenMode1('page002/1/mind-diary-checkin');
                    }}>
                      <div className="image1">
                        <img  src={mapItem.iconCardPathName} alt=""/>
                      </div>
                    </div>)
                })
              }
            </div>
          </div>
          <div className="checkInBannerWrapper">

          <div className="cardWrapper">
              {
                Array.from(["",]).map((mapItem, mapIndex)=> {
                  return (
                    <div className="cardItem " 
                      onClick={e=> {

                      e.preventDefault();
                      e.stopPropagation();

                      window.localStorage.setItem("selectedTab4", 1);
                      setScreenMode1('page004/998/chat-list');
                      }}
                    >
                      <div className="text1">
                      체크인하고 잘 통하는<br/>대화친구들을 만나보세요!
                      </div>
                      <div className="image1">
                        <img  src={`/static/images/ssokdak/Tab1/welcomeDummy2.png`} alt=""/>
                      </div>
                    </div>)
                })
              }
              </div>

          </div>
          <div className="recommendFriendIntroWrapper">

          {/* <div className="textWrapper">
              <div className="text1 ">추천 대화친구</div>
            </div>
            <div className="cardWrapper">
            {
                Array.from([
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                  appImages.EmotionsCard01,
                ]).map((mapItem, mapIndex)=> {
                  return (
                    <div className="cardItem " 
                    onClick={e=> {

                    e.preventDefault();
                    e.stopPropagation();
                    window.localStorage.setItem("selectedTab4", 1);
                    setScreenMode1('page004/998/chat-list');
                    }}>
                      <div className="image1">
                        <img  src={`/static/images/ssokdak/Tab1/welcomeDummy3.png`} alt=""/>
                      </div>
                      <div className="text1">
                        쏙닥쏙닥
                      </div>
                    </div>)
                })
              }
            </div>             */}
          </div>

          <div className="mindReportReceiveWrapper">

          <div className="cardWrapper">
              {
                Array.from(["",]).map((mapItem, mapIndex)=> {
                  return (
                    <div className="cardItem "
                    onClick={e=> {

                      e.preventDefault();
                      e.stopPropagation();

                      setScreenMode1('page003/0/mind-analysis');
                    }}>
                      <div className="text1">
                      나의 마음분석 <br/>리포트가 도착했어요!
                      </div>
                      <div className="image1">
                        <img  src={`/static/images/ssokdak/Tab1/welcomeDummy4.png`} alt=""/>
                      </div>
                    </div>)
                })
              }
              </div>

          </div>


          <div className=" " style={{ marginBottom: `100px`, height : `20vh`}}></div>

        </div>
        <TabBottom/>
        </>
      }
      </>
      
    );
}
