import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";
import TabBottom from "../../TabBottom/Tab";

 
const pageCode1 =   'page005/0/settings';
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);

    const [boolPopup1, setBoolPopup1] =  useState(false);
    

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      if (viewScreenMode.mode !== pageCode1) {
        return;
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    // console.log("selectedPageObject", selectedPageObject)

    const menuArr1 = [
      // { label : `알림`, subLabel : '', code : ``, root : true, lastCategory : false, pageCode : "" , },
      // { label : `푸시알림 설정`, subLabel : '', code : ``, root : false, lastCategory : true, pageCode : "page005/2/push", },
      
      // { label : `고객센터`, subLabel : '', code : ``, root : true, lastCategory : false, pageCode : "",  },
      // { label : `공지사항`, subLabel : '', code : ``, root : false, lastCategory : false,pageCode : "",   },
      // { label : `고객센터`, subLabel : '', code : ``, root : false, lastCategory : true, pageCode : "",  },
      
      { label : `서비스`,  subLabel : '',code : ``, root : true, lastCategory : false, pageCode : "",  },
      { label : `개인정보 처리방침`, subLabel : '', code : ``, root : false,  lastCategory : false,pageCode : 'page005/2/term1',  },
      { label : `서비스 이용약관`, subLabel : '', code : ``, root : false, lastCategory : false, pageCode : 'page005/2/term2',  },
      { label : `앱 버전`, subLabel : `0.1`,  code : ``, root : false,  lastCategory : true,pageCode : "",  },

      // { label : ``, code : ``, root : true, },
      
      // { label : "알람", code : 'page005/2/setting-notification', },
      // { label : "수신동의", code : 'page005/2/setting-subscription-agreement', },

      // { label : "자주묻는질문", code : 'page005/2/setting-faq', },
      // { label : "1:1문의", code : 'page005/2/setting-questions', },
      // { label : "공지사항", code : 'page005/2/setting-notice', },
      // // { label : "튜토리얼", code : 'page005/2/tutorial', },

      // { label : "이용약관", code : 'page005/2/term', },
      // { label : "개인정보처리방침",  code : 'page005/2/term', },

    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',

    return (
      <>
        <div className={`aa01_c`}  > 
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className="menu_container0 menu_container1">
          {
            menuArr1.map((mapItem, mapIndex) => {

              if (mapItem.root === true) {
                return <div className="rootCategoryLabel">{mapItem.label} &nbsp; <div className="">{mapItem.subLabel}</div></div>
              }
              return (
                <>
                <div className={"map_item0 map_item" + (mapIndex + 1)}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    if (mapItem.pageCode != "") {
                      
                    setScreenMode1(mapItem.pageCode);
                    }
                  }}
                >
                  {mapItem.label}
                </div>
                {mapItem.lastCategory === true && <div className="borderLine10"></div>}
                
                </>
              )
            })
          }
          <div className={"map_item0 "}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                  window.localStorage.clear();
                  window.location.reload();

                }}
              >
                로그아웃
              </div>
            {/* <div className={"map_small_item0 "}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  회원탈퇴
                </div> */}
          </div>
          <div className=" " style={{ marginBottom: `200px`, height : `200px`}}></div>
          <TabBottom/>
        </div>
        <style>{`
        .borderLine10 {
          width : 100vw;
          height : 10px;

          background : #F2F2F2;
          margin-top: 20px;
          margin-bottom : 20px;
        }
          .rootTopDiv1 {
            width : 100vw;
            height: 10px ;
            background : #F2F2F2;
          }
          .rootCategoryLabel {

            width: calc(100vw - 40px);
            height: 34px;


            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            /* identical to box height, or 14px */
            display: flex;
            align-items: center;
            text-align: center;

            /* Gray/05 */
            color: #A6A6A6;
            margin-left: 20px;
            margin-right: 20px;


          }
          .map_small_item0 {


            width: calc(100% - 40px);
            height: 36px;
            margin-left: 20px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */
display: flex;
align-items: center;
text-align: center;
text-decoration-line: underline;

/* Gray/05 */
color: #A6A6A6;

          }
          .menu_container0 {
            width: calc(100vw);
            height: 100vh;

            margin-top: 52px;

            background: #FFFFFF;
            border-radius: 10px;

            display: flex;
            flex-direction: column;

            margin-bottom: auto;


          }
          .map_item0 {

            width: calc(100% - 40px);
            height: 36px;
            margin-left: 20px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            /* identical to box height, or 16px */
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.002em;
            
            /* Gray/09 */
            color: #2D2D2D;
            
            
            margin-top: 10px;
            

          }
          .map_item1 {
            border-top: none;
          }
          .map_item0 > img {
            width : 8px;
            height: 8px;
            object-fit : contain;
            margin-left: auto;
          }
          
        `}</style>
      </>
      
    );
}
