import React, { useState, useMemo, useRef, useEffect } from 'react'
import TinderCard from 'react-tinder-card'
import Firebase from 'firebase';
import moment from "moment";

const conversationTopicArray = [
  {
      index : 1, 
      enName : '',
      krName : '정신건강',
  },
  {
      index : 2, 
      enName : '',
      krName : '성생활',
  },
  {
      index : 3, 
      enName : '',
      krName : '금전/사업',
  },
  {
      index : 4, 
      enName : '',
      krName : '대인관계',
  },
  {
      index : 5, 
      enName : '',
      krName : '자아/성격',
  },
  {
      index : 6, 
      enName : '',
      krName : '결혼/육아',
  },
  {
      index : 7, 
      enName : '',
      krName : '이별/이혼',
  },
  {
      index : 8, 
      enName : '',
      krName : '가족',
  },
]
const sampleDB = [
  {
    name: 'Richard Hendricks',
    url: '/static/images/ssokdak/Tab4/dummy1.png'
  },
  {
    name: 'Erlich Bachman',
    url: '/static/images/ssokdak/Tab4/dummy1.png'
  },
  {
    name: 'Monica Hall',
    url: '/static/images/ssokdak/Tab4/dummy1.png'
  },
  {
    name: 'Jared Dunn',
    url: '/static/images/ssokdak/Tab4/dummy1.png'
  },
  {
    name: 'Dinesh Chugtai',
    url: '/static/images/ssokdak/Tab4/dummy1.png'
  }
]

function Advanced (props) {
  const [currentIndex, setCurrentIndex] = useState(sampleDB.length - 1)
  const [lastDirection, setLastDirection] = useState()
  const [arrayData1, setArrayData1] = useState([]);
  const [arrayData2, setArrayData2] = useState([]);
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const childRefs = useMemo(
    () =>
      Array(sampleDB.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < sampleDB.length - 1

  const canSwipe = currentIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
  }

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < sampleDB.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  useEffect(()=> {

    const authStoreKeyName = localStorage.getItem("authStoreKeyName");
    const authStoreUserName = localStorage.getItem("authStoreUserName");
    const authStore1 = localStorage.getItem("authStore")
    Firebase.database().ref()
    .child("behaviors/community-checkins")
    // .orderByChild("username")
    // .equalTo(authStoreUserName)
    .once("value", (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        console.log("community-checkins--- data", data)

        if (data && Object.values(data) &&  Object.values(data).length > 0) {
          setArrayData1(Object.values(data))

          const array1 = Object.values(data);
          const filter1 = array1.filter((filterItem, filterIndex)=> {
            if (filterItem.created_day === moment().format("YYYY-MM-DD")){
              return filterItem
            }
          }) 

        }
      }
    });

    Firebase.database().ref()
    .child("behaviors/friends")
    .on("value", (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        // console.log("community-checkins--- data", data)

        if (data && Object.values(data) &&  Object.values(data).length > 0) {
          // setArrayData1(Object.values(data))

          const array1 = Object.values(data);
          setArrayData2(array1)

        }
      }
    });


    


  }, [])

  // console.log("props.checkInDataAll", props.checkInDataAll)

  return (
    <div>
      <div className='cardContainer'>
        {arrayData1 && arrayData1
        .filter((filterItem, filterIndex)=> {

          const authStoreUserName = localStorage.getItem("authStoreUserName");
          if ( filterItem.username !== authStoreUserName) {
            return (filterItem)

          }
        })
        .filter((filterItem, filterIndex)=> {
          const userAll = props.userAll;
          const index1 = userAll && Object.values(userAll) && Object.values(userAll).findIndex((findItem, findIndex)=> {
            if (findItem.username === filterItem.username) {
              return findItem;
            }
          });
          const authStoreUserName = localStorage.getItem("authStoreUserName");
          if (Object.values(userAll)[index1].username === authStoreUserName) {

          } else {
            return filterItem
          }



          return filterItem
        })
        .map((mapItem, index) => {

          // console.log("filterValue", props.filterValue)

          const userAll = props.userAll;
          const index1 = userAll && Object.values(userAll) && Object.values(userAll).findIndex((findItem, findIndex)=> {
            if (findItem.username === mapItem.username) {
              return findItem;
            }
          });

          // console.log("> userAll", props.userAll)

          const photos1 = index1 > -1 ? [] : ( Object.values(userAll)[index1] &&  Object.values(userAll)[index1].photos ?  Object.values(userAll)[index1].photos :  []);
          var link1 = '/static/images/ssokdak/Tab4/dummy1.png';
          if (photos1 && photos1.length > 0) {
            link1 = photos1[0]
          }
        
          const findindex1 =  arrayData2.findIndex((findItem, findIndex)=> {
            if (mapItem.userData.username === findItem.toUsername) {
              return findItem
            }
          });

          var friendCheck = false;
          if (findindex1 > -1) {
            friendCheck = true;
          }

          

          var filterCheck = true;
          if (props.filterValue) {
            if (mapItem.topicKrName === props.filterValue.krName) {
              filterCheck = true;
            } else {
              filterCheck = false;
            }
          }
          

          if (filterCheck === false){
            return <></>
          }



          return (

          <TinderCard
          ref={childRefs[index]}
          className='swipe'
          key={mapItem.name}
          onSwipe={(dir) => swiped(dir, mapItem.name, index)}
          onCardLeftScreen={() => outOfFrame(mapItem.name, index)}
        >
          {
            Array.from([""]).map((mapItemNo, mapIndex)=> {
              return (

          <div
          style={{ backgroundImage: 'url(' +  link1 + ')' }}
          className='card'
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            props && props.callBack1(mapItem);
            
          }}
        >
          <div className='bottomInfoDiv1'>
            <div className='row0 row1'>
              <div className='text0 text1'>{mapItem.userData && mapItem.userData.nickname ?mapItem.userData.nickname  : "" }</div>
              {/* <div className='text0 text1'>32</div> */}
            </div>
            <div className='row0 row2'>
                                { 
                                Array.from(conversationTopicArray) 
                                .filter((filterItem , filterIndex)=> {

                                  const ext0 = mapItem.topic && mapItem.topic.findIndex((findItem, findIndex)=> {
                                    // console.log("( mapIndex) === findItem", ("" + filterIndex) === findItem);
                                    if (("" + filterIndex) === findItem) {
                                      return findItem
                                    }
                                  });

                                  // console.log("ext0", ext0)

                                  if (ext0 > -1) {
                                    return (filterItem)
                                  }

                                })
                                .map((mapItem, mapIndex)=> {

                                    // // console.log("todayMoodData.topic", mapItem.topic)

                                    // const ext0 = mapItem.topic && mapItem.topic.findIndex((findItem, findIndex)=> {
                                    //   if (("" + mapIndex) === findItem) {
                                    //     return findItem
                                    //   }
                                    // })

                                    // if (ext0 < 0) {
                                    //   return <></>
                                    // }

                                    return (
                                    <div className="tagItem " 
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1('page004/998/chat-daily-first')
                                        }}
                                    >
                                        {/* #{mapItem.krName} */}
                                        #{mapItem.krName} 
                                    </div>)
                                })
                                
                                }
              
              {/* <div className='tagItem'>&nbsp;&nbsp;#대화주제&nbsp;&nbsp;</div>
              <div className='tagItem'>&nbsp;&nbsp;#대화주제&nbsp;&nbsp;</div> */}
            </div>
            <div className='row0 row3'>
              {/* {`오늘의 기분은 행복 그자체다 밥먹고 산책하고 강아지 산책하고 강아지 구경하고 너무너무 행복한 일상이였`} */}
            </div>
            <div className='row0 row4'>
              {/* chatCheck.svg */}
              <img className="img0 img1" 
              src={friendCheck === true ? `/static/images/ssokdak/Tab4/chatCheck.svg` : `/static/images/ssokdak/Tab4/chatPlus.svg`}  
              alt=''
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                // props && props.callBack2(mapItem);

                if (friendCheck === true ) {
                  return;
                }

                const authStoreUserName = localStorage.getItem("authStoreUserName");
                        
                Firebase.database().ref().child("behaviors/friends")
                .push({
                  date : `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
                  text : "", 
                  more : '',
                  type : '',
                  fromUsername : '' + authStoreUserName,
                  toUsername : mapItem.userData && mapItem.userData.username  ? '' +mapItem.userData.username : '',
                });

              }}
              />
              <div className='button0 button1' 
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                props && props.callBack3(mapItem);
              }}
              >
                <img className="img0 img2" src={`/static/images/ssokdak/Tab4/bubbleWhite.svg`}  alt=''/>
                <div className='text0 text1'>대화하기</div>
              </div>
            </div>
          </div>
        </div>
              )
            })
          }
        </TinderCard>
          )
        })}

      </div>
      {/* <div className='buttons'>
        <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} onClick={() => swipe('left')}>Swipe left!</button>
        <button style={{ backgroundColor: !canGoBack && '#c3c4d3' }} onClick={() => goBack()}>Undo swipe!</button>
        <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} onClick={() => swipe('right')}>Swipe right!</button>
      </div> */}
      {/* {lastDirection ? (
        <h2 key={lastDirection} className='infoText'>
          You swiped {lastDirection}
        </h2>
      ) : (
        <h2 className='infoText'>
          Swipe a card or press a button to get Restore Card button visible!
        </h2>
      )} */}
    </div>
  )
}

export default Advanced
