import firebase from "firebase";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC2n8cRFJeLlXaq3FspUNcXeiAzOswHN78",
  authDomain: "sdsd000-47cfd.firebaseapp.com",
  databaseURL: "https://sdsd000-47cfd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sdsd000-47cfd",
  storageBucket: "sdsd000-47cfd.appspot.com",
  messagingSenderId: "228346163897",
  appId: "1:228346163897:web:3566f1056e154bb5bea168",
  measurementId: "G-LYNW385XNT"
};

const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb;