import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";
import TabBottom from "../../TabBottom/Tab";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const pageCode1 =   'page005/2/setting-subscription-agreement';
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);


    const [boolToggleA, setBoolToggleA] = useState(false);
    const [boolToggle1, setBoolToggle1] = useState(false);
    const [boolToggle2, setBoolToggle2] = useState(false);
    const [boolToggle3, setBoolToggle3] = useState(false);
    const [boolToggle4, setBoolToggle4] = useState(false);
    const [boolToggle5, setBoolToggle5] = useState(false);

    const [boolPopup1, setBoolPopup1] =  useState(false);
    

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    // console.log("selectedPageObject", selectedPageObject)

    const menuArr1 = [
      { label : "푸시 알람 수신동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "설문 수집 동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "위치 정보 동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "광고 수신 동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "카메라 앱 사용 동의", code : 'page005/2/setting-subscription-agreement', },
    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',

    // 071/btn_toggle0.png
    // 071/btn_toggle1.png

    return (
      <>
        <div className={`aa01_c`} style={{ background : ` #F8F8F8`}}> 
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className=" " style={{ height : `95px`}}></div>
          <div className="menu_container0 menu_container1">
          {
            menuArr1.map((mapItem, mapIndex) => {
              return (
                <div className={"map_item0 map_item" + (mapIndex + 1)}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    
                  }}
                >
                  <span className="text1">
                  {mapItem.label}</span>
                <img src={`/static/icons/071/btn_toggle0.png`} style={{width : `60px`, height: `30px` }}/>
                </div>
              )
            })
          }

          </div>
          <div className=" " style={{ marginBottom: `200px`, height : `200px`}}></div>
          <TabBottom/>
        </div>
        <style>{`
          .menu_container0 {
            width: calc(100vw - 40px);
            border-radius: 10px;

            display: flex;
            flex-direction: column;

            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: auto;

          }
          .map_item0 {

            width: calc(100% - 40px);

            height: 50px;
            left: 0px;
            top: 0px;
            
            background: #FFFFFF;
            border: 1px solid rgba(33, 37, 41, 0.2);
            border-radius: 30px;

            color: #FF7A7A;

            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 60px;

            margin-left: auto;
            margin-right: auto;
            position: relative;

            display: flex;
            align-items: center;

            margin-top: 10px;
          }
          .map_item1 {
            margin-top: 0px;
          }
          .map_item0 > img {
            width: 60px;
            height: 30px;
            object-fit : contain;
            margin-left: auto;
            margin-right: 20px;
          }
          .map_item0 > .text1 {
            margin-left: 20px;
          }
          
        `}</style>
      </>
      
    );
}
