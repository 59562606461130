import React, { Component, useEffect, useState, useContext , useRef} from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../../redux/authRedux/auth.reducer";
import { setAuth } from "../../../redux/authRedux/auth.actions";
import { category_KEY } from "../../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../../redux/filterRedux/filter.actions";

import uuid from 'react-uuid';
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import TabBottom from "../../TabBottom/Tab";

import DropDownClickable from "../../../components/DropdownClickable";
import CalendarModule from "../CalendarModule/index";
import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';
import STT from "./STT"

import "./aa01.css";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    // [
    //   { list: 'ordered' },
    //   { list: 'bullet' },
    //   { indent: '-1' },
    //   { indent: '+1' },
    // ],
    // ['link', 'image', 'video'],
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]


const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]

const pageCode1 =   'page002/0/mind-diary';

// <div className={``}></div>
export default function ThisComponent(props) {
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);

    const [selectedFilter1, setSelectedFilter1] = useState(
      {
        label : "전체", 
        index: 1,
      },);
    const [selectedFilter2, setSelectedFilter2] = useState(null);

    const [boolTapped1, setBoolTapped1] = useState(false);

    const [currentCardsData, setCurrentCardsData] = useState([]);
    const [data, setData] = useState('No result');
    const [qrMode, setQrMode] = useState("false");
    const [url, setUrl] = useState("");

    const [arraySelected, setArraySelected] = useState([]);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [pageStep, setPageStep] = useState(1);

    const [textCalendarMode, setTextCalendarMode] = useState("week");

    const [arrayData1, setArrayData1] = useState([]);
    const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));

    const [boolPopup1, setBoolPopup1] = useState(false);
    const [boolPopup2, setBoolPopup2] = useState(false);
    const [textValue1, setTextValue1] = useState([]);
    const [textValue2, setTextValue2] = useState([]);

    const [selectedItem ,setSelectedItem] = useState(null);
    const [quillValue1, setQuillValue1] = useState();


    const downloadQRCode = (e) => {
      e.preventDefault();
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    const qrcode = (
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={50}
        bgColor={"#fff"}
        level={"H"}
      />
    );

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',

      setTextCalendarMode("week")
      setDate1(moment().format("YYYY-MM-DD"))
      setBoolPopup1(false)
      setBoolPopup2(false)
      setTextValue1("")
      setTextValue2("")
      setSelectedItem()
      setBoolTapped1(false)

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      if (true && true) {
        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        const authStore1 = localStorage.getItem("authStore");
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        Firebase.database().ref()
        .child("behaviors/diary-checkins")
        .orderByChild("username")
        .equalTo(authStoreUserName)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            // console.log("behaviors/posts --- data", data)
            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setArrayData1(Object.values(data))
            }
          }
        });

        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    // console.log("CHECKCHECK - 001", );
                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        
                        setUrl("ssokdak@nickname:" + data[keyName].nickname)

                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }
    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`}> 
          <div 
            className="calendarWeekWrapper" 
            style={{ 
              minHeight : textCalendarMode === "week" ? "52px" : "52px",
              transition: `all 0.5s ease-out`,
            }}
          >
          <div className="calendarWeekTopBar">
              <div className="calendarWeekDiv1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  if (textCalendarMode === "week") {
                    setTextCalendarMode("month")
                  } else {
                    setTextCalendarMode("week")

                  }
                }}
              >
                  <div className="calendarWeekTopLabel">
                    {moment(date1, "YYYY-MM-DD").format("MMM YYYY")}
                  </div>
                  <img className="image0 image1" src={`/static/images/ssokdak/Tab2/calendarTopArrowDown.svg`} alt=""
                  />
              </div>
              <img className="calendarTopMenuIcon" src={`/static/images/ssokdak/Tab2/calendarTopIcon.svg`} alt=""
                style={{
                  transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                  if (textCalendarMode === "week") {
                    setTextCalendarMode("month")
                  } else {
                    setTextCalendarMode("week")

                  }
                  return;
                  
                }}
              />
          </div>
            {
              textCalendarMode === "week" && 
              <>
                <div className="calendarEmotionPreviewWrapper">
                <div className="calendarEmotionPreviewRow">
                  {
                    Array.from([0,1,2,3,4,5,6 ]).map((mapItem, mapIndex)=> {
                      const today = new Date();
                      const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
                      const day1 = moment(firstDay)
                      const itemDay  = day1.add(mapItem, "day")
                      const itemDayFormatDD = itemDay.format("DD");
                      const itemDayFormatYYYYMMDD = itemDay.format("YYYY-MM-DD");

                      // console.log("itemDay", itemDay.format("YYYY-MM-DD HH:mm:ss"));
                      const dataDay = arrayData1.filter((filterItem, filterIndex)=> {
                        if (filterItem.created_day === itemDayFormatYYYYMMDD) {
                          return filterItem;
                        }
                      });
                      // console.log("dataDay", dataDay);


                      return (

                        <div className="calendarEmotionPreviewDiv1">
                          <img className="calendarEmotionPreviewImage1" 
                          // src={`/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`} 
                          src={
                            dataDay && dataDay.length > 0 
                            ? dataDay[0].emotionObject &&  dataDay[0].emotionObject.iconOnlyPathName &&  dataDay[0].emotionObject.iconOnlyPathName 
                            :
                            `/static/images/ssokdak/Tab2/emoEmpty.svg`
                          }
                          alt=""/>
                          <div className="calendarEmotionPreviewText1">{itemDayFormatDD}</div>
                          {/* <div className="calendarImageRow0">
                            <div className="calendarImageDiv1">
                              <img className="calendarImage0 calendarImage1" src="" alt=""/>
                            </div>
                          </div> */}
                        </div>
                      )
                    })
                  }
                </div>
                </div>
              </>
            }

            {
              textCalendarMode === "month" &&
              // true && 
              <>
              {/* Calendar */}
                <CalendarModule 
                  // style={{ marginTop: `52px`}}
                  year={""}
                  month={""}
                  day={""}
                  date={date1}
                  data={arrayData1}
                  callBack1={(param1, param2, param3 )=> {
                    
                    // console.log("callBack1. param1", param1);
                    var temp1 = "";
                    var temp2 = "";
                    var temp3 = "";
                    if (param1 && param1 !== "") {
                      temp1 = param1.split("-")[0]
                      temp2 = param1.split("-")[0]
                      temp3 = param1.split("-")[0]
                    }
                    setDate1(param1)

                    setTextCalendarMode("week")

                    // if (selectedUserUsername == "") {
                    //   alert("로그인이 필요합니다.");
                    //   return;
                    // }
                    // setTextModeStep("table/time")
                    // window.scrollTo({top: 0});
                    // window.scrollTo({ top: 0, behavior: 'smooth' })
                  }} 
                  callBack2={null}
                ></CalendarModule>
              </>
            }
          </div>



          <div className="calendarContentsWrapper">
            {/* <div className="calendarContentsDailyWrapper"></div> */}
            <div className="calendarContentsDateLabel">
                {moment(date1, "YYYY-MM-DD").format("YYYY MM DD")}
                {`.`}
                &nbsp;
                {moment(date1, "YYYY-MM-DD").format("dddd").substring(0,3)}
                &nbsp;
                {`(선택일자)`}
              </div>
            {
              arrayData1 && arrayData1.length === 0 &&
              <div className="emptyWrapper">
                <img className="emptyCenterImage1" 
                    src={`/static/images/ssokdak/Empty/emptyDiary100.png`}
                    alt=""/>
                  <div className="emptyText1">
                    아직 기록된 마음이 없어요<br/>마음을 이모티콘으로 기록해 보세요
                  </div>
                  <div 
                    className="emptyButton0 emptyButton1" 
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                      setScreenMode1('page002/1/mind-diary-checkin')
                    }}
                >
                    <div className="text1">마음 기록하기</div>
                    <img className="icon1" 
                        src={`/static/images/ssokdak/Empty/emptyIcon100.svg`}
                        alt=""
                        />
                  </div>
              </div>
            }
            {
              arrayData1 && arrayData1.length >= 1 &&
              <>
              {
              Array.from(arrayData1)
              .filter((filterItem , filterIndex)=> {
                if (filterItem.created_day === date1) {
                  return filterItem
                }
              })
              .sort(function(a, b)  {
                return moment(a.created_at, "YYYY-MM-DD HH:mm:ss").fromNow() -  moment(b.created_at, "YYYY-MM-DD HH:mm:ss").fromNow();
              })
              .map((mapItem, mapIndex)=> {
                // const parsedObject1 = mapItem && mapItem.contents && parse(mapItem.contents);
                // console.log("mapItem", mapItem);
                return (
                  <div className="calendarContentsDailyItem" 
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      setBoolPopup1(true);
                      setSelectedItem(mapItem);
                      setTextValue1(mapItem.reason)
                      setTextValue2(mapItem.did)
                      setQuillValue1(mapItem.contents)
                    }}
                  >
                    <div className="calendarContentsDailyItemInfoRow1">
                      <div className="calendarContentsDailyItemInfoCol1">
                        {/* <img className="image0 image1" src={`/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`} alt=""/> */}
                        <img className="image0 image1" src={mapItem.emotionObject.iconOnlyPathName} alt=""/>
                      </div>
                      <div className="calendarContentsDailyItemInfoCol2">
                        <div className="text0 text1">
                          {/* {parsedObject1 && parsedObject1.substring(0,10)} */}
                          {/* {mapItem && mapItem.pre_contents && mapItem.pre_contents.substring(0,10)} */}
                          {mapItem.emotionObject && mapItem.emotionObject.krName ? mapItem.emotionObject.krName : ""} 
                          </div>
                        <div className="text0 text2">
                          {/* {`12:00 AM`} */}
                          {/* {mapItem && mapItem.created_time ? moment(mapItem.created_time , "hh:mm A") : ""} */}
                          {mapItem.created_time}
                        </div>
                      </div>
                    </div>
                    <div className="calendarContentsDailyItemInfoRow2">
                      <div className="calendarContentsDailyItemInfoColA">
                        {/* {`오늘의 기분은 행복 그자체다 밥먹고 산책하고 강아지 구경하고 너무너무 행복한 일상이였다 매일매일이 이렇...`} */}
                          {mapItem && mapItem.pre_contents && mapItem.pre_contents.substring(0,20)}
                      </div>
                    </div>
                  </div>
                )
              })
            } 
            </>
            }
          </div>
          {
            setSelectedItem === null &&
            boolTapped1 === true && 
            <div className="fabBlur"
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setBoolTapped1(false);
            }} ></div>
          }
          <div className="fabContainer">
            {boolTapped1 === false && 
            <>
            <div 
              className="fab0 fab1">
              <img className="image0 image1" 
                src={`/static/images/ssokdak/Tab2/fabEmo.png`} alt=""
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  // setBoolTapped1(true);
                  setScreenMode1('page002/1/mind-diary-checkin')
                }}
              />
            </div>
            <div className="fab0 fab3">
              <img className="image0 image1" src={`/static/images/ssokdak/Tab2/fabPlus.svg`}  alt=""
              
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setBoolTapped1(true);
              }}
              />
            </div>
            </>}
            {boolTapped1 === true && 
            <>
            <div className="fab0 fab1">
              <div className="fabInnerRow0 fabInnerRow1"

                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setScreenMode1('page002/1/mind-diary-checkin/stt')
                }}
              >
                <div className="text0 text1">음성인식</div>
                <img className="image0 image1" 
                  src={`/static/images/ssokdak/Tab2/fabFunction1.png`} 
                  alt="" 
                />
              </div>
              {/* <div className="fabInnerRow0 fabInnerRow2"></div> */}
              {/* <div className="fabInnerRow0 fabInnerRow3"></div> */}
            </div>
            {/* <div className="fab0 fab2">
              <img className="image0 image1" src={`/static/images/ssokdak/Tab2/fabFunction2.png`}  alt=""
              
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setBoolTapped1(true);
              }}
              />
            </div>
            <div className="fab0 fab3">
              <img className="image0 image1" src={`/static/images/ssokdak/Tab2/fabFunction3.png`}  alt=""
              
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setBoolTapped1(true);
              }}
              />
            </div> */}
            </>}
          </div>


          <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: boolPopup1 === false ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`,
                          paddingLeft : `0px`,
                          paddingRight : `0px`,
                          overflow : 'hidden',
                        }}
                        >
                          <div className="blurView"></div>
                          <div 
                            className="contentView" style={{ 
                            height: boolPopup1 === false ? 0 : `100vh`,
                            }} 
                          >  
                            {
                              boolPopup1 === true &&
                              
                              <div 
                              className="bottomSheetEmoWrapper" 
                              style={{ 
                                paddingLeft : `0px`,
                                paddingRight : `0px`,
                                overflow : 'scroll',
                              }}> 

                    <div className="checkInPageWrapper">

<div className="checkInTopWrapper" >
    <div className="checkInTopTopBar">
        <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
            onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                // setScreenMode1( 'page002/0/mind-diary')
                // setPageStep(1);
                setBoolPopup1(false);
            }}
        />
        <div className="checkInTopTopLabel1">
            {moment().format("YYYY년 MM월 DD일")}
        </div>
        <img className="checkInTopTopIcon2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
        
        onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            setBoolPopup1(false);
        }}
        />
        
    </div>
</div>
<div className="checkInEmoPrevWrapper"
  style={{
    height : (pageStep >= 3 && pageStep <= 5) ? `0px` :`192px`
  }}
>
    {
        // clickedIndex > -1 &&
        // emoCardArray[clickedIndex] &&
        selectedItem && 
        <div className="iconWrapper1">
          <img className="icon1" src={selectedItem.emotionObject.iconOnlyPathName} alt=""/>
          <div className="row0 row1">
            <div className="iconKrName1">{`#`}{selectedItem.emotionObject.krName}</div> 
          </div>
        </div>
    }
    <div></div>
</div>
<div className="checkInEmoWriteWrapper" 

style={{
  height : `calc(100vh - 250px)`,
  borderRadius : `30px 30px 0px 0px`,
}}
>

    <div className="editButtonRow">  
        <div className="editStepDiv1">
            {/* <div className="dot0 dot1 " style={{ background : (pageStep === 2 || pageStep === 3) ? '#FF7A7A' : '#e2e2e2' }}></div>
            <div className="dot0 dot1 " style={{ background : (pageStep === 4 || pageStep === 5) ? '#FF7A7A' : '#e2e2e2' }}></div>
            <div className="dot0 dot1 " style={{ background : (pageStep === 6 || pageStep === 7) ? '#FF7A7A' : '#e2e2e2' }}></div> */}
        </div>
    </div>    
      <div className="editContentRow">
        <div className="editRowStep3Wrapper">
          <div className="text0 text1">
          <textarea 
            ref={textAreaRef1}
            className="input0"
            value={textValue1}
            // onChange={e=> {
            //   setTextValue1(e.target.value);
            // }}
            disabled={true}
          />
          </div>
          <div className="text0 text2">
          <textarea 
            ref={textAreaRef2}
            className="input0 input1"
            value={textValue2}
            // onChange={e=> {
            //   setTextValue2(e.target.value);
            // }}
            disabled={true}
          /></div>

          {/* <QuillWrapper
            style={{
              maxWidth : `calc(100vw - 80px)`,
              width :`calc(100vw - 80px)`,
              minHeight: `400px`,
              maxHeight: `calc(100vh - 200px)`,
              marginBottom : `80px`,
              color : `#000`,
              
            }}
          
            theme={'snow'}
            id={'description'}
            placeholder={' '}
            value={quillValue1}
            modules={modules}
            formats={formats}
            // onChange={(event) => setQuillValue1(event)}
          />  */}
          <div className="text0 text2" >
          {parse(quillValue1)}
          </div>

          {
            selectedItem && selectedItem.imageLink1 && selectedItem.imageLink1 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink2 && selectedItem.imageLink2 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink3 && selectedItem.imageLink3 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink4 && selectedItem.imageLink4 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink5 && selectedItem.imageLink5 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }

        {
            selectedItem && selectedItem.imageLink6 && selectedItem.imageLink6 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink7 && selectedItem.imageLink7 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink8 && selectedItem.imageLink8 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink9 && selectedItem.imageLink9 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }
          {
            selectedItem && selectedItem.imageLink10 && selectedItem.imageLink10 !== "" && 
            <img className="image0 image1" src={selectedItem.imageLink1} alt=""/>
          }

          
        </div>
      </div> 

</div>
 


</div>


                              </div>
                            }
                          </div>
                        </div>


          <div className=" " style={{ marginBottom: `200px`, height : `100px`}}></div>
        </div>
        <TabBottom/>
        </>
      }
      </>
      
    );
}
