import appImages from "./appImages";
const pageCodeInfoArr = [
  // page004/998/chat-daily-first
  {
    id : 99990011,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '채팅목록',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page004/998/chat-daily-first',
    parent_code : 'page004/998/chat-list',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled :  false,
    top_nav_other_function : '',
  },

  {
    id : 99990001,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '채팅목록',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page004/998/chat-list',
    parent_code :'page001/0/home',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled :  false,
    top_nav_other_function : '',
  },
  {
    id : 99990002,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '채팅상세',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page004/998/chat-detail',
    parent_code :'page001/0/home',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled :  false,
    top_nav_other_function : '',
  },
  {
    id : 99900001,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '주문하기',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page002/999/order',
    parent_code :'page001/0/home',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled :  false,
    top_nav_other_function : '',
  },

  {
    id : 99900002,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '장바구니',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page002/999/cart',
    parent_code :'page001/0/home',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled :  false,
    top_nav_other_function : '',
  },


  {
    id : 99900003,
    icon_path_default : appImages._020_7,
    icon_path_active  : appImages._021_7,
    name_kr : '주문완료',
    name_en : '',
    purpose_kr : '쏙닥쏙닥',
    purpose_en : 'main/remind',
    purpose_code : '',

    page_code : 'page002/999/order-result',
    parent_code :'page001/0/home',
    href : '',

    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : true,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },

  {
    id : 99900004,
    icon_path_default : appImages._020_10,
    icon_path_active  : appImages._021_10,
    name_kr : '피드 추가',
    name_en : '',
    purpose_kr : '피드 추가',
    purpose_en : 'main/settings',
    purpose_code : '',

    page_code : 'page002/2/feed-create',
    parent_code : "page001/0/home",
    href : '',
    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : false,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },

  {
    id : 99900005,
    icon_path_default : appImages._020_10,
    icon_path_active  : appImages._021_10,
    name_kr : '피드 상세',
    name_en : '',
    purpose_kr : '피드 상세',
    purpose_en : 'main/settings',
    purpose_code : '',

    page_code : 'page002/2/feed-detail',
    parent_code : "page001/0/home",
    href : '',
    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : false,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },

  {
    id : 99900006,
    icon_path_default : appImages._020_10,
    icon_path_active  : appImages._021_10,
    name_kr : '내 동네 ',
    name_en : '',
    purpose_kr : '내 동네 ',
    purpose_en : 'main/settings',
    purpose_code : '',

    page_code : 'page002/2/around-create',
    parent_code : "page001/0/home",
    href : '',
    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : false,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },

  {
    id : 99900006,
    icon_path_default : appImages._020_10,
    icon_path_active  : appImages._021_10,
    name_kr : '내 동네 ',
    name_en : '',
    purpose_kr : '내 동네 ',
    purpose_en : 'main/settings',
    purpose_code : '',

    page_code : 'page002/2/around-detail',
    parent_code : "page001/0/home",
    href : '',
    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : false,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },
  {
    id : 99900006,
    icon_path_default : appImages._020_10,
    icon_path_active  : appImages._021_10,
    name_kr : '내 동네 목록',
    name_en : '',
    purpose_kr : '내 동네 목록',
    purpose_en : 'main/settings',
    purpose_code : '',

    page_code : 'page002/1/around-list',
    parent_code : "page001/0/home",
    href : '',
    top_nav_display_visible : true,
    top_nav_back_enabled : true,
    top_nav_more_enabled : false,
    top_nav_star_enabled : false,
    top_nav_search_enabled : false,
    top_nav_other_function : '',
  },


    // BASED.
    {
      id : 0,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '스플래쉬',
      name_en : '',
      purpose_kr : '스플래쉬',
      purpose_en : 'splash',
      purpose_code : 'load/splash',
  
      page_code : 'page000/0/splash',
      parent_code : '',
      href : '',
  
      top_nav_display_visible : false,
      top_nav_back_enabled : false,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
  
      id : 10000,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '처음',
      name_en : '',
  
      purpose_kr : '처음',
      purpose_en : 'login',
      purpose_code : 'auth/login',
      
      page_code : 'page000/0/first',
      parent_code : '',
      href : '',
  
      top_nav_display_visible : false,
      top_nav_back_enabled : false,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
  
      id : 10000,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '로그인',
      name_en : '',
  
      purpose_kr : '로그인',
      purpose_en : 'login',
      purpose_code : 'auth/login',
      
      page_code : 'page000/0/login',
      parent_code : '',
      href : '',
  
      top_nav_display_visible : false,
      top_nav_back_enabled : false,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
  
      id : 10001,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '아이디/비밀번호찾기',
      name_en : '',
      purpose_kr : '아이디 찾기',
      purpose_en : 'auth/find-id',
      purpose_code : '',
  
      page_code : 'page000/1/find-id',
      parent_code : 'page000/0/login',
      href : '',
      
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
  
      id : 10002,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '아이디/비밀번호찾기',
      name_en : '',
      purpose_kr : '비밀번호 찾기',
      purpose_en : 'auth/find-pw',
      purpose_code : '',
  
      page_code : 'page000/1/find-pw',
      parent_code : 'page000/0/login',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
  
      id : 10003,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '회원가입',
      name_en : '',
  
      purpose_kr : '회원가입',
      purpose_en : 'register',
      purpose_code : 'auth/register',
      
      page_code : 'page000/1/register',
      parent_code : 'page000/0/login',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page001/0/home',
      parent_code : 'page001/0/home',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page002/0/mind-diary',
      parent_code : 'page001/0/home',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    // 

    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page002/1/mind-diary-checkin',
      parent_code : 'page002/0/mind-diary',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page002/1/mind-diary-checkin/stt',
      parent_code : 'page002/0/mind-diary',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },

    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page002/1/mind-diary-checkin/ocr',
      parent_code : 'page002/0/mind-diary',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },

    {
      id : 1,
      icon_path_default : appImages._020_6,
      icon_path_active  : appImages._021_6,
      name_kr : '',
      name_en : '',
      purpose_kr : '홈',
      purpose_en : 'main/home',
      purpose_code : '',
  
      page_code : 'page002/1/mind-diary-checkin/cam',
      parent_code : 'page002/0/mind-diary',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },

    
    {
      id : 5,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '설정',
      name_en : '',
      purpose_kr : '설정',
      purpose_en : 'main/settings',
      purpose_code : '',
  
      page_code : 'page005/0/settings',
      parent_code :  'page005/0/mypage',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 21,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '목록',
      name_en : '',
      purpose_kr : '내부@@TITLE@@__목록',
      purpose_en : 'main/settings',
      purpose_code : '',
  
      page_code : 'page002/1/remind-list',
      parent_code : 'page001/0/home',
      href : '',
  
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
      
    },
  
    {
      id : 77,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '즐겨찾기',
      name_en : '',
      purpose_kr : '즐겨찾기',
      purpose_en : 'main/star-favorite',
      purpose_code : '',
  
      page_code : 'page002/2/star-favorite',
     parent_code : 'page002/1/remind-list',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : true,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      // 폴더, 키워드 , 설명 저장 되도록
      id : 81,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '',
      name_en : '',
      purpose_kr : '카드_키워드_설명_이미지보기',
      purpose_en : 'main/card-detail',
      purpose_code : '',
  
      page_code : 'page002/2/card-detail',
     parent_code : 'page001/0/home',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      // 폴더, 키워드 , 설명 수정 되도록
      id : 82,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '카드수정',
      name_en : '',
      purpose_kr : '카드_키워드_설명_이미지보기',
      purpose_en : 'main/card-update',
      purpose_code : '',
  
      page_code : 'page002/2/card-update',
     parent_code : 'page002/1/remind-list',
      href : '',
      top_nav_display_visible : false,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
  
    {
      id : 101,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '마이페이지',
      name_en : '',
      purpose_kr : '설정/마이페이지',
      purpose_en : 'main/setting-mypage',
      purpose_code : '',
  
      page_code : 'page005/0/mypage',
      parent_code : '',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      id : 101,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '탈퇴',
      name_en : '',
      purpose_kr : '설정/마이페이지/탈퇴',
      purpose_en : 'main/setting-mypage-withdrawl',
      purpose_code : '',
  
      page_code : 'page005/3/setting-mypage-withdrawl',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      id : 102,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '알람설정',
      name_en : '',
      purpose_kr : '알람설정',
      purpose_en : 'main/setting-notification',
      purpose_code : '',
  
      page_code : 'page005/2/setting-notification',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 102,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '알람설정',
      name_en : '',
      purpose_kr : '알람설정/시간설정/시간수정',
      purpose_en : 'main/setting-notification-fix',
      purpose_code : '',
  
      page_code : 'page005/3/setting-notification',
      parent_code : 'page005/2/setting-notification',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 103,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '수신동의',
      name_en : '',
      purpose_kr : '수신동의',
      purpose_en : 'main/setting-subscription-agreement',
      purpose_code : '',
  
      page_code : 'page005/2/setting-subscription-agreement',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 104,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '자주묻는질문',
      name_en : '',
      purpose_kr : '자주묻는질문',
      purpose_en : 'main/setting-faq',
      purpose_code : '',
  
      page_code : 'page005/2/setting-faq',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      // 기획 문의해야함
      id : 105,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '자주묻는질문등록',
      name_en : '',
      purpose_kr : '자주묻는질문 등록',
      purpose_en : 'main/setting-faq-add',
      purpose_code : '',
  
      page_code : 'page005/3/setting-faq-add',
      parent_code : 'page005/2/setting-faq',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 105,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '1:1문의',
      name_en : '',
      purpose_kr : '1:1문의 등록',
      purpose_en : 'main/setting-question-add',
      purpose_code : '',
  
      page_code : 'page005/3/setting-question-add',
      parent_code : 'page005/2/setting-question',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 106,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '1:1문의',
      name_en : '',
      purpose_kr : '1:1문의 목록',
      purpose_en : 'main/setting-questions',
      purpose_code : '',
  
      page_code : 'page005/2/setting-questions',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 107,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '1:1문의',
      name_en : '',
      purpose_kr : '1:1문의 상세',
      purpose_en : 'main/setting-question-detail',
      purpose_code : '',
  
      page_code : 'page005/2/setting-question-detail',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
  
    {
      id : 108,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '공지사항',
      name_en : '',
      purpose_kr : '공지사항 목록',
      purpose_en : 'main/setting-notice',
      purpose_code : '',
  
      page_code : 'page005/2/setting-notice',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      id : 108,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '공지사항',
      name_en : '',
      purpose_kr : '공지사항 등록',
      purpose_en : 'main/setting-notice',
      purpose_code : '',
  
      page_code : 'page005/3/setting-notice-add',
      parent_code : 'page005/2/setting-notice',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      id : 109,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '공지사항',
      name_en : '',
      purpose_kr : '공지사항 상세',
      purpose_en : 'main/setting-notice-detail',
      purpose_code : '',
  
      page_code : 'page005/2/setting-notice-detail',
      parent_code : 'page005/2/setting-notice',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },

    {
      id : 111,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '알림설정',//'이용약관/개인정보처리방침',
      name_en : '',
      purpose_kr :  '알림설정',
      purpose_en : 'main/push',
      purpose_code : '',
  
      page_code : 'page005/2/push',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 111,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '개인정보처리방침',//'이용약관/개인정보처리방침',
      name_en : '',
      purpose_kr :  '이용약관/개인정보처리방침',
      purpose_en : 'main/tutorial',
      purpose_code : '',
  
      page_code : 'page005/2/term1',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 111,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '서비스 이용약관',//'이용약관/개인정보처리방침',
      name_en : '',
      purpose_kr :  '이용약관/개인정보처리방침',
      purpose_en : 'main/tutorial',
      purpose_code : '',
  
      page_code : 'page005/2/term2',
      parent_code : 'page005/0/settings',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  

    {
      id : 201,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '데이터결과',
      name_en : '',
      purpose_kr : '데이터결과',
      purpose_en : 'main/game-history-detail',
      purpose_code : '',
  
      page_code : 'page003/2/game-history-detail',
      parent_code : 'page003/0/history',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 202,
      icon_path_default : appImages._020_10,
      icon_path_active  : appImages._021_10,
      name_kr : '데이터결과',
      name_en : '',
      purpose_kr : '데이터결과',
      purpose_en : 'main/game-history-attendance',
      purpose_code : '',
  
      page_code : 'page003/2/game-history-attendance',
      parent_code : 'page003/0/history',
      href : '',
      top_nav_display_visible : false,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
  
    {
      id : 301,
      icon_path_default : appImages._020_9,
      icon_path_active  : appImages._021_9,
      name_kr : '공유폴더목록',
      name_en : '',
      purpose_kr : '공유폴더내_카드목록',
      purpose_en : 'main/share-card-list',
      purpose_code : '',
  
      page_code : 'page004/0/share',
      parent_code : 'page001/0/home',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
    },
    {
      id : 302,
      icon_path_default : appImages._020_9,
      icon_path_active  : appImages._021_9,
      name_kr : '공유폴더_카드신고',
      name_en : '',
      purpose_kr : '공유폴더내_카드신고 내용입력',
      purpose_en : 'main/share-card',
      purpose_code : '',
  
      page_code : 'page004/0/share-report',
      parent_code : '',
      href : '',
      top_nav_display_visible : false,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
  
  
    },
  
  
    {
      id : 888,
      icon_path_default : appImages._020_9,
      icon_path_active  : appImages._021_9,
      name_kr : '검색',
      name_en : '',
      purpose_kr : '검색',
      purpose_en : 'main/search',
      purpose_code : '',
  
      page_code : 'page088/0/search',
      parent_code : '',
      href : '',
      top_nav_display_visible : false,
      top_nav_back_enabled : true,
      top_nav_more_enabled : false,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : 'search',
  
    },
  
  
  
    {
      id : 888,
      icon_path_default : appImages._020_9,
      icon_path_active  : appImages._021_9,
      name_kr : '검색',
      name_en : '',
      purpose_kr : '검색',
      purpose_en : 'main/search-result',
      purpose_code : '',
  
      page_code : 'page088/2/search-result',
      parent_code : 'page088/0/search',
      href : '',
      top_nav_display_visible : true,
      top_nav_back_enabled : true,
      top_nav_more_enabled : true,
      top_nav_star_enabled : false,
      top_nav_search_enabled : false,
      top_nav_other_function : '',
  
  
    },
  
  
  
  ];

  const pageCodeOnlyArr = [
    
    'page000/0/splash',
    'page000/0/login',
    'page000/1/find-id',
    'page000/1/find-pw',
    'page000/1/register',
    'page001/0/home',
    // 'page005/2/setting-mypage',
    'page005/2/setting-mypage',
    'page005/3/setting-mypage-withdrawl',
    'page005/2/setting-notification',
    'page005/3/setting-notification',
    'page005/2/setting-subscription-agreement',
    'page005/2/setting-faq',
    'page005/3/setting-faq-add',
    'page005/3/setting-question-add',
    'page005/2/setting-questions',
    'page005/2/setting-question-detail',
    'page005/2/setting-notice',
    'page005/2/setting-notice-detail',
    'page005/2/tutorial',
    'page005/2/term',
    'page003/2/game-history-detail',
    'page003/2/game-history-attendance',
    'page004/0/share',
    'page004/0/share-report',
    'page088/0/search',
    'page088/2/search-result',
    

    'page002/2/feed-create',
    'page002/2/feed-detail',
  ];

  const pageCodeComponentArr = [
    // // BASIC
    // AUTH (CHECK)
    { page_code : 'page000/0/splash', component : null },
    { page_code : 'page000/0/login', component : null },
    { page_code : 'page000/1/find-id', component : null },
    { page_code : 'page000/1/find-pw', component : null },
    { page_code : 'page000/1/register', component : null },
    // TAB1
    { page_code : 'page001/0/home', component : null },
    // TAB5
    { page_code : 'page005/2/setting-mypage', component : null },
    { page_code : 'page005/3/setting-mypage-withdrawl', component : null },
    { page_code : 'page005/2/setting-notification', component : null },
    { page_code : 'page005/3/setting-notification', component : null },
    { page_code : 'page005/2/setting-subscription-agreement', component : null },
    { page_code : 'page005/2/setting-faq', component : null },
    { page_code : 'page005/3/setting-faq-add', component : null },
    { page_code : 'page005/3/setting-question-add', component : null },
    { page_code : 'page005/2/setting-questions', component : null },
    { page_code : 'page005/2/setting-question-detail', component : null },
    { page_code : 'page005/2/setting-notice', component : null },
    { page_code : 'page005/2/setting-notice-detail', component : null },
    { page_code : 'page005/2/tutorial', component : null },
    { page_code : 'page005/2/term', component : null },
    // OTHER / DIALOG TYPE SCREEN
    { page_code : 'page088/0/search', component : null },
    { page_code : 'page088/2/search-result', component : null },

  ]

  export default {
    pageCodeInfoArr,  
    pageCodeOnlyArr,
    pageCodeComponentArr,
  }