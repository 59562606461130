import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a02.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
const pageCode1 =  'page000/1/find-id';
export default function ThisComponent(props) {

    const [objectPopupData1, setObjectPopupData1] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [textInput3, setTextInput3] = useState("");
    const [textInput4, setTextInput4] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    const [boolPasswordShow3, setBoolPasswordShow3] = useState(false);
    const [boolPasswordShow4, setBoolPasswordShow4] = useState(false);

    const [boolPopup1, setBoolPopup1 ] = useState(false);
    const [boolPopup2, setBoolPopup2 ] = useState(false);

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    useEffect(()=> {
      setTextInput1("");
      setTextInput2("");
    }, [selectedTabIndex])


    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])


    useEffect(()=> {
      setTextInput1("");
      setTextInput2("");
      setTextInput3("");
      setTextInput4("");
      setSelectedTabIndex(0)

    }, [])


    function handleFindUserExists(paramColumnName, paramValue, paramMode, paramMore1, paramMore2) {

      // var email =  textInput1
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }

      console.log("handleFindUserExists - textInput - 0", paramValue)

      // restoration_code : 복구코드

      Firebase.database().ref()
            .child("accounts")
            .orderByChild(paramColumnName)
            .equalTo( paramValue)
            .once("value", (snapshot) => {

                console.log("handleFindUserExists - textInput - 1", snapshot)

                if (snapshot.val()) {
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {

                         console.log("handleFindUserExists - textInput - 2", snapshot)
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        

                        var values1 = Object.values(snapshot.val());

                        console.log("@@TEST@@ handleFindUserExists - textInput - 2", values1 && values1[0])

                        if (paramMode == 1 &&  values1 && values1[0] && values1[0].username) {

                          setObjectPopupData1(
                            <div 
                              className="global_popup"  
                              onClick={e=> {
                                e.preventDefault(); 
                                e.stopPropagation();
                                setObjectPopupData1(null); }} 
                              style={{ color : `#1165F5`}}
                              >
                              {/* 해당 {paramColumnName.toUpperCase()} 사용중입니다. */}
                              ID : {values1[0].username}
                              </div>)
                        }
                        if (paramMode == 2 &&  values1 && values1[0] && values1[0].username) {

                          if (values1[0].username == textInput1 && values1[0].restoration_code == textInput3 ) {

                            setObjectPopupData1(
                              <div 
                                className="global_popup"  
                                onClick={e=> {
                                  e.preventDefault(); 
                                  e.stopPropagation();
                                  setObjectPopupData1(null); }} 
                                style={{ color : `#1165F5`}}
                                >
                                {/* 해당 {paramColumnName.toUpperCase()} 사용중입니다. */}
                                새로발급된 비밀번호 : {values1[0].password}
                                </div>)
                          } else {

                            setObjectPopupData1(
                              <div 
                                className="global_popup"  
                                onClick={e=> {
                                  e.preventDefault(); 
                                  e.stopPropagation();
                                  setObjectPopupData1(null); }} 
                                style={{ color : `#ff0000`}}
                                >
                                {/* 해당 {paramColumnName.toUpperCase()} 사용중입니다. */}
                                입력한 정보가 일치하지 않는 항목이 있습니다.
                                </div>)
                          }
                        }


                        if (data[keyName].status == "YES") {
                            
                        } else {
                            // setBlockSystem(true);
                        }
                    } else {
                      console.log("handleFindUserExists - textInput - 3", snapshot)
                        //조건 분기 필요
                    }

                }  else {
                  console.log("handleFindUserExists - textInput - 4", snapshot)
                  
                  setObjectPopupData1(
                  <div className="global_popup"  
                    onClick={e=> {
                      e.preventDefault(); 
                      e.stopPropagation();
                      setObjectPopupData1(null); 
                    }}  
                    style={{ color : `#1165F5`}}>
                      해당 {paramColumnName.toUpperCase()} 사용가능합니다.
                      </div>
                    )

                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : textInput1, 
                  //     email : textInput1, 
                  //     password : textInput2,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    
    }



    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    // 렌더, render() render-return
    return (
      <>
      {objectPopupData1}
        <div className={`a02_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className="tab_wrapper0 tab_wrapper1">
            {/* #181818,  #DEE2E6; */}
            <div 
              className="tab_col0 tab_col1"
              style={{ 
                color : selectedTabIndex == 0 ? '#181818' : '#FF7A7A',
                borderBottom : selectedTabIndex == 0 ? '2px solid #181818' : '2px solid #DEE2E6',
              }}
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setSelectedTabIndex(0);
              }}
            >아이디 찾기</div>
            <div className="tab_col0 tab_col2"
            
            style={{ 
              color : selectedTabIndex == 1 ? '#181818' : '#FF7A7A',
              borderBottom : selectedTabIndex == 1 ? '2px solid #181818' : '2px solid #DEE2E6',
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setSelectedTabIndex(1);
            }}
            >비밀번호 찾기</div>
          </div>
          
          <div className="main">
            <div className="title_wrapper0 title_wrapper1">
              {
                selectedTabIndex == 0 ?
                <>
                <img 
                  src={`/static/icons/000/icon_person.png`}
                />
                </>
                :
                <>
                
              <img 
                src={`/static/icons/000/icon_key.png`}
              />
              </>
                
              }
              {
                selectedTabIndex == 0 ?
                `아이디 찾기` : `비밀번호 찾기`
              }
            </div>
            <div className="textinput_form_container0 textinput_form_container1">
              <input 
                value={textInput1}
                onChange={e=> {
                  // e.preventDefault();
                  // e.stopPropagation();
                  setTextInput1(e.target.value);
                }}
                placeholder={selectedTabIndex == 0 ? `이메일을 입력하세요` : `아이디를 입력하세요`}
              />
              {
                textInput1.length > 0 &&
                <img src={appImages._000_2} 
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextInput1("");
                }}
                />
              }
            </div>


            {
              selectedTabIndex == 0 ?
              <></>
              :
              <>
            
            <div className="textinput_form_container0 textinput_form_container2">
                <input 
                  type={`text`}
                  value={textInput2}
                  onChange={e=> {
                    // e.preventDefault();
                    // e.stopPropagation();
                    setTextInput2(e.target.value);
                  }}
                  placeholder={selectedTabIndex == 0 ? `생년월일(20010123)을 입력하세요` : `이메일을 입력하세요`}
                />
                
                {
                  textInput2.length > 0 &&
                  <img src={appImages._000_2} />
                } 
               
                {
                  textInput2.length > 0 &&
                  <img src={appImages._000_2} 
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput2("");
                  }}
                  />
                }
              </div>
              <div className="textinput_form_container0 textinput_form_container2">
                <input 
                  type={`password`}
                  value={textInput3}
                  maxLength={4}
                  onChange={e=> {
                    // e.preventDefault();
                    // e.stopPropagation();
                    setTextInput3(e.target.value);
                  }}
                  placeholder={`비밀번호 복구 코드를 입력하세요.`}
                />
                
                {
                  textInput3.length > 0 &&
                  <img src={appImages._000_2} />
                } 
               
                {
                  textInput3.length > 0 &&
                  <img src={appImages._000_2} 
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput3("");
                  }}
                  />
                }
              </div>
              </>
            }
            <div className="signin_button0 signin_button1"
              onClick={e=>{
                e.preventDefault();
                e.stopPropagation();
                if (selectedTabIndex == 0) {

                  handleFindUserExists("email", textInput1, 1)
                } else {

                  handleFindUserExists("email", textInput2, 2, textInput1, textInput3)
                }
              }}  
            >비밀번호 찾기</div>

          </div>


        </div>
      </>
      
    );
}
