import React, { Component, useEffect, useState, useContext, useRef } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../../redux/authRedux/auth.reducer";
import { setAuth } from "../../../redux/authRedux/auth.actions";
import { category_KEY } from "../../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../../redux/filterRedux/filter.actions";
import imageCompression from "browser-image-compression";

import uuid from 'react-uuid';
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import TabBottom from "../../TabBottom/Tab";

import DropDownClickable from "../../../components/DropdownClickable";

import "./aa01.css";

const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]



  const conversationTopicArray = [
    {
        index : 1, 
        enName : '',
        krName : '정신건강',
    },
    {
        index : 2, 
        enName : '',
        krName : '성생활',
    },
    {
        index : 3, 
        enName : '',
        krName : '금전/사업',
    },
    {
        index : 4, 
        enName : '',
        krName : '대인관계',
    },
    {
        index : 5, 
        enName : '',
        krName : '자아/성격',
    },
    {
        index : 6, 
        enName : '',
        krName : '결혼/육아',
    },
    {
        index : 7, 
        enName : '',
        krName : '이별/이혼',
    },
    {
        index : 8, 
        enName : '',
        krName : '가족',
    },
  ]

const pageCode1 =   'page005/0/mypage';

// <div className={``}></div>
export default function ThisComponent(props) {
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);


    const [url, setUrl] = useState("");
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const [boolPopup1, setBoolPopup1] = useState(false);
  const [popupMode, setPopupMode] = useState("");

  const [textInput1, setTextInput1] = useState("");
  const [textInput2, setTextInput2] = useState("");
  const [textInput3, setTextInput3] = useState("");
  const [textInput4, setTextInput4] = useState("");
  const [textInput5, setTextInput5] = useState("");
  const [textInput6, setTextInput6] = useState("");
  const [textInput7, setTextInput7] = useState("");
  const [textInput8, setTextInput8] = useState("");
  const [textInput9, setTextInput9] = useState("");
  const [textInput10, setTextInput10] = useState("");


  const [boolCheck1, setBoolCheck1] = useState(false);
  const [boolCheck2, setBoolCheck2] = useState(false);
  const [boolCheck3, setBoolCheck3] = useState(false);

  const [intSelectedTab, setIntSelectedTab] = useState(0);

  const imageRef0 = useRef();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const imageRef6 = useRef();
  const imageRef7 = useRef();
  const imageRef8 = useRef();
  const imageRef9 = useRef();
  const imageRef10 = useRef();

  const [imageUpload0, setImageUpload0] = useState('');
  const [imageUpload1, setImageUpload1] = useState('');
  const [imageUpload2, setImageUpload2] = useState('');
  const [imageUpload3, setImageUpload3] = useState('');
  const [imageUpload4, setImageUpload4] = useState('');
  const [imageUpload5, setImageUpload5] = useState('');
  const [imageUpload6, setImageUpload6] = useState('');
  const [imageUpload7, setImageUpload7] = useState('');
  const [imageUpload8, setImageUpload8] = useState('');
  const [imageUpload9, setImageUpload9] = useState('');
  const [imageUpload10, setImageUpload10] = useState('');
  
  const [imageLink0, setImageLink0] = useState('');
  const [imageLink1, setImageLink1] = useState('');
  const [imageLink2, setImageLink2] = useState('');
  const [imageLink3, setImageLink3] = useState('');
  const [imageLink4, setImageLink4] = useState('');
  const [imageLink5, setImageLink5] = useState('');
  const [imageLink6, setImageLink6] = useState('');
  const [imageLink7, setImageLink7] = useState('');
  const [imageLink8, setImageLink8] = useState('');
  const [imageLink9, setImageLink9] = useState('');
  const [imageLink10, setImageLink10] = useState('');

  const [arrayData1, setArrayData1] = useState([]);
  const [arrayData2, setArrayData2] = useState([]);
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));


  const [textAge, setTextAge] = useState("");
  const [textLocation, setTextLocation] = useState("");
  const [textGender, setTextGender] = useState("");
  const [arrayHashtag, setArrayHashtag] = useState([]);
  const [textBrother, setTextBrother] = useState("");
  const [textJob, setTextJob] = useState("");
  const [textChildren, setTextChildren] = useState("");

  const [arrayChildren,setArrayChildren ]=  useState([]);
  const [arrayTextChildren0, setArrayTextChildren0] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren1, setArrayTextChildren1] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren2, setArrayTextChildren2] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren3, setArrayTextChildren3] = useState(["","","","","","","","","","",]);

  const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
  const [objectUserKeyname, setObjectUserKeyname] = useState("");
  const [objectUserData, setObjectUserData] = useState(null);

  const [todayMoodData, setTodayMoodData] = useState(null);

  const [textValue1, setTextValue1] = useState("")

  const upload = (paramIndex, paramUpload)=>{

    if (viewScreenMode.mode !== pageCode1) {
      return;
    }

    var returnName1 = `${paramUpload.name}_${moment().format("YYYYMMDD_HHmmss")}`;
    if(paramUpload == null)
      return;
      const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(paramUpload)
      uploadTask
      .on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        // switch (snapshot.state) {
        //   case 'paused':
        //     console.log('Upload is paused');
        //     break;
        //   case 'running':
        //     console.log('Upload is running');
        //     break;
        // }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        fireDb.storage().ref('images').child(returnName1).getDownloadURL()
         .then(fireBaseUrl => {
          //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
          console.log("fireBaseUrl", fireBaseUrl)

          



          if (paramIndex === 0) {
            setImageLink0(fireBaseUrl);
            // 
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname).update({"avatar" : fireBaseUrl});
          } 
          if (paramIndex === 1) {
            setImageLink1(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 2) {
            setImageLink2(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 3) {
            setImageLink3(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 4) {
            setImageLink4(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 5) {
            setImageLink5(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 6) {
            setImageLink6(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 7) {
            setImageLink7(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 8) {
            setImageLink8(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 9) {
            setImageLink9(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
          if (paramIndex === 10) {
            setImageLink10(fireBaseUrl);
            var tempArr = [];
            if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
              tempArr = [...objectUserData.photos]
              tempArr[paramIndex - 1] = fireBaseUrl
            } else {
              tempArr = [fireBaseUrl]
            }
            Firebase.database().ref()
            .child("accounts/" + objectUserKeyname + "/photos").update(tempArr);
          }
         })
      }
    );

  }


  const actionImgCompress = async (paramIndex, fileSrc, type) => {
    console.log("압축 시작");

    const options = {
      maxSizeMB: 0.10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      // 압축 결과
      const compressedFile = await imageCompression(fileSrc, options);
      upload(paramIndex, compressedFile)

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=> {
    const index1 = 0;
    actionImgCompress(0, imageUpload0);
    // checkImageNumber();
  },[
    imageUpload0,
  ])

  useEffect(()=> {
    const index1 = 1;
    actionImgCompress(index1, imageUpload1);
    checkImageNumber();
  },[
    imageUpload1,
  ])
  useEffect(()=> {
    const index1 = 2;
    actionImgCompress(index1, imageUpload2);
    checkImageNumber();
  },[
    imageUpload2,
  ])
  useEffect(()=> {
    const index1 = 3;
    actionImgCompress(index1, imageUpload3);
    checkImageNumber();
  },[
    imageUpload3,
  ])

  useEffect(()=> {
    const index1 = 4;
    actionImgCompress(index1, imageUpload4);
    checkImageNumber();
  },[
    imageUpload4,
  ])
  useEffect(()=> {
    const index1 = 5;
    actionImgCompress(index1, imageUpload5);
    checkImageNumber();
  },[
    imageUpload5,
  ])
  useEffect(()=> {
    const index1 = 6;
    actionImgCompress(index1, imageUpload6);
    checkImageNumber();
  },[
    imageUpload6,
  ])
  useEffect(()=> {
    const index1 = 7;
    actionImgCompress(index1, imageUpload7);
    checkImageNumber();
  },[
    imageUpload7,
  ])

  useEffect(()=> {
    const index1 = 8;
    actionImgCompress(index1, imageUpload8);
    checkImageNumber();
  },[
    imageUpload8,
  ])
  useEffect(()=> {
    const index1 = 9;
    actionImgCompress(index1, imageUpload9);
    checkImageNumber();
  },[
    imageUpload9,
  ])
  useEffect(()=> {
    const index1 = 10;
    actionImgCompress(index1, imageUpload10);
    checkImageNumber();
  },[imageUpload10,])

  function checkImageNumber() {
    var checkIndex1 = 0;
    if (imageUpload1) {
      checkIndex1 ++;
    }
    if (imageUpload2) {
      checkIndex1 ++;
    }
    if (imageUpload3) {
      checkIndex1 ++;
    }
    if (imageUpload4) {
      checkIndex1 ++;
    }
    if (imageUpload5) {
      checkIndex1 ++;
    }
    if (imageUpload6) {
      checkIndex1 ++;
    }
    if (imageUpload7) {
      checkIndex1 ++;
    }
    if (imageUpload8) {
      checkIndex1 ++;
    }
    if (imageUpload9) {
      checkIndex1 ++;
    }
    if (imageUpload10) {
      checkIndex1 ++;
    }
    setIntUploadedNumber1(checkIndex1)

  }


  const resizeTextArea = () => {
    if (textAreaRef1 && textAreaRef1.current && textAreaRef1.current.style) {

      textAreaRef1.current.style.height = "auto";
      textAreaRef1.current.style.height = textAreaRef1.current.scrollHeight + "px";
  
    }
    if (textAreaRef2 && textAreaRef2.current && textAreaRef2.current.style) {

      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + "px";
    }
  };

    const downloadQRCode = (e) => {
      e.preventDefault();
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    
  const handleEnter = (e) => {
    // if (textSearch1 == "") {
    //   return;
    // }
    if (textInput9.trim() == "") {
      return;
    }
    if (arrayHashtag.length == 9) {
      return;
    }

    if ( e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      // handleLogin();
      var tempArray = [...arrayHashtag];
      tempArray.push(textInput9)
      setArrayHashtag(tempArray);
      setTextInput9("");

    }
}

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore") 
      if (true && true) {
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        Firebase.database().ref()
        .child("behaviors/diary-checkins")
        .orderByChild("username")
        .equalTo(authStoreUserName)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/posts --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setArrayData1(Object.values(data))
            }
          }
        });

        Firebase.database().ref()
        .child("behaviors/community-checkins")
        .orderByChild("username")
        .equalTo(authStoreUserName)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/posts --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setArrayData2(Object.values(data))
              const array1 = Object.values(data)
              const filter1 = array1.filter((filterItem, filterIndex)=> {
                if (filterItem.created_day === moment().format("YYYY-MM-DD")){
                  return filterItem
                }
              })

              if (filter1.length > 0) {

                setTodayMoodData(filter1[0])
              }

            }
          }
        });


        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        // setObjectUserData(data[keyName]);
                        // setObjectUserKeyname(keyName)
                        // props && props.handleUser(keyName, data[keyName]); 
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName);
                        setUrl("ssokdak@nickname:" + data[keyName].nickname)
                        if (data[keyName].gender) {

                          setTextGender( data[keyName].gender)
                        }
                        if (data[keyName].job) {

                          setTextJob( data[keyName].job)
                        }
                        
                        if (data[keyName].birth) {

                          var split1 =  data[keyName].birth.split("-")
                          var year1 = split1[0];
                          var month1 = split1[1];
                          var date1 = split1[2];

                          const today = new Date();
                          const birthDate = new Date(parseInt(year1), parseInt(month1) - 1, parseInt(date1)); // 2000년 8월 10일
                    
                          let age = today.getFullYear()  - birthDate.getFullYear() + 1;
                          setTextAge(age); 
                        }
                        if (data[keyName].hobby) {

                          setArrayHashtag( data[keyName].hobby)
                        }
                        if (data[keyName].location) {

                          setTextLocation( data[keyName].location)
                        }
                        if (data[keyName].brother) {

                          var split1 =  data[keyName].brother.split("/")
                          var temp1 = split1[0];
                          var temp2 = split1[1];
                          setTextBrother(temp1 + "남 " + temp2 + "녀")
                        }
                        if (data[keyName].arrayChildren) {

                          setArrayChildren(data[keyName].arrayChildren)
                          var count = 0;
  
                          data[keyName].arrayTextChildren0.map(item => {
                            if (item === "male") count++;
                          });
                          var count2 = 0;
  
                          data[keyName].arrayTextChildren0.map(item => {
                            if (item === "female") count2++;
                          });
                          
                          setTextChildren("" + count + "남 " + count2 + "녀")
                          
                          setArrayTextChildren0(data[keyName].arrayTextChildren0)
                          setArrayTextChildren1(data[keyName].arrayTextChildren1)
                          setArrayTextChildren2(data[keyName].arrayTextChildren2)
                          setArrayTextChildren3(data[keyName].arrayTextChildren3)
                        }
 
                        if (data[keyName].intro) {

                          setTextValue1(data[keyName].intro)
                        } 
                        // brotherPublic, childrenPublic
                        
                        if (data[keyName].brotherPublic) {

                          setBoolCheck1(data[keyName].brotherPublic)
                        } 
                        if (data[keyName].jobPublic) {

                          setBoolCheck2(data[keyName].jobPublic)
                        } 
                        if (data[keyName].childrenPublic) {

                          setBoolCheck3(data[keyName].childrenPublic)
                        }
                        if (data[keyName].avatar) {

                          setImageLink0(data[keyName].avatar);
                        }

                        console.log("data[keyName].photos", data[keyName].photos)
                        if (data[keyName].photos) {
                          var tempCount1 = 1;
                          console.log("data[keyName].photos.length ", data[keyName].photos.length )
                          if (data[keyName].photos.length >= tempCount1) {
                            console.log("data[keyName].photos.length 111 ", data[keyName].photos[tempCount1 - 1])

                            setImageLink1(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            console.log("data[keyName].photos.length 222 ", data[keyName].photos[tempCount1 - 1])
                            setImageLink2(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink3(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink4(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink5(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink6(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink7(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink8(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink9(data[keyName].photos[tempCount1 - 1]);
                          }
                          tempCount1 = tempCount1 + 1
                          if (data[keyName].photos.length >= tempCount1) {
                            setImageLink10(data[keyName].photos[tempCount1 - 1]);
                          } 
                          
                        }

                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });



      }

    }, [useSelector(state => state)])


    useEffect(()=> {

      resizeTextArea();
    }, [textValue1])


    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }
    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`}> 
        
      <div 
            className="profTopNavWrapper" 
            style={{ 
              // minHeight : textCalendarMode === "week" ? "52px" : "52px",
              transition: `all 0.5s ease-out`,
            }}
          >
          <div className="profTopNavTopBar">
              {/* <div className="profTopNavDiv1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                미리보기
              </div> */}
              <img className="profTopIcon1" src={`/static/images/ssokdak/Tab5/iconTopGear.svg`} alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                  marginLeft: 'auto',
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                  setScreenMode1('page005/0/settings')

                }}
              />
          </div>
        
          <div className="profTopPrevWrapper">
            <div className="profImgWrapper" 
            >
              <img className="profImg" 
              // src={`https://dummyimage.com/600x400/aaa/eee.gif`} 
              src={imageLink0 === "" ? `/static/images/ssokdak/Tab5/defaultProf.svg` : imageLink0} 
              alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  imageRef0.current.click();
                }}
              />
            </div>
            <div className="proNicknameWrapper">
                <div className="text0 text1">
                {objectUserData && objectUserData.nickname ?  objectUserData.nickname : "-"}
                  </div> 
                {/* <img className="icon0 icon1" src={`/static/images/ssokdak/Tab5/iconPencil.svg`} alt=""
                    style={{
                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                    // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    }}
                /> */}
            </div>

            <div className="profPrevStatusWrapper">
                <div className="tab0 tab1">

                    <img className="icon0 icon1" src={`/static/images/ssokdak/Tab5/iconPickProfile.svg`} alt=""
                        style={{
                        // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                        // transition: `all 0.5s ease-out`,
                        }}
                        onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();

                        }}
                    />
                    <div className="col0 col1">
                        <div className="text0 text1">내 친구</div>
                        <div className="text0 text2">0</div>
                    </div>
                </div>
                <div className="tabDiv1"></div>
                <div className="tab0 tab2">
                    <img className="icon0 icon1" src={`/static/images/ssokdak/Tab5/iconPinkDiary.svg`} alt=""
                        style={{
                        // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                        // transition: `all 0.5s ease-out`,
                        }}
                        onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();

                        }}
                    />
                    <div className="col0 col1">
                        <div className="text0 text1">내 마음기록</div>
                        <div className="text0 text2">{arrayData1.length}</div>
                    </div>
                </div>
            </div>


              <div className="checkin-button1 "
              
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                  setScreenMode1( 'page004/998/chat-list');
                }}>
                    커뮤니티 체크인
                </div>

          </div>


        <div className="chatTabMenuWrapper">
          <div className="chatTab0 chatTab1" 
            // onClick={e=> {}}
          >
            <div className="text0 text1"
            style={{ 
              color : intSelectedTab === 0 ? `#0f0f0f` : `#8B8B8B`,
              borderBottom : intSelectedTab === 0 ? `2px solid #0f0f0f` : `2px solid transparent`
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setIntSelectedTab(0);
            }}
            >내 프로필</div>
          </div>
          <div className="chatTab0 chatTab2">
            <div className="text0 text1"
            style={{ 
              color : intSelectedTab === 1 ? `#0f0f0f` : `#8B8B8B`,
              borderBottom : intSelectedTab === 1 ? `2px solid #0f0f0f` : `2px solid transparent`
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setIntSelectedTab(1);
            }}>체크인 정보</div>
          </div>
        </div>

            {
                intSelectedTab === 0 &&
                <>
                <div className="profTopTabContentWrapper">
                  <div className="sectionLabel0 sectionLabel1">
                      {`오늘의 기분`}
                  </div>
                  <div className="sectionGrayWrapper0 sectionGrayWrapper1" 
                  >
                      <div className="row0 row1" 
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setScreenMode1('page004/998/chat-daily-first')
                                }}>
                          <img 
                              className="icon0 icon1" 
                              // src={emoCardArray[0].iconOnlyPathName} 

                              src={todayMoodData && todayMoodData.mind? todayMoodData.mind.iconOnlyPathName : `/static/images/ssokdak/Tab5/defaultEmo.svg`} 
                              alt=""
                              style={{
                              // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                              // transition: `all 0.5s ease-out`,
                              }}
                              />
                          <div className="text0 text1 textEmpty">
                            {todayMoodData  && todayMoodData.mind? todayMoodData.mind.krName :
                            `오늘 기분은 어때요?`
                            }
                            
                              {/* {emoCardArray[0].krName} */}
                          </div>
                          
                          {todayMoodData  && todayMoodData.mind? <></> :

                                <img 
                                className="icon0 icon2" 
                                src={`/static/images/ssokdak/Tab5/iconBlackPlus.svg`} 
                                alt=""
                                style={{
                                // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                // transition: `all 0.5s ease-out`,
                                }}
                                />
                          }
                      </div>
                  </div>
      
                  <div className="sectionLabel0 sectionLabel1">
                      {`오늘의 관심주제`}
                  </div>
                  <div className="sectionGrayWrapper0 sectionGrayWrapper2">

                  {
                                todayMoodData  && todayMoodData.topic?  
                                <div className="row0">
                                { 
                                Array.from(conversationTopicArray) 
                                .map((mapItem, mapIndex)=> {

                                    // console.log("todayMoodData.topic", todayMoodData.topic)

                                    const ext0 = todayMoodData.topic && todayMoodData.topic.findIndex((findItem, findIndex)=> {
                                      if (("" + mapIndex) === findItem) {
                                        return findItem
                                      }
                                    })

                                    if (ext0 < 0) {
                                      return <></>
                                    }

                                    return (
                                    <div className="tagggItemWhite " 
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1('page004/998/chat-daily-first')
                                            setScreenMode1('page004/998/chat-daily-first')
                                        }}
                                    >
                                        {/* #{mapItem.krName} */}
                                        #{ mapItem.krName} 
                                    </div>)
                                })
                                }
                                </div>
                                :

                                <div className="emptyIconOnly">
                                  <img 
                              className="icon0 icon2" 
                              src={`/static/images/ssokdak/Tab5/iconBlackPlus.svg`} 
                              alt=""
                              style={{
                              // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                              // transition: `all 0.5s ease-out`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setScreenMode1('page004/998/chat-daily-first')
                              }}
                              />
                                </div>
                              } 
                  </div>
      
                  <div className="sectionLabel0 sectionLabel1">
                      {`자기소개`}
                      <div className="rightFloatUpdateButton"
                      
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        Firebase.database().ref()
                        .child("accounts/" + objectUserKeyname).update({"intro" : textValue1});
                      }}
                      >수정</div>
                  </div>
                  <div className="sectionGrayWrapper0 sectionGrayWrapper3">
                      <div className="row0 row1">
                        {/* {`소개를 남겨주셔서 더 알려주세요`} */}
                          <textarea 
                            ref={textAreaRef1}
                            className="textArea001 input0"
                            value={textValue1}
                            onChange={e=> {
                              setTextValue1(e.target.value);
                            }}
                            placeholder={`소개를 남겨주셔서 더 알려주세요`}
                          />

                      </div>
                  </div>
                  <div className="sectionLabel0 sectionLabel1">
                      {`프로필 이미지`}
                  </div>
                  <div className="imagePlusRow0 imagePlusRow1">
                      
                    <div className="row0 row1">
      
      <input ref={imageRef0} 
              type="file" 
              onChange={(e)=>{
                
                setImageUpload0(e.target.files[0])
              }} 
              // accept="image/*"
               accept=".gif, .jpg, .png"
             // accept=".png"
              style={{display : 'none'}} alt="" />
              <button onClick={upload} style={{display : 'none'}}>Upload</button>
      
                  <input ref={imageRef1} 
                          type="file" 
                          onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          <input ref={imageRef2} 
                          type="file" 
                          onChange={(e)=>{setImageUpload2(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef3} 
                          type="file" 
                          onChange={(e)=>{setImageUpload3(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef5} 
                          type="file" 
                          onChange={(e)=>{setImageUpload5(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef6} 
                          type="file" 
                          onChange={(e)=>{setImageUpload6(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef7} 
                          type="file" 
                          onChange={(e)=>{setImageUpload7(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef8} 
                          type="file" 
                          onChange={(e)=>{setImageUpload8(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef9} 
                          type="file" 
                          onChange={(e)=>{setImageUpload9(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef10} 
                          type="file" 
                          onChange={(e)=>{setImageUpload10(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                  <div className="radius001_image_row_wrapper">
      
                  <div className="radius001_image_wrapper" 
                          style={{ 
                            background: `rgba(33, 37, 41, 0.7)`,
                            border : `none` ,
                          }}
                        >
                          {
                            // imageUpload1 == '' &&
                            <>
                            <div className="radius001_0" 
                              onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              if (imageUpload1) {
                              } else {
                                imageRef1.current.click();
                                return;
                              }
                              if (imageUpload2) {
                              } else {
                                imageRef2.current.click();
                                return;
                              }
                              if (imageUpload3) {
                              } else {
                                imageRef3.current.click();
                                return;
                              }
                              if (imageUpload4) {
                              } else {
                                imageRef4.current.click();
                                return;
                              }
                              if (imageUpload5) {
                              } else {
                                imageRef5.current.click();
                                return;
                              }
                              if (imageUpload6) {
                              } else {
                                imageRef6.current.click();
                                return;
                              }
                              if (imageUpload7) {
                              } else {
                                imageRef7.current.click();
                                return;
                              }
                              if (imageUpload8) {
                              } else {
                                imageRef8.current.click();
                                return;
                              }
                              if (imageUpload9) {
                              } else {
                                imageRef9.current.click();
                                return;
                              }
                              if (imageUpload10) {
                              } else {
                                imageRef10.current.click();
                                return;
                              }
      
                            }}>
      
                              <img className="radius001_image0" 
                              src={`/static/images/ssokdak/Tab5/iconBlackPlus.svg`} alt="" />
      
      
                              <div className="radius001_desc0">사진을<br/>등록해주세요.</div>
                            </div>
                            </>
                          }
                          <div>
                          </div>
                    </div>
      
                    {
                      imageLink1 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();
                      }}
                    >
                      {
                        imageLink1 !== '' &&
                        <>
                        <img className="full_image" src={imageLink1} alt=""/>
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink2 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef2.current.click();
                      }}
                    >
                      {
                        imageLink2 !== '' &&
                        <>
                        <img className="full_image" src={imageLink2} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink3 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef3.current.click();
                      }}
                    >
                      {
                        imageLink3 !== '' &&
                        <>
                        <img className="full_image" src={imageLink3} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink4 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef4.current.click();
                      }}
                    >
                      {
                        imageLink4 !== '' &&
                        <>
                        <img className="full_image" src={imageLink4} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
      
      
      {
                      imageLink5 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef5.current.click();
                      }}
                    >
                      {
                        imageLink5 !== '' &&
                        <>
                        <img className="full_image" src={imageLink5} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                                    imageUpload6 !== '' && 
                    
                                    <div className="radius001_image_wrapper" 
                                    style={{ 
                                      background:  `transparent`,
                                      border : `1px solid #aeaeae`,
                                    }}
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      imageRef6.current.click();
                                    }}
                                  >
                                    {
                                      imageUpload6 !== '' &&
                                      <>
                                      <img className="full_image" src={imageLink6} alt="" />
                                      </>
                                    }
                                    <div>
                                    </div>
                              </div>
                                  }
                                  {
                              imageUpload7 !== '' && 
              
                              <div className="radius001_image_wrapper" 
                              style={{ 
                                background:  `transparent`,
                                border : `1px solid #aeaeae`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                imageRef7.current.click();
                              }}
                            >
                              {
                                imageUpload7 !== '' &&
                                <>
                                <img className="full_image" src={imageLink7} alt="" />
                                </>
                              }
                              <div>
                              </div>
                        </div>
                            }
                            {
                        imageUpload8 !== '' && 
        
                        <div className="radius001_image_wrapper" 
                        style={{ 
                          background:  `transparent`,
                          border : `1px solid #aeaeae`,
                        }}
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          imageRef8.current.click();
                        }}
                      >
                        {
                          imageUpload8 !== '' &&
                          <>
                          <img className="full_image" src={imageLink8} alt="" />
                          </>
                        }
                        <div>
                        </div>
                  </div>
                      }
                      {
                  imageUpload9 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload9 !== '' &&
                    <>
                    <img className="full_image" src={imageLink9} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
      
      {
                  imageUpload10 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload10 !== '' &&
                    <>
                    <img className="full_image" src={imageLink10} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
                      </div>
                      </div>
                  </div>
      
      
      
      
                </div>
                </>
            }



            {
                intSelectedTab === 1 &&
                <>
                <div className="profTopTabContentWrapper">
                    <div className="infoRow0 infoRow1">
                        <div className="text0 text1">성별</div>
                        <div className="text0 text2">
                          {textGender === "female" && "여"}
                          {textGender === "male" && "남"}
                        </div>
                        <img 
                            className="icon0 icon1" 
                            src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                            alt=""
                            style={{
                            // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                            // transition: `all 0.5s ease-out`,
                            }}
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              setBoolPopup1(true);
                              setPopupMode("gender");
                            }}
                        />
                    </div>
                        <div className="infoRow0 infoRow1">
                            <div className="text0 text1">나이</div>
                            <div className="text0 text2">{textAge}</div>
                            <img 
                                className="icon0 icon1" 
                                src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                alt=""
                                style={{
                                // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                // transition: `all 0.5s ease-out`,
                                }}
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setBoolPopup1(true);
                                  setPopupMode("age");

                                }}
                            />
                        </div>
                        <div className="infoRow0 infoRow1">
                            <div className="text0 text1">지역</div>
                            <div className="text0 text2">{textLocation}</div>
                            <img 
                                className="icon0 icon1" 
                                src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                alt=""
                                style={{
                                // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                // transition: `all 0.5s ease-out`,
                                }}
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setBoolPopup1(true);
                                  setPopupMode("location");

                                }}
                            />
                        </div>
                  <div className="infoRow0 infoRow1">
                            <div className="text0 text1">{`취미/관심사`}</div>
                            <div className="text0 text2"></div>
                            <img 
                                className="icon0 icon1" 
                                src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                alt=""
                                style={{
                                // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                // transition: `all 0.5s ease-out`,
                                }}
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setBoolPopup1(true);
                                  setPopupMode("hobby");

                                }}
                            />
                        </div>

                  <div className="sectionGrayWrapper0 sectionGrayWrapper2" style={{ marginTop: `0px`}}>
                      <div className="row0">
      
                      {
                      Array.from(arrayHashtag)
                      // Array.from(conversationTopicArray)
                      // .sort(function(a, b)  {
                      //     return a.index - b.index;
                      // })
                      .map((mapItem, mapIndex)=> {
                          return (
                          <div className="tagggItemWhite " 
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                              }}
                          >
                              #{mapItem}
      
                              <img 
                                  className="icon0 icon2" 
                                  src={`/static/images/ssokdak/Tab5/iconGrayClose.svg`} 
                                  alt=""
                                  style={{
                                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                  // transition: `all 0.5s ease-out`,
                                  }}
                                  onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  var tempArray = [...arrayHashtag];
                                  tempArray.splice(mapIndex, 1);
                                  setArrayHashtag(tempArray);
                                  
    
      
                                  }}
                                  />
                          </div>)
                      })
                      }
                      </div>
                  </div>
                        <div className="infoRow0 infoRow1">
                            <div className="text0 text1">내 정보공개</div>
                            <DropDownClickable
                                value={
                                    <img 
                                        className="icon0 icon1" 
                                        src={`/static/images/ssokdak/Tab5/iconQuestionMark.svg`} 
                                        alt=""
                                        style={{
                                        // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                        // transition: `all 0.5s ease-out`,
                                        }}
                                    />}
                                options={<div className="questionGuideDrop1">
                                    <div className="text1">
                                    {parse(`해당 항목들은 선택항목들로<br/>노출여부를 선택할 수 있습니다. `)}
                                    </div>
                                    <style>{`
                                    .questionGuideDrop1 {

                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                        width: 175px;
                                        height: 68px;

                                        /* Gray/08 */
                                        background: #424242;
                                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
                                        border-radius: 8px;

                                    }
                                    .questionGuideDrop1 .text1 {
                                         width : 100%;
                                        height :  calc(100% - 32px);

                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 150%;
                                        /* or 18px */
                                        display: flex;
                                        align-items: center;

                                        /* White */
                                        color: #FFFFFF;
                                        margin-left: 10px;
                                        margin-right : auto;
                                    }
                                    `}</style>
                                </div>}
                            >

                            </DropDownClickable>
                        </div>
                        <div className="infoRow0 infoRow2">
                            <div className="row0 row1">
                                <div className="text0 text1">형제관계</div>
                                <img 
                                    className="icon0 icon1" 
                                    src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setBoolPopup1(true);
                                      setPopupMode("brother");

                                    }}
                                />
                                <img 
                                    className="icon0 icon2" 
                                    src={
                                      boolCheck1 === true ?
                                      `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                                      :
                                      `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    Firebase.database().ref()
                                    .child("accounts/" + objectUserKeyname).update({"brotherPublic" : !boolCheck1});

                                    setBoolCheck1(!boolCheck1)




                                    

                                    }}
                                />
                            </div>
                            <div className="row0 row2">
                                <div className="text0 text1">{textBrother.replaceAll("0남", "").replaceAll("0녀", "")}</div>
                            </div>
                        </div>
                        <div className="infoRow0 infoRow2">
                            <div className="row0 row1">
                                <div className="text0 text1">직업</div>
                                <img 
                                    className="icon0 icon1" 
                                    src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setBoolPopup1(true);
                                    setPopupMode("job");

                                    }}
                                />
                                <img 
                                    className="icon0 icon2" 
                                    src={
                                      boolCheck2 === true ?
                                      `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                                      :
                                      `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    Firebase.database().ref()
                                    .child("accounts/" + objectUserKeyname).update({"jobPublic" : !boolCheck2});

                                    setBoolCheck2(!boolCheck2)

                                    }}
                                />
                            </div>
                            <div className="row0 row2">
                                <div className="text0 text1">{textJob}</div>
                            </div>
                        </div>
                        <div className="infoRow0 infoRow2">
                            <div className="row0 row1">
                                <div className="text0 text1">자녀육아</div>
                                <img 
                                    className="icon0 icon1" 
                                    src={`/static/images/ssokdak/Tab5/iconArrowRight.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setBoolPopup1(true);
                                    setPopupMode("children");

                                    }}
                                />
                                <img 
                                    className="icon0 icon2" 
                                    src={
                                      boolCheck3 === true ?
                                      `/static/images/ssokdak/Tab5/iconSwitchPink.svg`
                                      :
                                      `/static/images/ssokdak/Tab5/iconSwitchGray.svg`} 
                                    alt=""
                                    style={{
                                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                    // transition: `all 0.5s ease-out`,
                                    }}
                                    onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    Firebase.database().ref()
                                    .child("accounts/" + objectUserKeyname).update({"childrenPublic" : !boolCheck3});
                                    setBoolCheck3(!boolCheck3)

                                    }}
                                />
                            </div>
                            <div className="row0 row2">
                                <div className="text0 text1">{textChildren.replaceAll("0남", "").replaceAll("0녀", "")}</div>
                            </div>
                        </div>

                </div>


                </>
                }


                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: boolPopup1 === false ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          <div className="contentView"> 
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setBoolPopup1(false);
                                  setPopupMode("");
                              }}
                              />
                            </div>
                            {
                              boolPopup1 === true &&
                              
                            <div className="bottomSheetEmoWrapper">
                              
                              {
                                popupMode === "gender" &&
                              <div className="topLabel">
                              {  <>{parse(`성별을<br/>알려주세요.`)}</>}
                              <div className="genderInitImgWrapper ">
                                  <div className="genderItem">
                                      <img className="img0 img1" 
                                      src={ 
                                          textInput6 === "female" ?
                                          `/static/images/ssokdak/Init/genderFeMaleOn.png` 
                                          :
                                          `/static/images/ssokdak/Init/genderFeMaleOff.png` 
                                      } alt=""
                                      onClick={e=> {
                                          e.preventDefault();
                                          e.stopPropagation();


                                          Firebase.database().ref()
                                          .child("accounts/" + objectUserKeyname).update({"gender" : "female"});



                                          setTextInput6("female")
                                          setTextGender("female")
                                          setBoolPopup1(false);

                                          
                                      }}
                                      />
                                  </div>
                                  <div className="genderItem">
                                      <img className="img0 img1" 
                                      src={ 
                                          textInput6 === "male" ?
                                          `/static/images/ssokdak/Init/genderMaleOn.png` 
                                          :
                                          `/static/images/ssokdak/Init/genderMaleOff.png` 
                                      } alt=""
                                      onClick={e=> {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setTextInput6("male")
                                          setTextGender("male")
                                          setBoolPopup1(false);


                                          Firebase.database().ref()
                                          .child("accounts/" + objectUserKeyname).update({"gender" : "male"});
                                      }}
                                      />
                                  </div>
                              </div>
                              </div>
                              }
                              {
                                popupMode === "age" &&
                                <>
                                <div className="topLabel">{parse(`나이를<br/>알려주세요.`)}
                                <div className="inputRow0 inputRow1">
                                  <div className="inputWrapper0 inputWrapper1">
                                      <input 
                                        value={textInput1}
                                        onChange={e=> {
                                          // e.preventDefault();
                                          // e.stopPropagation();

                                          var temp = e.target.value.replace(/[^0-9]+/g, "");
                                          if (temp.length > 2) {
                                              return;
                                          }
                                          
                                          setTextInput1(temp);
                                        }}
                                        placeholder={`DD`}
                                      />
                                  </div>
                                  <div className="inputWrapper0 inputWrapper2">
                                      <input 
                                        value={textInput2}
                                        onChange={e=> {
                                          // e.preventDefault();
                                          // e.stopPropagation();
                                          var temp = e.target.value.replace(/[^0-9]+/g, "");
                                          if (temp.length > 2) {
                                              return;
                                          }
                                          setTextInput2(temp);
                                        }}
                                        placeholder={`MM`}
                                      />
                                  </div>
                                  <div className="inputWrapper0 inputWrapper3">
      
                                  <input 
                                        value={textInput3}
                                        onChange={e=> {
                                          // e.preventDefault();
                                          // e.stopPropagation();
                                          var temp = e.target.value.replace(/[^0-9]+/g, "");
                                          if (temp.length > 4) {
                                              return;
                                          }
                                          setTextInput3(temp);
                                        }}
                                        placeholder={`YYYY`}
                                      />
      
                                  </div>
                                </div>
                                        
                                        {
                                          props && props.appKeyboardVisible === "visible" ?
                                          <></>
                                          :

                                          <div className="small_confirm"

                                          onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
  
                                            
                                            if (textInput1 === "" || textInput2 === "" || textInput3 === "") {
                                              return;
  
                                            } else {
  
                                              const today = new Date();
                                              const birthDate = new Date(textInput3, parseInt(textInput2) - 1, textInput1); // 2000년 8월 10일
                                        
                                              let age = today.getFullYear()  - birthDate.getFullYear() + 1;
                                              setTextAge(age);
  
                                              var temp2 = textInput2;
                                              var temp1 = textInput1;
                                              if (parseInt(textInput2) < 10 ) {
                                                temp2 = "0" + temp2;
                                              }
                                              if (parseInt(textInput1) < 10 ) {
                                                temp1 = "0" + temp1;
                                              }
                                              
                                              Firebase.database().ref()
                                              .child("accounts/" + objectUserKeyname).update({"birth" : textInput3 + "-" + temp2 + "-" + temp1 });
                                              
                                              setBoolPopup1(false);
                                              setPopupMode("");
                                            }
  
  
  
                                        }}
                                    >
                                      확인
                                    </div>
                                        }

                                </div>

                                
                                </>
                              }

                              {
                                popupMode === "location" &&
                                <>
                                
                  <div className="selectJobWrapper">
                    {
                      Array.from(
                      ["서울", "경기도", "인천", "강원도", "충청도", "전라도", "경상도","부산", "제주"]
                      ).map((mapItem, mapIndex)=> {
                        return (
                          <div className="selectJobItem"

                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation(); 
                            setTextInput7(mapItem);
                            setTextLocation(mapItem);
                            Firebase.database().ref()
                            .child("accounts/" + objectUserKeyname).update({"location" :  mapItem});
                            
                            setBoolPopup1(false);
                            setPopupMode("");
                        }}
                          >
                            <div className="text0 text1">{mapItem}</div>
                            <img className="icon0 icon1" 
                              src={ mapItem === textInput7
                                ? `/static/images/ssokdak/Tab4/checkPink1.svg` : 

                                `/static/images/ssokdak/Tab4/checkGray1.svg`
                              } alt=""
                              style={{ marginLeft : 'auto'}} 
                              />
                          </div>
                        )
                      })
                    }
                  </div>
                                </>
                              }

                            {
                              popupMode === "hobby" &&
                              <>
                              <div className="topLabel">{parse(`취미를<br/>가지고 있나요?`)}
                              <div className="inputRow0 inputRow1">
                                <div className="inputWrapper0 inputWrapperA">
                                    <input 
                                      value={textInput9}
                                      placeholder={`엔터를 이용해 입력해주세요`}
                                      onChange={e=> {
                                        setTextInput9(e.target.value);
                                      }}
                                      maxLength={12}
                                      onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        
                                      }}
                                      onKeyDown={handleEnter}
                                    />
                                </div>
                              </div>

                  <div className="sectionGrayWrapper0 sectionGrayWrapper2" style={{ marginTop: `0px`}}>
                      <div className="row0">
      
                      {
                      Array.from(arrayHashtag)
                      // Array.from(conversationTopicArray)
                      // .sort(function(a, b)  {
                      //     return a.index - b.index;
                      // })
                      .map((mapItem, mapIndex)=> {
                          return (
                          <div className="tagggItemWhite " 
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                              }}
                          >
                              #{mapItem}
      
                              <img 
                                  className="icon0 icon2" 
                                  src={`/static/images/ssokdak/Tab5/iconGrayClose.svg`} 
                                  alt=""
                                  style={{
                                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                  // transition: `all 0.5s ease-out`,
                                  }}
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    var tempArray = [...arrayHashtag];
                                    tempArray.splice(mapIndex, 1);
                                    setArrayHashtag(tempArray);
                                    
      
                                  }}
                                  />
                          </div>)
                      })
                      }
                      </div>
                  </div>
                                  <div className="small_confirm"

                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();


                                if (arrayHashtag.length > 0) {
                                  Firebase.database().ref()
                                  .child("accounts/" + objectUserKeyname).update({"hobby" :  arrayHashtag});
                            
                                } else {
                            
                                  Firebase.database().ref()
                                  .child("accounts/" + objectUserKeyname).update({"hobby" :  []});
                                }

                                setBoolPopup1(false);
                                setPopupMode("");

                            }}
                                  >
                                    확인
                                  </div></div>

                              
                              </>
                            }

                            {
                              popupMode === "brother" && 
                              <>
                              <div className="topLabel">{parse(`형제관계가<br/>어떻게 되나요?`)}<div className="inputRow0 inputRow1">
                                <div className="inputWrapper0 inputWrapper1">
                                    <input 
                                      value={textInput4}
                                      onChange={e=> {
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        var temp = e.target.value.replace(/[^0-9]+/g, "");
                                        if (temp.length > 2) {
                                            return;
                                        }
                                        
                                        // if (parseInt(temp) > 12) {
                                        //   temp = 12;
                                        // }
                                        setTextInput4(temp);
                                      }}
                                      placeholder={`01`}
                                    />
                                </div>
                                <div className="inputWrapperPostfixLabel">남</div>
                                <div className="inputWrapper0 inputWrapper2">
                                    <input 
                                      value={textInput5}
                                      onChange={e=> {
                                        // e.preventDefault();
                                        // e.stopPropagation();
                                        var temp = e.target.value.replace(/[^0-9]+/g, "");
                                        if (temp.length > 2) {
                                            return;
                                        }
                                        
                                        // if (parseInt(temp) > 12) {
                                        //   temp = 12;
                                        // }
                                        setTextInput5(temp);
                                      }}
                                      placeholder={`01`}
                                    />
                                </div>
                                <div className="inputWrapperPostfixLabel">녀</div>
                              </div>

                              {
                                props && props.appKeyboardVisible === "visible" ?
                                <>
                                </>
                                :
                                <div className="small_confirm"
  
                                onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
  
                                if (textInput4 === "" || textInput5 === "") {
    
                                } else {
  
                                  Firebase.database().ref()
                                  .child("accounts/" + objectUserKeyname).update({"brother" :  textInput4 + "/" + textInput5});
                                  setTextBrother(textInput4 + "남 " + textInput5 + "녀")
                                  setBoolPopup1(false);
                                  setPopupMode("");
                                }
  
                                }}
                                >
                                  확인
                                </div>
                              }

                              </div>
                              
                              </>
                            }
                            {
                              popupMode === "job" && 
                              <>
                              <div className="topLabel">{parse(`직업이<br/>무엇인가요?`)}
                              <div className="inputRow0 inputRow1"> 
                                <div className="sectionGrayWrapper0 sectionGrayWrapper2" style={{ marginTop: `0px`}}>
                                    <div className="row0">
                    
                                    {
                                    Array.from([
                                      "전업주부","회사원","교사/교직원","전문직 종사자",
                                      "공무원","학생/대학원생","구직/이직자","육아 휴직자",
                                      "자영업자","파트타임/계약직 종사자", 
                                    ])
                                    // Array.from(conversationTopicArray)
                                    // .sort(function(a, b)  {
                                    //     return a.index - b.index;
                                    // })
                                    .map((mapItem, mapIndex)=> {
                                        return (
                                        <div className="tagggItemWhite " 
                                            style={{
                                              background : textInput6 === mapItem ? "#FF7A7A" : "#ffffff",
                                              color : textInput6 === mapItem ? "#ffffff" : "#2D2D2D",
                                              border : textInput6 === mapItem ? "1px solid #ffffff" : "1px solid #E2E2E2"
                                            }}
                                            // disabled={true}
                                            onClick={e=> {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                Firebase.database().ref()
                                                .child("accounts/" + objectUserKeyname).update({"job" : mapItem});

                                                setTextInput6(mapItem)
                                                setTextJob(mapItem);

                                            }}
                                        >
                                            {mapItem} 
                                        </div>)
                                    })
                                    }
                                    </div>
                                </div>
                              </div>
                              {
                                 props && props.appKeyboardVisible === "visible" ?
                                 <></>
                                 :

                                 <div className="small_confirm"

                                 onClick={e=> {
                                 e.preventDefault();
                                 e.stopPropagation();
   
                                 if (textInput6 == "") {
   
                                 } else {
                                   Firebase.database().ref()
                                   .child("accounts/" + objectUserKeyname).update({"job" : textInput6});
                             
                                   setTextJob(textInput6);
   
                                 }
                                 setBoolPopup1(false);
                                 setPopupMode("");
                                 }}
                                 >
                                   확인
                                 </div>
                                 
                              }
                              </div>

                              
                              </>
                            }
                            {
                              popupMode === "children" && 
                              <><>
                              <div className="topLabel">{parse(`육아중인 자녀에 대해<br/>얘기해줄래요?`)}
                              <div className="rowItemOptionSelectorWrapper">
                                  
                                          {
                                            arrayChildren.map((mapItem, mapIndex)=> {
                                              return (
    
                              <div className="inputRow0 inputRow1">
                              {/* <div className="inputWrapper0 inputWrapper00">
                                  <input 
                                    value={textInput1}
                                    onChange={e=> {
                                      // e.preventDefault();
                                      // e.stopPropagation();
                                      setTextInput1(e.target.value);
                                    }}
                                    placeholder={`DD`}
                                  />
                              </div> */}
                              <div className="genderWrapper">
                                <div className="gender1" 
                                 style={{
                                  color : 
                                  arrayTextChildren0[mapIndex] === "male" ?
                                  `#fff` : `#000`,
                                  background : 
                                  arrayTextChildren0[mapIndex] === "male" ?
                                  `#FF7A7A` : `transparent`
                              }}
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  
                                  var temp = ["","","","","","","","","","",]
                                  temp = [...arrayTextChildren0];
                                  temp[mapIndex] = "male";
    
                                  setArrayTextChildren0(temp)
                                }}
                              >남</div>
    
                                <div className="gender2"
                                 style={{
                                  color : 
                                  arrayTextChildren0[mapIndex] === "female" ?
                                  `#fff` : `#000`,
                                  background : 
                                  arrayTextChildren0[mapIndex] === "female" ?
                                  `#FF7A7A` : `transparent`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                var temp = ["","","","","","","","","","",]
                                temp = [...arrayTextChildren0];
                                temp[mapIndex] = "female";
    
                                setArrayTextChildren0(temp)
                              }}
                              >녀</div>
                              </div>
                              <div className="inputWrapper0 inputWrapper1">
                                  <input 
                                    value={arrayTextChildren1[mapIndex]}
                                    onChange={e=> {
                                      // e.preventDefault();
                                      // e.stopPropagation();
                                        var temp = e.target.value.replace(/[^0-9]+/g, "");
                                        if (temp.length > 2) {
                                            return;
                                        }
    
                                        var tempA = ["","","","","","","","","","",]
                                        tempA = [...arrayTextChildren1];
                                        tempA[mapIndex] = temp;
                                        setArrayTextChildren1(tempA);
    
                                    }}
                                    placeholder={`DD`}
                                  />
                              </div>
                              <div className="inputWrapper0 inputWrapper2">
                                  <input 
                                    value={arrayTextChildren2[mapIndex]}
                                    onChange={e=> {
                                      // e.preventDefault();
                                      // e.stopPropagation();
                                        var temp = e.target.value.replace(/[^0-9]+/g, "");
                                        if (temp.length > 2) {
                                            return;
                                        }
                                        var tempA = ["","","","","","","","","","",]
                                        tempA = [...arrayTextChildren2];
                                        tempA[mapIndex] = temp;
                                        setArrayTextChildren2(tempA);
                                    }}
                                    placeholder={`MM`}
                                  />
                              </div>
                              <div className="inputWrapper0 inputWrapper3">
    
                              <input 
                                    value={arrayTextChildren3[mapIndex]}
                                    onChange={e=> {
                                      // e.preventDefault();
                                      // e.stopPropagation();
                                        var temp = e.target.value.replace(/[^0-9]+/g, "");
                                        if (temp.length > 4) {
                                            return;
                                        }
                                        var tempA = ["","","","","","","","","","",]
                                        tempA = [...arrayTextChildren3];
                                        tempA[mapIndex] = temp;
                                        setArrayTextChildren3(tempA);
                                    }}
                                    placeholder={`YYYY`}
                                  />
    
                              </div>
                            </div>
                                              )
    
                                            })
                                          }
    
                                  <div className="childrenAddRow1">
                                    <img className="img0 img1" 
                                    src={
                                      "/static/images/ssokdak/Tab5/iconBlackPlus.svg"
                                    } alt=""
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      var temp = [...arrayChildren];
                                      temp.push("");
                                      setArrayChildren(temp)
                                    }}
                                    />
                                    <div 
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      var temp = [...arrayChildren];
                                      temp.push("");
                                      setArrayChildren(temp)
                                    }}>추가하기</div>
                                    {/* src="/static/images/ssokdak/Tab5/iconBlackPlus.svg" */}
                                  </div>
     
                                  {
                                    props && props.appKeyboardVisible === "visible" ?
                                    <></>
                                    :
                                    <div className="small_confirm"
  
                                      onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
  
                                      Firebase.database().ref()
                                      .child("accounts/" + objectUserKeyname).update({
                                        "arrayChildren" : arrayChildren,
                                        "arrayTextChildren0" : arrayTextChildren0, 
                                        "arrayTextChildren1" : arrayTextChildren1, 
                                        "arrayTextChildren2" : arrayTextChildren2, 
                                        "arrayTextChildren3" : arrayTextChildren3, 
                                      });
  
                                      setBoolPopup1(false);
                                      setPopupMode("");
                                      }}
                                      >
                                        확인
                                      </div>
                                    
                                  }
                                    </div>
                              </div>
                              
                            </>
                              </>
                            }
                          </div>
                            }


                          </div>
                        </div>





        </div>

          <div className=" " style={{ marginBottom: `200px`, height : `40vh`}}></div>
        </div>
        <TabBottom/>
        </>
      }
      </>
      
    );
}
