import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";;

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const termUse = `<br/>모바일 서비스 이용약관
<br/>
<br/>제1장 총칙
<br/>
<br/>제1조(목적)
<br/>① 이 약관은 주식회사 안드레이아(이하 ‘회사’라 합니다)가 모바일 기기를 통해 제공하는 서비스 및 이에 부수하는 네트워크, 웹사이트, 기타 서비스(이하 ‘서비스’라고 합니다)의 이용에 대한 회사와 서비스 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
<br/>
<br/>제2조(용어의 정리)
<br/>① 이 약관에서 사용되는 용어의 정리는 아래와 같습니다.
<br/>  1. “회사”라 함은 모바일 기기를 통하여 서비스를 제공하는 사업자를 의미합니다.
<br/>  2. “이용자”란 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.
<br/>  3. “임시이용자”란 회사가 제공하는 서비스의 일부만 이용하는 자를 의미합니다.
<br/>  4. “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다.
<br/>  5. “이용자 정보”란 이용자의 기기정보, 결제정보 등 이용자가 회사에 제공한 정보와 서비스 이용정보, 이용요금 결제정보 등을 통칭합니다.
<br/>  6. “콘텐츠”란 모바일 기기로 이용할 수 있도록 회사가 서비스 제공과 관련하여 디지털 방식으로 제작한 유료 또는 무료의 내용물 일체를 의미합니다.
<br/>  7. “스토어”란 모바일 기기에서 애플리케이션을 설치하고 결제할 수 있도록 구축된 환경을 의미합니다.
<br/>  8. “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위해 모바일 기기를 통하여 다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다.
<br/>  9. “서비스”라 함은 회사가 제공하는 모든 편의사항과 이용자가 모바일 기기에서 실행하는 회사 소유의 애플리케이션 및 이에 부수하는 서비스를 의미합니다.
<br/>② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 관례에 따릅니다.
<br/>
<br/>제3조(회사 정보 등의 제공)
<br/>회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스 내 혹은 회사의 웹사이트 내에 표시합니다. 다만, 개인정보 처리방침과 약관은 사용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
<br/>상호 및 대표자의 성명
<br/>사업 소재지 주소
<br/>팩스번호 및 전자우편 주소
<br/>사업자 등록번호
<br/>통신판매업 신고번호
<br/>개인정보 처리방침
<br/>서비스 이용약관
<br/>
<br/>제4조(약관의 효력 및 변경)
<br/>① 회사는 이 약관의 내용을 이용자가 알 수 있도록 서비스 내 또는 그 연결 화면에 게시합니다. 이 경우 이 약관의 내용 중 서비스 중단, 청약철회, 환급, 계약 해제 및 해지, 회사의 면책사항 등과 같은 중요한 내용은 굵은 글씨 등으로 명확하게 표시하거나 별도의 연결화면 등을 통하여 회원이 알아보기 쉽게 처리합니다.
<br/>② 회사가 약관을 개정한 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 서비스 내 또는 그 연결화면에 게시하여 회원에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고 제23조1항의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
<br/>③ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제2항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 이용자가 개정약관에 대해 동의하지 않는 경우 회사 또는 이용자는 서비스 이용계약을 해지할 수 있습니다.
<br/>④ 회사는 이용자가 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.
<br/>⑤ 회사는 [전자상거래 등에서의 소비자보호에 관한 법률], [약관의 규제에 관한 법률], [정보통신망이용촉진 및 정보보호 등에 관한 법률] 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
<br/>
<br/>제5조(이용계약의 체결 및 적용)
<br/>① 이용 계약은 이용자가 되고자 하는 자(이하 “이용신청자”라 합니다.)가 이 약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고, 회사가 그 신청에 대해 승낙함으로써 체결됩니다.
<br/>② 회사는 이용신청자의 신청에 대해 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용 신청에 대해서는 승낙을 거절할 수 있습니다.
<br/>  1. 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
<br/>  2. 부정한 용도로 서비스를 이용하고자 하는 경우
<br/>③ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
<br/>  1. 회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 있는 경우
<br/>  2. 서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한 경우
<br/>  3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우
<br/>
<br/>제6조(약관 외 준칙)
<br/>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 [전자상거래 등에서의 소비자 보호에 관한 법률], [약관의 규제에 관한 법률], [정보통신망 이용촉진 및 정보보호 등에 관한 법률], [콘텐츠 산업진흥법] 등 관련 법령 또는 상 관례에 따릅니다.
<br/>
<br/>제7조(운영정책)
<br/>① 약관을 적용하기 위하여 필요한 사항과 약관에서 구체적인 범위를 위임한 사항을 운영정책으로 정할 수 있습니다.
<br/>② 회사는 운영정책을 정한 경우, 이 내용을 이용자가 알 수 있도록 서비스 내 또는 그 연결화면에 게시합니다.
<br/>③ 운영정책을 개정하는 경우에는 제4조 제2항의 절차에 따릅니다. 다만, 운영정책 개정 내용이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의 방법으로 사전에 공지합니다.
<br/>1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
<br/>2. 이용자의 권리 및 의무와 관련 없는 사항을 개정하는 경우
<br/>3. 운영 정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 이용자가 예측할 수 있는 범위 내에서 운영정책을 개정하는 경우
<br/>
<br/> 
<br/>제2장 개인정보 관리
<br/>
<br/>제8조(개인정보의 보호 및 사용)
<br/>① 회사는 관련 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.
<br/>② 회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를 제외하고는 이용자의 개인정보를 본인 동의 없이 타인에게 제공하지 않습니다.
<br/>③ 회사는 이용자의 귀책사유로 개인정보가 유출되어 발생한 피해에 대해 책임을지지 않습니다.
<br/>
<br/>
<br/>제3장 이용계약 당사자의 의무
<br/>
<br/>제9조(회사의 의무)
<br/>① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.
<br/>② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보(신용 정보 포함)보호를 위해 보안 시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보 처리방침에서 정한 경우를 제외하고는 이용자의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
<br/>③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실 및 훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
<br/>
<br/>제10조(이용자의 의무)
<br/>① 이용자는 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안됩니다.
<br/>회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위
<br/>타인의 신용카드, 유/무선 전화, 은행 계좌 등을 도용하여 유료 콘텐츠를 구매하는 행위, 다른 이용자의 ID 및 비밀번호를 부정사용하는 행위
<br/>서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위
<br/>회사의 서비스를 이용하여 얻은 정보를 무단으로 복제, 유통, 조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
<br/>타인을 기망하여 이득을 취하는 행위, 회사의 서비스 이용과 관련하여 타인에게 피해를 입히는 행위
<br/>회사나 타인의 지식재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위
<br/>회사로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가 혹은 삽입하거나, 서버를 해킹 혹은 역설계하거나, 소스코드나 애플리케이션 데이터를 유출 혹은 변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경 혹은 도용하여 회사를 사칭하는 행위
<br/>그 밖에 사회통념에 반하는 행위
<br/>② 이용자의 계정 및 모바일 기기에 관한 관리 책임은 이용자에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안됩니다. 모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을지지 않습니다.
<br/>③ 이용자는 각 스토어에서 부정한 결제가 이루어지지 아니하도록 결제 비밀번호 기능을 설정하고 관리하여야 합니다. 이용자의 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다.
<br/>
<br/>
<br/>제4장 서비스 이용 및 이용 제한
<br/>
<br/>제11조(서비스의 제공)
<br/>① 회사는 제5조의 규정에 따라 이용계약이 완료된 이용자에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.
<br/>② 회사는 이용자에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.
<br/>③ 회사는 이용자의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수 있습니다.
<br/>
<br/>제12조(서비스이 이용)
<br/>① 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 정지할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와 기간을 애플리케이션 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.
<br/>시스템 정기정검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우
<br/>정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우
<br/>전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우
<br/>② 회사는 모바일 기기를 위한 전용 애플리케이션 또는 네트워크를 이용하여 서비스를 제공합니다. 이용자는 애플리케이션을 다운로드하여 설치하거나 네트워크를 이용하여 무료 또는 유료로 서비스를 이용할 수 있습니다.
<br/>④ 유료 콘텐츠의 경우에는 해당 서비스에 명시된 요금을 지급하여야 이용할 수 있습니다. 네트워크를 통해 애플리케이션을 다운로드하거나 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.
<br/>⑤ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 모바일 기기 또는 이동통신사의 특성에 맞도록 제공됩니다. 모바일 기기의 변경 · 번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이 불가능할 수 있으며, 이 경우 회사는 책임을지지 않습니다.
<br/>⑥ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을지지 않습니다.
<br/>
<br/>제13조(서비스의 변경 및 중단)
<br/>① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에 공지합니다. 다만, 버그 · 오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.
<br/>② 회사는 영업양도 · 분할 · 합병 등에 따른 영업의 폐지, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자 · 중단사유 · 보상조건 등을 서비스 애플리케이션 초기화면 또는 그 연결화면을 통해 공지하고 제23조 제1항의 방법으로 이용자에게 통지합니다.
<br/>
<br/>제14조(정보의 수집 등)
<br/>① 회사는 서비스의 원활하고 안정적인 운영 및 서비스 품질의 개선을 위하여 이용자의 개인정보를 제외한 이용자의 모바일 기기 정보(설정, 사양, 운영체제, 버전 등)를 수집 · 활용할 수 있습니다.
<br/>② 회사는 서비스 개선 및 이용자 대상 서비스 소개 등을 위한 목적으로 이용자에게 추가정보를 요청할 수 있습니다. 이 요청에 대해 이용자는 승낙하거나 거절할 수 있으며, 회사가 이 요청을 할 경우에는 이용자가 이 요청을 거절할 수 있다는 뜻을 함께 고지합니다.
<br/>
<br/>제15조(광고의 제공)
<br/>① 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 사용자에 한하여 전자우편, 문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우 사용자는 언제든지 수신을 거절할 수 있으며, 회사는 사용자의 수신 거절 시 광고성 정보를 발송하지 아니합니다.
<br/>② 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.
<br/>③ 제2항에 따라 타인이 제공하는 광고나 서비스에 견결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 사용자의 손해에 대하여도 회사는 책임을지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.
<br/>
<br/>제16조(저작권 등의 귀속)
<br/>① 회사가 제작한 서비스 내의 콘텐츠의 대한 저작권과 기타 지식재산권은 회사에 귀속합니다.
<br/>② 이용자는 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지식재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제 · 전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안됩니다.
<br/>
<br/>제17조(유료 콘텐츠의 구매, 사용기간 및 이용)
<br/>① 서비스 내에서 이용자가 구매한 유료 콘텐츠는 해당 어플리케이션을 다운로드 받거나 설치한 모바일 기기에서만 이용할 수 있습니다.
<br/>② 이용자가 구해만 유료 콘텐츠의 이용기간은 구매 시 명시된 기간에 따릅니다. 다만, 제13조 제2항에 따라 서비스 중단이 이루어지는 경우 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단 공지 시 공지된 서비스의 중단일자까지로 합니다.
<br/>
<br/>
<br/>제5장 청약철회, 과오납급의 환급 및 이용계약의 해지
<br/>
<br/>제18조(대금결제)
<br/>① 콘텐츠에 대한 구매 대금의 부과와 납부는 원칙적으로 이동통신사나 스토어 사업자등에서 정하는 정책이나 방법에 따릅니다. 또한 각 결제수단별 한도가 회사나 스토어 사업자가 정하는 정책 또는 정부의 방침에 따라 부여되거나 조정될 수 있습니다.
<br/>② 콘텐츠의 구매대금을 외화로 결제하는 경우에는 환율 · 수수료 등으로 인하여 실제 청구금액이 서비스의 상점 등에서 표시된 가격과 달라질 수 있습니다.
<br/>
<br/>제19조(청약 철회 등)
<br/>① 회사와 유료 콘텐츠의 구매에 관한 계약을 체결한 이용자는 구매계약일과 콘텐츠 이용 가능일 중 늦은 날부터 7일 이내에 별도의 수수료 · 위약금 등의 부담 없이 청약 철회를 할 수 있습니다.
<br/>② 이용자는 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 다만, 가분적 콘텐츠로 구성된 구매계약의 경우에는 가분적 콘텐츠 중 다음 각 호에 해당하지 아니하는 나머지 부분에 대하여는 그러하지 아니합니다.
<br/>구매 즉시 사용되거나 적용되는 유료 콘텐츠
<br/>추가혜택이 제공되는 경우에 그 추가 혜택이 사용된 콘텐츠
<br/>개봉행위를 사용으로 볼 수 있거나 개봉시 효용이 결정되는 콘텐츠의 개봉해위가 있는 경우
<br/>③ 회사는 제2항 각 호의 규정에 따라 청약 철회가 불가능한 콘텐츠의 경우에는 그 사실을 이용자가 쉽게 알 수 있는 곳에 명확하게 표시하고, 해당 콘텐츠의 시험사용 상품을 제공(한시적 이용의 허용, 체험용 제공 등)하거나 이에 대한 제공이 곤란한 경우에는 콘텐츠에 관한 정보 제공함으로써 이용자의 청약철회의 권리행사가 방해받지 아니하도록 조치합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 각 호의 청약철회 제한사유에도 불구하고 이용자는 청약철회를 할 수 있습니다.
<br/>④ 이용자는 제1항 및 제2항에도 불구하고 구매한 유료 콘텐츠의 내용이 표시 · 광고의 내용과 다르거나 구매계약의 내용과 다르게 이행된 경우에 해당 콘텐츠가 이용 가능하게 된 날부터 3개월 이내, 그 사실을 안날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.
<br/>⑤ 이용자가 청약철회를 할 경우 회사는 플랫폼사업자 또는 스토어 사업자를 통해 구매내역을 확인합니다. 또한 회사는 이용자의 정당한 철회 사유를 확인하기 위해 이용자에게 제공받은 정보를 통해 이용자에게 연락할 수 있으며, 추가적인 증빙을 요구할 수 있습니다.
<br/>⑥ 제1항부터 제4항까지의 규정에 따라 청약철회가 이루어질 경우 회사는 지체 없이 이용자의 유료 콘텐츠를 회수하고 3영업일 이내에 대금을 환급합니다. 이 경우 회사가 환급을 지연할 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률」 및 같은 법 시행령 제21조의3에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다.
<br/>⑦ 미성년자가 모바일 기기에서 콘텐츠 구매계약을 체결하는 경우, 회사는 법정대리인의 동의가 없으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이 구매계약을 체결한 때에는 미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을 허락한 재산으로 콘텐츠를 구매한 경우 또는 미성년자가 속임수로써 자기를 성년자로 믿게하거나 법정대리인의 동의가 있는 것으로 믿게 한 경우네는 취소할 수 없습니다.
<br/>⑧ 콘텐츠 구매계약의 당사자가 미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자 정보, 결제 수단 명의자 등을 근거로 판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및 법정대리인임을 증명할 수 있는 서류의 제출을 요청할 수 있습니다.
<br/>
<br/>제20조(과오납금의 환급)
<br/>① 회사는 과오납금이 발생하는 경우 과오납금을 이용자에게 환급합니다. 다만, 과오납금이 회사의 고의 또는 과실 없이 이용자의 과실로 인하여 발생한 경우에는 그 환급에 소요되는 실제 비용은 합리적인 범위 내에서 이용자가 부담합니다.
<br/>② 애플리케이션을 통한 결제는 스토어 사업자가 제공하는 결제방식에 따르며 결제 과정에서 과오납금이 발생하는 경우, 회사 또는 스토어 사업자에게 환급을 요청하여야 합니다.
<br/>③ 애플리케이션의 다운로드 또는 네트워크 서비스의 이용으로 인해 발생한 통신요금(통신료, 데이터 통화료 등)은 환급 대상에서 제외될 수 있습니다.
<br/>④ 환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 각 스토어 사업자에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다. 회사는 이용자로부터 환급에 필요한 정보를 받은 날부터 5영업일 이내에 환급합니다.
<br/>
<br/>제6장 손해배상 및 면책조항 등
<br/>
<br/>제21조(손해배상)
<br/>① 회사 또는 이용자는 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
<br/>② 회사가 개별서비스 제공자와 제휴 계약을 맺고 이용자에게 개별서비스를 제공하는 경우에 이용자가 이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 사용자에게 손해가 발생한 경우에 그 손해에 대해서는 개별서비스 제공자가 책임을 집니다.
<br/>
<br/>제22조(회사의 면책)
<br/>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임을지지 않습니다.
<br/>② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
<br/>③ 회사는 사용자의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을지지 않습니다. 다만, 사용자에게 부득이하거나 정당한 사유가 있는 경우에는 그러하지 아니합니다.
<br/>④ 이용자가 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을지지 않습니다.
<br/>⑤ 회사는 이용자가 다른 이용자 또는 타인과 서비스를 매개로 발생한 거래나 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해에 대해 책임을지지 않습니다.
<br/>⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 사용자에게 발생한 손해에 대해서는 책임을지지 않습니다. 그러나 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
<br/>⑦ 회사는 이용자가 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을지지 않습니다.
<br/>⑧ 회사는 이용자가 모바일 기기 비밀번호, 스토어 사업자가 제공하는 비밀번호 등을 관리하지 않아 발생하는 제3자 결제에 대해 책임을지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
<br/>⑨ 사용자가 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
<br/>⑩ 이용자의 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
<br/>⑪ 회사는 임시이용자가 서비스 이용으로 발생한 손해에 대해서는 책임을지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
<br/>
<br/>제23조(이용자에 대한 통지)
<br/>① 회사가 이용자에게 통지를 하는 경우 이용자의 전자우편주소, 전자메모, 서비스 내 공지, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.
<br/>② 회사는 이용자 전체에게 통지를 하는 경우 7일 이상 서비스 내에 게시하거나 팝업 화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.
<br/>
<br/>제24조(재판권 및 준거법)
<br/>이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 이용자 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.
<br/>
<br/>제25조(이용자의 고충처리 및 분쟁해결)
<br/>① 회사는 이용자의 편의를 고려하여 이용자의 의견이나 불만을 제시하는 방법을 서비스 내 또는 그 연결화면에 안내합니다. 회사는 이러한 이용자의 의견이나 불만을 처리하기 위한 전담인력을 운영합니다.
<br/>② 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이을 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 이용자에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제23조 제1항에 따라 통지합니다.
<br/>③ 회사와 이용자 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 이용자에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.
`

const termPriv = `
<br/>개인정보 처리방침
<br/>
<br/><주식회사 안드레이아>
<br/>(http://andreia.kr, 이하 ‘안드레이아’)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. 안드레이아는개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)를 통하여 공지할 것입니다.
<br/>
<br/>○본 방침은 2023년 06월 01일부터 시행됩니다.
<br/>
<br/>1. 처리하는 개인정보의 항목 및 처리 목적
<br/>안드레이아는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.
<br/>
<br/>가. 재화 또는 서비스 제공
<br/>콘텐츠 제공 등을 목적으로 개인정보를 처리합니다.
<br/>
<br/>나. Contact 페이지를 통한 연결
<br/>정보주체의 선택에 따라 Contact 페이지에 직접 기록한 이메일 및 메시지 내용을 처리합니다.
<br/>
<br/>다. 마케팅 및 광고에의 활용
<br/>신규 서비스(제품) 개발 및 맞춤 서비스 제공 등을 목적으로 개인정보를 처리합니다.
<br/>
<br/>2. 개인정보 파일 현황
<br/>1) 고객 지원 정보
<br/> · 개인정보 파일명 : Contact 
<br/> · 개인정보 항목 : 이메일 아이디, 메시지 내용
<br/> · 수집방법 : 홈페이지
<br/>2) 결제정보
<br/> · 개인정보 파일명 : 결제정보
<br/> · 개인정보 항목 : 구매 아이디, 서비스 이용기록, 접속 로그
<br/> · 수집방법: 모바일 어플리케이션
<br/>
<br/>
<br/>3. 개인정보의 처리 및 보유 기간 　
<br/>① 안드레이아는 법령에 따른 개인정보 보유 · 이용기간 또는 정보 주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유 · 이용 기간 내에서 개인정보를 처리 · 보유합니다.
<br/>
<br/>② 개인정보 처리 및 보유 기간은 다음과 같습니다.
<br/> · 보유근거 : 구매 이력 관리를 통한 유료 서비스의 제한적 제공 / 고객 지원 및 피드백 제공
<br/> · 보유기간 : 3년
<br/> · 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
<br/>
<br/>4. 정보 주체와 법정대리인의 권리 · 의무 및 그 행사방법
<br/>이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
<br/>
<br/>① 정보 주체는 안드레이아에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리 정지 요구 등의 권리를 행사할 수 있습니다.
<br/>
<br/>② 제1항에 따른 권리 행사는 안드레이아에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 안드레이아는 이에 대해 지체없이 조치하겠습니다.
<br/>
<br/>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
<br/>
<br/>④ 개인정보 열람 및 처리 정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보 주체의 권리가 제한 될 수 있습니다.
<br/>
<br/>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
<br/>
<br/>⑥ 안드레이아는 정보주체 권리에 따른 열람의 요구, 정정 · 삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
<br/>
<br/>
<br/>
<br/>5. 개인정보의 파기
<br/>안드레이아는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
<br/>
<br/> · 파기절차
<br/>이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정 기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
<br/> · 파기기한
<br/>이용자의 개인정보는 개인정보의 보유기간이 경관된 경우에는 보유기간의 종료일부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
<br/> · 파기방법
<br/>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
<br/>
<br/>6. 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항
<br/>① 안드레이아는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
<br/>
<br/>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.
<br/>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보완 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
<br/>나. 쿠키의 설치 운영 및 거부 : 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다. 
<br/>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
<br/>
<br/>7. 개인정보 보호책임자 작성
<br/>① 안드레이아는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호잭임자를 지정하고 있습니다.
<br/> · 성명 : 김성훈
<br/> · 직책 : 대표이사
<br/> · 직급 : 대표이사
<br/> · 연락처 : contact@andreia.kr
<br/>
<br/>② 정보주체께서는 안드레이아의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 안드레이아는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
<br/>
<br/>8. 개인정보 처리 방침 변경
<br/>① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는 변경 사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
<br/>
<br/>9. 개인정보의 안전성 확보 조치
<br/>안드레이아는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
<br/>
<br/>1) 정기적인 자체 감사 실시
<br/>개인정보 취급 관련 안전성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
<br/>
<br/>2) 개인정보 취급 직원의 최소화 및 교육
<br/>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
<br/>
<br/>3) 내부관리계획의 수립 및 시행
<br/>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
<br/>
<br/>4) 해킹 등에 대비한 기술적 대책
<br/>안드레이아는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 · 점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
<br/>
<br/>5) 개인정보의 암호화
<br/>이용자의 개인정보는 비밀번호로 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
<br/>
<br/>6) 접속 기록의 보관 및 위변조 방지
<br/>개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
<br/>
<br/>7) 개인정보에 대한 접근 제한
<br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
<br/>
<br/>8) 문서보안을 위한 잠금장치 사용
<br/>개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
<br/>
<br/>9) 비인가자에 대한 출입 통제
<br/>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입 통제 절차를 수립, 운영하고 있습니다.
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>모바일 어플리케이션 개인정보 정책
<br/>
<br/>어떤 정보를 얻고 어떻게 사용하나요?
<br/>계정을 생성하지 않는 모바일 어플리케이션은 귀하의 이메일 주소를 저장하거나 개인적인 데이터에 액세스할 수 없습니다. 귀하가 앱 내에 기록한 데이터는 귀하의 핸드폰에 직접 저장되며 모든 연산 역시 귀하의 핸드폰에서 수행됩니다. 다만 귀하의 선택에 따라, 기록한 데이터를 Google Drive(Android) 또는 Apple iCloud(iOS)에 백업할 수 있습니다. 백업은 사설 클라우드 공간에 저장되며 Google은 백업 파일에 액세스 할 수 없습니다. 다만, 이메일 혹은 의견보내기 항목을 통해 귀하가 직접 지원을 요청할 때, 귀하에게 피드백 혹은 서비스에 대한 지원을 제공하기 위해 직접 기록해주신 이메일을 사요합니다.
<br/>
<br/>2. 백업
<br/>정기적으로 데이터를 백업할 것을 권장하며, 사용자는 백업 파일을 수동으로 내보내고 원하는 곳에 저장하거나 선택적으로 클라우드 백업을 만들 수 있습니다. 클라우드 백업은 외부에서 접근할 수 없는 사용자의 개인 클라우드 공간에 보관됩니다.
<br/>
<br/>Android 사용자는 선택적으로 Google 드라이브를 사용하여 데이터를 백업 할 수 있습니다. 백업은 개인 Google 드라이브 공간에 저장되며 Google이 제공하는 할당량에 포함됩니다. 당사는 귀하의 Google 계정에 대한 보안 연결을 사용하며 데이터는 당사 또는 다른 앱이 액세스 할 수 있는 폴더에 안전하게 저장됩니다. http://www.google.com/policies/privacy/ Google 개인 정보 보호 정책을 확인하세요. 백업에 사용되는 계정에 대해 사용자에게 알리기 위해 앱 내에서 계정 세부 정보를 표시하기 위해 Android 내의 이메일 및 이름 권한이 요청됩니다. 앱은 앱 내에서 이메일 주소와 이름을 유지하지 않습니다. 앱은 연결된 계정에 대한 Google 로그인을 요청하고 백업 섹션에 액세스 할 때만 계정정보를 표시합니다. 사용자가 익명의 분석데이터 수집에 동의하더라도 귀하의 이메일 주소 또는 이름은 분석 데이터와 함께 전송되지 않습니다.
<br/>
<br/>iOS 사용자는 선택적으로 iCloud Drive를 사용하여 데이터를 백업할 수 있습니다. 백업은 개인 iCloud 드라이브 공간에 저장되며 Apple에 제공되는 할당량에 포함됩니다. iCloud는 전송 중 정보를 암호화하고 암호화 된 형식으로 iCloud에 저장하며 인증을 위해 보안 토큰을 사용하여 정보를 보호합니다. 특정 민감한 정보의 경우 Apple은 종단 간 암호화를 사용합니다. 즉 iCloud에 로그인 한 기기에서만 사용자 정보에 접근할 수 있습니다. Apple을 비롯한 어느 누구도 종단 간 암호화 된 정보에 액세스 할 수 없습니다. http://support.apple.com/enus/HT202303 iCloud 보안 및 개인정보 개요에 대해 자세히 알아볼 수 있습니다.
<br/>
<br/>3. Analytics
<br/>애플리케이션은 귀하의 동의없이 사용 또는 충돌 보고 데이터를 수집하지 않습니다. 데이터 수집에 동의한 경우 애플리케이션은 서비스를 제공하고, 사용자의 요구를 이해하고, 서비스 개선하는데 필수적인 일부 사용 데이터를 수집합니다. 앱 시작, 탭, 클릭, 스크롤 정보, 인앱구매, 화명 방문, 세션기간 또는 귀하가 당사 앱과 상호 작용하는 방법에 해해 기타 정보와 같은 이러한 데이터는 수집에 동의한 모든 사용자로부터 익명화되고 집계됩니다. 데이터 수집에 동의한 경우 애플리케이션은 앱과 안정성과 성능을 개선할 수 있도록 충돌 및 성능 보고서도 수집합니다.
<br/>
<br/>4. 사진
<br/>귀하의 선택에 따라 애플리케이션에 사진을 첨부할 수 있습니다. 귀하의 사진은 당사 앱의 저장소에 있는 귀하의 핸드폰에 로컬로 저장됩니다. 클라우드 백업 (Google 드라이브 또는 iCloud)을 선택하며, 스토리지 할당량에 포함됩니다. 백업 파일을 수동으로 내보내도록 선택하면 사진이 항목과 함께 내보낸 백업 파일에 포함됩니다. 하지만 외부에서는 귀하의 사진에 액세스 할 수 없습니다. 사용자가 익명의 분석 데이터 수집에 동의한 경우에도 귀하의 사진은 분석 데이터와 함께 전송되지 않습니다.
<br/>
<br/>5. Android
<br/>Android 앱에 대해 다음과 같은 타사 서비스를 사용합니다. Firebase 용 Google 애널리틱스(Google Inc.)
<br/>  · 이 서비스는 사용 패턴을 이해하고 Android 앱을 개선하기 위해 사용 데이터를 수집하고 분석하는데 도움이됩니다.
<br/>  · 수집 된 데이터 : 쿠키, 고유 기기 식별자, Android 광고, ID, Firebase 설치 ID, 애널리틱스 앱 인스턴트 ID, 사용데이터, 익명 처리 된 IP주소, 세션 기간, 기기 모델, 운영체제, 지역, 인앱과 같은 다양한 사용 및 기기 데이터 구매, 최초 실행, 앱 열기, 앱 업데이트
<br/>  · 데이터 보존 : 3년
<br/>Firebase Crashlytics (Google Inc.)
<br/>  · 이 서비스는 앱에서 충돌 및 오류를 식별하는데 도움이 됩니다. Android 앱의 안정성과 안정성을 개선하는 데 도움이 됩니다.
<br/>  · 수집된 데이터 : Crashlytics 설치 UUID, 충돌 추적, 기기 모델, 지역, 운영체제
<br/>  · 데이터 보존 : 90일
<br/>
<br/>
<br/>6. iOS
<br/>당사는 iOS 앱에 대해 다음과 같은 타사 서비스를 사용합니다. Firebase 용 Google 애널리틱스 (Google Inc.)
<br/>  · 이 서비스는 사용 패턴을 이해하고 iOS 앱을 개선하기 위해 사용 데이터를 수집 및 분석하는데 도움이 됩니다.
<br/>  · 수집 된 데이터 : 다양한 사용 데이터, Firebase 설치 ID, 애널리틱스 앱 인스턴스 ID, 익명 처리 된 IP 주소, 세션 기간, 기기 모델, 운영체제, 지역, 인앱 구매, 최초 실행, 앱 열기, 앱 업데이트
<br/>  · 데이터 보존 : 3년
<br/>Firebase Crashlytics (Google Inc.)
<br/>  · 이 서비스는 앱에서 충돌 및 오류를 식별하는 데 도움이 됩니다. iOS 앱의 안정성과 안정성을 개선하는데 도움이 됩니다.
<br/>  · 수집된 데이터 : Crashlytics 설치 UUID, 충돌 추적, 기기 모델, 지역, 운영체제
<br/>  · 데이터 보존 : 90일
<br/>
<br/>7. 광고
<br/>광고는 앱을 무료로 유지하는 데 도움이 됩니다. 유료 버전에서는 광고가 게시되지 않으며 AdMob을 호출하지 않습니다. 광고 내용은 귀하의 항목이나 메모를 기반으로 하지 않습니다. 귀하의 데이터는 광고 제공 업체와 공유하지 않습니다. 무료 버전은 귀하의 선택에 따라 AdMob (AdMob Google Inc.) 서비스를 사용합니다. AdMob을 사용하여 귀하의 관심사에 따라 광고 배너 및 기타 광고를 표시합니다. AdMob은 쿠키를 사용하여 사용자를 식별하고 리타게팅 기술을 사용할 수 있습니다. 즉, 앱 외부에서 감지된 광고를 포함하여 귀하의 관심사와 행동에 맞는 광고를 표시할 수 있습니다. 예를 들어 이전에 신발을 판매하는 e-shop을 방문한 적이 있다면 신발이나 신발 가게를 홍보하는 광고가 더 많이 표시될 수 있습니다. Google의 광고 요구 사항은 Google의 광고원칙 http://www.google.com/policies/pricacy/partners/에 의해 요약 될 수 있습니다. 사용자에게 긍정적인 경험을 제공하기 위해 배치됩니다.
<br/>  · 수집되는 항목 : 쿠키, Google 광고주 ID
<br/>
<br/>8. 의견 수집
<br/>당사는 앱 내에서 이용자가 작성하여 전송가능한 ‘의견 보내기’ 기능을 통해 귀하의 의견을 수지합니다. 수집을 위해 다음과 같은 타사 서비스를 사용합니다.
<br/>Slack(Slack Technologies LLC.)
<br/>  · 이 서비스는 귀하의 의견을 당사에 전송하는 데 사용됩니다.
<br/>  · 수집 된 데이터 : 버전정보, 운영체제, 지역, 기기 모델, 전송 된 메시지
<br/>  · 데이터 보존 : 어플리케이션 운영 중단시까지
<br/>9. 갱신
<br/>당사는 본 정책을 수정하거나 교체할 수 있습니다. 향후 개인 정보 보호 정책에 대한 변경 사항은 이 페이지에 게시됩니다.
<br/>
<br/>10. 문의
<br/>응용 프로그램을 사용하는 동안 개인정보 보호에 대해 질문이 있으실 경우 contact@andreia.kr.을 통해 문의해주십시오.
<br/>
<br/>감사합니다.
<br/>
<br/>안드레이아 배상 









`
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 = 'page005/2/term2';
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(1);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("");
    const [textInput4 , setTextInput4] = useState("");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    

    const [boolPopup1, setBoolPopup1] =  useState(false);
    

    const menuArr1 = [
      { label : "마이페이지",  code : 'page005/2/setting-mypage' },
      { label : "알람", code : 'page005/2/setting-notification', },
      { label : "수신동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "자주묻는질문", code : 'page005/2/setting-faq', },
      { label : "1:1문의", code : 'page005/2/setting-questions', },
      { label : "공지사항", code : 'page005/2/setting-notice', },
      { label : "튜토리얼", code : 'page005/2/tutorial', },
      { label : "이용약관", code : 'page005/2/term', },
      { label : "개인정보처리방침",  code : 'page005/2/term', },

    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',


    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`a04_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />

          {
            stepIndex == 1 &&
            <div className="step_content_container0 step_content_container1">
              <div className="term_text0 term_text1">쏙닥쏙닥 앱이용을 위해 아래 약관에 동의해 주세요!</div>
              <div className="hl"></div>
              <div className="textinput_form_check_row0">
                <div className="col100">
                  <div 
                    className="label_text1  "
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    이용약관
                  </div>
                  
                    
                </div>

              </div>

              {
                true && 
                // boolMoreInfoOpen1 == true &&
                <div className="term_textarea0 term_textarea4">
                { parse(termUse)}
                </div>
                }
                <div className="hl"></div>
                <div className="textinput_form_check_row0">
                  <div className="col100">
                    <div 
                      className="label_text1 "
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >개인정보 처리방침
                    </div>
                    
                      
                  </div>
  
                </div>
  
                {
                true && 
                  // boolMoreInfoOpen2 == true &&
                  <div className="term_textarea0 term_textarea4">
                  { parse(termPriv)}
                  </div>
                  }

                <div className="hl"></div>
                
            </div>
          }

          <style>
            {
              `
                .label_text1 {

font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #212529;
                }

.term_textarea4{

  width: calc(100% - 80px);
  height: 240px;
  
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #ADB5BD;

  padding: 20px;


  overflow-y: scroll;

  margin-top: 20px;
}


              `
            }
          </style>



        </div>
      </>
      
    );
}
