import {SET_POPUPMODE} from './popupModeRedux.actionType'


export const POPUPMODE_KEY   = 'popupModeStore'

let initialState = {
    mode: 'dialog/0/000'
}

let popupModeReducer = (state = initialState, action) =>{
    let {type, payload} = action;
    console.log("[popupModeReducer] ::: ", payload);

    switch(type){
        case SET_POPUPMODE:
            return{
                mode : payload
            }
        default: return {
            mode: initialState.mode
        }
    }

}

export {popupModeReducer}