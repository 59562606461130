import {SET_SCREENMODE} from './screenMode.actionTypes'


export const SCREENMODE_KEY   = 'screenModeStore'

let initialState = {
    mode: 'page000/0/splash'
}

let screenModeReducer = (state = initialState, action) =>{
    let {type, payload} = action;
    console.log("[screenModeReducer] ::: ", payload);

    switch(type){
        case SET_SCREENMODE:
            return{
                mode : payload
            }
        default: return {
            mode:  initialState.mode
        }
    }

}

export {screenModeReducer}