import React, { Component, useEffect, useState, useContext, useRef } from "react";

import parse from "html-react-parser";
import {useSelector, useDispatch} from 'react-redux'
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";

import { toast } from "react-toastify";
import uuid from 'react-uuid';

import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";


import "./aa03.css";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""


 
const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]

const conversationTopicArray = [
  {
      index : 1, 
      enName : '',
      krName : '정신건강',
  },
  {
      index : 2, 
      enName : '',
      krName : '성생활',
  },
  {
      index : 3, 
      enName : '',
      krName : '금전/사업',
  },
  {
      index : 4, 
      enName : '',
      krName : '대인관계',
  },
  {
      index : 5, 
      enName : '',
      krName : '자아/성격',
  },
  {
      index : 6, 
      enName : '',
      krName : '결혼/육아',
  },
  {
      index : 7, 
      enName : '',
      krName : '이별/이혼',
  },
  {
      index : 8, 
      enName : '',
      krName : '가족',
  },
]
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 = 'page004/998/chat-detail';
export default function ThisComponent(props) {

    const wrapperRef = useRef();
    const messageBoxRef = useRef();
    const [objectPopupData1, setObjectPopupData1] = useState(null);

    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(3);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("01011112222");
    const [textInput4 , setTextInput4] = useState("1234");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");
    const [textInput21 , setTextInput21] = useState("");

    const [messageFromDevice0, setmessageFromDevice0] = useState("");
    const [blockSystem, setBlockSystem] = useState(false);
    const [fbContactKeyName, setFbContactKeyName] = useState("");
    const [boolCheckA, setBoolCheckA] = useState(false);
    
    const imageRef1 = useRef();
    
    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);
    
    const [objectOpponentUserKeyname, setObjectOpponentUserKeyname] = useState("");
    const [objectOpponentUserData, setObjectOpponentUserData] = useState(null);

    const [arrayChatMessages, setArrayChatMessages] = useState([]);
    const [arrayUserData, setArrayUserData ] = useState([]);
    const [arrayCheckInData, setArrayCheckInData] = useState([]);

    useEffect(()=> {
      setStepIndex(3);
    },[])

    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    useEffect(()=> {
      console.log("@@@@viewScreenMode", viewScreenMode)

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }

        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        const authStore1 = localStorage.getItem("authStore")



    Firebase.database().ref()
    .child("accounts")
    .once("value", (snapshot) => {

      const data = snapshot.val();
      console.log("CHECKCHECK - 001", );

      // console.log("[테스트] >> snapshot.val()", )
      // console.log("[테스트] >> data", data)

      if (data) {

        const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
        setArrayUserData(data);

        const chatRoomOne = window.localStorage.getItem("chatRoomOne");
  
        // console.log("chatRoomOne", chatRoomOne)
        const findIndex1 = Object.values(snapshot.val()).findIndex((findItem, mapIndex)=> {
          // console.log("findItem", findItem)
          if (findItem.username === chatRoomOne ) {
            return findItem;
          }
        })
        console.log("findIndex1", findIndex1)
  
        if (findIndex1 > -1 ) {
          setObjectOpponentUserData( Object.values(snapshot.val())[findIndex1]);
          setObjectOpponentUserKeyname(Object.keys(snapshot.val())[findIndex1])
        }
        
  
      }
    });


        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        

                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                     
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });


      }



      const chatRoomOne = window.localStorage.getItem("chatRoomOne");
    Firebase.database().ref()
    .child("behaviors/community-checkins")
    .orderByChild("username")
    .equalTo(chatRoomOne)
    .once("value", (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val(); 

        if (data && Object.values(data) &&  Object.values(data).length > 0) {
          // const findIndex1 = Object.values(snapshot.val()).findIndex((mapItem, mapIndex)=> {
          //   if (mapItem.username === chatRoomOne ) {
          //     return mapItem;
          //   }
          // })
          // if (findIndex1 > -1 ) {
            
          // }
          const filter1 = Object.values(snapshot.val())
          // .filter((filterItem, filterIndex)=> {
          //   if (filterItem.created_day === moment().format("YYYY-MM-DD")){
          //     return filterItem
          //   }
          // })
          .filter((filterItem, filterIndex)=> {
            if (filterItem.username === chatRoomOne ) {
              return filterItem;
            }
          })

          if (filter1.length > 0) {
            setArrayCheckInData(filter1);

            // if ()
            setObjectOpponentUserData(filter1[0].userData)
            // setObjectOpponentUser(filter1[0].userData)
          }

          
    
    
        }
      }
    });


    },[useSelector(state => state)])

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // } 

    useEffect(()=> {

      if (objectUserKeyname && objectUserKeyname !== "") {

        Firebase.database().ref().child("behaviors").child(`messages`)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            // console.log("data", data)
            const chats = Object.values(data);
            if (chats && chats.length > 0) {
              setArrayChatMessages(chats);

            }
          }
        });
      }
    }, [objectUserKeyname])


    useEffect(()=> {
      if(objectPopupData1 != null) {
        setTimeout(()=> {
          setObjectPopupData1(null);
        }, 3000)
      }
    }, [objectPopupData1])


    const handleEnter = (e) => {
      // if (textSearch1 == "") {
      //   return;
      // }
      if ( e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        // handleLogin();

        if (textInput2 === "") {
          alert("공백이 있습니다.")
          return;
      }
      
      Firebase.database().ref()
      .child("behaviors")
      .child(`messages`)
      .push({
        date : `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        text : textInput2, 
        more : '',
        type : '',
        fromUsername : '' + objectUserData.username,
        fromNickname : '' + objectUserData.nickname,
        fromKey : '' + objectUserKeyname ,
        toUsername : objectOpponentUserData && objectOpponentUserData.username  ? '' + objectOpponentUserData.username : '',
        toNickname : objectOpponentUserData && objectOpponentUserData.nickname  ? '' + objectOpponentUserData.nickname : '',
        toKey : '' + objectOpponentUserKeyname,
      });


      setTextInput2("");
      // messageBoxRef.current.scrollTo(5000, 5000);
      // wrapperRef.current.scrollTo(5000, 5000);

      // const messageBoxWrapDiv1 = document.getElementById("messageBoxRef");

      // messageBoxWrapDiv1.scrollTo({ bottom: 0, behavior: 'smooth' })
      
      // window.scrollTo(0, messageBoxRef.current.offsetHeight);
      // window.scrollTo(0, wrapperRef.current.offsetHeight);
      
      }
  }



    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }


    // 렌더, return-render,
    return (
      <>
        {objectPopupData1}
        <div className={`a04_c`}>
          
        {
                  arrayCheckInData.length > 0 && 
                  arrayCheckInData.map((mapItem, mapIndex)=> {
                    if (mapIndex > 0) {
                      return <></>
                    }
                    return (

      <div 
      className="chatRoomWrapper" 
      style={{ 
        // minHeight : textCalendarMode === "week" ? "52px" : "52px",
        transition: `all 0.5s ease-out`,
      }}
    >
    <div className="chatRoomTopBar">
        <img className="chatTopLeftIcon2" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
          style={{
            // transform : textCalendarMode === "week" ? '0deg' : '180deg',
            // transition: `all 0.5s ease-out`,
          }}
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            window.localStorage.setItem("selectedTab4", 2);
            setScreenMode1('page004/998/chat-list')

          }}
        />
        <div className="chatTopItem">
          <div className="col1">
            <div className="imageWrapper">
              <img className="" src={mapItem.userData && mapItem.userData.avatar && mapItem.userData.avatar !== "" ? mapItem.userData.avatar :`/static/images/ssokdak/Tab5/defaultProf.svg` }  alt=""></img>
              
            </div>
            {/* <div className="dotRedM"></div> */}
          </div>
          <div className="col2">
            <div className="row0 row1">
              <div className="text0 text1">{mapItem.userData && mapItem.userData.nickname ? mapItem.userData.nickname : ""}</div>
              {/* <img className="profAdd" src={`/static/images/ssokdak/Tab4/profAdd.svg`} alt=""
                  style={{
                    // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                    // transition: `all 0.5s ease-out`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                  }}
                /> */}
            </div>
            <div className="row0 row2">
              {/* <div className="text0 text1">{`현재 활동 중`}</div> */}
            </div>
          </div>
        </div>
        {/* <img className="chatTopMenuIcon2" src={`/static/images/ssokdak/Tab4/topMore2.svg`} alt=""
          style={{
            // transform : textCalendarMode === "week" ? '0deg' : '180deg',
            // transition: `all 0.5s ease-out`,
          }}
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            
          }}
        /> */}
    </div>
  </div>
                    )
                  })
                }

          <div class="wrapper" 
            id={`wrapperRef`}
            // ref={wrapperRef}
          >
                {
                  arrayCheckInData.length > 0 && 
                  arrayCheckInData.map((mapItem, mapIndex)=> {
                    if (mapIndex > 0) {
                      return <></>
                    }
                    return (
                  <div className="chatRoomDetailTopPreviewWrapper">
                  <div className="profileWrapper">
                    <img 
                      className="profImg" 
                      src={mapItem.userData && mapItem.userData.avatar && mapItem.userData.avatar !== "" ? mapItem.userData.avatar :`/static/images/ssokdak/Tab5/defaultProf.svg` }
                      alt=""
                    />
                  </div>
                  <div className="text0 text1">{mapItem.userData && mapItem.userData.nickname ? mapItem.userData.nickname : ""}</div>
                  <div className="buttonBox0 buttonBox1">
                    <div className="textWrapper">
                      <div className="text00 text01">오늘 기분은</div>

                      { 
                                Array.from(conversationTopicArray) 

                                .filter((filterItem , filterIndex)=> {

                                  const ext0 = mapItem.topic && mapItem.topic.findIndex((findItem, findIndex)=> {
                                    // console.log("( mapIndex) === findItem", ("" + filterIndex) === findItem);
                                    if (("" + filterIndex) === findItem) {
                                      return findItem
                                    }
                                  });

                                  // console.log("ext0", ext0)

                                  if (ext0 > -1) {
                                    return (filterItem)
                                  }

                                })
                                .map((mapItem, mapIndex)=> {

                                    // console.log("mapItem", mapItem)

                                      if (mapIndex >= 1) {
                                        return <></>
                                      }
 
                                    return (
                                      <img className="img00 img01" 
                                        src={emoCardArray[mapItem.index].iconOnlyPathName} 
                                        alt="" 
                                        onClick={e=> {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          
                                        }}
                                      />)
                                })
                                }



                      <div className="text00 text02">이에요</div>
                    </div>
                  </div>
                  <div className="buttonBox0 buttonBox2">
                    <div className="textWrapper">
                    {/* {mapItem && mapItem.topicKrName ?<div className="text00 text01">#{mapItem.topicKrName}</div> : ""} */}
                    {

                      Array.from(conversationTopicArray) 
                      .filter((filterItem , filterIndex)=> {

                        const ext0 = mapItem.topic && mapItem.topic.findIndex((findItem, findIndex)=> {
                          // console.log("( mapIndex) === findItem", ("" + filterIndex) === findItem);
                          if (("" + filterIndex) === findItem) {
                            return findItem
                          }
                        });

                        // console.log("ext0", ext0)

                        if (ext0 > -1) {
                          return (filterItem)
                        }

                      }).map((mapItem, mapIndex)=> {

                        // console.log("tg", mapItem)

                        if (mapIndex >=3 ) {
                          return (<></>)
                        }
                        return (<div className="text00 text01">#{mapItem.krName}</div>)
                      })

                    }
                    </div>
                  </div>
                  <div className="text0 text2">
                    대화상대의 프로필 내용을 참고해서 대화를 시작해보세요!<br/>
                    대화 상대방도 반갑게 맞이해줄 거에요 :)
                  </div>
                  
                </div>
                    )
                  })
                }

            <div  id={`messageBoxRef`} className="chatUl chatRoomDetailContentWrapper" ref={messageBoxRef}> 
                
                {/* <div className="chatItemWrapper1">
                  <li className="chat_bulb1">
                      <div className="text0 text1">{`테스트데이터테스트데이터테스트데이터테스트데이터`}</div>
                      <div className="date0 date1">{moment().format("hh:mm A")}</div>
                  </li>
                </div> */}
                {
                  arrayChatMessages && arrayChatMessages
                  .sort(function(a, b)  {
                    return a.created_at - b.created_at;
                  })
                  .filter((filterItem, filterIndex)=> {
                    if (objectOpponentUserData && objectUserData) {
                      if ((filterItem.toUsername == objectOpponentUserData.username && filterItem.fromUsername == objectUserData.username) ||
                        (filterItem.fromUsername == objectOpponentUserData.username && filterItem.toUsername == objectUserData.username) 
                        
                        ) {
                        return filterItem
                      }
                    }
                  })
                  .map((mapItem, mapIndex)=> {
                    // console.log("mapItem", mapItem)
                    return (
                      <div className={ mapItem.fromUsername == objectUserData.username ? "chatItemWrapper2" : "chatItemWrapper1" }>
                      <li className={ mapItem.fromUsername == objectUserData.username ? "chat_bulb2" : "chat_bulb1" }>    
                        <div className="text0 text1">{mapItem && mapItem.text && mapItem.text}</div>
                        <div className="date0 date1">{moment().format("hh:mm A")}</div>
                      </li>
                      </div>
                    )
                  })
                }
            
            
              <div className="" style={{ height : `140px`}}></div>
            </div>
            </div>
            <div className="chat_send_box_wrapper">

            <div 
              className="chat_message_box"
              style={{ marginTop: 12}}
              >
                <div className="input_wrapper0 " 
                style={{
                    //  background : `#fff`
                    width : `calc(100% - 20px)`,
                    marginRight: `auto`
            }}
                >

                <input 
              value={textInput2}
              onChange={e=> {
                setTextInput2(e.target.value);
              }}
              placeholder={`보낼 메시지를 입력해주세요.`}
              onKeyDown={handleEnter}
            />
            {
              // textInput2.length > 0 &&
              <img src={ textInput2.length > 0 ? 
                '/static/images/ssokdak/Tab4/chatSend1.svg' 
                :
                '/static/images/ssokdak/Tab4/chatSend0.svg' 
              } 
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                if (textInput2 === "") {
                    alert("공백이 있습니다.")
                    return;
                }
                
                console.log("objectUserData", objectUserData)
                console.log("objectOpponentUserData", objectOpponentUserData)




    Firebase.database().ref()
    .child("accounts")
    .once("value", (snapshot) => {

      const data = snapshot.val();
      console.log("CHECKCHECK - 001 - 001", );

      // console.log("[테스트] >> snapshot.val()", )
      // console.log("[테스트] >> data", data)

      if (data) {

        const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
        setArrayUserData(data);
        const chatRoomOne = window.localStorage.getItem("chatRoomOne");
        // console.log("chatRoomOne", chatRoomOne)
        const findIndex1 = Object.values(snapshot.val()).findIndex((findItem, mapIndex)=> {
          // console.log("findItem", findItem)
          if (findItem.username === chatRoomOne ) {
            return findItem;
          }
        })
        console.log("findIndex1", findIndex1)
        console.log("data[findIndex1]",  Object.values(snapshot.val())[findIndex1])
  
        if (findIndex1 > -1 ) {
          setObjectOpponentUserData( Object.values(snapshot.val())[findIndex1]);
          setObjectOpponentUserKeyname(Object.keys(snapshot.val())[findIndex1])

          Firebase.database().ref()
          .child("behaviors")
          .child(`messages`)
          .push({
            date : `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            text : textInput2, 
            more : '',
            type : '',
            fromUsername : '' + objectUserData.username,
            fromNickname : '' + objectUserData.nickname,
            fromKey : '' + objectUserKeyname ,
            toUsername :  Object.values(snapshot.val())[findIndex1] &&   Object.values(snapshot.val())[findIndex1].username  ? '' +   Object.values(snapshot.val())[findIndex1].username : '',
            toNickname :   Object.values(snapshot.val())[findIndex1] &&   Object.values(snapshot.val())[findIndex1].nickname  ? '' +   Object.values(snapshot.val())[findIndex1].nickname : '',
            // toKey : '' + Object.keys(snapshot.val())[findIndex1],
          });


          setTextInput2("");
        }
        
  
      }
    });
                // messageBoxRef.current.scrollTo(5000, 5000);
                // wrapperRef.current.scrollTo(5000, 5000);

                // const messageBoxWrapDiv1 = document.getElementById("messageBoxRef");

                // messageBoxWrapDiv1.scrollTo({ bottom: 0, behavior: 'smooth' })
                
              }}
              alt=""
              />
            }
                </div>
                {/* <div className="button0" 
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    if (textInput2 == "") {
                        alert("공백이 있습니다.")
                        return;
                    }
                    
                    Firebase.database().ref().child("accounts").child(`/${objectUserKeyname}/messages`)
                    .push({
                      date : `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
                      text : textInput2
                    });
                    setTextInput2("");
                    
                  }}
                >전송</div> */}
          </div>

            </div>
        </div>
        <style>{`
        @import "compass/css3";

        $scroll:10px;
        ::-webkit-scrollbar{width:$scroll;}
        ::-webkit-scrollbar-thumb{border-radius:$scroll;background-color:rgba(255,255,255,0.2);}
        ::-webkit-scrollbar-track{border-radius:$scroll;background-color:rgba(255,255,255,0.1);}
        
        .chatItemWrapper1 {
          width : 100%;
          display: flex;
          flex-direction : row;
          align-items: flex-start;
          justify-content: flex-start;
          padding-bottom : 10px;
        }
        .chatItemWrapper2 {
          width : 100%;
          display: flex;
          flex-direction : row;
          align-items: flex-end;
          justify-content: flex-end;
          padding-bottom : 10px;
        }
        
        *,*:before,*:after{box-sizing:border-box;}
        .chatUl,li{list-style-type:none;}
        .wrapper {margin-top:20px; min-height : 100vh;}
        .wrapper .chatUl{ width:100%;margin:0 auto; overflow-x:hidden;overflow-y:scroll;padding-left: 20px !important;}
        .wrapper .chatUl li{
            margin-bottom:20px;padding:10px 20px;background-color:rgba(255,255,255,0.65);position:relative;border-radius:10px;font:1em/1.6 "arial";clear:both;text-shadow:1px 1px 0 rgba(255,255,255,0.35);
        }
        .chat_bulb1 {
            float:left;margin-left:80px;
            position : relative;
            max-width : 180px;
        }
        .chat_bulb2 {
            float:right;
            color:rgba(0,0,0,0.9);
            text-shadow:1px 1px 0 rgba(0,0,0,0.75);
            background-color:#FFE8E8 !important;
            position : relative;
            max-width : 180px;
            margin-right: 20px !important;
        }
        .chat_bulb1 .text1 {
          
          width : 100%;
          display: flex;
          flex-direction : row;
          align-items: flex-end;
          justify-content: flex-end;
          flex-wrap : wrap;
        }
        .chat_bulb2 .text1 {
          
          width : 100%;
          display: flex;
          flex-direction : row;
          align-items: flex-end;
          justify-content: flex-end;
          flex-wrap : wrap;
        }
        .chat_bulb1 > .date1 {
          position : absolute;
          bottom : -20px;
          left: 0px;

font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 130%;
/* identical to box height, or 13px */
display: flex;
align-items: flex-end;

/* Gray/05 */
color: #A6A6A6;

        }


        .chat_bulb2 > .date1 {
          position : absolute;
          bottom : -20px;
          right: 0px;

font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 130%;
/* identical to box height, or 13px */
display: flex;
align-items: flex-end;

/* Gray/05 */
color: #A6A6A6;

        }

        .chat_bulb1:before{left:-80px;background-image:url(${arrayCheckInData && arrayCheckInData[0] && arrayCheckInData[0].userData && arrayCheckInData[0].userData.avatar && arrayCheckInData[0].userData.avatar !== "" ? arrayCheckInData[0].userData.avatar :`/static/images/ssokdak/Tab5/defaultProf.svg` });
        }

        .wrapper {
          width : 100vw;
        }
        .wrapper .chatUl li:before,.wrapper .chatUl li:after{position:absolute;content:"";}
        .wrapper .chatUl li:before{width:45px;height:45px;top:0;border-radius:50%;background-position:50% 50%;background-repeat:no-repeat;}
        .wrapper .chatUl li:after{width:0;height:0;top:15px;border-top:15px solid rgba(255,255,255,0.65);}
        
        
        @media only screen and (min-width:768px){
          .wrapper .chatUl{
            width: 100%;
            min-height:100vh;
          }
        }
        .wrapper .chatUl {
            
        }

        .chat_send_box_wrapper {
          width : 100vw;
          height : 80px;
          z-index: 999;
          position : fixed;
          bottom: 0;
          background-color : #ffffff;

          display: flex;
          flex-direction : row;
        }

        .chat_send_box_wrapper .chat_message_box {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px 6px 0px 16px;
          gap: 2px;
          
          width: calc(100% - 40px);
          margin-left: 20px;
          height: 40px;
          
          /* Main/White */
          background: #FFFFFF;
          /* Gray/04 */
          border: 1px solid #C4C4C4;
          border-radius: 20px;
          position : relative;
        }
        .chat_send_box_wrapper .chat_send_button  {
          width : 40px;
          height : 40px;
          display: flex;
          flex-direction : row;
          align-items: center;
          justify-content: center;

        }
        .chat_send_box_wrapper input {
          width : calc(100% - 10px) !important;
          height : 100%;
        }
        .chat_send_box_wrapper img {
          width : 24px;
          height : 24px;
          object-fit : contain;

          position : absolute;
          top: 6px;
          right : 10px;
        }
        


        `}</style>
      </>
      
    );
}
