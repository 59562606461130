import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./index.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""

// 
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);


    const [objectUserData, setObjectUserData] = useState(null);
    const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
    const [objectArrayFolderData1, setObjectArrayFolderData1] = useState([]);
    const [objectArrayGameData1, setObjectArrayGameData1] = useState([]);

    const [objectPopupData1, setObjectPopupData1] = useState(null);

    const [currentFolderData, setCurrentFolderData] =  useState(null);
    const [currentCardsData, setCurrentCardsData] =  useState([]);

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }

  
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code=== viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      const currentFolderItem = localStorage.getItem("currentFolderItem");
      
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")
      var arrayCardData1 = [];
      var arrayFolderData1 = [];
      var arrayGameData1 = [];
      if (true && true) {
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                        
                        // window.localStorage.setItem("authStore",  JSON.stringify(data[keyName]));
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        props && props.handleUser && props.handleUser(keyName, data[keyName]);
                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                        if (data[keyName].folders) {
                          
                          console.log("data[keyName].folders - Object.values()",Object.values( data[keyName].folders));
                          if (Object.values( data[keyName].folders) && Object.values( data[keyName].folders).length > 0)
                          setObjectArrayFolderData1(Object.values( data[keyName].folders))

                        var folders = Object.values( data[keyName].folders) ;
                        if (folders && folders.length > 0 ) {
                          const findIndex1 = folders.findIndex((findItem, findIndex)=> {
                            if (findItem.id=== currentFolderItem) {

                              return (
                                findItem
                              )
                            }
                          })

                          if (findIndex1 > -1 ) {
                            setCurrentFolderData(folders[findIndex1])
                            // props && props.handleFolder(Object.keys(data[keyName].folders)[findIndex1], folders[findIndex1], Object.keys(data[keyName].folders)[findIndex1].id);

                            if (folders[findIndex1] && folders[findIndex1].cards && folders[findIndex1].cards.length > 0) {
                              console.log("=== folders[findIndex1].cards - Object.values()",Object.values( folders[findIndex1].cards));
                              
                              var cards = Object.values( folders[findIndex1].cards);
                              setCurrentCardsData(cards);

                            }

                          }else {
                            // 없음
                          }
                        }

                        }else {
                          setObjectArrayFolderData1([])
                        }
                        if (data[keyName].games) {
                          setObjectArrayGameData1([...data[keyName].games])
                        }else {
                          setObjectArrayGameData1([])
                        }
                        // if (data[keyName].status=== "YES") {
                        // } else {
                        // }
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });



      }


    }, [useSelector(state => state)])

    // console.log("selectedPageObject", selectedPageObject)


    return (

      <>
      {/* {

        selectedPageObject &&
        selectedPageObject.top_nav_display_visible &&
        selectedPageObject.top_nav_display_visible  === true &&
        selectedPageObject.top_nav_other_function=== "search" &&
        // 검색바 -> 따로 페이지로만 빼는게 나을 듯
        <div className={`navigation_top_t`}>
          <div className="nav_col0 nav_col1"></div>
        </div>
      } */} 
      {
        selectedPageObject &&
        selectedPageObject.top_nav_display_visible &&
        selectedPageObject.top_nav_display_visible  === true &&
        selectedPageObject.top_nav_other_function=== "" &&
        // true &&
      <div className={`navigation_top_t`} style={{
        background : currentFolderData &&
        currentFolderData.background &&
        selectedPageObject.page_code=== "page002/1/remind-list" ? currentFolderData.background : "#fff"
      }}>
        <div className="nav_col0 nav_col1">

        {
        selectedPageObject &&
        selectedPageObject.top_nav_back_enabled &&
        selectedPageObject.top_nav_back_enabled  === true &&
        // true && 
            <img 
            className={`image1_0 image1_1`} 
            src={`/static/images/ssokdak/Tab2/topBack1.svg`}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setScreenMode1(selectedPageObject.parent_code);
            }}
            alt=""
            />
          }
          
        </div>
        <div className="nav_col0 nav_col2">
        {
          selectedPageObject &&
          selectedPageObject.name_kr &&
          <>
          {/* const pageCode1 =  "page002/1/remind-list"; */}
          { 
          currentFolderData &&
          currentFolderData.title &&
          selectedPageObject.page_code=== "page002/1/remind-list" ?
            currentFolderData.title
            :
            selectedPageObject.name_kr
          }
            
          </>
        }
        </div>
            
       
        <div className="nav_col0 nav_col3">
        {
        selectedPageObject &&
        selectedPageObject.top_nav_search_enabled &&
        selectedPageObject.top_nav_search_enabled  === true &&
        // true && 
            <img 
            className={`image1_0 image1_1`} 
            src={appImages._000_3} 
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setScreenMode1('page088/0/search');
              // 
            }}
            alt=""
            />
          }
        </div>
      </div>
      }
      </>
      
    );
}
