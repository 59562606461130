import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./aa01.css";
import "./aa02.css";
import "./examples/tinder.css"


import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";
import Hammer from "react-hammerjs";
import Advanced from './examples/Advanced'
import Simple from './examples/Simple'

import TabBottom from "../../TabBottom/Tab";

import SdOtherUserProfileScreen from "./SdOtherUserProfileScreen";

const conversationTopicArray = [
  {
      index : 1, 
      enName : '',
      krName : '정신건강',
  },
  {
      index : 2, 
      enName : '',
      krName : '성생활',
  },
  {
      index : 3, 
      enName : '',
      krName : '금전/사업',
  },
  {
      index : 4, 
      enName : '',
      krName : '대인관계',
  },
  {
      index : 5, 
      enName : '',
      krName : '자아/성격',
  },
  {
      index : 6, 
      enName : '',
      krName : '결혼/육아',
  },
  {
      index : 7, 
      enName : '',
      krName : '이별/이혼',
  },
  {
      index : 8, 
      enName : '',
      krName : '가족',
  },
]
 
const pageCode1 = 'page004/998/chat-list';
// <div className={``}></div>
// <div className={``}></div>
export default function ThisComponent(props) {
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

  const [textInput1, setTextInput1] = useState("");
  const [textInput2, setTextInput2] = useState("");
  const [textInput3, setTextInput3] = useState("");
  const [textInput4, setTextInput4] = useState("");
  const [textInput5, setTextInput5] = useState("");
  const [textInput6, setTextInput6] = useState("");
  const [textInput7, setTextInput7] = useState("");
  const [textInput8, setTextInput8] = useState("");
  const [textInput9, setTextInput9] = useState("");
  const [textInput10, setTextInput10] = useState("");
  const [textInput11, setTextInput11] = useState("");
  const [textInput12, setTextInput12] = useState("");
  const [textInput13, setTextInput13] = useState("");
  const [textActiveFilter1, setTextActiveFilter1] = useState("");

  const [boolFirst, setBoolFirst] = useState(true);
  const [boolPopup1, setBoolPopup1 ] = useState(false);
  const [boolPopup2, setBoolPopup2 ] = useState(false);

  const [boolPopup3, setBoolPopup3 ] = useState(true);
  const [boolPopup4, setBoolPopup4 ] = useState(false); 
  const [boolPopup5, setBoolPopup5 ] = useState(false); 
  const [selectedUserDetail, setSelectedUserDetail]= useState(); 

  const [arraySelected, setArraySelected] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [objectPopupData1, setObjectPopupData1] = useState(null);

  const [intSelectedTab, setIntSelectedTab] = useState(0);
  const [intEmptyProfileStep1, setIntEmptyProfileStep1] = useState(0);
  const [clickedIndex2, setClickedIndex2] = useState(-1);
  const [selectedTopic, setSelectedTopic]  = useState([]);


  const [textAge, setTextAge] = useState("");
  const [textLocation, setTextLocation] = useState("");
  const [textGender, setTextGender] = useState("");
  const [arrayHashtag, setArrayHashtag] = useState([]);
  const [textBrother, setTextBrother] = useState("");
  const [textJob, setTextJob] = useState("");
  const [textChildren, setTextChildren] = useState("");

  const [arrayChildren,setArrayChildren ]=  useState([]);
  const [arrayTextChildren0, setArrayTextChildren0] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren1, setArrayTextChildren1] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren2, setArrayTextChildren2] = useState(["","","","","","","","","","",]);
  const [arrayTextChildren3, setArrayTextChildren3] = useState(["","","","","","","","","","",]);

  const [arrayData1, setArrayData1] = useState([]);
  const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
  const [objectUserKeyname, setObjectUserKeyname] = useState("");
  const [objectUserData, setObjectUserData] = useState(null);

  const [arrayUserData, setArrayUserData ] = useState([]);
  const [arrayCheckInData, setArrayCheckInData] = useState([]);

  let dispatch = useDispatch()
  // view data from store
  let viewScreenMode = useSelector((state) =>{
    return state[SCREENMODE_KEY]
  })

  let setScreenMode1 = (param) => {
    dispatch(setScreenMode(param))
  }

  // if (viewScreenMode.mode != "popup/mypage/notice") {
  //     return <></>
  // }
  useEffect(()=> {

    setIntEmptyProfileStep1(0);
    // setIntSelectedTab(0)
    // setIntEmptyProfileStep1(0);
    if (viewScreenMode && viewScreenMode.mode) {
      // console.log()
      const arr1 = appPages.pageCodeInfoArr;
      const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
          if (arrItem && arrItem.page_code === viewScreenMode.mode) {
            return arrItem
          }
      })

      if (selectedPageIndex > -1) {
        setSelectedPageObject(arr1[selectedPageIndex])
      }
    }
    if (viewScreenMode.mode !== pageCode1) {
      return;
    }
    const currentFolderItem =  window.localStorage.getItem("currentFolderItem");
    const authStoreKeyName = localStorage.getItem("authStoreKeyName");
    const authStoreUserName = localStorage.getItem("authStoreUserName");
    const authStore1 = localStorage.getItem("authStore");
    const selectedTab4 = localStorage.getItem("selectedTab4");

    console.log("selectedTab4", selectedTab4)
    if (selectedTab4 ) {
      if  (selectedTab4 == 1) {
        setIntSelectedTab(0)
      }
      if  (selectedTab4 == 2) {
        setIntSelectedTab(1)
      }
    }

    Firebase.database().ref()
    .child("accounts")
    .once("value", (snapshot) => {

      const data = snapshot.val();
      console.log("CHECKCHECK - 001", );

      // console.log("[테스트] >> snapshot.val()", )
      // console.log("[테스트] >> data", data)
      const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
      setArrayUserData(data);
    });


    Firebase.database().ref()
    .child("accounts")
    .orderByChild("username")
    .equalTo(authStoreUserName)
    .once("value", (snapshot) => {
        if (snapshot.val()) {
            const data = snapshot.val();
            console.log("CHECKCHECK - 001", );

            // console.log("[테스트] >> snapshot.val()", )
            // console.log("[테스트] >> data", data)
            const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
            if (keyName) { 
                window.localStorage.setItem("authStoreKeyName",  keyName);
                
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                window.localStorage.setItem("authStoreKeyName",  keyName);
                
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                // setObjectUserData(data[keyName]);
                // setObjectUserKeyname(keyName)
                // props && props.handleUser(keyName, data[keyName]); 
                setObjectUserData(data[keyName]);
                setObjectUserKeyname(keyName); 

                var isCBTPopupOpen = true;
                var cbtCount = 0;

                if (data[keyName].gender) {

                  setTextGender( data[keyName].gender)

                  cbtCount = cbtCount + 1;
                }
                if (data[keyName].job) {

                  setTextJob( data[keyName].job)
                  cbtCount = cbtCount + 1;
                }
                
                if (data[keyName].birth) {

                  var split1 =  data[keyName].birth.split("-")
                  var year1 = split1[0];
                  var month1 = split1[1];
                  var date1 = split1[2];

                  const today = new Date();
                  const birthDate = new Date(parseInt(year1), parseInt(month1) - 1, parseInt(date1)); // 2000년 8월 10일
            
                  let age = today.getFullYear()  - birthDate.getFullYear() + 1;
                  setTextAge(age); 
                }
                if (data[keyName].hobby) {

                  setArrayHashtag( data[keyName].hobby)
                  cbtCount = cbtCount + 1;
                } else {
                  cbtCount = cbtCount + 1;
                }
                if (data[keyName].location) {

                  setTextLocation( data[keyName].location)
                  cbtCount = cbtCount + 1;
                }
                if (data[keyName].brother) {

                  var split1 =  data[keyName].brother.split("/")
                  var temp1 = split1[0];
                  var temp2 = split1[1];
                  setTextBrother(temp1 + "남 " + temp2 + "녀")
                  cbtCount = cbtCount + 1;
                }
                if (data[keyName].arrayChildren) {

                  setArrayChildren(data[keyName].arrayChildren)
                  setArrayTextChildren0(data[keyName].arrayTextChildren0)
                  setArrayTextChildren1(data[keyName].arrayTextChildren1)
                  setArrayTextChildren2(data[keyName].arrayTextChildren2)
                  setArrayTextChildren3(data[keyName].arrayTextChildren3)
                  cbtCount = cbtCount + 1;
                }

                // brotherPublic, childrenPublic
                
                if (data[keyName].brotherPublic) {

                  // setBoolCheck1(data[keyName].brotherPublic)
                } 
                if (data[keyName].jobPublic) {

                  // setBoolCheck2(data[keyName].jobPublic)
                } 
                if (data[keyName].childrenPublic) {

                  // setBoolCheck3(data[keyName].childrenPublic)
                }

                if (cbtCount <= 4 ) {
                  setBoolPopup3(true);
                } else {

                  setBoolPopup3(false);
                }
             
            } else {

            }

        }  else {
            // alert("존재하지 않는 회원입니다.");
        }
    });


    Firebase.database().ref()
    .child("behaviors/community-checkins")
    // .orderByChild("username")
    // .equalTo(authStoreUserName)
    .once("value", (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        console.log("community-checkins--- data", data)

        if (data && Object.values(data) &&  Object.values(data).length > 0) {

          
          const array1 = Object.values(data);
          const filter1 = array1.filter((filterItem, filterIndex)=> {
            if (filterItem.created_day === moment().format("YYYY-MM-DD")){
              return filterItem
            }
          })
          const users1 = filter1.filter((filterItem, filterIndex)=> {
            if (filterItem.created_day === moment().format("YYYY-MM-DD")){
              return filterItem
            }
          }).filter((filterItem, filterIndex)=> {
            if (filterItem.username === authStoreUserName){
              return filterItem
            }
          })

          if (users1.length > 0) {
              
            if (users1.length > 0) {
              setBoolFirst(false);
            } else {

                setBoolFirst(true);
            }
          } else {

              setBoolFirst(true);
          } 
          setArrayCheckInData(Object.values(data))


        }
      }
    });

    


  }, [useSelector(state => state)])

  function handleCreate() { 
                                          
    if (textInput1 === "" || textInput2 === "" || textInput3 === "") {
      return;

    } else {

      const today = new Date();
      const birthDate = new Date(textInput3, parseInt(textInput2) - 1, textInput1); // 2000년 8월 10일

      let age = today.getFullYear()  - birthDate.getFullYear() + 1;
      setTextAge(age);

      var temp2 = textInput2;
      var temp1 = textInput1;
      if (parseInt(textInput2) < 10 ) {
        temp2 = "0" + temp2;
      }
      if (parseInt(textInput1) < 10 ) {
        temp1 = "0" + temp1;
      }
      
      Firebase.database().ref()
      .child("accounts/" + objectUserKeyname).update({"birth" : textInput3 + "-" + temp2 + "-" + temp1 });
      
    }


    if (textInput4 === "" || textInput5 === "") {
  
    } else {

      Firebase.database().ref()
      .child("accounts/" + objectUserKeyname).update({"brother" :  textInput4 + "/" + textInput5});
      setTextBrother(textInput4 + "남 " + textInput5 + "녀")
    }


    Firebase.database().ref()
    .child("accounts/" + objectUserKeyname).update({"job" :  textInput6});

    Firebase.database().ref()
    .child("accounts/" + objectUserKeyname).update({"location" :  textInput7});
    
    Firebase.database().ref()
    .child("accounts/" + objectUserKeyname).update({
      "arrayChildren" : arrayChildren,
      "arrayTextChildren0" : arrayTextChildren0, 
      "arrayTextChildren1" : arrayTextChildren1, 
      "arrayTextChildren2" : arrayTextChildren2, 
      "arrayTextChildren3" : arrayTextChildren3, 
    });


    if (arrayHashtag.length > 0) {
      Firebase.database().ref()
      .child("accounts/" + objectUserKeyname).update({"hobby" :  arrayHashtag});

    } else {

      // Firebase.database().ref()
      // .child("accounts/" + objectUserKeyname).update({"hobby" :  []});
    }



    setBoolPopup1(false);
    setBoolPopup2(false);
    setBoolPopup3(false);
    

    // setScreenMode1('page001/0/home')


  }
  function excerpt(text) {
    return text.length > 100 ? text.substring(0, 100) + "..." : text;
}


  async function resizeImg(photo) {
    const canvas = document.createElement("canvas");
    canvas.width = 250;
    canvas.height = 250;
    const ctx = canvas.getContext("2d");
    const img = await createImg(photo);

    const [x, y, w, h] = squareCenterCrop(img, 250);
    ctx.drawImage(img, x, y, w, h);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  }

  function createImg(photo) {
    const img = new Image();
    img.src = photo;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
    });
  }

  
  function squareCenterCrop(img, size) {
    let x = 0,
      y = 0,
      w = size,
      h = size;

    if (img.naturalWidth > img.naturalHeight) {
      w = (size * img.naturalWidth) / img.naturalHeight;
      x = -Math.abs(w - size) / 2;
    } else {
      h = (size * img.naturalHeight) / img.naturalWidth;
      y = -Math.abs(h - size) / 2;
      console.log(x, y, w, h);
    }
    return [x, y, w, h];
  }



  const handleEnter = (e) => {
    // if (textSearch1 == "") {
    //   return;
    // }
    if (textInput9.trim() === "") {
      return;
    }
    if (arrayHashtag.length === 9) {
      return;
    }

    if ( e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      // handleLogin();
      var tempArray = [...arrayHashtag];
      tempArray.push(textInput9)
      setArrayHashtag(tempArray);
      setTextInput9("");

    }
}



  useEffect(()=> {
    if (intEmptyProfileStep1 == 5) {
      setTextInput8("")
    }
  }, [intEmptyProfileStep1])

  // console.log("currentFolderData", currentFolderData);

  const [showAdvanced, setShowAdvanced] = useState(true)

  if (viewScreenMode.mode !== pageCode1) {
    return <></>
  }

  return (
    <>
      <div className={`aa01_c`}> 
        <div 
            className="chatWeekWrapper" 
            style={{ 
              // minHeight : textCalendarMode === "week" ? "52px" : "52px",
              transition: `all 0.5s ease-out`,
            }}
          >
          <div className="chatWeekTopBar">
              <img className="chatTopLeftIcon" src={`/static/images/ssokdak/Tab4/topFilter1.svg`} alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPopup4(true)

                }}
              />
              <div className="chatWeekDiv1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Community
              </div>
              {/* <img className="chatTopMenuIcon" src={`/static/images/ssokdak/Tab4/topBell1.svg`} alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                }}
              /> */}
          </div>
        </div>

        <div className="chatTabMenuWrapper">
          <div className="chatTab0 chatTab1" 
            // onClick={e=> {}}
          >
            <div className="text0 text1"
            style={{ 
              color : intSelectedTab === 0 ? `#0f0f0f` : `#8B8B8B`,
              borderBottom : intSelectedTab === 0 ? `2px solid #0f0f0f` : `2px solid transparent`
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setIntSelectedTab(0);
            }}
            >친구찾기</div>
          </div>
          <div className="chatTab0 chatTab2">
            <div className="text0 text1"
            style={{ 
              color : intSelectedTab === 1 ? `#0f0f0f` : `#8B8B8B`,
              borderBottom : intSelectedTab === 1 ? `2px solid #0f0f0f` : `2px solid transparent`
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setIntSelectedTab(1);
            }}>대화목록</div>
          </div>
        </div>

            {
              intSelectedTab === 0 &&
              <>
              { boolFirst === true &&
              <div className="cardFirst" style={{ background : `transparent` }}>
                <img className="" src={'/static/images/ssokdak/Tab4/dailyFirstChatIntro.png' } alt=""
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setScreenMode1('page004/998/chat-daily-first')
                  }}
                ></img>
                </div>
                  }



                  { boolFirst === false &&
                    <>
                    {showAdvanced ? <Advanced 
                    filterValue={clickedIndex2 > -1 ? conversationTopicArray[clickedIndex2] : null}
                    userAll={arrayUserData}
                    checkInDataAll={arrayCheckInData}
                    callBack1={e=> {
                      // 프로필 보기
                            window.localStorage.setItem("chatUserOne", e.userData.username);
                            window.localStorage.setItem("chatAddOne", e.userData.username);
                            window.localStorage.setItem("chatRoomOne", e.userData.username);
                            // setScreenMode1('page004/998/chat-detail');
                            setSelectedUserDetail(e.userData.username);
                            setBoolPopup5(true);

                    }}
                    callBack2={e=> {
                      // 친구추가
                            window.localStorage.setItem("chatUserOne", e.userData.username);
                            window.localStorage.setItem("chatAddOne", e.userData.username);
                            window.localStorage.setItem("chatRoomOne", e.userData.username);
                            setScreenMode1('page004/998/chat-detail');
                    }}
                    callBack3={e=> {
                      // 대화하기
                            window.localStorage.setItem("chatUserOne", e.userData.username);
                            window.localStorage.setItem("chatAddOne", e.userData.username);
                            window.localStorage.setItem("chatRoomOne", e.userData.username);
                            setScreenMode1('page004/998/chat-detail');
                    }}
                  /> : <Simple 
                  userAll={arrayUserData}
                  checkInDataAll={arrayCheckInData}
                callBack1={e=> {
                  // 프로필 보기
                  window.localStorage.setItem("chatUserOne", e.userData.username);
                  window.localStorage.setItem("chatAddOne", e.userData.username);
                  window.localStorage.setItem("chatRoomOne", e.userData.username);
                        setScreenMode1('page004/998/chat-detail');
                }}
                callBack2={e=> {
                  // 친구추가
                  window.localStorage.setItem("chatUserOne", e.userData.username);
                  window.localStorage.setItem("chatAddOne", e.userData.username);
                  window.localStorage.setItem("chatRoomOne", e.userData.username);
                        setScreenMode1('page004/998/chat-detail');
                }}
                callBack3={e=> {
                  // 대화하기
                  window.localStorage.setItem("chatUserOne", e.userData.username);
                  window.localStorage.setItem("chatAddOne", e.userData.username);
                  window.localStorage.setItem("chatRoomOne", e.userData.username);
                        setScreenMode1('page004/998/chat-detail');
                }}
                 />}
                  </>
                  }
      
      
      
              </>
            }

            {
              intSelectedTab === 1 &&
              <div className="chatListWrapper">
                {
                  
                  arrayCheckInData && 
                  arrayCheckInData
                  .filter((filterItem, filterIndex)=> {
                    if ( filterItem.username !== objectUserData.username) {
                      return (filterItem)

                    }
                  })
                  .map((mapItem, mapIndex)=> {
                    // console.log("mapItem", mapItem);
                    console.log("arrayUserData", arrayUserData)
                    return (

                      <div className="chatItem" 
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          window.localStorage.setItem("chatUserOne", mapItem.userData.username);
                          window.localStorage.setItem("chatAddOne", mapItem.userData.username);
                          window.localStorage.setItem("chatRoomOne", mapItem.userData.username);
                          setScreenMode1('page004/998/chat-detail');
                        }}
                      >
                        <div className="col1">
                          <div className="imageWrapper">
                            <img className=""   src={mapItem.userData && mapItem.userData.avatar && mapItem.userData.avatar !== "" ? mapItem.userData.avatar :`/static/images/ssokdak/Tab5/defaultProf.svg` } alt=""></img>
                            
                          </div>
                          {/* <div className="dotRed"></div> */}
                        </div>
                        <div className="col2">
                          <div className="row0 row1">
                            <div className="text0 text1">{mapItem && mapItem.userData && mapItem.userData ? mapItem.userData.nickname : ""}</div>
                            <div className="text0 text2">
                              <div className="dotPurple"></div>
                              {mapItem && mapItem.created_time  ? mapItem.created_time : ""}
                              </div>
                          </div>
                          <div className="row0 row2">
                            <div className="text0 text1">
                              {/* {`...`} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div></div>
              </div>
            }

          <TabBottom></TabBottom>

      </div>
      {
        boolPopup4 && 
        <div 
                className="bottomSheet0 bottomSheetForEmotionCard" 
                style={{
                  // height: boolPopup4 === false ? 0 : `100vh`,
                  height: `100vh`,
                  // transition: `all 0.5s ease-out`
                }}
                >
                <div className="blurView" onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  // setScreenMode1("page001/0/home");
                  setBoolPopup4(false);
                }}></div>
                <div className="contentView" > 
                  <div className="bottomSheetEmoWrapper">
                  <div className="wrapDiv1">  
                  <div className="row0 row1"
                  >
                    <div className="text0 text1">필터</div>
                    <img className="closeBtn" 
                    src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                    style={{ marginLeft : 'auto'}}
                    onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        setBoolPopup4(false);
                    }}
                    />
                    </div>
                    {
                        Array.from(conversationTopicArray)
                        .sort(function(a, b)  {
                          return a.index - b.index;
                        })
                        .map((mapItem, mapIndex)=> {
                          const ext0 = selectedTopic.findIndex((findItem, findIndex)=> {
                            if (findItem === ("" + mapIndex)) {
                              return findItem
                            }
                            
                          });
  
  
                          return (
                            <div className="tagggItem " 
                                style={{
                                    background :  ext0 > -1?
                                    `#FF7A7A` : `#F5F5F5`,
  
                                    
                                    color :  ext0 > -1 ?
                                    `#FFFFFF` : `#2D2D2D`,
  
                                    
                                }}
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    // setClickedIndex2(mapIndex)
                                    var selected1 = [...selectedTopic];
                                    var fixed1 = [];
  
                                    const ext1 = selected1.findIndex((findItem, findIndex)=> {
                                      if (findItem === ("" +  mapIndex)) {
                                        return findItem
                                      }
                                      
                                    });
                                    console.log("mapIndex", mapIndex);
                                    console.log("ext1", ext1);
                                    if (ext1 > -1){
                                      selected1.splice(ext1, 1);
                                      setSelectedTopic(selected1)
                                    } else {
                                      fixed1 = [...selected1, ("" + mapIndex)]
                                      console.log("fixed1", fixed1);
                                      setSelectedTopic(fixed1);
                                    }
  
                                }}
                            >
                                #{mapItem.krName}
                            </div>)
                        })
                      }
                    </div>
                    </div>
                </div>
              </div>

      }

    {boolPopup3 &&
      <div 
              className="bottomSheet0 bottomSheetForEmotionCard" 
              style={{
                height: `100vh`,
                // height: boolPopup3 === false ? 0 : `100vh`,
                transition: `all 0.5s ease-out`
              }}
              >
                <div className="blurView" onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setScreenMode1("page001/0/home");
                  setBoolPopup3(false);
                  setIntEmptyProfileStep1(0);
                }}></div>
                {
                  intEmptyProfileStep1 === 0 &&

                <div className="contentView" > 
                <div className="row0 row1"
                >
                  {/* <img className="closeBtn" 
                  src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                  style={{ marginLeft : 'auto'}}
                  onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                      // setScreenMode1( 'page002/0/mind-diary')
                  }}
                  /> */}
                </div>

                  <div className="communityFirstRowWrapper0 ">
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();

                                setScreenMode1("page001/0/home");
                              }}
                              />
                            </div>
                    <div className="row0 row1">
                      <img className="image0 image1" src="/static/images/ssokdak/Tab4/chatSupporterAi.svg" alt=""></img>
                    </div>
                    <div className="row0 row2">
                      <div className="text0 text1">
                        {parse(`커뮤니티 사용은 처음이시죠?<br/>프로필을 먼저 완성해주세요`)}
                      </div>
                    </div>
                    <div className="row0 row3">
                      <div className="text0 text2">
                        {parse(`
                        커뮤니티는 프로필 완성 후에 사용 가능해요.<br/>
                        완성한 프로필을 바탕으로 관심사/감정이 비슷한<br/>
                        대화친구를 추천해 드려요 :)`)}

                      </div>
                    </div>
                    <div className="row0 row4">
                      <div className="button0 button1" onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        setIntEmptyProfileStep1(1);
                      }}>
                        프로필 작성하러 가기
                      </div>
                    </div>

                  </div>


              </div>
                }
{
                  intEmptyProfileStep1 >= 300 &&

                <div className="contentView" style={{ position : 'fixed', bottom : 0}}> 
                <div className="row0 row1"
                >
                  <div className="text0 text1">{intEmptyProfileStep1 === 300 && "직업"}{intEmptyProfileStep1 === 400 && "지역"}을 선택해주세요.</div>
                  <img className="closeBtn" 
                  src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                  style={{ marginLeft : 'auto'}}
                  onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();

                      if (intEmptyProfileStep1 === 300 ) {

                        setIntEmptyProfileStep1(3);
                        setTextInput6("");
                      }
                      if (intEmptyProfileStep1 === 400 ) {

                        setIntEmptyProfileStep1(4);
                        setTextInput7("");
                      }
                  }}
                  />
                </div>
                  <div className="selectJobWrapper">
                    {
                      Array.from(intEmptyProfileStep1 === 300  ?[
                        "전업주부","회사원","교사/교직원","전문직 종사자",
                        "공무원","학생/대학원생","구직/이직자","육아 휴직자",
                        "자영업자","파트타임/계약직 종사자", 
                      ] :
                      ["서울", "경기도", "인천", "강원도", "충청도", "전라도", "경상도","부산", "제주"]
                      ).map((mapItem, mapIndex)=> {
                        return (
                          <div className="selectJobItem"

                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            if (intEmptyProfileStep1 === 300 ) {
      
                              setIntEmptyProfileStep1(3 );
                              setTextInput6(mapItem);
                            }
                            if (intEmptyProfileStep1 === 400 ) {
      
                              setIntEmptyProfileStep1(4);
                              setTextInput7(mapItem);
                            }
                        }}
                          >
                            <div className="text0 text1">{mapItem}</div>
                            <img className="icon0 icon1" 
                              src={
                                (intEmptyProfileStep1 === 300  && mapItem === textInput6 )
                                ? `/static/images/ssokdak/Tab4/checkPink1.svg` : 
                                (intEmptyProfileStep1 === 400  && mapItem === textInput7 )
                                ? `/static/images/ssokdak/Tab4/checkPink1.svg` : 

                                `/static/images/ssokdak/Tab4/checkGray1.svg`
                              } alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (intEmptyProfileStep1 === 300 ) {
            
                                    setIntEmptyProfileStep1(3 );
                                    setTextInput6(mapItem);
                                  }
                                  if (intEmptyProfileStep1 === 400 ) {
            
                                    setIntEmptyProfileStep1(4);
                                    setTextInput7(mapItem);
                                  }
                              }}
                              />
                          </div>
                        )
                      })
                    }
                  </div>

              </div>
                }
                {
                  intEmptyProfileStep1 >= 1 &&
                  <div className="fullContentView" 
                    style={{ zIndex : (intEmptyProfileStep1 >= 300) ? -1 : 999}}
                  >
                  <div 
                        className="fullContentTopWrapper" 
                        style={{ 
                          // minHeight : textCalendarMode === "week" ? "52px" : "52px",
                          transition: `all 0.5s ease-out`,
                        }}
                      >
                      <div className="fullContentTopTopBar">
                          <div className="fullContentTopDiv0"
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {intEmptyProfileStep1.toString().replaceAll("0", "")}/6
                          </div>
                          <div className="fullContentTopDiv1"
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            Check-in
                          </div>
                          <img className="fullContentTopDiv2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                            style={{
                              // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                              // transition: `all 0.5s ease-out`,
                            }}
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              setIntEmptyProfileStep1(0);

                            }}
                          />
                      </div>
                      {
                        intEmptyProfileStep1 === 1 && <>
                          <div className="topLabel">{parse(`나이를<br/>알려주세요.`)}</div>
                          <div className="inputRow0 inputRow1">
                            <div className="inputWrapper0 inputWrapper1">
                                <input 
                                  value={textInput1}
                                  onChange={e=> {
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }
                                    if (parseInt(temp) > 31) {
                                      temp = 31;
                                    }
                                    
                                    setTextInput1(temp);
                                  }}
                                  placeholder={`DD`}
                                />
                            </div>
                            <div className="inputWrapper0 inputWrapper2">
                                <input 
                                  value={textInput2}
                                  onChange={e=> {
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }
                                    
                                    if (parseInt(temp) > 12) {
                                      temp = 12;
                                    }
                                    setTextInput2(temp);
                                  }}
                                  placeholder={`MM`}
                                />
                            </div>
                            <div className="inputWrapper0 inputWrapper3">

                            <input 
                                  value={textInput3}
                                  onChange={e=> {
                                    // e.preventDefault();
                                    // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 4) {
                                        return;
                                    }
                                    
                                    if (parseInt(temp) > 2000) {
                                      temp = 2000;
                                    }
                                    setTextInput3(temp);
                                  }}
                                  placeholder={`YYYY`}
                                />

                            </div>
                          </div>
                        </>
                      }

                      {
                        intEmptyProfileStep1 === 2 && <>
                          <div className="topLabel">{parse(`형제관계가<br/>어떻게 되나요?`)}</div>
                          <div className="inputRow0 inputRow1">
                            <div className="inputWrapper0 inputWrapper1">
                                <input 
                                  value={textInput4}
                                  onChange={e=> {
                                    // e.preventDefault();
                                    // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }
                                    
                                    // if (parseInt(temp) > 12) {
                                    //   temp = 12;
                                    // }
                                    setTextInput4(temp);
                                  }}
                                  placeholder={`01`}
                                />
                            </div>
                            <div className="inputWrapperPostfixLabel">남</div>
                            <div className="inputWrapper0 inputWrapper2">
                                <input 
                                  value={textInput5}
                                  onChange={e=> {
                                    // e.preventDefault();
                                    // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }
                                    
                                    // if (parseInt(temp) > 12) {
                                    //   temp = 12;
                                    // }
                                    setTextInput5(temp);
                                  }}
                                  placeholder={`01`}
                                />
                            </div>
                            <div className="inputWrapperPostfixLabel">녀</div>
                          </div>
                        </>
                      }

{
                        (intEmptyProfileStep1 === 3 || intEmptyProfileStep1 === 300) && <>
                          <div className="topLabel">{parse(`직업이<br/>무엇인가요?`)}</div>
                          <div className="inputRow0 inputRow1">
                            <div className="inputWrapper0 inputWrapperA"
                            
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              
                              setIntEmptyProfileStep1(300);

                            }}>
                                <input 
                                  value={textInput6}
                                  placeholder={`직업을 입력해 주세요.`}
                                  disabled={true}
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    setIntEmptyProfileStep1(300);

                                  }}
                                />
                            </div>
                          </div>
                        </>
                      }

                      {
                        (intEmptyProfileStep1 === 4 || intEmptyProfileStep1 === 400) && <>
                          <div className="topLabel">{parse(`어느 지역에서<br/>거주 중인가요?`)}</div>
                          <div className="inputRow0 inputRow1">
                            <div className="inputWrapper0 inputWrapperA"
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    setIntEmptyProfileStep1(400);

                                  }}>
                                <input 
                                  value={textInput7}
                                  placeholder={`지역을 입력해 주세요.`}
                                  disabled={true}
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    setIntEmptyProfileStep1(400);

                                  }}
                                />
                            </div>
                          </div>
                        </>
                      }


                      {
                        (intEmptyProfileStep1 === 5) && <>
                          <div className="topLabel">
                            {textInput8 === "" && parse(`육아중인<br/>자녀가 있나요?`)}
                            {textInput8 !== "" && parse(`육아중인 자녀에 대해<br/>얘기해줄래요?`)}
                          </div>
                          
                          <div className="rowItemOptionSelectorWrapper">
                            
                                    {
                                      textInput8 === "" && 
                                        Array.from([
                                            "네",
                                            "아니오",
                                        ]).map((mapItem, mapIndex)=> {
                                            return (
                                                <div className={"rowItemOption0 rowItemOption" + (mapIndex + 1)}
                                                style={{
                                                    color : 
                                                    textInput8 === mapItem ?
                                                    `#fff` : `#000`,
                                                    background : 
                                                    textInput8 === mapItem ?
                                                    `#FF7A7A` : `#fff`,
                                                }}
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setTextInput8(mapItem)
                                                    if (mapItem === "아니오") {
                                                      setIntEmptyProfileStep1(intEmptyProfileStep1 + 1);
                                                    }
                                                    
                                                }}>
                                                    <img className="img0 img1" 
                                                    src={ 
                                                        textInput8 === mapItem ?
                                                        `/static/images/ssokdak/Init/iconWhiteCheck.svg` 
                                                        :
                                                        `/static/images/ssokdak/Init/iconWhiteCheck.svg` 
                                                    } alt=""
                                                    />
                                                    <div className="text0 text1" >
                                                      {mapItem}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                      textInput8 !== "" && <>
                                      {
                                        arrayChildren.map((mapItem, mapIndex)=> {
                                          return (

                          <div className="inputRow0 inputRow1">
                          {/* <div className="inputWrapper0 inputWrapper00">
                              <input 
                                value={textInput1}
                                onChange={e=> {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                  setTextInput1(e.target.value);
                                }}
                                placeholder={`DD`}
                              />
                          </div> */}
                          <div className="genderWrapper">
                            <div className="gender1" 
                             style={{
                              color : 
                              arrayTextChildren0[mapIndex] === "male" ?
                              `#fff` : `#000`,
                              background : 
                              arrayTextChildren0[mapIndex] === "male" ?
                              `#FF7A7A` : `transparent`
                          }}
                            onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              
                              var temp = ["","","","","","","","","","",]
                              temp = [...arrayTextChildren0];
                              temp[mapIndex] = "male";

                              setArrayTextChildren0(temp)
                            }}
                          >남</div>

                            <div className="gender2"
                             style={{
                              color : 
                              arrayTextChildren0[mapIndex] === "female" ?
                              `#fff` : `#000`,
                              background : 
                              arrayTextChildren0[mapIndex] === "female" ?
                              `#FF7A7A` : `transparent`,
                          }}
                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            var temp = ["","","","","","","","","","",]
                            temp = [...arrayTextChildren0];
                            temp[mapIndex] = "female";

                            setArrayTextChildren0(temp)
                          }}
                          >녀</div>
                          </div>
                          <div className="inputWrapper0 inputWrapper1">
                              <input 
                                value={arrayTextChildren1[mapIndex]}
                                onChange={e=> {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }

                                    var tempA = ["","","","","","","","","","",]
                                    tempA = [...arrayTextChildren1];
                                    tempA[mapIndex] = temp;
                                    setArrayTextChildren1(tempA);

                                }}
                                placeholder={`DD`}
                              />
                          </div>
                          <div className="inputWrapper0 inputWrapper2">
                              <input 
                                value={arrayTextChildren2[mapIndex]}
                                onChange={e=> {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 2) {
                                        return;
                                    }
                                    var tempA = ["","","","","","","","","","",]
                                    tempA = [...arrayTextChildren2];
                                    tempA[mapIndex] = temp;
                                    setArrayTextChildren2(tempA);
                                }}
                                placeholder={`MM`}
                              />
                          </div>
                          <div className="inputWrapper0 inputWrapper3">

                          <input 
                                value={arrayTextChildren3[mapIndex]}
                                onChange={e=> {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                    var temp = e.target.value.replace(/[^0-9]+/g, "");
                                    if (temp.length > 4) {
                                        return;
                                    }
                                    var tempA = ["","","","","","","","","","",]
                                    tempA = [...arrayTextChildren3];
                                    tempA[mapIndex] = temp;
                                    setArrayTextChildren3(tempA);
                                }}
                                placeholder={`YYYY`}
                              />

                          </div>
                        </div>
                                          )

                                        })
                                      }

                              <div className="childrenAddRow1">
                                <img className="img0 img1" 
                                src={
                                  "/static/images/ssokdak/Tab5/iconBlackPlus.svg"
                                } alt=""
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  var temp = [...arrayChildren];
                                  temp.push("");
                                  setArrayChildren(temp)
                                }}
                                />
                                <div 
                                onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  var temp = [...arrayChildren];
                                  temp.push("");
                                  setArrayChildren(temp)
                                }}>추가하기</div>
                                {/* src="/static/images/ssokdak/Tab5/iconBlackPlus.svg" */}
                              </div>

                        </>
                                    }
                                </div>
                        </>
                      }


{
                        (intEmptyProfileStep1 === 6) && <>
                          <div className="topLabel">{parse(`취미를<br/>가지고 있나요?`)}
                          
                          
                          <div className="inputRow0 inputRow1">
                            <div className="inputWrapper0 inputWrapperA">
                                <input 
                                  value={textInput9}
                                  placeholder={`엔터를 이용해 입력해주세요`}
                                  onChange={e=> {
                                    setTextInput9(e.target.value);
                                  }}
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                  }}
                                  maxLength={12}
                                  onKeyDown={handleEnter}
                                />
                            </div>

                          </div>

                  <div className="sectionGrayWrapper0 sectionGrayWrapper2" style={{ marginTop: `0px`}}>
                      <div className="row0">
      
                      {
                      Array.from(arrayHashtag)
                      // Array.from(conversationTopicArray)
                      // .sort(function(a, b)  {
                      //     return a.index - b.index;
                      // })
                      .map((mapItem, mapIndex)=> {
                          return (
                          <div className="tagggItemWhite " 
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                              }}
                          >
                              #{mapItem}
      
                              <img 
                                  className="icon0 icon2" 
                                  src={`/static/images/ssokdak/Tab5/iconGrayClose.svg`} 
                                  alt=""
                                  style={{
                                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                                  // transition: `all 0.5s ease-out`,
                                  }}
                                  onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    var tempArray = [...arrayHashtag];
                                    tempArray.splice(mapIndex, 1);
                                    setArrayHashtag(tempArray);
                                    
      
                                  }}
                                  />
                          </div>)
                      })
                      }
                      </div>
                  </div>
                          
                          </div>
                        </>
                      }


                      {
                        boolPopup3 === true && 
                        props && props.appKeyboardVisible !== "visible"  && 
                        <>
                        <div className="start_button0 start_button1"
                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                              if (intEmptyProfileStep1 < 6) {
                                setIntEmptyProfileStep1(intEmptyProfileStep1 + 1);
                              } else {
                                setIntEmptyProfileStep1(-1);
                                handleCreate();
                              }
                          }}
                        >
                          다음
                        </div>
        
        
                        <div className="start_button0 start_button2"
                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                              if (intEmptyProfileStep1 <= 6) {
                                setIntEmptyProfileStep1(intEmptyProfileStep1 - 1);
                              } else {
                                // setScreenMode1('page001/0/home')
                              }
                          }}
                        >
                          이전
                        </div>
        
                        </>
                      } 

                    </div>
                  </div>
                }
              </div>

    
    }

    {
      boolPopup5 &&
      <div 
              className="bottomSheet0 bottomSheetForEmotionCard" 
              style={{
                height: `100vh`,
                // height: boolPopup5 === false ? 0 : `100vh`,
                transition: `all 0.5s ease-out`
              }}
              >
                <div className="blurView" onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                }}></div> 
                <div className="contentView" > 
                {
                  boolPopup5 === true && 
                  <SdOtherUserProfileScreen 
                    selectedUserName={selectedUserDetail} 
                    callBackClose={e=> {
                      setBoolPopup5(false);
                      setSelectedUserDetail();
                    }}
                  />
                }
                </div>
              </div>

    }
    </>
    
  );
}
