import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./aa01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const pageCode1 =  'page088/2/search-result';
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    const [textActiveFilter1, setTextActiveFilter1] = useState("");
    const [textActiveFilter2, setTextActiveFilter2] = useState("");
    const [textActiveFilter3, setTextActiveFilter3] = useState("grid");

    const [boolPopup1, setBoolPopup1 ] = useState(false);
    const [arraySelected, setArraySelected] = useState([]);

    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);

    const [boolSaveList1, setBoolSaveList1] = useState(false);
    const [arraySearchText, setArraySearchText] = useState([]);
    const [objectSearchText1, setObjectSearchText1] = useState("");
    const [objectSearchText2, setObjectSearchText2] = useState("");
    const [objectSearchText3, setObjectSearchText3] = useState("");

    const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
    const [objectArrayFeedData1, setObjectArrayFeedData1] = useState([]);

    const [currentCardsData, setCurrentCardsData] = useState([]);

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }
      getUserData();
      getPostData();
      getFeedData();
    }, [useSelector(state => state)])


    function getPostData() {

      Firebase.database().ref()
      .child("behaviors/posts")
      // .orderByChild("random_id16")
      // .equalTo(currentCardItem)
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          console.log("behaviors/posts --- data", data)

          if (data && Object.values(data) &&  Object.values(data).length > 0) {
            setObjectArrayCardData1(Object.values(data))

            const indexArr1  = Object.values(data);
            setCurrentCardsData(indexArr1);
          }
        }
      });


    }

    function getFeedData() {

      Firebase.database().ref()
      .child("behaviors/feeds")
      // .orderByChild("random_id16")
      // .equalTo(currentCardItem)
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          console.log("behaviors/feeds --- data", data)

          if (data && Object.values(data) &&  Object.values(data).length > 0) {
            setObjectArrayFeedData1(Object.values(data))

            const indexArr1  = Object.values(data);
            setCurrentCardsData(indexArr1);
          }
        }
      });

    }

    function getUserData() {

      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")

      Firebase.database().ref()
      .child("accounts")
      .orderByChild("username")
      .equalTo(authStoreUserName)
      .once("value", (snapshot) => {
          if (snapshot.val()) {
              const data = snapshot.val();
              const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
              if (keyName) { 
                  window.localStorage.setItem("authStoreKeyName",  keyName);
                  
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                  window.localStorage.setItem("authStoreKeyName",  keyName);
                  
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                  setObjectUserData(data[keyName]);
                  setObjectUserKeyname(keyName);

                  if (data[keyName] && data[keyName].recent_search) {
                    setArraySearchText([{
                      text : data[keyName].recent_search.text,
                      date : data[keyName].recent_search.date
                    }]);
                  } else {
                  }
                  props && props.handleUser(keyName, data[keyName]);
               
              } else {

              }

          }  else {
              // alert("존재하지 않는 회원입니다.");
          }
      });


    }


    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }


    return (
      <>
        <div className={`sr01_c`}> 
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          
          <div className="card__filterrow0 card__filterrow1" >
            <div className="folder_filter_div1" 
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
                검색어  : {arraySearchText && arraySearchText[0] &&arraySearchText[0].text &&arraySearchText[0].text}
              <img src={`/static/icons/052/icon_plus.png`}/>
            </div>
            {/* <div className="folder_filter_div3">
              <div className={textActiveFilter3 == "grid" ? "img_wrapper0 img_wrapper1" : "img_wrapper0 img_wrapper2"}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextActiveFilter3("grid")
                }}
              >
                <img src={`/static/icons/052/icon_grid.png`}/>
              </div>
              <div className={textActiveFilter3 == "list" ? "img_wrapper0 img_wrapper1" : "img_wrapper0 img_wrapper2"}
               onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setTextActiveFilter3("list")
              }}
              >
                <img src={`/static/icons/052/icon_list.png`}/>
              </div>
            </div> */}
          </div>
          {/* <div className="card__filterrow0 card__filterrow2">
            <div className="text0 text1">
              총<span className="text0 text2">21</span>개
            </div>
            <img className="checkicon0" src={`/static/icons/052/icon_check0.png`}/>
            <div className="text0 text3" 
            onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();

                setBoolPopup1(true)

            }}>
              전체선택
            </div>
          </div> */}
          <>
          

        <div className="card__list" style={{ marginBottom : 20, }}>
        <div className="row0 row2 cardgrid0" style={{ overflow : 'scroll'}}>
            {
              objectArrayFeedData1 &&
              objectArrayFeedData1
              .map((mapItem, mapIndex)=> {
                const findIndex1 = arraySelected.findIndex((findItem, findIndex)=> {
                  if (findItem == mapItem.id) {return (findItem)}
                });

                console.log("mapItem", mapItem);

                return (
                  <div className="card1" 
                  style={{ border : `1px solid #000`}}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    console.log("currentCardItem - mapItem.random_id16", mapItem.random_id16)
                    props && props.handleCard && props.handleCard(mapItem.random_id16, mapItem, mapItem.random_id16);
                    window.localStorage.setItem("currentCardItem",mapItem.random_id16)
                    
                    // setScreenMode1('page002/2/card-detail')
                // page002/2/card-detail
                  }}>
                    {
                      mapItem && mapItem.image_link1 && 
                      mapItem.image_link1 != "" && 
                      <img className="thumbnail0" src={mapItem.image_link1} />

                    }
                  <div className="row0 row1">
                    {/* <div className="tag0"></div> */}
                    <div className="star">
                      {/* <img src={`/static/icons/010/3.png`} /> */}
                    </div>
                    {/* {
                        // mapItem.checked == false ?
                        findIndex1 < 0  ?
                        <img 
                          className="checkicon0" 
                          src={findIndex1 > -1 ? `/static/icons/052/icon_check0.png` : `/static/icons/052/icon_check0.png`}
                          onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            var tempLists = [...arraySelected];
                            var  sendList = [];
                            const findIndex11 = tempLists.findIndex((findItem, findIndex)=> {
                              if (findItem == mapItem.id)
                              return (findItem)
                            });
                            if (findIndex11 > -1) {
                              sendList = tempLists.filter((filterItem, filterIndex)=> {
                                if (filterItem == mapItem.id) {

                                } else {

                                  return (filterItem)
                                }
                              })
                            } else {
                              tempLists.push(mapItem.id)
                              sendList = tempLists;
                            }
                            setArraySelected(sendList);
                            
                          }}
                          
                        />
                        :
                        
                        <div 
                        style={{ width : `24px`, height : `24px`, borderRadius : `4px`, marginLeft: 'auto', background : `#1165F5`}}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          // setBoolCheck1(false)

                          var tempLists = [...arraySelected];
                          var  sendList = [];
                          const findIndex11 = tempLists.findIndex((findItem, findIndex)=> {
                            if (findItem == mapItem.id)
                            return (findItem)
                          });
                          if (findIndex11 > -1) {
                            sendList = tempLists.filter((filterItem, filterIndex)=> {
                              if (filterItem == mapItem.id) {

                              } else {

                                return (filterItem)
                              }
                            })
                          } else {
                            tempLists.push(mapItem.id)
                            sendList = tempLists;
                          }

                          console.log("sendList", sendList)
                          setArraySelected(sendList);
                          
                        }}>
                          </div>
                      } */}
                  </div>
                    <div className="text1">
                      {mapItem.title}
                    </div>
                    <div className="text2">
                      {/* {parse(mapItem.preview)} */}
                    </div>
                    <div className="text3">
                      {/* {mapItem.nickname} */}
                    </div>

                  </div>
                )
              })
            }
          </div>


        </div>


        <div className="top_recent_card_wrapper0">
                    <div className="top_recent_card_wrapper0" style={{ paddingBottom : 0}}>
                      <div className="row0 row1">
                        <div className="text1" style={{ marginLeft: 20, flexDirection : 'row' }}>
                          인기
                        </div>
                        <div className="text1" style={{ marginLeft: 20, flexDirection : 'row' }}>
                          실시간
                        </div>
                        <div className="text1" style={{ marginLeft: 20, flexDirection : 'row' }}>
                          이웃
                        </div>
                        <img src={`/static/down-chevron.png`} />
                      </div>
                      </div>
            


            <div className="row0 row2 cardrow0">

            {
              currentCardsData &&
              currentCardsData
              // Array.from([
              //   {
              //     title : `수학2`,
              //     description: `3차 방정식을
              //     사용하여 아..`,
              //     name : `이종철님`,
              //   },
                
                
              // ])
              .map((mapItem, mapIndex)=> {

                return (
                  <div className="card0" 
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    window.localStorage.setItem("currentCardItem",mapItem.random_id16)
                    
                    setScreenMode1('page002/2/card-detail')
                // page002/2/card-detail
                  }}>
                  <div className="row0 row1">
                    {/* <div className="tag0"></div> */}
                    {/* <div className="star">
                      <img src={`/static/icons/010/3.png`} />
                    </div> */}
                    
                  </div>
                    <div className="text1">
                      {mapItem.title}
                    </div>
                    <div className="text2">
                      {/* {parse(mapItem.description)} */}
                    </div>
                    <div className="text3">
                      {mapItem.nickname}
                    </div>

                  </div>
                )
              })
            }
            </div>
          </div>

          </>
          {/* <div className="bottom_more_button0 ">
            <div className="">
              더보기
              <img src={`/static/icons/052/icon_arrow.png`}/>
            </div>
          </div> */}
        </div>



        {
            boolPopup1 == true &&
            <>
                <div className="popup_background0" 
                onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolPopup1(false);
                }}
                >

                <div className="popupcontent0 search_bottom_popup1">
                    <img className="close0" src={appImages._000_2} />
                    <div className="button0 button1" 
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            // setBoolPopup1(false);
                            
                        }}
                        >
                        <div className="label0 label1">메뉴1</div>
                        <img className="img0 img1" src={`/static/icons/055/icon_move1.png`} />
                    </div>
                    <div className="button0 button2"
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            // setBoolPopup1(false);
                        }}
                        >
                        <div className="label0 label1">메뉴2</div>
                        <img className="img0 img1" src={`/static/icons/055/icon_delete0.png`}  />
                    </div>
                
                </div>

                </div>
            </>
        }
      </>
      
    );
}
