import React, { Component, useEffect, useState, useContext, useRef } from "react";

import parse from "html-react-parser";
import {useSelector, useDispatch} from 'react-redux'
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";

import { toast } from "react-toastify";
import uuid from 'react-uuid';

import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";

const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]


  const conversationTopicArray = [
    {
        index : 1, 
        enName : '',
        krName : '정신건강',
    },
    {
        index : 2, 
        enName : '',
        krName : '성생활',
    },
    {
        index : 3, 
        enName : '',
        krName : '금전/사업',
    },
    {
        index : 4, 
        enName : '',
        krName : '대인관계',
    },
    {
        index : 5, 
        enName : '',
        krName : '자아/성격',
    },
    {
        index : 6, 
        enName : '',
        krName : '결혼/육아',
    },
    {
        index : 7, 
        enName : '',
        krName : '이별/이혼',
    },
    {
        index : 8, 
        enName : '',
        krName : '가족',
    },
  ]
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 = 'page004/998/chat-daily-first';
export default function ThisComponent(props) {

    const [objectPopupData1, setObjectPopupData1] = useState(null);

    const [clickedIndex, setClickedIndex] = useState(-1);
    const [clickedIndex2, setClickedIndex2] = useState(-1);
    const [selectedTopic, setSelectedTopic]  = useState([]);

    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(3);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("01011112222");
    const [textInput4 , setTextInput4] = useState("1234");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");
    const [textInput21 , setTextInput21] = useState("");

    const [messageFromDevice0, setmessageFromDevice0] = useState("");
    const [blockSystem, setBlockSystem] = useState(false);
    const [fbContactKeyName, setFbContactKeyName] = useState("");
    const [boolCheckA, setBoolCheckA] = useState(false);
    
    const imageRef1 = useRef();
    
    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);

    const [arrayChatMessages, setArrayChatMessages] = useState([]);

    const [intGuideStep, setIntGuideStep]= useState(1);
    const [boolPopup1, setBoolPopup1] = useState(false);
    const [boolPopup2, setBoolPopup2] = useState(false);
  

    useEffect(()=> {
      setStepIndex(3);
    },[])

    function makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {

      setTextInput1("in");
      setTextInput2("");
      setTextInput3("01011112222");
      setTextInput4("1234");
      setTextInput5("");
      setTextInput6("");
      setTextInput7("");
      setTextInput8("");
  
      // STEP 3 USAGE
  
      setTextInput11("");
      setTextInput12("");
      setTextInput13("");
      setTextInput14("");
      setTextInput15("");
      setTextInput16("male");
      setTextInput17("");
      setTextInput18("");
      setTextInput19("");
      
    },[]);

    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }

        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        const authStore1 = localStorage.getItem("authStore")
        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        

                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                     
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });
      }
      
    }, [useSelector(state => state)])

    useEffect(()=> {

      if (objectUserKeyname && objectUserKeyname != "") {

        Firebase.database().ref().child("accounts").child(`/${objectUserKeyname}/messages`)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("data", data)
            const chats = Object.values(data);
            if (chats && chats.length > 0) {
              setArrayChatMessages(chats);

            }
          }
        });
      }
    }, [objectUserKeyname])


    useEffect(()=> {
        if(objectPopupData1 != null) {
          setTimeout(()=> {
            setObjectPopupData1(null);
          }, 3000)
        }
      }, [objectPopupData1])

      useEffect(()=> {
        if(intGuideStep === 2) {
          setTimeout(()=> {
            setIntGuideStep(3)
          }, 1000)
        }
        if(intGuideStep === 4) {
          setTimeout(()=> {
            setIntGuideStep(5)
          }, 1000)
        }
      }, [intGuideStep])
    
    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }


    // 렌더, return-render,
    return (
      <>
        {objectPopupData1}
        <div className={`a04_c`}>


      <div 
            className="chatSettingWrapper" 
            style={{ 
              // minHeight : textCalendarMode === "week" ? "52px" : "52px",
              transition: `all 0.5s ease-out`,
            }}
          >
          <div className="chatSettingTopBar">
              {/* <img className="chatTopLeftIcon" src={`/static/images/ssokdak/Tab4/topFilter1.svg`} alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                }}
              /> */}
              <div className="chatSettingDiv1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                매칭설정
              </div>
              <img className="chatTopMenuIcon" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                style={{
                  // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                  // transition: `all 0.5s ease-out`,
                }}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setScreenMode1(selectedPageObject.parent_code);
                }}
              />
          </div>
        </div>
        <div className="chatSettingStepperWrapper">
            <div className="stepDiv1"
                style={{
                    width : `calc(100% / 6 * ${intGuideStep})`
                }}
            ></div>
        </div>

                
            <div className="chatGuideWrapper">

                {
                    intGuideStep >= 1 &&
                    <>
                    <img className="chatAiIcon" src={`/static/images/ssokdak/Tab4/chatSupporterAi.svg`} alt=""
                    style={{
                      // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                      // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div className="chatBubble-left0 chatBubble-left1">
                    <div className="text0 text1">
    
                    {parse(`
                    반가워요! 쏙닥이에요<br/>
                    오늘 대화상대 매칭을 도와드리기 위해<br/>
                    대화주제 & 기분설정을 도와드릴게요.<br/><br/>
                    <span class='text-bold'>2가지 질문</span>을 답해주면 되요<br/>
                    간단하죠?☺️ 그럼 질문 시작할게요!`
                    )}
                    </div>
                  </div>
                  <div className="chatBubble-left0 chatBubble-left2"
                  >
                    <div className="text0 text1">
                    {parse(`<span class='text-bold'>오늘 하루 기분은 어땠나요?</span>`)}
                    <div className="button0 button1" onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        setBoolPopup1(true);
                    }}>기분 선택하기</div>
    
                    </div>
                  </div>
                    </>
                }
                {
                    intGuideStep >= 2 &&
                    clickedIndex > -1 && 
                    <div className="chatBubble-right0">
                         <div className="text0 text1">
                            {
                                "#" + emoCardArray[clickedIndex].krName
                            }
                        </div>
                    </div>
                }

                {
                    intGuideStep >= 3 &&
                    clickedIndex > -1 && 
                    <>
                    <img className="chatAiIcon" src={`/static/images/ssokdak/Tab4/chatSupporterAi.svg`} alt=""
                    style={{
                      // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                      // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div className="chatBubble-left0 chatBubble-left1">
                    <div className="text0 text1">
    
                    {parse(`
                    오늘 기분은 <span class='text-bold'>#${emoCardArray[clickedIndex].krName}</span> 네요<br/>
                    매칭할 수 있는 대화친구를<br/>
                    찾았어요!<br/>
                    그럼 이제 마지막 질문이에요`
                    )}
    
    
                    </div>
                  </div>
                  <div className="chatBubble-left0 chatBubble-left2"
                  >
                    <div className="text0 text1">
                    {parse(`<span class='text-bold'>오늘 대화하고 싶은 주제는 무엇인가요?</span>`)}
                    <div className="button0 button1" onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        setBoolPopup2(true);
                    }}>대화주제 선택하기</div>
    
                    </div>
                  </div>
                    </>
                }
                {
                   intGuideStep >= 4 &&
                   clickedIndex > -1 && 
                   <div className="chatBubble-right0">
                        {
                          Array.from(selectedTopic).map((mapItem, mapIndex)=> {
                            return ( 
                              <div className="text0 text1">
                                  {
                                      "#" + conversationTopicArray[mapItem].krName
                                  }
                              </div>
                            )
                          })
                        }
                   </div>
               }


{
                    intGuideStep >= 5 &&
                    clickedIndex > -1 && 
                    <>
                    <img className="chatAiIcon" src={`/static/images/ssokdak/Tab4/chatSupporterAi.svg`} alt=""
                    style={{
                      // transform : textCalendarMode === "week" ? '0deg' : '180deg',
                      // transition: `all 0.5s ease-out`,
                    }}
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                  <div className="chatBubble-left0 chatBubble-left1">
                    <div className="text0 text1">
    
                    {parse(`
                    오늘의 매칭설정 완료!<br/>
                    잘 통할 대화친구들을 찾았어요!<br/>
                    대화친구들을 만나보러 갈까요?<br/>
                    `
                    )}
    
    
                    </div>
                  </div>
                  <div className="submitButton2" 
                    style={{ marginBottom : 40}}
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();


                      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
                      const authStoreUserName = localStorage.getItem("authStoreUserName");
                      const authStore1 = localStorage.getItem("authStore")

                      Firebase.database().ref()
                      .child("behaviors/community-checkins").push({
                        mind : emoCardArray[clickedIndex],
                        mindKrName : "" + emoCardArray[clickedIndex].krName,
                        topic : selectedTopic,
                        topicKrName : selectedTopic.length > 0 ? "" :  "",
                        username : authStoreUserName,
                        userData : objectUserData,
                        created_at : moment().format("YYYY-MM-DD HH:mm:ss"),
                        created_day : moment().format("YYYY-MM-DD"),
                        created_time : moment().format('hh:mm A')
                      });
                      window.localStorage.setItem("selectedTab4", 1);
                      setScreenMode1('page004/998/chat-list');

                    }}> 대화하러가기</div>
                    </>
                }



            </div>
            

            {
                              boolPopup1 === true &&
                              
                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: boolPopup1 === false ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          <div className="contentView"> 
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                //   setScreenMode1( 'page002/0/mind-diary')
                                    setBoolPopup1(false)
                              }}
                              />
                            </div>
                            {
                              boolPopup1 === true &&
                              
                            <div className="bottomSheetEmoWrapper">
                            <div className="wrapDiv1">
                            {
                                Array.from(emoCardArray)
                                .sort(function(a, b)  {
                                  return a.index - b.index;
                                })
                                .map((mapItem, mapIndex)=> {
                                  return (
                                    <div className="cardItem " 
                                    >
                                      {
                                         (mapIndex === clickedIndex )&&

                                         <div className="selectImg0">
                                         <img className="closeBtn" 
                                         src={`/static/images/ssokdak/Tab2/editSave1.svg`} alt=""
                                         style={{ marginLeft : 'auto'}}
                                         onClick={e=> {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             setScreenMode1( 'page002/0/mind-diary')
                                         }}
                                         />
                                       </div>
                                      }
                                      <div className="cardImg0">
                                        <img  src={mapItem.iconCardPathName} alt="" 
                                          style={{
                                            border : (mapIndex === clickedIndex )?  `1px solid #FF7A7A` : `1px solid #ffffff`,
                                            borderRadius : `10px`,
                                          }}
                                          onClick={e=> {
                                            setClickedIndex(mapIndex);
                                            // setPageStep(2);

                                            setBoolPopup1(false);
                                            setIntGuideStep(2);

                                        }}/>
                                      </div>
                                    </div>)
                                })
                              }
                            </div>
                          </div>
                            }
                          </div>
                        </div>
                            }
                            {
                                              boolPopup2 === true &&
                                              
                                        <div 
                                        className="bottomSheet0 bottomSheetForEmotionCard" 
                                        style={{
                                          height: boolPopup2 === false ? 0 : `100vh`,
                                          transition: `all 0.5s ease-out`
                                        }}
                                        >
                                          <div className="blurView"></div>
                                          <div className="contentView"> 
                                            <div className="row0 row1"
                                                style={{ marginTop: 28 }}
                                            >
                                                <div className="sheetText0 text0 text1">
                                                대화주제를 선택해주세요.</div>
                                              <img className="closeBtn" 
                                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                                              style={{ marginLeft : 'auto'}}
                                              onClick={e=> {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                //   setScreenMode1( 'page002/0/mind-diary')
                                                    setBoolPopup1(false)
                                              }}
                                              />
                                            </div>
                                            {
                                              boolPopup2=== true &&
                                              
                                            <div className="bottomSheetEmoWrapper">
                                            <div className="wrapDiv1">
                                            {
                                                Array.from(conversationTopicArray)
                                                .sort(function(a, b)  {
                                                  return a.index - b.index;
                                                })
                                                .map((mapItem, mapIndex)=> {
                                                  const ext0 = selectedTopic.findIndex((findItem, findIndex)=> {
                                                    if (findItem === ("" + mapIndex)) {
                                                      return findItem
                                                    }
                                                    
                                                  });


                                                  return (
                                                    <div className="tagggItem " 
                                                        style={{
                                                            background :  ext0 > -1?
                                                            `#FF7A7A` : `#F5F5F5`,

                                                            
                                                            color :  ext0 > -1 ?
                                                            `#FFFFFF` : `#2D2D2D`,

                                                            
                                                        }}
                                                        onClick={e=> {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            
                                                            // setClickedIndex2(mapIndex)
                                                            var selected1 = [...selectedTopic];
                                                            var fixed1 = [];

                                                            const ext1 = selected1.findIndex((findItem, findIndex)=> {
                                                              if (findItem === ("" +  mapIndex)) {
                                                                return findItem
                                                              }
                                                              
                                                            });
                                                            console.log("mapIndex", mapIndex);
                                                            console.log("ext1", ext1);
                                                            if (ext1 > -1){
                                                              selected1.splice(ext1, 1);
                                                              setSelectedTopic(selected1)
                                                            } else {

                                                              if (selected1.length >= 3) {
                                                                return;
                                                              }

                                                              fixed1 = [...selected1, ("" + mapIndex)]
                                                              console.log("fixed1", fixed1);
                                                              setSelectedTopic(fixed1);
                                                            }

                                                        }}
                                                    >
                                                        #{mapItem.krName}
                                                    </div>)
                                                })
                                              }
                                              <div className="submitButton1"
                                                style={{
                                                    background : selectedTopic.length > 0 ? `#2D2D2D` :  `#E2E2E2`,
                                                    color : selectedTopic.length > 0 ? `#ffffff` : `#A6A6A6` 
                                                }}
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setBoolPopup2(false);
                                                    setIntGuideStep(intGuideStep + 1);
                                                }}

                                              >선택 완료</div>
                                            </div>
                                          </div>
                                            }
                                          </div>
                                        </div>
                                            }

            {/* chatSupporterAi */}
            </div>
      </>
      
    );
}
