import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";;

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 = 'page005/2/term';
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(1);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("");
    const [textInput4 , setTextInput4] = useState("");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    

    const [boolPopup1, setBoolPopup1] =  useState(false);
    

    const menuArr1 = [
      { label : "마이페이지",  code : 'page005/2/setting-mypage' },
      { label : "알람", code : 'page005/2/setting-notification', },
      { label : "수신동의", code : 'page005/2/setting-subscription-agreement', },
      { label : "자주묻는질문", code : 'page005/2/setting-faq', },
      { label : "1:1문의", code : 'page005/2/setting-questions', },
      { label : "공지사항", code : 'page005/2/setting-notice', },
      { label : "튜토리얼", code : 'page005/2/tutorial', },
      { label : "이용약관", code : 'page005/2/term', },
      { label : "개인정보처리방침",  code : 'page005/2/term', },

    ]
    // 'page005/2/setting-mypage', 
    // 'page005/3/setting-mypage-withdrawl',
    // 'page005/2/setting-notification',
    // 'page005/3/setting-notification',
    // 'page005/2/setting-subscription-agreement',
    // 'page005/2/setting-faq',
    // 'page005/3/setting-faq-add',
    // 'page005/3/setting-question-add',
    // 'page005/2/setting-questions',
    // 'page005/2/setting-question-detail',
    // 'page005/2/setting-notice',
    // 'page005/2/setting-notice-detail',
    // 'page005/2/tutorial',
    // 'page005/2/term',


    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`a04_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />

          {
            stepIndex == 1 &&
            <div className="step_content_container0 step_content_container1">
              <div className="term_text0 term_text1">쏙닥쏙닥 앱이용을 위해 아래 약관에 동의해 주세요!</div>
              <div className="hl"></div>
              <div className="textinput_form_check_row0">
                <div className="col100">
                  <div 
                    className="label_text1  "
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    이용약관
                  </div>
                  
                    
                </div>

              </div>

              {
                true && 
                // boolMoreInfoOpen1 == true &&
                <div className="term_textarea0 term_textarea4">
                {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                </div>
                }
                <div className="hl"></div>
                <div className="textinput_form_check_row0">
                  <div className="col100">
                    <div 
                      className="label_text1 "
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >개인정보 처리방침
                    </div>
                    
                      
                  </div>
  
                </div>
  
                {
                true && 
                  // boolMoreInfoOpen2 == true &&
                  <div className="term_textarea0 term_textarea4">
                  {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                  </div>
                  }

                <div className="hl"></div>
                
            </div>
          }

          <style>
            {
              `
                .label_text1 {

font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #212529;
                }

.term_textarea4{

  width: calc(100% - 80px);
  height: 240px;
  
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #ADB5BD;

  padding: 20px;


  overflow-y: scroll;

  margin-top: 20px;
}


              `
            }
          </style>



        </div>
      </>
      
    );
}
