import React, { Component, useEffect, useState, useContext , useRef} from "react";

import parse from "html-react-parser";
import "./a01.css";
import {useSelector, useDispatch} from 'react-redux'

import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";

import { toast } from "react-toastify";

import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../redux/authRedux/auth.reducer";
import { setAuth } from "../../redux/authRedux/auth.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 

const pageCode1 =  "page000/0/login";

// <div className={``}></div>
export default function ThisComponent(props) {
  const [objectPopupData1, setObjectPopupData1] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);

    const [boolCheck1, setBoolCheck1] = useState(false);

    const testRef = useRef();

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }
    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    
    const [messageFromDevice0, setmessageFromDevice0] = useState("");
    const [blockSystem, setBlockSystem] = useState(false);
    const [fbContactKeyName, setFbContactKeyName] = useState("");


    const handleEnter = (e) => {
      // if (textSearch1 == "") {
      //   return;
      // }
      if ( e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        handleLogin();
      }
  }
      // if (messageFromDevice0 && (("" + messageFromDevice0).indexOf("8210") == 0  )) {
    useEffect(()=> {
            console.log("[테스트] >> --------------------", );
            console.log("[테스트] >> []", messageFromDevice0.substr(4,15))
            console.log("[테스트] >> []", "010" + messageFromDevice0.substr(4,15))
            console.log("[테스트] >> ===================", );
            Firebase.database().ref()
            .child("accounts")
            .orderByChild("account")
            .equalTo( textInput1)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        if (data[keyName].status == "YES") {
                            
                        } else {
                            setBlockSystem(true);
                        }
                    } else {

                        setBlockSystem(true);
                    }
                    setFbContactKeyName(keyName);
                    
                    // if (snapshot.val() && snapshot.val()[keyName].location != "")  {
                    //     setTextSelectedLocation(snapshot.val()[keyName].location)

                    //     setSearchedInput101(moment().format("YYYY"))
                    //     setSearchedInput102(moment().format("MM"))
                    //     setSearchedInput103(moment().format("DD"))
                    //     setSearchedInput104(moment().format("HH"))
                    //     setSearchedInput105(moment().format("mm"))
                    //     setSearchedInput106(moment().format("ss"))
                

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });
    

    }, [])

  //   useEffect(()=> {
  //     if(fbContactKeyName != "" ) {
  //         Firebase.database().ref().child("accounts").child(`/${fbContactKeyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
  //         Firebase.database().ref().child("accounts").child(`/${fbContactKeyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
  //     }
  // }, [fbContactKeyName])



//   useEffect(()=> {
//     if(fbContactKeyName != "" ) {
//         Firebase.database().ref().child("accounts").child(`/${fbContactKeyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
//         Firebase.database().ref().child("accounts").child(`/${fbContactKeyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
//     }
// }, [fbContactKeyName])

useEffect(()=> {
  if(objectPopupData1 != null) {
    setTimeout(()=> {
      setObjectPopupData1(null);
    }, 3000)
  }
}, [objectPopupData1])

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])


    function handleCheckEmail() {
      var email =  textInput1
      var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

        if(exptext.test(email)==false){
          toast.error("이메일형식이 올바르지 않습니다.");
          return false;
        }
    }


    function handleLogin() {

      // var email =  textInput1
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }

      console.log("textInput1 - 0", textInput1)

      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo( textInput1)
            .once("value", (snapshot) => {

                  console.log("textInput1 - 1", snapshot)

                if (snapshot.val()) {

                  console.log("textInput1 - 2", snapshot.val())
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    console.log("textInput1 - 3", keyName)

                    if (keyName) {

                        console.log("textInput1 - 4", data[keyName])

                        
                        Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"auto_login" : moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                        if (data[keyName].password == textInput2) {
                          console.log("textInput1 - 5",data[keyName].password )
                            //비밀번호와 일치하는지 여부
                            // toast.success("인증되었습니다. ");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}>환영합니다.</div>)
                            setAuth1({
                                username:  data[keyName].username,
                                password:  data[keyName].password,
                                token:  '',
                                more:  data[keyName],
                            })

                            // console.log("data[keyName]", data[keyName])

                            window.localStorage.setItem("authStoreKeyName",  keyName)
                            window.localStorage.setItem("authStoreKeyName",  keyName);
                            
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                            // window.localStorage.setItem()
                            //지금 수정중
                            setTimeout(()=> {
                              setScreenMode1( 'page001/0/home');
                            }, 1000 )

                        } else {
                          console.log("textInput1 - 6", textInput2 )
                            // toast.error("인증실패하였습니다. 비밀번호를 확인해주세요.");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다. 비밀번호를 확인해주세요.</div>)
                        }
                    } else {
                        //조건 분기 필요
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                    }

                }  else {
                  setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                  // 테스트 코드
                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : textInput1, 
                  //     email : textInput1, 
                  //     password : textInput2,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    }



    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
      {objectPopupData1}
        <div className={`a01_c`}> 
          <img className="image0 image1" src={`/static/images/ssokdak/Splash/logo001.svg`} style={{ maxWidth : `200px`}} alt=""/>
          <div className="textinput_form_container0 textinput_form_container1">
            <input 
              value={textInput1}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput1(e.target.value);
              }}
              onKeyDown={handleEnter}
              placeholder={`아이디를 입력하세요`}
            />
            {
              textInput1.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput1("");
              }}
              alt=""
              />
            }
          </div>

          <div className="textinput_form_container0 textinput_form_container2">
            <input 
              type={boolPasswordShow1 === true ? `text` : `password`}
              value={textInput2}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput2(e.target.value);
              }}
              onKeyDown={handleEnter}
              placeholder={`패스워드를 입력하세요`}
            />
            {/* 
            {
              textInput2.length > 0 &&
              <img src={appImages._000_2} />
            } 
            */}
            {
              // textInput2.length > 0 &&
              // boolPasswordShow1 == false &&
              <img src={`/static/icons/000/icon_eye.png`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPasswordShow1(!boolPasswordShow1)
                }}
                alt=""
              />
            }
          </div>

          <div className="textinput_form_check_row0">

              {/* {
                boolCheck1 === false &&
                <img 
                src={`/static/icons/000/icon_check0.png`}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    
                    setBoolCheck1(true)
                  }}
                  alt=""
                />
              } */}

                {/* {
                  boolCheck1 === true &&

                  <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                  
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setBoolCheck1(false)
                    
                  }}>
                    </div>
                }
                 */}
              {/* <div 
                className="text1 "
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  setBoolCheck1(!boolCheck1)
                }}
              >자동로그인</div> */}
              {/* <div className="vl"></div> */}
              {/* <div className="text2 "
                style={{ marginLeft : 'auto'}}
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setScreenMode1( 'page000/1/find-id')
                }}
              >아이디 / 비밀번호 찾기</div> */}
          </div>
          {/* icon_social_kako.png
          icon_social_apple.png
          icon_social_google.png
          icon_social_naver.png */}
          <div className="signin_button0 signin_button1"
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              // setScreenMode1( 'page001/0/home');
              
              // handleSignUp();
              handleLogin();

            }}
          >
            로그인
          </div>
          <div className="signin_button0 signin_button2" 
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();

            setScreenMode1('page000/0/first');
          }}
          >회원가입</div>
          {/* <div className="signin_button0 signin_button3" 
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            // alert("준비중입니다.")
            // toast.error("소셜개발자 계정 설정이 필요합니다.")

            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#06BE34`}}>
              소셜개발자 계정 설정이 필요합니다.
              </div>)

            // setScreenMode1('page000/1/register');
          }}
          >
            <img src={`/static/icons/000/icon_social_kako.png`} alt=""/>
            카카오톡 로그인
          </div>
          <div className="signin_button0 signin_button4"
          
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            // alert("준비중입니다.")
            // toast.error("소셜개발자 계정 설정이 필요합니다.")

            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#06BE34`}}>
              소셜개발자 계정 설정이 필요합니다.
              </div>)
            // setScreenMode1('page000/1/register');
          }}
          >
            <img src={`/static/icons/000/icon_social_naver.png`}  alt=""/>
            네이버로 로그인
          </div>
          <div className="signin_button0 signin_button5"
          
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            // alert("준비중입니다.")

            // toast.error("소셜개발자 계정 설정이 필요합니다.")
            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#06BE34`}}>
            소셜개발자 계정 설정이 필요합니다.
            </div>)

            // setScreenMode1('page000/1/register');
          }}>
            <img src={`/static/icons/000/icon_social_google.png`}  alt=""/>
            구글아이디 로그인
          </div>
          <div className="signin_button0 signin_button6"
          
          onClick={e=> {
            e.preventDefault();
            e.stopPropagation();
            // alert("준비중입니다.")

            // setScreenMode1('page000/1/register');
            // toast.error("소셜개발자 계정 설정이 필요합니다.")

            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#06BE34`}}>
              소셜개발자 계정 설정이 필요합니다.
              </div>)

              // testRef.current.click();

          }}>
            <img src={`/static/icons/000/icon_social_apple.png`}  alt=""/>
            애플아이디 로그인
          </div> */}

          {/* <input type="file" ref={testRef} value="파일첨부" accept="image/*" style={{display: "none"}} /> */}

          <div className="" style={{ marginBottom : 100 }}></div>

        </div>
      </>
      
    );
}
