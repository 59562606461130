import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./aa01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const pageCode1 =  'page005/2/setting-faq';
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    const [textActiveFilter1, setTextActiveFilter1] = useState("");
    const [textActiveFilter2, setTextActiveFilter2] = useState("");
    const [textActiveFilter3, setTextActiveFilter3] = useState("grid");

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [arrayPageData, setArrayPageData] = useState([]);

    const [boolPopup1, setBoolPopup1] = useState(false);
    const [boolPopup2, setBoolPopup2] = useState(false);

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }


    
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")
      var arrayCardData1 = [];
      var arrayFolderData1 = [];
      var arrayGameData1 = [];
      if (true && true) {
      const parseAuthStore1 = JSON.parse(authStore1)
      console.log("parseAuthStore1", parseAuthStore1);

      var currentFolderItem = window.localStorage.getItem("currentFolderItem")
      Firebase.database().ref()
          .child("faq")
          .once("value", (snapshot) => {
              if (snapshot.val()) {
                  const data = snapshot.val();

                  console.log(">>>>>>>>>>data", data);
                  
                  if (data && Object.values(data) && Object.values(data).length > 0) {
                    setArrayPageData(Object.values(data));
                  }
                  
                  


              }  else {
                  // alert("존재하지 않는 회원입니다.");
              }
          });



    }


    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`aa01_c`}> 
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className="" style={{height: 80}}></div>
          <div className="card__filterrow0 card__filterrow2">
            <div className="text0 text1">
              {/* 총<span className="text0 text2">21</span>개 */}
              {/* 총<span className="text0 text2">{arrayPageData && arrayPageData.length}</span>개 */}
            </div>
            
            {/* <div className="folder_filter_div2">
              등록순↑
              <img src={`/static/icons/052/icon_arrow.png`}/>
            </div> */}
          </div>
          <div className="card__list">
          <div className="row0 row2 cardgrid0">
              {

                arrayPageData &&
                arrayPageData
                // Array.from([
                //   {
                //     title : `앱 사용방법 관련 자주 묻는 문의입니다. 튜토리얼 진행 후 다시 보고 싶으다면 읽어주세요`,
                //     description: `앱 사용방법 관련 자주 묻는 문의입니다. 튜토리얼 진행 후 다시 보고 싶으다면 읽어주세요<br/>앱 사용방법 관련 자주 묻는 문의입니다. 튜토리얼 진행 후 다시 보고 싶으다면 읽어주세요<br/>앱 사용방법 관련 자주 묻는 문의입니다. 튜토리얼 진행 후 다시 보고 싶으다면 읽어주세요<br/>`,
                //     name : `이종철님`,
                //     background : `#fff`,
                //     lock : false,
                //     number : 31,
                //   },
                  
                  
                // ])
                .map((mapItem, mapIndex)=> {
                  return (
                    <div 
                      className="faqbox0" 
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setScreenMode1( 'page002/1/remind-list')
                        // page002/0/remind
                        // page002/1/remind-list
                        if (mapIndex == selectedItemIndex) {
                          setSelectedItemIndex(-1);
                        } else {
                          setSelectedItemIndex(mapIndex);
                        }
                      }}
                    >
                      <div 
                        className="text_row0 text_row1" 
                        style={{ background :  mapIndex == selectedItemIndex ? `rgba(134, 142, 150, 0.2)` : `#fff`}} 
                      >
                        <div className="text_q1" >Q.&nbsp;</div>
                        <div className="text_t1" >{mapItem.title}</div>
                        <img className="moreimg0" src={selectedItemIndex == mapIndex ? `/static/icons/079/arrow1.png` : `/static/icons/079/arrow0.png`} />
                      </div>
                      {
                        selectedItemIndex == mapIndex &&
                        <div className="text_row0 text_row2">
                          {parse(mapItem.description)}
                        </div>
                      }
                      

                    </div>
                  )
                })
              }
            </div>

          </div>
          {/* <div className="bottom_more_button0 ">
            <div className="">
              더보기
              <img src={`/static/icons/052/icon_arrow.png`}/>
            </div>
          </div> */}
        </div>


      {
          boolPopup1 == true &&
          <>
              <div className="popup_background0" 
              onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPopup1(false);
              }}
              >

              <div className="popupcontent0 search_bottom_popup1">
                  <img className="close0" src={appImages._000_2} />
                  <div className="button0 button1" 
                      onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolPopup1(false);
                          setBoolPopup2(true);

                          setScreenMode1('page005/3/setting-faq-add');
                      }}
                      >
                      <div className="label0 label1">자주묻는질문생성</div>
                      {/* <img className="img0 img1" src={`/static/icons/055/icon_move1.png`} /> */}
                  </div>
                  <div className="button0 button2"
                      onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          // setBoolPopup1(false);
                          setBoolPopup1(false);
                      }}
                      >
                      <div className="label0 label1">취소</div>
                      {/* <img className="img0 img1" src={`/static/icons/055/icon_delete0.png`}  /> */}
                  </div>
              
              </div>

              </div>
          </>
      }
      

        <style>{`
        .text_row0 {
          width : 100%;
          display: flex;
          flex-direction: row;
          
        }
        .text_row1 {
          padding-bottom : 20px;
        }
        .text_row2 {
          width : calc(100% - 60px);

          margin-top: 20px;
          margin-left: auto;
          margin-right : auto;
          margin-bottom: 20px;
          color : #FF7A7A;
          font-size: 14px;

        }
        .moreimg0 {
          width : 8px;
          height: 8px;
          object-fit : contain;

          margin-top: 20px;
          margin-left: auto;
          margin-right: 20px;
        }
          .text_q1 {

            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 25px;
            /* identical to box height, or 179% */
            letter-spacing: -0.05em;

            color: #181818;

            margin-top: 20px;
          }
          .text_t1 {
            max-width : 240px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 25px;
            /* identical to box height, or 179% */
            letter-spacing: -0.05em;
            
            color: #212529;

            margin-top: 20px;
          }
        
        `}</style>
      </>
      
    );
}
