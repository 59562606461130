import {combineReducers} from 'redux'
import {counterReducer, COUNTER_KEY} from './counterRedux/counter.reducer'
import {screenModeReducer, SCREENMODE_KEY} from './screenModeRedux/screenMode.reducer'
import {popupModeReducer, POPUPMODE_KEY} from './popupModeRedux/popupModeRedux.reducer'

import {categoryReducer, category_KEY} from './categoryRedux/category.reducer'
import {filterReducer, filter_KEY} from './filterRedux/filter.reducer'

import {authReducer, AUTH_KEY} from './authRedux/auth.reducer'

let rootReducer = combineReducers({
    // counterStore : counterReducer,
    categoryStore: categoryReducer,
    filterStore: filterReducer, 
    screenModeStore : screenModeReducer,
    popupModeStore : popupModeReducer,
    authStore: authReducer,
})

export {rootReducer}