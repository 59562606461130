import {SET_SCREENMODE} from './screenMode.actionTypes'


let setScreenMode = (param) =>{
    return{
        type: SET_SCREENMODE,
        payload: param
    }
}

export {setScreenMode}