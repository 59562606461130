import React, { Component, useEffect, useState, useContext } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../../redux/authRedux/auth.reducer";
import { setAuth } from "../../../redux/authRedux/auth.actions";
import { category_KEY } from "../../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../../redux/filterRedux/filter.actions";

import uuid from 'react-uuid';
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import TabBottom from "../../TabBottom/Tab";

import DropDownClickable from "../../../components/DropdownClickable";
import CalendarModule from "../../_Tab2Screens/CalendarModule/index";

import "./aa01.css";


const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]


const conversationTopicArray = [
  {
      index : 1, 
      enName : '',
      krName : '정신건강',
  },
  {
      index : 2, 
      enName : '',
      krName : '성생활',
  },
  {
      index : 3, 
      enName : '',
      krName : '금전/사업',
  },
  {
      index : 4, 
      enName : '',
      krName : '대인관계',
  },
  {
      index : 5, 
      enName : '',
      krName : '자아/성격',
  },
  {
      index : 6, 
      enName : '',
      krName : '결혼/육아',
  },
  {
      index : 7, 
      enName : '',
      krName : '이별/이혼',
  },
  {
      index : 8, 
      enName : '',
      krName : '가족',
  },
]
 
const pageCode1 =   'page003/0/mind-analysis';

// <div className={``}></div>
export default function ThisComponent(props) {
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null); 

    const [selectedFilter1, setSelectedFilter1] = useState(
      {
        label : "전체", 
        index: 1,
      },);
    const [selectedFilter2, setSelectedFilter2] = useState(null);
    const [boolTapped1, setBoolTapped1] = useState(false);
    const [boolTapped2, setBoolTapped2] = useState(false);
    const [url, setUrl] = useState(""); 
    const [textCalendarMode, setTextCalendarMode] = useState("Week"); 
    const [arrayData1, setArrayData1] = useState([]);
    const [sortOutArray1, setSortOutArray1] = useState([...emoCardArray]);
    const [sortOutCount1, setSortOutCount1] = useState(0);

  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }
 
    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      if (true && true) {
        const authStoreKeyName = localStorage.getItem("authStoreKeyName");
        const authStoreUserName = localStorage.getItem("authStoreUserName");
        const authStore1 = localStorage.getItem("authStore");
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        // const script = document.createElement('script');
        // script.src = '/static/js/donut.js';
        // script.id = 'donut';
        // document.body.appendChild(script);
        // script.onload = () => { 
      
        // };

        
        Firebase.database().ref()
        .child("behaviors/diary-checkins")
        .orderByChild("username")
        .equalTo(authStoreUserName)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/posts --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setArrayData1(Object.values(data))
              selectTextCalMode(Object.values(data));
            }
          }
        });
 
        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        
                        setUrl("ssokdak@nickname:" + data[keyName].nickname) 
                     
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });




      }

    }, [useSelector(state => state)])


    useEffect(()=> {

      selectTextCalMode(arrayData1);
    }, [textCalendarMode])


    function selectTextCalMode(paramArray1) {

      if (textCalendarMode === "Year") { // 이번연도
        const yearArray = paramArray1.filter((filterItem, filterIndex)=> {
          if (moment(filterItem.created_day, "YYYY-MM-DD").format("YYYY") === moment().format("YYYY")) {
            return filterItem
          }
        });

        console.log("yearArray", yearArray);

        sortOutChart1(yearArray)


      }
      if (textCalendarMode === "Week") { // 이번 주

        var weekArray = []
        for (var i=0; i<6;i++) {

          const today = new Date();
          const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
          const day1 = moment(firstDay)
          const itemDay  = day1.add(i, "day")
          const itemDayFormatDD = itemDay.format("DD");
          const itemDayFormatYYYYMMDD = itemDay.format("YYYY-MM-DD");
          const filterArray1 = paramArray1.filter((filterItem, filterIndex)=> {
            if (filterItem.created_day === itemDayFormatYYYYMMDD) {
              return filterItem
            }
          });
          weekArray = [...weekArray, ...filterArray1];
        }
        
        sortOutChart1(weekArray)

        
      }
      if (textCalendarMode === "Month") { // 이번 달

 
        // const momentPast1Month = moment().add("month", -1) 
        // for( var i=0;i < 31 ; i++) {

        //   const itemDay  = momentPast1Month.add(i, "day")
        //   const itemDayFormatDD = itemDay.format("DD");
        //   const itemDayFormatYYYYMMDD = itemDay.format("YYYY-MM-DD");
        //   const filterArray1 = arrayData1.filter((filterItem, filterIndex)=> {
        //     if (filterItem.created_day === itemDayFormatYYYYMMDD) {
        //       return filterItem
        //     }
        //   });
        //   monthArray = [...monthArray, ...filterArray1]; 
        // }
         
        const monthArray = paramArray1.filter((filterItem, filterIndex)=> {
          if (moment(filterItem.created_day, "YYYY-MM-DD").format("YYYY-MM") === moment().format("YYYY-MM")) {
            return filterItem
          }
        });


        console.log("monthArray", monthArray);
        sortOutChart1(monthArray)
        
      }
      if (textCalendarMode === "Day") { // 데일리

        const filterArray1 = paramArray1.filter((filterItem, filterIndex)=> {
          if (filterItem.created_day === moment().format("YYYY-MM-DD")) {
            return filterItem
          }
        });

        // console.log("filterArray1", filterArray1);
        sortOutChart1(filterArray1)
      }
    }

    function sortOutChart1 (array1) {

      var countArray=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,];
      var indexArray=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,];
      var emotionArray=[...emoCardArray];
      // var index001 = 0;
      // var index002 = 0;
      // var index003 = 0;
      // var index004 = 0;

      for(var i=0;i< array1.length; i++) {
        const idex1 = emoCardArray.findIndex((findItem, findIndex)=> {
          if (array1[i].emotionKrName === findItem.krName) {
            return findItem
          }
        });

        if (idex1 > -1) {
          countArray[idex1] = countArray[idex1] + 1;
        }
      }

      var totalCount = 0;
      for(i=0;i< countArray.length; i++) {
        emotionArray[i].count = countArray[i];
        totalCount = totalCount + countArray[i];
      }
      setSortOutArray1(emotionArray); 
      setSortOutCount1(totalCount);
    }
    

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }
    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`} style={{ background : '#FFF4F4'}}> 
          <div 
            className="calendarWeekWrapper" 
            style={{ 
              minHeight : boolTapped2 ===  true ? "52px" : "52px",
              transition: `all 0.5s ease-out`,
            }}
          >
          <div className="calendarWeekTopBar">
              <div className="calendarWeekDiv1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolTapped2(true);
                }}
              >
                  <div className="calendarWeekTopLabel">
                    {textCalendarMode}
                    </div>
                  <img className="image0 image1" src={`/static/images/ssokdak/Tab2/calendarTopArrowDown.svg`} alt=""
                  />
              </div>
          </div>
          {
            boolTapped2 === true && 
            <div className="fabBlur"
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setBoolTapped1(false);
            }} ></div>
          }
          </div>

          <div className="calendarContentsWrapper">
            <div className="reportAllWrapper">
              <div className="reportPartWrapper0 reportPartWrapper1">
                <div className="text0 text1">
                  {textCalendarMode === "Year" &&`이번 연도`}
                  {textCalendarMode === "Week" &&`이번 주`}
                  {textCalendarMode === "Month" &&`이번 달`}
                  {textCalendarMode === "Day" &&`데일리`},&nbsp;Top2 감정
                </div>
                <div className="text0 text2">
                  {parse(`${`감정 체크인을 통해 남겨주신 기록을<br/>바탕으로 차트를 구성하였어요`}`)}
                </div>
                <div className="row0 row1">
                  <div className="col0 col1">
                    <div className="donutWrapper">
                      <div className="donut1"></div>
                      <div className="donut-hole"></div>
                    </div>
                  </div>
                  <div className="col0 col2">
                      {
                        Array.from(sortOutArray1)
                        // .sort(function(a, b){return a.count - b.count})
                        .sort(function(a, b){return b.count - a.count})
                        .map((mapItem, mapIndex)=> {
                          // console.log("mapItem", mapItem)
                          if (mapIndex >= 2) {
                            return (<></>)
                          }
                          if (mapIndex === 0 && mapItem.count === 0) {
                            return (
                              <div className="row100 row101">
                                <img className="image0 image1" src={`/static/images/ssokdak/Tab2/emoEmpty.svg`} alt=""
                                  />
                                <div className="text100 text101">
                                  -
                                  </div>
                                <div className="text100 text102">
                                  0%
                                </div>
          
                              </div>)
                          }
                          if (mapIndex === 1 && mapItem.count === 0) {
                            return (
                              <div className="row100 row101">
                                <img className="image0 image1" src={`/static/images/ssokdak/Tab2/emoEmpty.svg`} alt=""
                                  />
                                <div className="text100 text101">
                                  -
                                  </div>
                                <div className="text100 text102">
                                  0%
                                </div>
          
                              </div>)
                          }
                          if (mapItem.count ) {
                          } else {

                            return (<></>)
                          }
                          
                          return (
                            <div className="row100 row101">
                              <img className="image0 image1" src={mapItem.iconOnlyPathName} alt=""
                                />
                              <div className="text100 text101">
                                {mapItem.krName}
                              </div>
                              <div className="text100 text102">
                                {sortOutCount1 > 0 && parseInt(mapItem.count / sortOutCount1 * 100) }
                                {sortOutCount1 === 0 && `0`}%
                              </div>
        
                            </div>
                            )
                        })
                      }
                      </div>
                </div>
              </div>

              {/*  */}
              <div className="reportPartWrapper0 reportPartWrapper2">

                <div className="text0 text1">

                  {textCalendarMode === "Year" &&`이번 연도`}
                  {textCalendarMode === "Week" &&`이번 주`}
                  {textCalendarMode === "Month" &&`이번 달`}
                  {textCalendarMode === "Day" &&`데일리`},&nbsp;감정변화 리포트
                </div>
                <div className="text0 text2">
                  {parse(`${`감정 체크인을 통해 남겨주신 기록을 바탕으로<br/>이번주의 느낀 감정들을 차트를 구성하였어요`}`)}
                </div>
                <div className="row0 row1 chartRow0" style={{ marginTop: `20px`, overflow : 'scroll'}}>
                  {
                     Array.from(sortOutArray1)
                     // .sort(function(a, b){return a.count - b.count})
                     .sort(function(a, b){return b.count - a.count})
                     .map((mapItem, mapIndex)=> {

                      if ((mapIndex + 1) > 6) {
                        return <></>
                      }
                      if ((mapIndex + 1) <= 6 && mapItem.count === 0) {
                        return (

                        <div className={"col0 col" + (mapIndex + 1)}>
                        <div className={"chartValueWrapper0 chartValueWrapper"  + (mapIndex + 1)}>
                          <div className={"activeCol0 activeCol"+ (mapIndex + 1)} style={{ height : `60px`, background : '#e3e3e3'}} ></div>
                        </div>
                        <img className="image0 image1" src={`/static/images/ssokdak/Tab2/emoEmpty.svg`} alt=""
                          />
                        <div className="text11">
                          {sortOutCount1 > 0 && parseInt(mapItem.count / sortOutCount1 * 100) }
                          {sortOutCount1 === 0 && `0`}%
                        </div>
                      </div>
                        )
                      }
                      //  

                      return (
                        <div className={"col0 col" + (mapIndex + 1)}>
                          <div className={"chartValueWrapper0 chartValueWrapper"  + (mapIndex + 1)}>
                            <div className={"activeCol0 activeCol"+ (mapIndex + 1)} style={{ height : `${parseInt(mapItem.count / sortOutCount1 * 100)}px`}} ></div>
                          </div>
                          <img className="image0 image1" src={mapItem.iconOnlyPathName } alt=""
                            />
                          <div className="text11">
                            {sortOutCount1 > 0 && parseInt(mapItem.count / sortOutCount1 * 100) }
                            {sortOutCount1 === 0 && `0`}%
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <style>{`
                .donutWrapper {

                  width : 120px;
                  height : 120px;
                  display: flex;
                  align-items: center;
                  justify-contents : center;
                  
                  position : relative;
                }
                .donut1 {
                  width : 120px;
                  height : 120px;
                  background: conic-gradient(#DBB8EC 45deg, #DBB8EC 45deg 135deg, #FF7A7A 135deg);
                  border-radius: 50%;
                  position : relative;
                }
                .donut-hole {
                  position : absolute;
                  background : #fff;
                  width : 80px;
                  height : 80px;
                  border-radius: 50%;
                  z-index: 66;
                  top: 50%;
                  left: 50%;
                  transform : translate(-50%, -50%);
                  
                }

                `}</style>
              

              </div>
              {/*  */}
              <div className="reportPartWrapper0 reportPartWrapper3">

                <div className="text0 text1">
                  {textCalendarMode === "Year" &&`이번 연도`}
                  {textCalendarMode === "Week" &&`이번 주`}
                  {textCalendarMode === "Month" &&`이번 달`}
                  {textCalendarMode === "Day" &&`데일리`},&nbsp;감정 키워드
                </div>
                <div className="text0 text2">
                  {parse(`${`감정 체크인을 통해 남겨주신 기록을<br/>바탕으로  태그로 나타내었어요`}`)}
                </div>
                <div className="row0 row1">
                {
                     Array.from(sortOutArray1)
                     // .sort(function(a, b){return a.count - b.count})
                     .sort(function(a, b){return b.count - a.count})
                     .map((mapItem, mapIndex)=> {

                      if ((mapIndex + 1) > 3) {
                        return <></>
                      }
                      if ((mapIndex + 1) <= 3 && mapItem.count === 0) {
                        return ( 
                          <></>
                        ) 
                      }else {
                        return  <div className="tagggItemGray">&nbsp;&nbsp;&nbsp;#{mapItem.krName}&nbsp;&nbsp;&nbsp;</div>
                      } 
                })
                }
                </div>

              </div>
            </div>

            <div className="bottomBannerWrapper">

            <div className="text0 text1">
                추천드리는 활동
                </div>
                <img className="image0 image1" src={`/static/images/ssokdak/Tab4/secretChat.png`} alt=""
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  window.localStorage.setItem("selectedTab4", 1);
                  setScreenMode1('page004/998/chat-list');
                }}
                  />
            </div>
              {/* secretChat.png */}

          </div>
          {
            boolTapped1 === true && 
            <div className="fabBlur"
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setBoolTapped1(false);
            }} ></div>
          }
          <div className="fabContainer">
            {
              boolTapped2 === true ?
              <div className="dropDownWrapper">
                <div className="dropDownItem" 
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextCalendarMode("Year")
                  setBoolTapped2(false);
                }}
                >Year</div>
                <div className="dropDownItem" 
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextCalendarMode("Month")
                  setBoolTapped2(false);
                }}>Month</div>
                <div className="dropDownItem" 
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextCalendarMode("Week")
                  setBoolTapped2(false);
                }}>Week</div>
                <div className="dropDownItem" 
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  setTextCalendarMode("Day")
                  setBoolTapped2(false);
                }}>Day</div>
              </div>
              :
              <>
              </>
            }
          </div>
          <div className=" " style={{ marginBottom: `500px`, height : `400px`}}></div>
        </div>
        <TabBottom/>
        </>
      }
      </>
      
    );
}
