import React, { useEffect, useState, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = (props) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [testMode, setTestMode] = useState("prod")

  useEffect(()=> {

    resetTranscript();
  }, [])


  useEffect(()=> { 
    if (props.action === "start") {
      resetTranscript();
      SpeechRecognition.startListening({continuous : true , language : 'ko'}) ;
      props && props.callBack({ type : props.type, transcript : transcript})
    }
    if (props.action === "stop") {
      SpeechRecognition.stopListening();
    }
  }, [props])


  useEffect(()=> { 
    if (transcript && transcript != "") {

      props && props.callBack({ type : props.type, transcript : transcript})
    }
  }, [transcript])

  console.log("[STT]listening",listening )

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <>
    <div>
      {
        testMode === "dev" &&
        <>
        <p className='listingText0 listingText1'>Microphone: {listening ? 'on' : 'off'}</p>
        <div className='sttButton0 sttButton1' 
        onClick={e=> {
          e.preventDefault();
          e.stopPropagation();
          SpeechRecognition.startListening({continuous : true , language : 'ko'}) ;
        }}
        >Start</div>
        <div className='sttButton0 sttButton2' 
        onClick={e=> {
          e.preventDefault();
          e.stopPropagation();
          SpeechRecognition.stopListening();
        }}>Stop</div>
        <div className='sttButton0 sttButton3' 
         onClick={e=> {
          e.preventDefault();
          e.stopPropagation();
          resetTranscript();
        }}>Reset</div>
        <p>{transcript}</p>
        </>
      }
    </div>

    <style>{`
      .sttButton0 {
        width : 60px;
        height : 32px;
        display: flex;
        align-items: center;
        justify-content : center;

        color : #000;
        border : 1px solid #000;

      }
    `}</style>
    
    </>
  );
};
export default Dictaphone;