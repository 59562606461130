import React, { Component, useEffect, useState, useContext, useLayoutEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import "./App.css";
import TabBottom from "./components/TabBottom/Tab";
import NavigationTop from "./components/NavigationTop/NavigationTop";
import SplashScreen from "./components/__SplashScreen/index";
import TabLeft from "./components/TabLeft/Tab";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { SCREENMODE_KEY } from "./redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "./redux/screenModeRedux/screenMode.actions";
import moment from "moment";
import { Helmet } from 'react-helmet';
import fireDb from "./firebase";
import Firebase from 'firebase';
import { toast } from "react-toastify";

import AuthFirstScreen from "./components/_AuthScreens/AuthFirstScreen";
import AuthLoginScreen from "./components/_AuthScreens/AuthLoginScreen";
import AuthFindIdScreen from "./components/_AuthScreens/AuthFindIdScreen";
import AuthFindPwScreen from "./components/_AuthScreens/AuthFindPwScreen";
import AuthRegisterScreen from "./components/_AuthScreens/AuthRegisterScreen";

// import AuthRegisterScreen from "./components/Tab1Screens/AuthRegisterScreen";

// TAB1
import Home000Screen from "./components/_Tab1Screens/Home000Screen";

// TAB5
import Settings000HomeScreen from "./components/_Tab5Screens/Archive/Settings000HomeScreen";
import Settings010MyPageScreen from "./components/_Tab5Screens/Archive/Settings010MyPageScreen";
import Settings011WithdrawlScreen from "./components/_Tab5Screens/Archive/Settings011WithdrawlScreen";
import Settings020NotificationListScreen from "./components/_Tab5Screens/Archive/Settings020NotificationListScreen";
import Settings021NotificationUpdateScreen from "./components/_Tab5Screens/Archive/Settings021NotificationUpdateScreen";
import Settings030SubscribeAgreeScreen from "./components/_Tab5Screens/Archive/Settings030SubscribeAgreeScreen";
import Settings040FaqScreen from "./components/_Tab5Screens/Archive/Settings040FaqScreen";
import Settings041FaqAdd from "./components/_Tab5Screens/Archive/Settings041FaqAdd";
import Settings050QuestionsScreen from "./components/_Tab5Screens/Archive/Settings050QuestionsScreen";
import Settings051QuestionsAddScreen from "./components/_Tab5Screens/Archive/Settings051QuestionsAddScreen";
import Settings060NoticeScreen from "./components/_Tab5Screens/Archive/Settings060NoticeScreen";
import Settings061NoticeAdd from "./components/_Tab5Screens/Archive/Settings061NoticeAdd";
import Settings070TutorialScreen from "./components/_Tab5Screens/Archive/Settings070TutorialScreen";
import Settings071TutorialAddScreen from "./components/_Tab5Screens/Archive/Settings071TutorialAddScreen";
import Settings080TermUsageScreen from "./components/_Tab5Screens/Archive/Settings080TermUsageScreen";
import Settings090TermPrivacyScreen from "./components/_Tab5Screens/Archive/Settings090TermPrivacyScreen";

// OTHER
import SearchScreen from "./components/_OtherScreens/SearchScreen"
import SearchResultScreen from "./components/_OtherScreens/SearchResultScreen"
import SsokdakMindDiaryScreen from "./components/_Tab2Screens/SSOKDAK/SsokdakMindDiaryScreen";
import SdMindDiaryCheckInBySTTScreen from "./components/_Tab2Screens/SSOKDAK/SdMindDiaryCheckInBySTTScreen";
import SdMindDiaryCheckInByOCRScreen from "./components/_Tab2Screens/SSOKDAK/SdMindDiaryCheckInByOCRScreen";
import SdMindDiaryCheckInByCamScreen from "./components/_Tab2Screens/SSOKDAK/SdMindDiaryCheckInByCamScreen";
import SsokdakMindDiaryCheckInScreen from "./components/_Tab2Screens/SSOKDAK/SsokdakMindDiaryCheckInScreen";
import SsokdakMindAnalysisScreen from "./components/_Tab3Screens/SSOKDAK/SsokdakMindAnalysisScreen";

import SsokdakChattingRoomDetailScreen from "./components/_Tab4Screens/SSOKDAK/SsokdakChattingRoomDetailScreen";
import SsokdakChattingRoomScreen from "./components/_Tab4Screens/SSOKDAK/SsokdakChattingRoomScreen"

import SsokdakDailyFirstMatchingOptionScreen from "./components/_Tab4Screens/SSOKDAK/SsokdakDailyFirstMatchingOptionScreen"

// SsokdaMypageScreen

import SsokdaMypageScreen from "./components/_Tab5Screens/SSOKDAK/SsokdaMypageScreen"

import SsokdakSettingTerm1 from "./components/_Tab5Screens/SSOKDAK/SsokdakSettingTerm1"

import SsokdakSettingTerm2 from "./components/_Tab5Screens/SSOKDAK/SsokdakSettingTerm2"

import SsokedakSettingPush from "./components/_Tab5Screens/SSOKDAK/SsokedakSettingPush"

// SsokedakSettingPush


const columns = [
  { accessor: 'name', label: 'Name' },
  { accessor: 'age', label: 'Age' },
  { accessor: 'is_manager', label: 'Manager', format: (value) => (value ? '✔️' : '✖️') },
  { accessor: 'start_date', label: 'Start Date' },
]

const rows = [
  { id: 1, name: 'Liz Lemon', age: 36, is_manager: true, start_date: '02-28-1999' },
  { id: 2, name: 'Jack Donaghy', age: 40, is_manager: true, start_date: '03-05-1997' },
  { id: 3, name: 'Tracy Morgan', age: 39, is_manager: false, start_date: '07-12-2002' },
  { id: 4, name: 'Jenna Maroney', age: 40, is_manager: false, start_date: '02-28-1999' },
  { id: 5, name: 'Kenneth Parcell', age: Infinity, is_manager: false, start_date: '01-01-1970' },
  { id: 6, name: 'Pete Hornberger', age: 42, is_manager: true, start_date: '04-01-2000' },
  { id: 7, name: 'Frank Rossitano', age: 36, is_manager: false, start_date: '06-09-2004' },
  { id: 8, name: null, age: null, is_manager: null, start_date: null },
]


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  // 
  const [agent, setAgent] = useState("Web");
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [messageFromIos, setMessageFromIos] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [authExisted, setAuthExisted] = useState(false);
  const { height, width } = useWindowDimensions();

  const [selectedUserKeyname, setSelectedUserKeyname] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserObject, setSelectedUserObject] = useState(null);
  const [selectedCardKeyname, setSelectedCardKeyname] = useState("");
  const [selectedCardId, setSelectedCardId] = useState("");
  const [selectedCardObject, setSelectedCardObject] = useState(null);
  const [selectedFolderKeyname, setSelectedFolderKeyname] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [selectedFolderObject, setSelectedFolderObject] = useState(null);
  const [selectedGameId, setSelectedGameId] = useState("");
  const [selectedGameKeyname, setSelectedGameKeyname] = useState("");
  const [selectedGameObject, setSelectedGameObject] = useState(null);

  const [arrayUser, setArrayUser] = useState([]);
  const [arrayCard, setArrayCard] = useState([]);
  const [arrayFolder, setArrayFolder] = useState([]);
  const [arrayGame, setArrayGame] = useState([]);
  const [arrayGames, setArrayGames] = useState([]);

  const [bottomButtonVisibleApp, setBottomButtonVisibleApp] = useState("hidden");

  function handleUser(paramString, paramObject, paramId) {
    if (paramString) {
      setSelectedUserKeyname(paramString);
    }
    if (paramObject) {
      setSelectedUserObject(paramObject)
    }
    if (paramId) {
      setSelectedUserId(paramId)
    }
  }

  function handleCard(paramString, paramObject, paramId) {

    if (paramString) {
      setSelectedCardKeyname(paramString);
    }
    if (paramObject) {
      setSelectedCardObject(paramObject)
    }
    if (paramId) {
      setSelectedCardId(paramId)
    }
  }

  function handleFolder(paramString, paramObject, paramId) {

    if (paramString) {
      setSelectedFolderKeyname(paramString);
    }
    if (paramObject) {
      setSelectedFolderObject(paramObject)
    }
    if (paramId) {
      setSelectedFolderId(paramId)
    }
  }

  function handleGame(paramString, paramObject, paramId) {

    if (paramString) {
      setSelectedGameKeyname(paramString);
    }
    if (paramObject) {
      setSelectedGameObject(paramObject)
    }
    if (paramId) {
      setSelectedGameId(paramId)
    }
  }


  function handleUsers(paramArray) {
    if (paramArray) {
      setArrayUser(paramArray);
    }
  }
  function handleData0(paramArray) {
    if (paramArray) {
      setArrayCard(paramArray);
    }
  }
  function handleOthers1(paramArray) {
    if (paramArray) {
      setArrayFolder(paramArray);
    }
  }
  function handleOthers2(paramArray) {
    if (paramArray) {
      setArrayGame(paramArray);
    }
  }
  function handleOthers2s(paramArray) {

    console.log("handleOthers2s(paramArray)",paramArray )
    if (paramArray) {
      setArrayGames(paramArray);
    }
  }

  useEffect(()=> {
    initNative();
    // window.localStorage.clear();

    if (window.webkit && window.webkit.messageHandlers) {
      // window.webkit.messageHandlers.IOSInterface.postMessage("What's the meaning of life, native code?"); 
      window.webkit.messageHandlers.IOSInterface.postMessage(JSON.stringify({
        name: 'Heechan',
        age: 25
      })); 
    }

  }, [])


  function initNative() {
      bind()
      callFunction();
  }
  function bind() {
    var message = {
        'action': 'bind',
        'name': 'message'
    };

    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.scriptHandler.postMessage(message);
    }
}

function test() {
    // alert('(Web)test()\nmessage :\n' + message);
}

function getMessage() {
    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.scriptHandler.postMessage('getMessage');
    }
}

function returnMessage(text) {
    alert('(Native)returnMessage() :\n' + text);
}

function callFunction() {
    var message = {
        'action': 'call',
        'function': 'returnFunction'
    };
    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.scriptHandler.postMessage(message);
    }
}

function returnFunction(text) {
    alert('(Web)returnFunction() :\n' + text);
    setMessageFromIos(text);
    var message = {
        'action': 'call',
        'function': 'returnFunction2'
    };
    if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.scriptHandler.postMessage(message);
    }
}

  const [textEmail, setTextEmail] = useState("")
  const [textPassword, setTextPassword] = useState("")

  const [image , setImage] = useState('');
  const upload = ()=>{
  if(image == null)
    return;
  fireDb.storage().ref(`/images/${image.name}`).put(image)
  .on("state_changed" , alert("success") , res=> {
    console.log(res);
  });
  }

  useEffect(() => {

    const eventFromAndroid =  (event) => {

        console.log("[웹뷰 통신 테스트] :::: -0001")
        console.log("[웹뷰 통신 테스트] :::: -0001 - 1", event)
        console.log("[웹뷰 통신 테스트] :::: -0001 - 2", JSON.stringify(event.detail))
        console.log("[웹뷰 통신 테스트] :::: -0001 - 3", event.detail.data)
        console.log("[웹뷰 통신 테스트] :::: -0002",  event.detail.data)
        // setTextTest1("" + event);
        var param1 = event.detail.data;

        var elements0 = document.getElementsByClassName("start_button0");
        var elements1 = document.getElementsByClassName("submit_button0"); 
        var id0 = document.getElementById("start_button0");

        setBottomButtonVisibleApp(event.detail.data);

        // if (id0 && id0) {
        //   if (param1 == "visible") {
        //   id0.style.display = "none";
        //   } else {
        //   id0.style.display = "flex";

        //   }
        //   id0.style.display = "none";

        // }

        // }
        // var x = elements0
        // var i;
        // for (i = 0; i < x.length; i++) {
        //   console.log("detail - 000 - ", x[i].style.display)
        //     x[i].style.display = 'none';
        // }

        // if (elements0 && elements0) {
        //   console.log("check - ", param1)
        //   console.log("elements0", elements0)

        //   if (param1 == "visible") {
            
        //     var x = elements0
        //     var i;
        //     for (i = 0; i < x.length; i++) {
        //       console.log("detail - 0 - ", x[i].style.display)
        //         x[i].style.display = 'none';
        //     }
        //   }

        //   if (param1 == "hidden") {
            
        //     var x = elements0
        //     var i;
        //     for (i = 0; i < x.length; i++) {
        //       console.log("detail - 1 - ", x[i].style.display)
        //         x[i].style.display = 'flex';
        //     }
        //   }


        // }
        // if (elements1 && elements1) {
        //   console.log("check - ", param1)
        //   console.log("elements1", elements1)
          
        //   if (param1 == "visible") {
            
        //     var x = elements1
        //     var i;
        //     for (i = 0; i < x.length; i++) {
        //       console.log("detail - 2 - ", x[i].style.display)
        //         x[i].style.display = 'none';
        //     }
        //   }

        //   if (param1 == "hidden") {
            
        //     var x = elements1
        //     var i;
        //     for (i = 0; i < x.length; i++) {
        //       console.log("detail - 3 - ", x[i].style.display)
        //         x[i].style.display = 'flex';
        //     }
        //   }
        // }




    }
    window.addEventListener('keyboardCheck', eventFromAndroid);
    window.addEventListener('AndroidToSend', eventFromAndroid);
    window.addEventListener('javascriptFunction', eventFromAndroid);
    window.addEventListener('eventFromAndroid', eventFromAndroid);

    if (window && window.BRIDGE) {
      window.BRIDGE.sendMessage(`{type : 'app/open', object : {} , more : '' }`);
    }
    if (window.BRIDGE) {
        // window.BRIDGE.showToastMessage("안드로이드 앱과 서버가 연결 되었습니다.");
        // window.BRIDGE.callJavaScriptFunction("123123", `{type : "string1", object: { param1 : 'param1'}}`);


    }
    console.log("agent is:", window?.navigator?.userAgent)

    console.log("platform is: ", window?.navigator?.platform)
    
    console.log("user agent data: ", window?.navigator?.userAgentData)
    
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var checkAgent = "Web";

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      checkAgent = "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      checkAgent = "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      checkAgent = "iOS";
    }

    setAgent(checkAgent)


    // setInterval(()=> {
    //   if (fcmTokenState && fcmTokenState !== "") {
    //     // checkFcmToken();
    //   }
    // }, 1000)

    return () => {
      window.removeEventListener('keyboardCheck', eventFromAndroid);
      window.removeEventListener('AndroidToSend', eventFromAndroid);
      window.removeEventListener('javascriptFunction', eventFromAndroid);
      window.removeEventListener('eventFromAndroid', eventFromAndroid);
    };
}, []);

console.log("[app.js] bottomButtonVisibleApp ", bottomButtonVisibleApp);

  function returnRenderFunction() {
    
      return (
        <Provider store={store}>
    
          <div className="__main">
            {/* TOPOF POUP / DIALOGUES */}
            {/* <PopupConfirmDelete1/> */}
            {/* COMMON */}
            {/* <PopupToastMessage/> */}
            {/* <PopupGridSkinGallery/> */}
            {/* <PopupInputMultiBox/> */}
    
            <SplashScreen/>
            {
              authExisted === false &&
              <>
                {/* NO logged-in page codes  */}
                {/* [
                  "page000/0/home",
                  "page000/0/login",
                  "page000/1/find-id",
                  "page000/1/find-pw",
                  "page000/1/register",
                ] */}
                {/* RENDERS */}
                <AuthFirstScreen 
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}
                
                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <AuthLoginScreen 
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <AuthFindIdScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <AuthFindPwScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}/>
    
                <AuthRegisterScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
    
                {/* AFTER AUTH */}
                {/* TAB 1 */}
                <Home000Screen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}/>
    
    
                {/* TAB 2 */}
                <SsokdakMindDiaryScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />

<SsokdakMindDiaryCheckInScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />

<SdMindDiaryCheckInBySTTScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />

<SdMindDiaryCheckInByOCRScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <SdMindDiaryCheckInByCamScreen
                                
                                selectedUserKeyname={selectedUserKeyname}
                                selectedUserObject={selectedUserObject}
                                selectedCardKeyname={selectedCardKeyname}
                                selectedCardObject={selectedCardObject}
                                selectedFolderKeyname={selectedFolderKeyname}
                                selectedFolderObject={selectedFolderObject}
                                selectedGameKeyname={selectedGameKeyname}
                                
                                selectedGameObject={selectedGameObject}
                                
                                selectedUserId={selectedUserId}
                                selectedCardId={selectedCardId}
                                selectedFolderId={selectedFolderId}
                                selectedGameId={selectedGameId}
                                handleUser={handleUser}
                                handleCard={handleCard}
                                handleFolder={handleFolder}
                                
                                handleGame={handleGame}
                    
                                arrayUser={arrayUser}
                                arrayCard={arrayCard}
                                arrayFolder={arrayFolder}
                                arrayGame={arrayGame}
                                handleUsers={handleUsers}
                                handleData0={handleData0}
                                handleOthers1={handleOthers1}
                                handleOthers2={handleOthers2}
                
                appKeyboardVisible={bottomButtonVisibleApp}
                                />

                {/* TAB 3 */}
              <SsokdakMindAnalysisScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />


                
    
    <SsokdakChattingRoomDetailScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
    
    
    <SsokdakChattingRoomScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
    
    
    <SsokdakDailyFirstMatchingOptionScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
    
                {/* TAB 5 */}
                <SsokdaMypageScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <SsokdakSettingTerm1
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <SsokdakSettingTerm2
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <SsokedakSettingPush
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <Settings000HomeScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <Settings010MyPageScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <Settings080TermUsageScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}/>
    
                <Settings020NotificationListScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}/>
    
    <Settings021NotificationUpdateScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}/>
                <Settings030SubscribeAgreeScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
    
                <Settings011WithdrawlScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings011WithdrawlScreen>
                <Settings040FaqScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}></Settings040FaqScreen>
    
                <Settings041FaqAdd
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings041FaqAdd>
                <Settings050QuestionsScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings050QuestionsScreen>
    
                <Settings051QuestionsAddScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings051QuestionsAddScreen>
                <Settings060NoticeScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings060NoticeScreen>
                <Settings061NoticeAdd
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings061NoticeAdd>
                <Settings070TutorialScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings070TutorialScreen>
    
    
                <Settings071TutorialAddScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                ></Settings071TutorialAddScreen>
    
                {/* OTHER */}
                <SearchScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                <SearchResultScreen
                
                selectedUserKeyname={selectedUserKeyname}
                selectedUserObject={selectedUserObject}
                selectedCardKeyname={selectedCardKeyname}
                selectedCardObject={selectedCardObject}
                selectedFolderKeyname={selectedFolderKeyname}
                selectedFolderObject={selectedFolderObject}
                selectedGameKeyname={selectedGameKeyname}
                
                selectedGameObject={selectedGameObject}
                
                selectedUserId={selectedUserId}
                selectedCardId={selectedCardId}
                selectedFolderId={selectedFolderId}
                selectedGameId={selectedGameId}
                handleUser={handleUser}
                handleCard={handleCard}
                handleFolder={handleFolder}
                
                handleGame={handleGame}
    
                arrayUser={arrayUser}
                arrayCard={arrayCard}
                arrayFolder={arrayFolder}
                arrayGame={arrayGame}
                handleUsers={handleUsers}
                handleData0={handleData0}
                handleOthers1={handleOthers1}
                handleOthers2={handleOthers2}

                appKeyboardVisible={bottomButtonVisibleApp}
                />
                
                
              </>
            }
            {/* {
              authExisted == true &&
              <>
                <NavigationTop></NavigationTop>
              </>
            } */}
    
              <style>
                {`
                .__main {
                  height: ${height}px;
                  min-height : ${height}px;
                  overflow-y: scroll;
                  overflow-x : hidden;
                }
                `}
              </style>
    
          </div>
        </Provider>
      )
  }



if (window.location.href.indexOf("/admin") > -1 ) {

  return  (
    <Provider store={store}>
    <BrowserRouter>
      <Routes>
        {/*    */}
        {/* <Route exact path="/admin/" element={<AdminPage000 />} /> */}
      

      </Routes>
    </BrowserRouter>
    </Provider>
)
}



if (window.location.href.indexOf("/app") > -1 ) {

  return  (
    <div className="" style={{width : `100vw`,  height: `100vh`,}}>
      <div className="" style={{width : `100vw`,  height: `100vh`, display : 'flex', alignItems : 'center', justifyContent : 'center'}} >
        {/* 광고영역 혹은 백그라운드 */}
        <div className="" style={{width : `100vw`,  height: `100vh`, background : `#181818`, position : 'fixed', zIndex : -1 }}></div>
        {/* iframe , 실제 구동영역 */}
        {agent === "Web" &&  <iframe className="" src={"/iframe"} style={{ width : `100vw`, maxWidth : `400px`, height: `100vh`, border : `none`, zIndex : 99, marginLeft : 'auto', marginRight : 'auto'}} />}
        {/* */}
        {agent !== "Web" && returnRenderFunction()}
      </div>
    </div>
  )
}


if (window.location.href.indexOf("/iframe") > -1 ) {


  // window.localStorage.clear();
  // 렌더, 렌더함수, render-return
  return (<>{returnRenderFunction()}</>)
}

 
return  (
  <div className="" style={{width : `100vw`,  height: `100vh`,}}>
    <div className="" style={{width : `100vw`,  height: `100vh`, display : 'flex', alignItems : 'center', justifyContent : 'center'}} >
      {/* 광고영역 혹은 백그라운드 */}
      <div className="" style={{width : `100vw`,  height: `100vh`, background : `#ffffff`, position : 'fixed', zIndex : -1 }}></div>
      {/* iframe , 실제 구동영역 */}
      {agent === "Web" &&  <iframe className="" src={"/iframe"} style={{ width : `100vw`, maxWidth : `400px`, height: `100vh`, border : `none`, zIndex : 99, marginLeft : 'auto', marginRight : 'auto'}} />}
      {/* */}
      {agent !== "Web" && returnRenderFunction()}
    </div>
  </div>
)


}

export default App;

