import React, { Component, useEffect, useState, useContext, useRef } from "react";

import styled from 'styled-components';
import moment from 'moment';

const This = styled.div`

width : 100%;
min-height : 652px;

border-bottom : 1px solid #2B2930;

.container {
    width: 100%;
}
.label0 {

  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  text-align: left;
  color: #212529;
}

.box0 {

    width: 100%;
    min-height: 652px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
  }

  .col0 {
    width: 100%;
    margin-right: auto;
  }

.col1 {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
}

.col1 > .row1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

  margin-bottom: 12px;
}


.today_button0 {

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 6px;
    
width: 32px;
height: 28px;
margin-left: 8px;
    
    /* Gray/gray_100 */
    background: #F2F4F6;
    border-radius: 12px;
    
    
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.3px;
/* Gray/gray_700 */
color: #495057;
    
    position: absolute;
    left : 0;

    white-space: nowrap;
    }
.tablerow0 {

    width: 100%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.col1 > .row2 {
    width: 100%;
//   height: 54px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  margin-bottom: 8px;
  position: relative;
}
.col1 > .row2 > .arrow0 {
    width: 20px;
    height: 20px;
    object-fit : contain;
}
.col1 > .row2 > .arrow1 {
    margin-right: 14px;
}
.col1 > .row2 > .arrow2 {
    margin-left:  14px;
}
.col1 > .row2 > .switch0 {
    width: 16px;
    height: 16px;
    object-fit : contain;
    margin-left: auto;
    margin-right: 8px;
}
.text1 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.6px;
    text-align: left;
    color: #212529;
}

 .text1_1 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: left;

    margin-left: auto;
    display: flex;

  }
  .text2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .text2_1 {
  
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 117% */
  letter-spacing: -0.3px;
  
  /* Gray/gray_900 */
  color: #212529;
  }
  .text2_2 {
  
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  letter-spacing: -0.3px;
  
  /* Gray/gray_900 */
  color: #212529;
  
  }
.text3 {

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  text-align: right;
  color: #90949c;
}

.tt0 {
    width : calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 6px 0;

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */

/* Gray/07 */
color: #666666;

}
.tt7 {

    border-right : none;
}
.td0 {
    width : calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 74.6px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-top: solid 1px #e6eaed;
    // border-bottom : solid 1px #e6eaed;
    // border-right : solid 1px #e6eaed;

    position: relative;
}
.td7 {
    border-right : none;

}
.td0 > .num_container {

  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;


  position: absolute;
  bottom: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  letter-spacing: 0.03em;
  
  /* Gray/07 */
  color: #666666;

}
.td0 > .dot_container {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 16px;
}

.dot0 {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
}


.dot1 {
    background-color : #f25555;
}
.dot1_1 {
    background-color :  #EE9D3D;
}

.dot2 {
    background-color : #00b2b2;
}

.dot3 {
    background-color : #4167d9;
}

.dot4 {
    background-color : #8D4AE1;
}
.text3 {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.3px;
    text-align: left;
}

.td0 > .num_container {

    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  
  
    position: absolute;
    bottom: 0px;
  
  }


  .td0 .emoImage0 {
    
    position: absolute;
    top: 10px;
    left: 50%;
    transform : translate(-50%);
  
  }


  .td0  .dot_container {
      
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      width: 100%;
      height: 30px;
  
      min-height: 30px;
  
  }
  .td0 > .schedule_containerA {
      
      width : 100%;
      height: 66px;
  
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      
      position: absolute;
      top: 40px;
  }
  .td0  .schedule_container0 {
      
      width : 100%;
      height: 16px;
      min-height: 16px;
  
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
  }
  .td0  .schedule_container1 {
      
  }
  .td0  .schedule_container2 {
      
  }
  .td0  .schedule_container3 {
  }
  .datecontent_div0 {
  
      width: 100%;
      text-align: left;
      padding-left: 4px;
      height: 16px;
      min-height: 16px;
  
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */
display: flex;
align-items: center;
letter-spacing: -0.3px;
  
      white-space : nowrap;
      
  }
  .datecontent_div0 > img{
  
  
  width: 24px;
  height: 16px;
  object-fit : contain;
  margin-left: auto;
  }
  .datecontent_div1 {
  
  color: #F25555;
  background-color : #FFF1F1;
  }
  .datecontent_div2 {
  
      color: #EE9D3D;
      background-color : #FFF6EA;
  }
  .datecontent_div3 {
  
      color: #00B2B2;
      background: #E7F9F9;
  }
  .datecontent_div4 {
  
      color: #4167D9;
      background-color : #EFF3FD;
  }
  .datecontent_div5 {
  
      color: #8D4AE1;
      background: #F8F2FF;
  }
  .dot0 {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  
  .dot1 {
      background-color : #f25555;
  }
  .dot1_1 {
      background-color : #EE9D3D;
  }
  .dot2 {
      background-color : #00b2b2;
  }
  .dot3 {
      background-color : #4167d9;
  }
  .dot4 {
      background-color : #8D4AE1;
  }
  
  .dot__0 {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;
      margin-right: 4px;
  }
  
  .dot__1 {
      background-color : #f25555;
  }
  .dot__2 {
      background-color : #EE9D3D;
  }
  .dot__3 {
      background-color : #00b2b2;
  }
  .dot__4 {
      background-color : #4167d9;
  }
  .dot__5 {
      background-color : #8D4AE1;
  }
  
  .text3 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      margin-left: auto;
  }
  .text3_1 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #495057;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  .text3_1_1 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #495057;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  .text3_2 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #495057;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  .text3_3 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #495057;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  .text3_4 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #495057;
  
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 2px;
  }
  
  @media only screen and (max-width: 400px) {
      /* ... */
  }
  @media only screen and (min-width: 400px) {
      /* ... */
  }
  @media only screen and (min-width: 600px) {
      /* ... */
  }
  @media only screen and (min-width: 768px) {
      /* ... */
  }
  @media only screen and (min-width: 992px) {
      /* ... */
  }
  @media only screen and (min-width: 1300px) {
      /* ... */
      
  }

`;

const Comp = (props) => { 


    const [selectedIntStateYear1, setSelectedIntStateYear1] = useState(moment().format("YYYY"));
    const [selectedIntStateMonth1, setSelectedIntStateMonth1] = useState(moment().format("MM"));
    const [selectedIntStateDate1, setSelectedIntStateDate1] = useState(moment().format("DD"));

    const [intStateYear1, setIntStateYear1] = useState(moment().format("YYYY"));
    const [intStateMonth1, setIntStateMonth1] = useState(moment().format("MM"));
    const [intStateDay1, setIntStateDay1] = useState(11);

    const [arrayRestDay, setArrayRestDay] = useState([]);
    const [scheduleA, setScheduleA] = useState([
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
        [],[],[],[],[],
    ]);
    
    useEffect(()=> {

        var stringFirstDay =  "" + (parseInt(intStateMonth1) + 0 ).toString();
        if (parseInt(intStateMonth1) - 1 < 10 ) {
            stringFirstDay = "0" + (parseInt(intStateMonth1) + 0 ).toString()
        }

        
    }, [])


    useEffect(()=> {
        if (props && props.pageArrayData && props.pageArrayData.length > 0) { 
            var countArr1 = [];
            
            var stringFirstDay =  "" + intStateMonth1;
            if (intStateMonth1 < 10 ) {
                stringFirstDay = "0" + intStateMonth1
            }
            var sendScheduleArr = [
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
                [],[],[],[],[],
            ];
            var pageArrayData = props.pageArrayData;
            const firstDayStr1 = getDayInt(`${intStateYear1}-${stringFirstDay}-01`);
            for (var i= 0 ; i < props.pageArrayData.length; i++) {
                const dayData1 = moment(pageArrayData[i].start_date, "YYYY-MM-DD 00:00:00").toDate().getTime();
                const dayData2 = moment(pageArrayData[i].end_date, "YYYY-MM-DD 00:00:00").toDate().getTime();
                for(var j = 1; j < 32; j++) {
                    var intDay1  = j;
                    var stringDay1 =  "" + intDay1;
                    if (intDay1 < 10 ) {
                        stringDay1 = "0" + j
                    }
                    var stringFullDay1 = `${intStateYear1}-${stringFirstDay}-${stringDay1} 00:00:00`;
                    // console.log("stringFullDay1", stringFullDay1)
                    const dayData0 = moment(stringFullDay1, "YYYY-MM-DD 00:00:00").toDate().getTime();
                    var sendObject = {
                        startedAt : false,
                        endedAt : false, 
                        data : null,
                        typeOrder : 99,
                    }
                    // console.log("pageArrayData[i].end_date", pageArrayData[i].end_date)
                    if (dayData0 === dayData1 || dayData2 === dayData0 || (dayData0 > dayData1 && dayData0 < dayData2)) {
                        if (pageArrayData[i]&& pageArrayData[i].start_date && pageArrayData[i]&& pageArrayData[i].end_date) {
                            sendObject = {
                                startedAt : stringFullDay1 === (pageArrayData[i].start_date + " 00:00:00"),
                                endedAt : stringFullDay1 === (pageArrayData[i].end_date+ " 00:00:00"), 
                                data : pageArrayData[i],
                                typeOrder : 
                                    pageArrayData[i].calendar_type === "red" ? 1 : 
                                    pageArrayData[i].calendar_type === "orange" ? 2 : 
                                    pageArrayData[i].calendar_type === "green" ? 3 : 
                                    pageArrayData[i].calendar_type === "blue" ? 4 : 
                                    pageArrayData[i].calendar_type === "purple" ? 5 : 
                                    99
                            }
                            // if (stringFullDay1 == pageArrayData[i].start_date && stringFullDay1 == pageArrayData[i].end_date) {
                            // }
                        } else {
                        }
                    } else {

                    }
                    sendScheduleArr[j].push(sendObject);

                    sendScheduleArr[j].sort(function(a, b)  {
                        return a.typeOrder - b.typeOrder;
                      });
                }

            }
            var sortScheduleArr1 = sendScheduleArr;
            for(var i=1; i < sortScheduleArr1.length; i++) {
                var send0 = sortScheduleArr1[i - 1];
                var send1 = sortScheduleArr1[i];
                for(var j=0; j <send1.length; j++) {
                    if (send0 && send0[0] && send0[0].data && send0[0].data.idx) {
                        if (send1[j] && send1[j].data &&  send1[j].data.idx) {

                            var tempData1 = send1[0] ;
                            send1[0] = send1[j];
                            send1[j] = tempData1;
                        }
                    }
                    if (send0 && send0[1] && send0[1].data && send0[1].data.idx) {
                        if (send1[j] && send1[j].data &&  send1[j].data.idx) {

                            var tempData1 = send1[1] ;
                            send1[1] = send1[j];
                            send1[j] = tempData1;
                        }
                        
                    }
                    if (send0 && send0[2] && send0[2].data && send0[2].data.idx) {
                        
                        if (send1[j] && send1[j].data &&  send1[j].data.idx) {

                            var tempData1 = send1[2] ;
                            send1[2] = send1[j];
                            send1[j] = tempData1;
                        }
                    }
                    if (send0 && send0[3] && send0[3].data && send0[3].data.idx) {
                        
                        if (send1[j] && send1[j].data &&  send1[j].data.idx) {

                            var tempData1 = send1[3] ;
                            send1[3] = send1[j];
                            send1[j] = tempData1;
                        }
                    }
                    if (send0 && send0[4] && send0[4].data && send0[4].data.idx) {
                        
                        if (send1[j] && send1[j].data &&  send1[j].data.idx) {

                            var tempData1 = send1[4] ;
                            send1[4] = send1[j];
                            send1[j] = tempData1;
                        }
                    }
                }
            }
            setScheduleA(sendScheduleArr);

        }

    }, [props, intStateYear1, intStateMonth1])

    function getDayInt(paramDate) {

        var weekKrString1 = new Array('일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일');
        var weekKrInt = new Array(1,2,3,4,5,6,7);
        // first Day
        var today = new Date(paramDate).getDay();
        var todayLabel = weekKrInt[today];

        return todayLabel;
    }
    

    function __apiGetKoreanCalendarDay() {
        var stringFirstDay =  "" + intStateMonth1;
        if (intStateMonth1 < 10 ) {
            stringFirstDay = "0" + intStateMonth1
        }
        const req = {
          header : { 
            Authorization: `Bearer ` + "",
          },
          data : {},
          query : `?start_date=${intStateYear1}-${stringFirstDay}-01&end_date=${intStateYear1}-${stringFirstDay}-31`
        }
        // _Calendar.getCalendarData(req)
        // .then(res => {
        //   //console.log("_Calendar.getCalendarData", res);
        //   if (res.status < 300) {
        //     setArrayRestDay(res.data.data.lists);
            
        //   }
        // })
      }
      useEffect(()=> {
        
        __apiGetKoreanCalendarDay();
    }, [])
    useEffect(()=> {
        __apiGetKoreanCalendarDay();
    }, [intStateYear1, intStateMonth1])

    function getDayString(paramDate) {

        var weekKrString1 = new Array('일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일');
        var weekKrInt = new Array(1,2,3,4,5,6,7);
        // first Day
        var today = new Date(paramDate).getDay();
        var todayLabel = weekKrString1[today];

        return todayLabel;
    }

    return (
        <This  {...props}>
            <div className=''>
                <div className='box0'>
                    <div className='col0 col1'>
                        <div className='row0 row2' style={{ alignItems : 'center', justifyContent: 'center', width : '100%'}}>

                        <div className='cursor_pointer today_button0'
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setIntStateYear1(parseInt(moment().format("YYYY")));
                                    setIntStateMonth1(parseInt(moment().format("MM")));

                                    var stringFirstDay =  "" + (parseInt(intStateMonth1) ).toString();
                                    if (parseInt(intStateMonth1) - 1 < 10 ) {
                                        stringFirstDay = "0" + (parseInt(intStateMonth1) ).toString()
                                    }

                                    var sendSubQuery = `&search_start_date=${(parseInt(moment().format("YYYY")) ).toString() + "-" + stringFirstDay + "-01"}&search_end_date=${(parseInt(moment().format("YYYY"))).toString() + "-" + stringFirstDay + "-31"}`;
                                    // props && props.handleLoadData(
                                    //     null, 
                                    //     null,
                                    //     null,
                                    //     999999,
                                    //    1,
                                    //    sendSubQuery
                                    // )


                                }}
                        >오늘</div>
                        <img className='arrow0 arrow1' 
                        alt=''
                         src="/static/images/ssokdak/Tab2/arrowLeft.png"
                        
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            if (intStateMonth1 === 1) {
                                setIntStateYear1(parseInt(intStateYear1) - 1);
                                setIntStateMonth1(12)
                                 
                            } else {
                                setIntStateMonth1(parseInt(intStateMonth1) - 1)

                                var stringFirstDay =  "" + (parseInt(intStateMonth1) - 1).toString();
                                if (parseInt(intStateMonth1) - 1 < 10 ) {
                                    stringFirstDay = "0" + (parseInt(intStateMonth1) - 1).toString()
                                }
 

                            }
                        }}
                        />
                            <div className='text0 text2'>
                                <span className='text2_2'>{intStateYear1}년</span>
                                &nbsp;
                                &nbsp;
                                <span className='text2_1'>{intStateMonth1}월</span>
                            </div>
                            <img   className='arrow0 arrow2' 
                         src="/static/images/ssokdak/Tab2/arrowRight.png"
                            alt=''
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                if (intStateMonth1 == 12 ) {
                                    setIntStateYear1(parseInt(intStateYear1)+ 1);
                                    setIntStateMonth1(1)

                                    var stringFirstDay =  "" + (parseInt(intStateMonth1) + 1).toString();
                                    if (parseInt(intStateMonth1) - 1 < 10 ) {
                                        stringFirstDay = "0" + (parseInt(intStateMonth1) + 1).toString()
                                    }


                                } else {

                                    setIntStateMonth1(parseInt(intStateMonth1) +  1)


                                    var stringFirstDay =  "" + (parseInt(intStateMonth1) + 1).toString();
                                    if (parseInt(intStateMonth1) - 1 < 10 ) {
                                        stringFirstDay = "0" + (parseInt(intStateMonth1) + 1).toString()
                                    }



                                }
                            }}
                            />

                        </div>
                        <div className='row0 row2'>

                        </div>
                        <div className='tablerow0 tablerow1'>
                            <div className='tt0 tt1'  style={{ color : `#FF7A7A`}} >S</div>
                            <div className='tt0 tt2'>M</div>
                            <div className='tt0 tt3'>T</div>
                            <div className='tt0 tt4'>W</div>
                            <div className='tt0 tt5'>T</div>
                            <div className='tt0 tt6'>F</div>
                            <div className='tt0 tt7' style={{ color : `#9F8FFF`}} >S</div>
                        </div>
                        {
                            Array.from([
                                "","","","","",""
                            ])
                            .map((mapItem, mapIndex)=> {
                                var stringFirstDay =  "" + intStateMonth1;
                                if (intStateMonth1 < 10 ) {
                                    stringFirstDay = "0" + intStateMonth1
                                }
                                const firstDayStr1 = getDayInt(`${intStateYear1}-${stringFirstDay}-01`);
                                const lastDay1 = new Date(intStateYear1, intStateMonth1, 0).getDate();
                                const returnDayIntValue1 =  7 * mapIndex + 0 - firstDayStr1 + 1;
                                const isCurrentMonth = moment().format("YYYY-MM") === ("" + intStateYear1 + "-" + stringFirstDay) ? true : false;

                                // 이전달 처리
                                var lastDay2 = new Date(intStateYear1, intStateMonth1 - 1, 0).getDate();
                                if (intStateMonth1 == 1) {
                                     lastDay2 = new Date(intStateYear1 - 1, 12, 0).getDate();
                                } else {

                                }
                                // 다음달 처리
                                var lastDay3 = new Date(intStateYear1, intStateMonth1 + 1, 0).getDate();
                                if (intStateMonth1 == 12) {
                                    lastDay3 = new Date(intStateYear1 + 1, 1, 0).getDate();
                                } else {

                                }
                                return (
                                    <div className='tablerow0 tablerow2' >
                                        {
                                            Array.from([
                                                1,2,3,4,5,6,7
                                            ]).map((colItem, colIndex)=> {
                                                const returnDayValue1 = 
                                                    returnDayIntValue1 + colItem <= 0 
                                                    ? (returnDayIntValue1 + colItem) 
                                                    : (returnDayIntValue1 + colItem) <= lastDay1 
                                                        ? (returnDayIntValue1 + colItem)  
                                                        : (returnDayIntValue1 + colItem) ;
                                                const returnDayValue1_Valid = 
                                                    returnDayIntValue1 + colItem <= 0 
                                                    ? -1
                                                    : (returnDayIntValue1 + colItem) <= lastDay1 
                                                        ? (returnDayIntValue1 + colItem)  
                                                        : -1 ;

                                                var returnZeroPaddingValue = returnDayValue1;
                                                if (returnDayValue1 < 10) {
                                                    returnZeroPaddingValue = "0" + returnDayValue1;
                                                }
                                                const isBeforeMonthDiv =  returnDayIntValue1 + colItem <= 0  ? true : false
                                                const isAfterMonthDiv =   (returnDayIntValue1 + colItem) <= lastDay1 ? false  : true
                                                
                                                const dateFiltered1 = [];
                                                const dateFiltered2 = [];
                                                const dateFiltered3 = [];

                                                const startAt1 = true;
                                                const endAt1 = false;
                                                const labelAt1 = false;

                                                // 공휴일 판단
                                                var stringFirstDay =  "" + intStateMonth1;
                                                if (intStateMonth1 < 10 ) {
                                                    stringFirstDay = "0" + intStateMonth1
                                                }
                                                var dayLoc1 = intStateYear1 + "" + stringFirstDay + "" + returnZeroPaddingValue
                                                var isRestDay = false;
                                                const findIndex1 = arrayRestDay.findIndex((findItem1, findIndex1)=> {
                                                    if (findItem1.locdate == dayLoc1) {
                                                        return findItem1
                                                    }
                                                })
                                                if (findIndex1 > -1) {
                                                    isRestDay = true;
                                                }
             
                                                var emoArray = props && props.data;
                                                var dataTimeString1 = intStateYear1 + "" + intStateMonth1 + "" + returnDayValue1;
                                                if (isBeforeMonthDiv && !isAfterMonthDiv && (lastDay2 + returnDayValue1)) {
                                                    dataTimeString1 = moment(
                                                        intStateYear1 + "-" + (intStateMonth1) + "-" + returnDayValue1, 
                                                        "YYYY-MM-01",
                                                        )
                                                        .add("month", -1)
                                                        .format("YYYY-MM")
                                                          + "-" + (lastDay2 + returnDayValue1);

                                                          console.log("dataTimeString1 - 000", dataTimeString1)
                                                }
                                                if (!isBeforeMonthDiv && !isAfterMonthDiv && returnDayValue1) {
                                                    dataTimeString1 = moment(
                                                        intStateYear1 + "-" + (intStateMonth1) + "-" + returnDayValue1, 
                                                        "YYYY-MM-DD",
                                                        )
                                                        .add("month", 0)
                                                        .format("YYYY-MM-DD");
                                                        

                                                        console.log("dataTimeString1 - 111", dataTimeString1)
                                                }
                                                if (!isBeforeMonthDiv && isAfterMonthDiv && (returnDayValue1 - lastDay1)) {
                                                    
                                                    var returnZeroPaddingValue = (returnDayValue1 - lastDay1);
                                                    if ((returnDayValue1 - lastDay1) < 10) {
                                                        returnZeroPaddingValue = "0" + (returnDayValue1 - lastDay1);
                                                    }
                                                    dataTimeString1 = moment(
                                                        intStateYear1 + "-" + (intStateMonth1 ) + "-" + returnDayValue1, 
                                                        "YYYY-MM-01",
                                                        )
                                                        .add("month", 1)
                                                        .format("YYYY-MM")
                                                         + "-" + returnZeroPaddingValue;
                                                    
                                                    console.log("dataTimeString1 - 222", dataTimeString1)
                                                }

                                                var arrayData = props && props.data;
                                                var filterData = [];
                                                if (arrayData && arrayData.length > 0) {
                                                    filterData = arrayData && arrayData.filter((filterItem, filterIndex)=> {
                                                        if (filterItem.created_day === dataTimeString1) {
                                                            return filterItem
                                                        }
                                                    });
                                                }
                                                
                                                // console.log("dataTimeString1", dataTimeString1)
                                                // console.log("filterData", filterData)
                                                


                                                return (
                                                    <div 
                                                    className={'td0 td' + colItem}
                                                    style={{ 
                                                        background :isBeforeMonthDiv || isAfterMonthDiv ? `#F9FAFB` : `#ffffff` ,
                                                    }}
                                                    onClick={e=> {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (scheduleA[returnDayValue1_Valid] && scheduleA[returnDayValue1_Valid].length > 0) {

                                                            // dispatch({
                                                            //     type: "POPUP_ITEM_SET",
                                                            //     payload: "CALENDAROPTION_POPUP", 
                                                            //     data : {
                                                            //         label : stringFirstDay + "월 " + returnDayValue1 + "일 " +  getDayString(`${intStateYear1}-${stringFirstDay}-${returnZeroPaddingValue}`),  // `${intStateYear1}-${stringFirstDay}-` + returnZeroPaddingValue,
                                                            //         data : scheduleA[returnDayValue1_Valid] // [0].data,
                                                            //     }, 
                                                            // });
                                                        }
                                                        var colItem1 = 1 //colItem
                                                        var returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
 
                                                        var returnDayValue1_Valid_11 = 
                                                            returnDayIntValue1 + colItem1 <= 0 
                                                            ? -1
                                                            : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                                ? (returnDayIntValue1 + colItem1)  
                                                                : -1 ;
        
                                                        var returnZeroPaddingValue = returnDayValue1;
                                                        if (returnDayValue1 < 10) {
                                                            returnZeroPaddingValue = "0" + returnDayValue1;
                                                        }

                                                        var arrWeek1 = [];
                                                        colItem1 = 1;
                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }


                                                        colItem1 = 2;

                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }

                                                        colItem1 = 3;

                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }

                                                        colItem1 = 4;

                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }

                                                        colItem1 = 5;
                                                        
                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }

                                                        colItem1 = 6;
                                                        
                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }

                                                        colItem1 = 7;
                                                        
                                                        returnDayValue1_11 = 
                                                        returnDayIntValue1 + colItem1 <= 0 
                                                        ? (returnDayIntValue1 + colItem1) 
                                                        : (returnDayIntValue1 + colItem1) <= lastDay1 
                                                            ? (returnDayIntValue1 + colItem1)  
                                                            : (returnDayIntValue1 + colItem1) ;
                                                        var isBeforeMonthDiv_11 =  returnDayIntValue1 + colItem1 <= 0  ? true : false
                                                        var isAfterMonthDiv_11 =   (returnDayIntValue1 + colItem1) <= lastDay1 ? false  : true
                                                        if (isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push((lastDay2 + returnDayValue1_11));
                                                        }
                                                        if (!isBeforeMonthDiv_11 && !isAfterMonthDiv_11) {
                                                            arrWeek1.push(returnDayValue1_11);
                                                        }
                                                        if (!isBeforeMonthDiv_11 && isAfterMonthDiv_11) {
                                                            arrWeek1.push((returnDayValue1_11 - lastDay1));
                                                        }
                                                        
                                                        // 
                                                        // if (isBeforeMonthDiv && !isAfterMonthDiv ) {
                                                        //     props && props.callBack1 && props.callBack1("", isBeforeMonthDiv,!isAfterMonthDiv , intStateYear1, intStateMonth1, (lastDay2 + returnDayValue1), colIndex, returnZeroPaddingValue, arrWeek1);
                                                        // }
                                                        // if (!isBeforeMonthDiv && !isAfterMonthDiv) {
                                                        //     props && props.callBack1 && props.callBack1("",!isBeforeMonthDiv , !isAfterMonthDiv,  intStateYear1, intStateMonth1, returnDayValue1, colIndex, returnZeroPaddingValue, arrWeek1);
                                                        // }
                                                        // if (!isBeforeMonthDiv && isAfterMonthDiv ) {   
                                                        //     props && props.callBack1 && props.callBack1("",!isBeforeMonthDiv , isAfterMonthDiv , intStateYear1, intStateMonth1, (returnDayValue1 - lastDay1), colIndex, returnZeroPaddingValue, arrWeek1);
                                                        // }
                                                    }}
                                                    >
                                                        <img 
                                                            className="emoImage0 emoImage1" 
                                                            // src={`/static/images/ssokdak/Tab2/emoEmpty.svg`} 
                                                            src={
                                                                filterData && filterData.length > 0  
                                                                ? `` + filterData[0].emotionObject.iconOnlyPathName
                                                                : `/static/images/ssokdak/Tab2/emoEmpty.svg`
                                                            }
                                                            style={{
                                                                width : `34px`,
                                                                height : `34px`,
                                                                objectFit : 'contain'
                                                            }}
                                                            alt=""
                                                            onClick={e=> {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                console.log("dataTimeString1 - ", dataTimeString1)
                                                                props && props.callBack1 && props.callBack1(dataTimeString1);
                                                            }}
                                                        />
                                                        <div 
                                                            className='num_container' 
                                                            style={{ 
                                                                background : (isCurrentMonth && moment().format("DD") === returnZeroPaddingValue) ? `#FF7A7A` : `transparent`, 
                                                                // 공휴일 판단 로직
                                                                color : 
                                                                    (isCurrentMonth && moment().format("DD") === returnZeroPaddingValue) 
                                                                    ? "#ffffff"
                                                                    : (isBeforeMonthDiv || isAfterMonthDiv ? `#ADB5BD` : (colIndex === 0 || (!isBeforeMonthDiv && !isAfterMonthDiv && returnDayValue1 && isRestDay))? `#FF6666` : colIndex ===6 ? `#557CF2` :`#000000`),
                                                                // border : `none`,
                                                                width : `32px`,
                                                                height: `24px`,
                                                            }}
                                                            onClick={e=> {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                console.log("dataTimeString1 - ", dataTimeString1)
                                                                props && props.callBack1 && props.callBack1(dataTimeString1);
                                                            }}
                                                        >
                                                            {isBeforeMonthDiv && !isAfterMonthDiv && (lastDay2 + returnDayValue1)} 
                                                            {!isBeforeMonthDiv && !isAfterMonthDiv && returnDayValue1} 
                                                            {!isBeforeMonthDiv && isAfterMonthDiv && (returnDayValue1 - lastDay1)} 
                                                        </div>

                                                    </div>

                                                )
                                            })
                                        }
                                </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </This>
    ) 
};

export default Comp;
