
import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./a03.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
 
const pageCode1 =  'page000/1/find-pw';
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [boolPopup1, setBoolPopup1 ] = useState(false);
    const [boolPopup2, setBoolPopup2 ] = useState(false);
    
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`a03_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          <div className="tab_wrapper0 tab_wrapper1">
            {/* #181818,  #DEE2E6; */}
            <div 
              className="tab_col0 tab_col1"
              style={{ 
                color : selectedTabIndex == 0 ? '#181818' : '#FF7A7A',
                borderBottom : selectedTabIndex == 0 ? '2px solid #181818' : '2px solid #DEE2E6',
              }}
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setSelectedTabIndex(0);
                
              }}
            >아이디 찾기</div>
            <div className="tab_col0 tab_col2"
            
            style={{ 
              color : selectedTabIndex == 1 ? '#181818' : '#FF7A7A',
              borderBottom : selectedTabIndex == 1 ? '2px solid #181818' : '2px solid #DEE2E6',
            }}
            onClick={e=> {
              e.preventDefault();
              e.stopPropagation();
              setSelectedTabIndex(1);
            }}
            >비밀번호 찾기</div>
          </div>
        </div>
      </>
      
    );
}
