
import React, { Component, useState, useEffect, useRef, useContext} from 'react';
import styled from 'styled-components';
import useWindowSize from './useWindowSize';

const This = styled.div`
    position: relative;
    padding: none;

    .dropbtn {
        width: 100%;
        color: white;
        font-size: 16px;
        border: none;
        color : #FF7A7A;

        font-size: 14px;
        letter-spacing: -0.1px;
        line-height: 23px;
        background : transparent;
    }
    .dropbtn:hover, .dropbtn:focus {
        
    }
    .dropdown {
        width: 100%;
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        position: absolute;
        min-width: calc(100% + 4px);

        overflow: auto;
        z-index: 40;

    }

    .dropdown-content a {
        color: black;
        text-decoration: none;
        display: block;

        width: 100%;
        white-space: nowrap;

    }

    .dropdown-content-text1 {
        color : #FF7A7A;
        font-size : 14px;
        margin-bottom : 10px;
    }
    .dropdown-content-text2 {
        color : #868E96;
        font-size : 12px;
        margin-top: 4px;
        margin-bottom: 4px;
    }

`;

  
// {props.value}
// {props.options}

const Comp = (props) => { 
    const { children} = props; 
    const [boolShowOption, setBoolShowOption] = useState(false);
    const [data, setData] = useState([]);
    
    const size = useWindowSize();
    
    const wrapperRef = useRef(null);
    const dropDownBtnRef = useRef(null);
    const myDropdownRef = useRef(null);


    useOutsideAlerter(wrapperRef);
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // alert("You clicked outside of me!");
                    setBoolShowOption(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

//     parentControl
// parentDropdownState
// parentHandleDropdown

    useEffect(()=> {
        if (props && props.parentControl && props.parentControl == true) {
            setBoolShowOption(props.parentDropdownState)
        } 

    }, [props])

    useEffect(()=> {
            setBoolShowOption(props.parentDropdownState)
    }, [props.parentDropdownState])


    return (
        <This  {...props}>
        <div className="dropdown" ref={wrapperRef}
        >
            <button 
                ref={dropDownBtnRef}
                id="dropDownBtn"
                className="dropbtn " 
                // disabled={}
                onClick={(e)=> {
                    e.preventDefault();
                    e.stopPropagation();

                    // myFunction();
                    console.log("props.parentDropdownState", props.parentDropdownState)
                    // if (props && props.parentControl && props.parentControl == true) {
                    //     // setBoolShowOption(props.parentDropdownState)
                    // } else {
                    //     setBoolShowOption(!boolShowOption);
                    // }

                    // if (props && props.parentControl && props.parentControl == true) {
                    //     if (props.parentDropdownState == true) {

                    //         setBoolShowOption(!boolShowOption);
                    //     }
                    // } else {

                    //     setBoolShowOption(!boolShowOption);
                    // }
                    // 
                    if (props.parentDropBlock === true) {
                    } else {
                        setBoolShowOption(!boolShowOption);
                    }
                }}
            >
                {props.value}
            </button>
            <div 
                ref={myDropdownRef}
                id="myDropdown" 
                className="dropdown-content"
                style={{ 
                    display: boolShowOption === true ? 'block' : 'none' , 
                    zIndex : 99,
                    marginLeft : props.marginRightFix === true ? '-150px' : '0px'
                }}
                onClick={()=> {
                    // myFunction();
                    setBoolShowOption(!boolShowOption);
                }}
            >
                
                {props.options}
            </div>
        </div>
        <style>{`
        `}</style>
        </This>
    ) 
};

export default Comp;
