import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./index.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""

const tabInfoArr = [
  {
    id : 1,
    icon_path_default : appImages._tabC001,
    icon_path_active  : appImages._tabC011,
    name_kr : '피드',
    name_en : '',
    page_code : 'page001/0/home',
    href : '',
  },
  {
    id : 3,
    icon_path_default : appImages._tabC002,
    icon_path_active  : appImages._tabC012,
    name_kr : '마음기록',
    name_en : '',
    page_code : 'page002/0/mind-diary',
    href : '',
  },
  {
    id : 4,
    icon_path_default : appImages._tabC003,
    icon_path_active  : appImages._tabC013,
    name_kr : '마음분석',
    name_en : '',
    page_code : 'page003/0/mind-analysis',
    href : '',
  },
  {
    id : 4,
    icon_path_default : appImages._tabC004,
    icon_path_active  : appImages._tabC014,
    name_kr : '커뮤니티',
    name_en : '',
    page_code : 'page004/998/chat-list',
    href : '',
  },
  {
    id : 5,
    icon_path_default : appImages._tabC005,
    icon_path_active  : appImages._tabC015,
    name_kr : '설정',
    name_en : '',
    page_code : 'page005/0/mypage',
    href : '',
  },
];
// 
// <div className={``}></div>
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }

    return (
      <div className={`tab_container_b`}>
        <div className={`tab_row0 tab_row1`}>
          {
            Array.from([...tabInfoArr]).map((tabItem, tabIndex)=> {
              return (
                <div className={`tab_col0 tab_col` + (tabIndex + 1)} >
                  <img 
                    className={`image1_0 image1_1`} 
                    src={tabItem.page_code === viewScreenMode.mode ? 
                      tabItem.icon_path_active : 
                      tabItem.icon_path_default} 
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();

                      window.localStorage.setItem("selectedTab4", 1);
                      setScreenMode1(tabItem.page_code);
                    }}
                    alt=""
                  />
                  {/* <div className={`text1_0 text1_1`} 
                    style={{ color : tabItem.page_code === viewScreenMode.mode ? "#181818" : "#FF7A7A"}}
                  >

                    {tabItem.name_kr}
                  </div> */}
                </div>
              )
            })
          }
        </div>
      </div>
    );
}
