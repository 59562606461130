import React, { Component, useEffect, useState, useContext, useRef } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { QrReader } from 'react-qr-reader';
import parse from "html-react-parser";
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../../redux/authRedux/auth.reducer";
import { setAuth } from "../../../redux/authRedux/auth.actions";
import { category_KEY } from "../../../redux/categoryRedux/category.reducer";
import { setcategory } from "../../../redux/categoryRedux/category.actions";
import { filter_KEY } from "../../../redux/filterRedux/filter.reducer";
import { setfilter } from "../../../redux/filterRedux/filter.actions";

import uuid from 'react-uuid';
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import TabBottom from "../../TabBottom/Tab";

import DropDownClickable from "../../../components/DropdownClickable";
import imageCompression from "browser-image-compression";


import QuillWrapper from "react-quill";
import 'react-quill/dist/quill.snow.css';
import "./aa01.css";

const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'camera'],
    // [
    //   { list: 'ordered' },
    //   { list: 'bullet' },
    //   { indent: '-1' },
    //   { indent: '+1' },
    // ],
    // // ['link', 'image', 'video'],
    // // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link',
  'image',
  // 'camera',
  // 'video',
]

const emoCardArray = [
  {
    index : 1,
    codeName : 'Happy',
    krName : '행복해요',
    iconCardPathName :  appImages.EmotionsCard08,
    iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Happy, Size=XL.svg`,
    colorCode : `#FFD7D7`,
},
  {
    index : 2,
      codeName : 'Excited',
      krName : '기뻐요',
      iconCardPathName :  appImages.EmotionsCard07,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Excited, Size=XL.svg`,
      colorCode : `#FFCCBC`,
  },
  {
    index : 3,
      codeName : 'Love',
      krName : '설레요',
      iconCardPathName :  appImages.EmotionsCard10,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Love, Size=XL.svg`,
      colorCode : `#FFC6E1`,
      
  },
  
  {
    index : 4,
      codeName : 'Comfy',
      krName : '편안해요',
      iconCardPathName :  appImages.EmotionsCard05,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Comfy, Size=XL.svg`,
      colorCode : `#FED2AA`,
  },
  {
    index : 5,
      codeName : 'Surprise',
      krName : '놀랐어요',
      iconCardPathName :  appImages.EmotionsCard14,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Surprise, Size=XL.svg`,
      colorCode : `#D8F1A2`,
  },
  {
    index : 6,
      codeName : 'Soso',
      krName : '그저 그래요',
      iconCardPathName :  appImages.EmotionsCard13,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Soso, Size=XL.svg`,
      colorCode : `#FDE9B5`,
  },
  
  {
    index : 7,
      codeName : 'Tired',
      krName : '힘들어요',
      iconCardPathName :  appImages.EmotionsCard15,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Tired, Size=XL.svg`,
      colorCode : `#DBB8EC`,
  },
  
  {
    index : 8,
      codeName : 'Depress',
      krName : '우울해요',
      iconCardPathName :  appImages.EmotionsCard06,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Depress, Size=XL.svg`,
      colorCode : `#D2D3DC`,
  },
  
  {
    index : 9,
      codeName : 'Sad',
      krName : '슬퍼요',
      iconCardPathName :  appImages.EmotionsCard11,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Sad, Size=XL.svg`,
      colorCode : `#C6E7FF`,
  },
  {
    index : 10,
      codeName : 'Angry',
      krName : '화나요',
      iconCardPathName : appImages.EmotionsCard01,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Angry, Size=XL.svg`,
      colorCode : `#FF9F9F`,
  },
  {
    index : 11,
      codeName : 'Bored',
      krName : '지루해요',
      iconCardPathName :  appImages.EmotionsCard04,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Bored, Size=XL.svg`,
      colorCode : `#A8F4F4`,
  },
  {
    index : 12,
      codeName : 'Annoyed',
      krName : '짜증나요',
      iconCardPathName :  appImages.EmotionsCard02,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Annoyed, Size=XL.svg`,
      colorCode : `#FFAD93`,
  },
  {
    index : 13,
      codeName : 'Lonely',
      krName : '외로워요',
      iconCardPathName :  appImages.EmotionsCard09,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Lonely, Size=XL.svg`,
      colorCode : `#CDF3FF`,
  },
  
  {
    index : 14,
      codeName : 'Scared',
      krName : '무서워요',
      iconCardPathName :  appImages.EmotionsCard12,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Scared, Size=XL.svg`,
      colorCode : `#C1CFFF`,
  },
  
  {
    index : 15,
      codeName : 'Anxious',
      krName : '불안해요',
      iconPath :  appImages.EmotionsCard03,
      iconOnlyPathName : `/static/images/ssokdak/Emotions/State=Anxious, Size=XL.svg`,
      colorCode : `#D4CABA`,
  },
  
  
]


const pageCode1 =   'page002/1/mind-diary-checkin';

// <div className={``}></div>
export default function ThisComponent(props) {
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textUploadId1, setTextUploadId1] = useState(moment().format("YYYYMMDDHHmmssms"))

    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);
    const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
    const [objectArrayFeedData1, setObjectArrayFeedData1] = useState([]);

    const [selectedFilter1, setSelectedFilter1] = useState(
      {
        label : "전체", 
        index: 1,
      },);
    const [boolTapped1, setBoolTapped1] = useState(false);

    const [currentCardsData, setCurrentCardsData] = useState([]);
    const [data, setData] = useState('No result');
    const [qrMode, setQrMode] = useState("false");
    const [url, setUrl] = useState("");

  const [arraySelected, setArraySelected] = useState([]);

  const [clickedIndex, setClickedIndex] = useState(-1);
  const [pageStep, setPageStep] = useState(1);

  const [textValue1, setTextValue1] = useState([]);
  const [textValue2, setTextValue2] = useState([]);
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const [boolPopup1, setBoolPopup1] = useState(false);
  const [boolPopup2, setBoolPopup2] = useState(false);
  const [boolPopup3, setBoolPopup3] = useState(false);

  const [quillValue1, setQuillValue1] = useState();

  const imageRef0 = useRef();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const imageRef6 = useRef();
  const imageRef7 = useRef();
  const imageRef8 = useRef();
  const imageRef9 = useRef();
  const imageRef10 = useRef();

  const [imageUpload0, setImageUpload0] = useState('');
  const [imageUpload1, setImageUpload1] = useState('');
  const [imageUpload2, setImageUpload2] = useState('');
  const [imageUpload3, setImageUpload3] = useState('');
  const [imageUpload4, setImageUpload4] = useState('');
  const [imageUpload5, setImageUpload5] = useState('');
  const [imageUpload6, setImageUpload6] = useState('');
  const [imageUpload7, setImageUpload7] = useState('');
  const [imageUpload8, setImageUpload8] = useState('');
  const [imageUpload9, setImageUpload9] = useState('');
  const [imageUpload10, setImageUpload10] = useState('');
  
  const [imageLink0, setImageLink0] = useState('');
  const [imageLink1, setImageLink1] = useState('');
  const [imageLink2, setImageLink2] = useState('');
  const [imageLink3, setImageLink3] = useState('');
  const [imageLink4, setImageLink4] = useState('');
  const [imageLink5, setImageLink5] = useState('');
  const [imageLink6, setImageLink6] = useState('');
  const [imageLink7, setImageLink7] = useState('');
  const [imageLink8, setImageLink8] = useState('');
  const [imageLink9, setImageLink9] = useState('');
  const [imageLink10, setImageLink10] = useState('');


  const [intUploadedNumber1, setIntUploadedNumber1] = useState(0);
  const upload = (paramIndex, paramUpload)=>{

    if (viewScreenMode.mode !== pageCode1) {
      return;
    }

    var returnName1 = `${paramUpload.name}_${moment().format("YYYYMMDD_HHmmss")}`;
    if(paramUpload == null)
      return;
      const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(paramUpload)
      uploadTask
      .on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        // switch (snapshot.state) {
        //   case 'paused':
        //     console.log('Upload is paused');
        //     break;
        //   case 'running':
        //     console.log('Upload is running');
        //     break;
        // }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        fireDb.storage().ref('images').child(returnName1).getDownloadURL()
         .then(fireBaseUrl => {
          //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
          console.log("fireBaseUrl", fireBaseUrl) 

          if (paramIndex === 0) {
            setImageLink0(fireBaseUrl);
            // 
            Firebase.database().ref()
            .child("behaviors/diary-checkins/" + textUploadId1).update({"avatar" : fireBaseUrl});
          } 
          if (paramIndex === 1) {
            setImageLink1(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
          if (paramIndex === 2) {
            setImageLink2(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
          }
          if (paramIndex === 3) {
            setImageLink3(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
          }
          if (paramIndex === 4) {
            setImageLink4(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
          }
          if (paramIndex === 5) {
            setImageLink5(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
          if (paramIndex === 6) {
            setImageLink6(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
          if (paramIndex === 7) {
            setImageLink7(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1+ "/photos").update(tempArr);
          }
          if (paramIndex === 8) {
            setImageLink8(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
          if (paramIndex === 9) {
            setImageLink9(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
          if (paramIndex === 10) {
            setImageLink10(fireBaseUrl);
            // var tempArr = [];
            // if (objectUserData.photos && objectUserData.photos.length > paramIndex) {
            //   tempArr = [...objectUserData.photos]
            //   tempArr[paramIndex - 1] = fireBaseUrl
            // } else {
            //   tempArr = [fireBaseUrl]
            // }
            // Firebase.database().ref()
            // .child("behaviors/diary-checkins/" + textUploadId1 + "/photos").update(tempArr);
          }
         })
      }
    );

  }



  const actionImgCompress = async (paramIndex, fileSrc, type) => {
    console.log("압축 시작");

    const options = {
      maxSizeMB: 0.10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      // 압축 결과
      const compressedFile = await imageCompression(fileSrc, options);
      upload(paramIndex, compressedFile)

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=> {
    const index1 = 0;
    actionImgCompress(0, imageUpload0);
    // checkImageNumber();
  },[
    imageUpload0,
  ])

  useEffect(()=> {
    const index1 = 1;
    actionImgCompress(index1, imageUpload1);
    checkImageNumber();
  },[
    imageUpload1,
  ])
  useEffect(()=> {
    const index1 = 2;
    actionImgCompress(index1, imageUpload2);
    checkImageNumber();
  },[
    imageUpload2,
  ])
  useEffect(()=> {
    const index1 = 3;
    actionImgCompress(index1, imageUpload3);
    checkImageNumber();
  },[
    imageUpload3,
  ])

  useEffect(()=> {
    const index1 = 4;
    actionImgCompress(index1, imageUpload4);
    checkImageNumber();
  },[
    imageUpload4,
  ])
  useEffect(()=> {
    const index1 = 5;
    actionImgCompress(index1, imageUpload5);
    checkImageNumber();
  },[
    imageUpload5,
  ])
  useEffect(()=> {
    const index1 = 6;
    actionImgCompress(index1, imageUpload6);
    checkImageNumber();
  },[
    imageUpload6,
  ])
  useEffect(()=> {
    const index1 = 7;
    actionImgCompress(index1, imageUpload7);
    checkImageNumber();
  },[
    imageUpload7,
  ])

  useEffect(()=> {
    const index1 = 8;
    actionImgCompress(index1, imageUpload8);
    checkImageNumber();
  },[
    imageUpload8,
  ])
  useEffect(()=> {
    const index1 = 9;
    actionImgCompress(index1, imageUpload9);
    checkImageNumber();
  },[
    imageUpload9,
  ])
  useEffect(()=> {
    const index1 = 10;
    actionImgCompress(index1, imageUpload10);
    checkImageNumber();
  },[imageUpload10,])

  function checkImageNumber() {
    var checkIndex1 = 0;
    if (imageUpload1) {
      checkIndex1 ++;
    }
    if (imageUpload2) {
      checkIndex1 ++;
    }
    if (imageUpload3) {
      checkIndex1 ++;
    }
    if (imageUpload4) {
      checkIndex1 ++;
    }
    if (imageUpload5) {
      checkIndex1 ++;
    }
    if (imageUpload6) {
      checkIndex1 ++;
    }
    if (imageUpload7) {
      checkIndex1 ++;
    }
    if (imageUpload8) {
      checkIndex1 ++;
    }
    if (imageUpload9) {
      checkIndex1 ++;
    }
    if (imageUpload10) {
      checkIndex1 ++;
    }
    setIntUploadedNumber1(checkIndex1)

  }


  const resizeTextArea = () => {
    if (textAreaRef1 && textAreaRef1.current && textAreaRef1.current.style) {

      textAreaRef1.current.style.height = "auto";
      textAreaRef1.current.style.height = textAreaRef1.current.scrollHeight + "px";
  
    }
    if (textAreaRef2 && textAreaRef2.current && textAreaRef2.current.style) {

      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + "px";
    }
  };

    const downloadQRCode = (e) => {
      e.preventDefault();
      setUrl("");
    };
  
    const qrCodeEncoder = (e) => {
      setUrl(e.target.value);
    };
  
    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let authStore1 = useSelector((state) =>{
      return state[AUTH_KEY]
    })


    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }


    let setCategory1 = (param) => {
      dispatch(setcategory(param))
    }
    let setFilter1 = (param) => {
      dispatch(setfilter(param))
    }


    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    const qrcode = (
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={50}
        bgColor={"#fff"}
        level={"H"}
      />
    );

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {

      setTextValue1("");
      setTextValue2("");
      setBoolPopup1(false);
      setQuillValue1();

      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

      
      if (viewScreenMode.mode !== pageCode1) {
        return;
      }
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore")
      if (true && true) {
        const parseAuthStore1 = JSON.parse(authStore1)
        console.log("parseAuthStore1", parseAuthStore1);

        Firebase.database().ref()
        .child("behaviors/posts")
        // .orderByChild("random_id16")
        // .equalTo(currentCardItem)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/posts --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setObjectArrayCardData1(Object.values(data))

              const indexArr1  = Object.values(data);
              setCurrentCardsData(indexArr1);
            }
          }
        });


        Firebase.database().ref()
        .child("behaviors/feeds")
        // .orderByChild("random_id16")
        // .equalTo(currentCardItem)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            console.log("behaviors/feeds --- data", data)

            if (data && Object.values(data) &&  Object.values(data).length > 0) {
              setObjectArrayFeedData1(Object.values(data))

              const indexArr1  = Object.values(data);
              setCurrentCardsData(indexArr1);
            }
          }
        });

        Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(authStoreUserName)
            .once("value", (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    console.log("CHECKCHECK - 001", );

                    // console.log("[테스트] >> snapshot.val()", )
                    // console.log("[테스트] >> data", data)
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) { 
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                        setObjectUserData(data[keyName]);
                        setObjectUserKeyname(keyName)
                        props && props.handleUser(keyName, data[keyName]);
                        
                        setUrl("everappy@nickname:" + data[keyName].nickname)

                        // setObjectArrayCardData1
                        // setObjectArrayFolderData1
                        // setObjectArrayGameData1
                        // if (data[keyName].cards) {
                        //   setObjectArrayCardData1([...data[keyName].cards])
                        // } else {
                        //   setObjectArrayCardData1([])
                        // }
                     
                    } else {

                    }

                }  else {
                    // alert("존재하지 않는 회원입니다.");
                }
            });



      }

    }, [useSelector(state => state)])



  
    function handleCreate() {


      console.log("handleCreate - 0", )

      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      
      const authStore1 = localStorage.getItem("authStore")

      setBoolPopup2(true);
      
      fireDb.database().ref().child("behaviors/diary-checkins/" + textUploadId1).update(
        {
          reason : textValue1,
          did : textValue2,
          contents : quillValue1 ? quillValue1 : '',
          pre_contents: quillValue1 ? quillValue1.replace(/<[^>]+>/g, '') : '',
          username : authStoreUserName,
          emotionKrName : emoCardArray[clickedIndex].krName,
          emotionObject : emoCardArray[clickedIndex],
          created_at : moment().format("YYYY-MM-DD HH:mm:ss"),
          created_day : moment().format("YYYY-MM-DD"),
          created_time : moment().format('hh:mm A'),

          imageLink1 : imageLink1, 
          imageLink2 : imageLink2, 
          imageLink3 : imageLink3, 
          imageLink4 : imageLink4, 
          imageLink5 : imageLink5, 
          imageLink6 : imageLink6, 
          imageLink7 : imageLink7, 
          imageLink8 : imageLink8, 
          imageLink9 : imageLink9, 
          imageLink10 : imageLink10, 
        }
        , (err) => {
        if (err) {
          // toast.error(err);
          console.log(err);
        } else {
          // toast.success("Signup  Successfully");

          // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

          setTimeout(()=> {
              setBoolPopup2(false);
              setTextValue1("")
              setTextValue2("")
              setPageStep(1);
              setQuillValue1();
              setImageLink0('')
              setImageLink1('')
              setImageLink2('')
              setImageLink3('')
              setImageLink4('')
              setImageLink5('')
              setImageLink6('')
              setImageLink7('')
              setImageLink8('')
              setImageLink9('')
              setImageLink10('')

              const pageCodeGo1 =  'page002/0/mind-diary';
              setScreenMode1(pageCodeGo1)
          }, 300)

          // alert("")
          
        }
      });

    
    }


    useEffect(()=> {

      if (pageStep === 6) {
        resizeTextArea();
      }

    }, [pageStep])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }
    // 렌더, render, 렌더-리턴, render-return
    return (
      <>
      {
        // qrMode == "false" &&
        <>
        <div className={`tap01_c`}> 
            {
                pageStep === 1 &&
                (
                
                <div className="checkInPageWrapper">

                <div className="checkInTopWrapper" >
                    <div className="checkInTopTopBar">
                        <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setScreenMode1( 'page002/0/mind-diary')
                            }}
                        />
                        <img className="checkInTopTopIcon2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                        
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                            setScreenMode1( 'page002/0/mind-diary')
                        }}
                        />
                    </div>
                  </div>
        
                  <div className="checkInLabelWrapper">
                    🔑 <br/>
                    오늘의 기분을<br/>
                    Check-In 해주세요 
                  </div>
                  <div className="checkInLabelEmoWrapper">
                    <div className="wrapDiv1">
                    {
                        Array.from(emoCardArray)
                        // .sort(function(a, b)  {
                        //   return a.index - b.index;
                        // })
                        .map((mapItem, mapIndex)=> {
                          return (
                            <div className="cardItem " 
                            >
                              {
                                 (mapIndex === clickedIndex )&&

                                 <div className="selectImg0">
                                 <img className="closeBtn" 
                                 src={`/static/images/ssokdak/Tab2/editSave1.svg`} alt=""
                                 style={{ marginLeft : 'auto'}}
                                 onClick={e=> {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     setScreenMode1( 'page002/0/mind-diary')
                                 }}
                                 />
                               </div>
                              }
                              <div className="cardImg0">
                                <img  src={mapItem.iconCardPathName} alt="" 
                                  style={{
                                    border : (mapIndex === clickedIndex )?  `1px solid #FF7A7A` : `1px solid #ffffff`,
                                    borderRadius : `10px`,
                                  }}
                                  onClick={e=> {
                                    setClickedIndex(mapIndex);
                                    setPageStep(2);
                                }}/>
                              </div>
                            </div>)
                        })
                      }
                    </div>
                   
                  </div>
                  </div>

                )
            }

            {
                pageStep >= 2 &&
                (
                    <div className="checkInPageWrapper">

                        <div className="checkInTopWrapper" >
                            <div className="checkInTopTopBar">
                                <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
                                    onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // setScreenMode1( 'page002/0/mind-diary')
                                        setPageStep(1);
                                    }}
                                />
                                <div className="checkInTopTopLabel1">
                                    {moment().format("YYYY년 MM월 DD일")}
                                </div>
                                <img className="checkInTopTopIcon2" src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                                
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setScreenMode1( 'page002/0/mind-diary')
                                }}
                                />
                                
                            </div>
                        </div>
                        <div className="checkInEmoPrevWrapper"
                          style={{
                            height : (pageStep >= 3 && pageStep <= 5) ? `0px` :`192px`
                          }}
                        >
                            {
                                clickedIndex > -1 &&
                                emoCardArray[clickedIndex] &&
                                <div className="iconWrapper1">
                                  <img className="icon1" src={emoCardArray[clickedIndex].iconOnlyPathName} alt=""/>
                                  <div className="row0 row1">
                                    <div className="iconKrName1">{`#`}{emoCardArray[clickedIndex].krName}</div>
                                    <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/editPencil.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setBoolPopup1(true);
                                        }}
                                    />
                                  </div>
                                </div>
                            }
                            <div></div>
                        </div>
                        <div className="checkInEmoWriteWrapper" 
                        
                        style={{
                          height : (pageStep >= 3 && pageStep <= 5) ? `calc(100vh - 50px)` :`calc(100vh - 250px)`,
                          borderRadius : (pageStep >= 3 && pageStep <= 5) ? `0px 0px 0px 0px` : `30px 30px 0px 0px`,
                        }}
                        >

                            <div className="editButtonRow">
                              {/* {
                                pageStep === 2 && 
                                <div className="editButtonDiv1">

                                    <img className="icon0 icon1" src={`/static/images/ssokdak/Tab2/edit1.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />
                                    <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/edit2.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />

                                    <img className="icon0 icon3" src={`/static/images/ssokdak/Tab2/edit3.svg`} alt=""
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setScreenMode1( 'page002/0/mind-diary')
                                            setPageStep(1);
                                        }}
                                    />
                                </div>
                              } */}
                                <div className="editStepDiv1">
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 2 || pageStep === 3) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 4 || pageStep === 5) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                    <div className="dot0 dot1 " style={{ background : (pageStep === 6 || pageStep === 7) ? '#FF7A7A' : '#e2e2e2' }}></div>
                                </div>
                            </div>
                            {
                              pageStep === 2 &&

                              <div className="editContentRow" 
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setPageStep(pageStep + 1);
                              }}
                              >
                              <div className="editRowStep1Wrapper">
                              {`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                               </div> 
                           </div>
                            }
                            {
                              pageStep === 3 &&
                              <div className="editContentRow">
                                <div className="editRowStep2Wrapper">
                                  <textarea 
                                    className="input0"
                                    value={textValue1}
                                    onChange={e=> {
                                      setTextValue1(e.target.value);
                                    }}
                                    placeholder={`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                                  />
                                </div>
                              </div>
                            }
                            {
                              pageStep === 4 &&

                              <div className="editContentRow" 
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                setPageStep(pageStep + 1);
                              }}
                              >
                              <div className="editRowStep1Wrapper">
                              {`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                               </div> 
                           </div>
                            }
                            {
                              pageStep === 5 &&
                              <div className="editContentRow">
                                <div className="editRowStep2Wrapper">
                                  <textarea 
                                    className="input0"
                                    value={textValue2}
                                    onChange={e=> {
                                      setTextValue2(e.target.value);
                                    }}
                                    placeholder={`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                                  />
                                </div>
                              </div>
                            }

                            { 
                              pageStep === 6 &&
                              <div className="editContentRow">
                                <div className="editRowStep3Wrapper">


                                  {/* TODO 사진 */}

                                  <div className="sectionLabel0 sectionLabel1">
                      {`업로드 이미지 (선택)`}
                  </div>
                  <div className="imagePlusRow0 imagePlusRow1">
                      
                    <div className="row0 row1">
      
      <input ref={imageRef0} 
              type="file" 
              onChange={(e)=>{
                
                setImageUpload0(e.target.files[0])
              }} 
              // accept="image/*"
               accept=".gif, .jpg, .png"
             // accept=".png"
              style={{display : 'none'}} alt="" />
              <button onClick={upload} style={{display : 'none'}}>Upload</button>
      
                  <input ref={imageRef1} 
                          type="file" 
                          onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          <input ref={imageRef2} 
                          type="file" 
                          onChange={(e)=>{setImageUpload2(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef3} 
                          type="file" 
                          onChange={(e)=>{setImageUpload3(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef4} 
                          type="file" 
                          onChange={(e)=>{setImageUpload4(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef5} 
                          type="file" 
                          onChange={(e)=>{setImageUpload5(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                          <input ref={imageRef6} 
                          type="file" 
                          onChange={(e)=>{setImageUpload6(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef7} 
                          type="file" 
                          onChange={(e)=>{setImageUpload7(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef8} 
                          type="file" 
                          onChange={(e)=>{setImageUpload8(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef9} 
                          type="file" 
                          onChange={(e)=>{setImageUpload9(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
                          <input ref={imageRef10} 
                          type="file" 
                          onChange={(e)=>{setImageUpload10(e.target.files[0])}} 
                          // accept="image/*"
                           accept=".gif, .jpg, .png"
                         // accept=".png"
                          style={{display : 'none'}} alt="" />
                          <button onClick={upload} style={{display : 'none'}}>Upload</button>
                          
      
                  <div className="radius001_image_row_wrapper">
      
                  <div className="radius001_image_wrapper" 
                          style={{ 
                            background: `rgba(33, 37, 41, 0.7)`,
                            border : `none` ,
                          }}
                        >
                          {
                            // imageUpload1 == '' &&
                            <>
                            <div className="radius001_0" 
                              onClick={e=> {
                              e.preventDefault();
                              e.stopPropagation();
                              if (imageUpload1) {
                              } else {
                                imageRef1.current.click();
                                return;
                              }
                              if (imageUpload2) {
                              } else {
                                imageRef2.current.click();
                                return;
                              }
                              if (imageUpload3) {
                              } else {
                                imageRef3.current.click();
                                return;
                              }
                              if (imageUpload4) {
                              } else {
                                imageRef4.current.click();
                                return;
                              }
                              if (imageUpload5) {
                              } else {
                                imageRef5.current.click();
                                return;
                              }
                              if (imageUpload6) {
                              } else {
                                imageRef6.current.click();
                                return;
                              }
                              if (imageUpload7) {
                              } else {
                                imageRef7.current.click();
                                return;
                              }
                              if (imageUpload8) {
                              } else {
                                imageRef8.current.click();
                                return;
                              }
                              if (imageUpload9) {
                              } else {
                                imageRef9.current.click();
                                return;
                              }
                              if (imageUpload10) {
                              } else {
                                imageRef10.current.click();
                                return;
                              }
      
                            }}>
      
                              <img className="radius001_image0" 
                              src={`/static/images/ssokdak/Tab5/iconBlackPlus.svg`} alt="" />
      
      
                              <div className="radius001_desc0">사진을<br/>등록해주세요.</div>
                            </div>
                            </>
                          }
                          <div>
                          </div>
                    </div>
      
                    {
                      imageLink1 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();
                      }}
                    >
                      {
                        imageLink1 !== '' &&
                        <>
                        <img className="full_image" src={imageLink1} alt=""/>
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink2 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef2.current.click();
                      }}
                    >
                      {
                        imageLink2 !== '' &&
                        <>
                        <img className="full_image" src={imageLink2} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink3 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef3.current.click();
                      }}
                    >
                      {
                        imageLink3 !== '' &&
                        <>
                        <img className="full_image" src={imageLink3} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                      imageLink4 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef4.current.click();
                      }}
                    >
                      {
                        imageLink4 !== '' &&
                        <>
                        <img className="full_image" src={imageLink4} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
      
      
      {
                      imageLink5 !== '' && 
      
                      <div className="radius001_image_wrapper" 
                      style={{ 
                        background:  `transparent`,
                        border : `1px solid #aeaeae`,
                      }}
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef5.current.click();
                      }}
                    >
                      {
                        imageLink5 !== '' &&
                        <>
                        <img className="full_image" src={imageLink5} alt="" />
                        </>
                      }
                      <div>
                      </div>
                </div>
                    }
                    {
                                    imageUpload6 !== '' && 
                    
                                    <div className="radius001_image_wrapper" 
                                    style={{ 
                                      background:  `transparent`,
                                      border : `1px solid #aeaeae`,
                                    }}
                                    onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      imageRef6.current.click();
                                    }}
                                  >
                                    {
                                      imageUpload6 !== '' &&
                                      <>
                                      <img className="full_image" src={imageLink6} alt="" />
                                      </>
                                    }
                                    <div>
                                    </div>
                              </div>
                                  }
                                  {
                              imageUpload7 !== '' && 
              
                              <div className="radius001_image_wrapper" 
                              style={{ 
                                background:  `transparent`,
                                border : `1px solid #aeaeae`,
                              }}
                              onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                imageRef7.current.click();
                              }}
                            >
                              {
                                imageUpload7 !== '' &&
                                <>
                                <img className="full_image" src={imageLink7} alt="" />
                                </>
                              }
                              <div>
                              </div>
                        </div>
                            }
                            {
                        imageUpload8 !== '' && 
        
                        <div className="radius001_image_wrapper" 
                        style={{ 
                          background:  `transparent`,
                          border : `1px solid #aeaeae`,
                        }}
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          imageRef8.current.click();
                        }}
                      >
                        {
                          imageUpload8 !== '' &&
                          <>
                          <img className="full_image" src={imageLink8} alt="" />
                          </>
                        }
                        <div>
                        </div>
                  </div>
                      }
                      {
                  imageUpload9 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload9 !== '' &&
                    <>
                    <img className="full_image" src={imageLink9} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
      
      {
                  imageUpload10 !== '' && 
      
                  <div className="radius001_image_wrapper" 
                  style={{ 
                    background:  `transparent`,
                    border : `1px solid #aeaeae`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    imageRef9.current.click();
                  }}
                >
                  {
                    imageUpload10 !== '' &&
                    <>
                    <img className="full_image" src={imageLink10} alt="" />
                    </>
                  }
                  <div>
                  </div>
            </div>
                }
                      </div>
                      </div>
                  </div>
      

                                  <div className="text0 text1">
                                  <textarea 
                                    ref={textAreaRef1}
                                    className="input0"
                                    value={textValue1}
                                    onChange={e=> {
                                      setTextValue1(e.target.value);
                                    }}
                                    placeholder={`왜 ${emoCardArray[clickedIndex].krName} 였나요?`}
                                    // disabled={true}
                                  />
                                  </div>
                                  <div className="text0 text2">
                                  <textarea 
                                    ref={textAreaRef2}
                                    className="input0 input1"
                                    value={textValue2}
                                    onChange={e=> {
                                      setTextValue2(e.target.value);
                                    }}
                                    placeholder= {`${emoCardArray[clickedIndex].krName} 라서 무엇을 했나요?`}
                                    // disabled={true}
                                  /></div>

                                  <QuillWrapper
                                    style={{
                                      maxWidth : `calc(100vw - 80px)`,
                                      width :`calc(100vw - 80px)`,
                                      minHeight: `400px`,
                                      maxHeight: `calc(100vh - 200px)`,
                                      marginBottom : `80px`,
                                      color : `#000`,
                                    }}
                                    theme={'snow'}
                                    id={'description'}
                                    placeholder={'추가설명을 입력해주세요'}
                                    value={quillValue1}
                                    modules={modules}
                                    formats={formats}
                                    onChange={(event) => setQuillValue1(event)}
                                    
                                  /> 

                                  
                                </div>
                              </div>
                            }
                        {
                          pageStep > 2 &&
                          
                          <div className="editButtonRow">
                            {
                              pageStep === 6 && <div className="editButtonDiv1">

                              {/* <img className="icon0 icon1" src={`/static/images/ssokdak/Tab2/edit1.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              />
                              <img className="icon0 icon2" src={`/static/images/ssokdak/Tab2/edit2.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              />

                              <img className="icon0 icon3" src={`/static/images/ssokdak/Tab2/edit3.svg`} alt=""
                                  onClick={e=> {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // setScreenMode1( 'page002/0/mind-diary')
                                      // setPageStep(1);
                                  }}
                              /> */}
                          </div>
                          }

                            <div className="editButtonDiv2">
                                    {
                                      (pageStep === 6 || 
                                      pageStep === 7) ?

                                    <div className="text0 text1" 
                                    onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // setScreenMode1( 'page002/0/mind-diary')
                                        // setPageStep(pageStep + 1);
                                        // if (textValue1 === "") {
                                        //   return;
                                        // }
                                        // if (textValue2 === "") {
                                        //   return;
                                        // }
                                        // if (quillValue1 == null) {
                                        //   return;
                                        // }
                                        handleCreate();
                                        
                                    }}
                                  >완료</div>
                                  :
                                  <>

                                    <div className="text0 text1" 
                                      onClick={e=> {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          // setScreenMode1( 'page002/0/mind-diary')
                                          setPageStep(pageStep + 1);
                                      }}
                                    >
                                      다음
                                    </div>

                                  </>
                                    }
                                </div>
                            {/*  */}
                        </div>
                        }

                        </div>

                        {
                          boolPopup2 === true &&
                          <div 
                          className="bottomSheet0 bottomSheetForEmotionCard" 
                          style={{
                            height: boolPopup2 === false ? 0 : `100vh`,
                            transition: `all 0.5s ease-out`
                          }}
                          > 
                            <div className="blurView"></div>
                          </div>
                        }


                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: boolPopup1 === false ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          <div className="contentView"> 
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  // setScreenMode1( 'page002/0/mind-diary')
                                  setBoolPopup1(false)

                              }}
                              />
                            </div>
                            {
                              boolPopup1 === true &&
                              
                            <div className="bottomSheetEmoWrapper">
                            <div className="wrapDiv1">
                            {
                                Array.from(emoCardArray)
                                .sort(function(a, b)  {
                                  return a.index - b.index;
                                })
                                .map((mapItem, mapIndex)=> {
                                  return (
                                    <div className="cardItem " 
                                    >
                                      {
                                         (mapIndex === clickedIndex )&&

                                         <div className="selectImg0">
                                         <img className="closeBtn" 
                                         src={`/static/images/ssokdak/Tab2/editSave1.svg`} alt=""
                                         style={{ marginLeft : 'auto'}}
                                         onClick={e=> {
                                             e.preventDefault();
                                             e.stopPropagation();
                                            //  setScreenMode1( 'page002/0/mind-diary')
                                         }}
                                         />
                                       </div>
                                      }
                                      <div className="cardImg0">
                                        <img  src={mapItem.iconCardPathName} alt="" 
                                          style={{
                                            border : (mapIndex === clickedIndex )?  `1px solid #FF7A7A` : `1px solid #ffffff`,
                                            borderRadius : `10px`,
                                          }}
                                          onClick={e=> {
                                            setClickedIndex(mapIndex);
                                            setPageStep(2);
                                        }}/>
                                      </div>
                                    </div>)
                                })
                              }
                            </div>
                          </div>
                            }
                          </div>
                        </div>



                    </div>

                )
            }
            

          {/* <div className=" " style={{ marginBottom: `200px`, height : `40vh`}}></div> */}
        </div>
        {/* <TabBottom/> */}
        </>
      }
      </>
      
    );
}
