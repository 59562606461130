import React, { Component, useEffect, useState, useContext , useRef} from "react";

import parse from "html-react-parser";
import "./a00.css";
import {useSelector, useDispatch} from 'react-redux'

import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import uuid from 'react-uuid';

import { toast } from "react-toastify";

import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import { AUTH_KEY } from "../../redux/authRedux/auth.reducer";
import { setAuth } from "../../redux/authRedux/auth.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";


import { resizeImage } from "./resize-image";
import imageCompression from "browser-image-compression";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 

const pageCode1 =  "page000/0/first";

// <div className={``}></div>
export default function ThisComponent(props) {
    const [boolInputFocused, setBoolInputFocused] = useState(false);

  const [objectPopupData1, setObjectPopupData1] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);

    const [textInput1, setTextInput1] = useState("");
    const [textInput2, setTextInput2] = useState("");
    const [textInput3, setTextInput3] = useState("");
    const [textInput4, setTextInput4] = useState("");
    const [textInput5, setTextInput5] = useState("");
    const [textInput6, setTextInput6] = useState("female");
    const [textInput7, setTextInput7] = useState("");
    const [textInput8, setTextInput8] = useState("");
    const [textInput9, setTextInput9] = useState("");
    const [textInput10, setTextInput10] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);

    const [boolCheck1, setBoolCheck1] = useState(false);

    const [boolCheckTermA, setBoolCheckTermA] = useState(false);
    const [boolCheckTerm1, setBoolCheckTerm1] = useState(false);
    const [boolCheckTerm2, setBoolCheckTerm2] = useState(false);
    const [boolCheckTerm3, setBoolCheckTerm3] = useState(false);


    const [intPageStep1, setIntPageStep1] = useState(1);


    const testRef = useRef();
    const imageRef1 = useRef();
    

    const [imageUpload1, setImageUpload1] = useState('');
    const [imageLink, setImageLink] = useState('');
 
    const upload = ()=>{

        if (viewScreenMode.mode !== pageCode1) {
          return;
        }
  
        var returnName1 = `${imageUpload1.name}_${moment().format("YYYYMMDD_HHmmss")}`;
        if(imageUpload1 == null)
          return;
          const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(imageUpload1)
          uploadTask
          .on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
          }, 
          () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            fireDb.storage().ref('images').child(returnName1).getDownloadURL()
             .then(fireBaseUrl => {
              //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
              console.log("fireBaseUrl", fireBaseUrl)
              setImageLink(fireBaseUrl);
             })
          }
        );
  
      }


  const actionImgCompress = async (fileSrc) => {
    console.log("압축 시작");

    const options = {
      maxSizeMB: 0.10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      // 압축 결과
      const compressedFile = await imageCompression(fileSrc, options);

      // const reader = new FileReader();
      // reader.readAsDataURL(compressedFile);
      // reader.onloadend = () => {
      //   const base64data = reader.result;
      //   imageHandling(base64data);
      // };
      var returnName1 = `${imageUpload1.name}_${moment().format("YYYYMMDD_HHmmss")}`;
      if(imageUpload1 == null)
        return;
        const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(compressedFile)
        uploadTask
        .on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          fireDb.storage().ref('images').child(returnName1).getDownloadURL()
           .then(fireBaseUrl => {
            //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            console.log("fireBaseUrl", fireBaseUrl)
            setImageLink(fireBaseUrl);
           })
        }
      );

    } catch (error) {
      console.log(error);
    }
  };


    useEffect(()=> {
      upload();
    },[imageUpload1])

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }
    let setAuth1 = (param) => {
      dispatch(setAuth(param))
    }

    
    const [messageFromDevice0, setmessageFromDevice0] = useState("");
    const [blockSystem, setBlockSystem] = useState(false);
    const [fbContactKeyName, setFbContactKeyName] = useState("");


    const handleEnter = (e) => {
      // if (textSearch1 == "") {
      //   return;
      // }
      if ( e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
      }
  }

  useEffect(()=> {

    if (boolCheckTerm1 === true && boolCheckTerm2 === true && boolCheckTerm3 === true) {
        setBoolCheckTermA(true);
    } else {
        setBoolCheckTermA(false);

    }

  },[boolCheckTerm1,boolCheckTerm2, boolCheckTerm3, boolCheckTermA])


  function handleFindUserExists(paramColumnName, paramValue) {

    // var email =  textInput1
    // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

    //   if(exptext.test(email)==false){
    //     toast.error("이메일형식이 올바르지 않습니다.");
    //     return false;
    // }
    // console.log("handleFindUserExists - textInput - 0", paramValue)

    Firebase.database().ref()
          .child("accounts")
          .orderByChild(paramColumnName)
          .equalTo( paramValue)
          .once("value", (snapshot) => {

            //   console.log("handleFindUserExists - textInput - 1", snapshot)

              if (snapshot.val()) {
                  const data = snapshot.val();
                  const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                  if (keyName) {

                       console.log("handleFindUserExists - textInput - 2", snapshot)
                      // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                      // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                      setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>해당 {paramColumnName.toUpperCase()} 사용중입니다.</div>)
                        

                      if (data[keyName].status === "YES") {
                          
                      } else {
                          // setBlockSystem(true);
                      }
                  } else {

                    // console.log("handleFindUserExists - textInput - 3", snapshot)
                    //   //조건 분기 필요

                      if (paramColumnName === "email") {
                        setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }}  style={{ color : `#1165F5`}}>해당 {paramColumnName.toUpperCase()} 사용가능합니다.</div>)
                        setIntPageStep1(2);
                     }
                     if (paramColumnName === "nickname") {
                       setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }}  style={{ color : `#1165F5`}}>해당 {paramColumnName.toUpperCase()} 사용가능합니다.</div>)
                    //    setIntPageStep1(7);

                    setBoolCheck1(true); 
                    }
    
                  }

              }  else {
                console.log("handleFindUserExists - textInput - 4", snapshot)
                
                if (paramColumnName === "email") {
                    setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }}  style={{ color : `#1165F5`}}>해당 {paramColumnName.toUpperCase()} 사용가능합니다.</div>)
                    setIntPageStep1(2);
                }

                if (paramColumnName === "nickname") {
                    setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }}  style={{ color : `#1165F5`}}>해당 {paramColumnName.toUpperCase()} 사용가능합니다.</div>)
                    // setIntPageStep1(7);
                    setBoolCheck1(true); 
                 }

                // fireDb.database().ref().child("accounts").push(
                //   {
                //     account : textInput1, 
                //     email : textInput1, 
                //     password : textInput2,
                //   }
                //   , (err) => {
                //   if (err) {
                //     toast.error(err);
                //     console.log(err);
                //   } else {
                //     toast.success("Signup  Successfully");
                //     alert("")
                    
                //   }
                // });
              }
          });
  
  } 
 
useEffect(()=> {
  if(objectPopupData1 != null) {
    setTimeout(()=> {
      setObjectPopupData1(null);
    }, 3000)
  }
}, [objectPopupData1])

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
        setTextInput1("")
        setTextInput2("")
        setTextInput3("")
        setTextInput4("")
        setTextInput5("")
        setTextInput6("")
        setTextInput7("female")
        setIntPageStep1(1);
        setImageLink("");
    
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }

    }, [useSelector(state => state)])


    function handleCheckEmail() {
      var email =  textInput1
      var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

        if(exptext.test(email)==false){
          toast.error("이메일형식이 올바르지 않습니다.");
          return false;
        }
    }

    function makeId(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    function handleSignUp() {
 
  
        Firebase.database().ref()
              .child("accounts")
              .orderByChild("username")
              .equalTo(textInput1)
              .once("value", (snapshot) => {
  
                    console.log("handleSignUp - 1", snapshot)
  
                  if (snapshot.val()) {
                      const data = snapshot.val();
                      const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                      if (keyName) {
  
                        // setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>해당 아이디 사용중입니다.</div>)
                          // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                          // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        //   if (data[keyName].status == "YES") {
                              
                        //   } else {
                        //       // setBlockSystem(true);
                        //   }
                      } else {
                          //조건 분기 필요
  
                      }
  
                  }  else {
                    
                    console.log("handleSignUp - 2", )

                        // step 1 : 이메일
                        // step 2 : 비밀번호
                        // step 3 : 휴대폰 번호 입력
                        // step 4 : 휴대폰 인증번호 입력 (skip) - 금액 든다.
                        // step 5 : 닉네임
                        // step 6 : - (없음)
                        // step 7 : 프로필 사진
                        // step 8 : 성별 (남/여에 따른 처리)
                        // step 9 : 자녀 유무
                        // step 10 : 약관 동의
                        // step 11 : 환영합니다.

                        // console.log("textInput1", textInput1);
                        // console.log("textInput2", textInput2);
                        // console.log("textInput3", textInput3);
                        // console.log("textInput4", textInput4);
                        // console.log("textInput5", textInput5);
                        // console.log("textInput6", textInput6);
                        // console.log("textInput7", textInput7);

                    fireDb.database().ref().child("accounts/" + textInput5).update(
                      {
                        check_agree1 : boolCheck1,
                        // check_agree2 : boolCheck2,
                        // check_agree3 : boolCheck3,
                        // check_agree4 : boolCheck4,
                        // nation : textInput1,  //"in" 내국인, "out" 외국인
                        // // 지금수정중
                        // parent_name : textInput2,
                        // contact_number : textInput3,
                        // contact_secret : textInput4,
                        // profile_image : imageLink,
                        username : textInput1, 
                        email : textInput1,
                        password : textInput2,
                        phone : textInput4,
                        nickname : textInput5,
                        avatar : imageLink ? imageLink : '',
                        gender : textInput6,
                        
                        info_children : textInput7,
                        // gender : textInput16, // "male", "female"
                        usertype : "normal", 
                        uuid : uuid(),
                        random_id16 : makeId(16),
                        random_id8 : makeId(8),
                        // restoration_code : textInput21,
  
                        // account : textInput1, 
                        // email : textInput1, 
                        // password : textInput2,
                      }
                      , (err) => {
                      if (err) {
                        // toast.error(err);
                        console.log(err);
                      } else {
                        // toast.success("Signup  Successfully");
  
                        setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)
  
                        setTimeout(()=> {
                            // const pageCodeGo1 =  "page000/0/login";
                            // setScreenMode1(pageCodeGo1)
                            handleLogin(textInput1, textInput2);
                        }, 1000)
  
                        // alert("")
                        
                      }
                    });
                  }
              });
      
      }
  



    function handleLogin(paramUsername, paramPW) {

      // var email =  textInput1
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }
    //   console.log("textInput1 - 0", textInput1)
      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo( paramUsername)
            .once("value", (snapshot) => {
                // console.log("textInput1 - 1", snapshot)
                if (snapshot.val()) {
                    // console.log("textInput1 - 2", snapshot.val())
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    // console.log("textInput1 - 3", keyName)
                    if (keyName) {
                        // console.log("textInput1 - 4", data[keyName])
                        Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"auto_login" : moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        if (data[keyName].password === paramPW) {
                            // console.log("textInput1 - 5",data[keyName].password )
                            // 비밀번호와 일치하는지 여부
                            // toast.success("인증되었습니다. ");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}>환영합니다.</div>)
                            setAuth1({
                                username:  data[keyName].username,
                                password:  data[keyName].password,
                                token:  '',
                                more:  data[keyName],
                            })
                            // console.log("data[keyName]", data[keyName])
                            window.localStorage.setItem("authStoreKeyName",  keyName)
                            window.localStorage.setItem("authStoreKeyName",  keyName);
                            
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                            // window.localStorage.setItem()
                            // 지금 수정중
                            setTimeout(()=> {
                              setScreenMode1( 'page001/0/home');
                            }, 1000 )

                        } else {
                          console.log("textInput1 - 6", textInput2 )
                            // toast.error("인증실패하였습니다. 비밀번호를 확인해주세요.");
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다. 비밀번호를 확인해주세요.</div>)
                        }
                    } else {
                        //조건 분기 필요
                            setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                    }

                }  else {
                  setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>인증실패하였습니다.</div>)
                  // 테스트 코드
                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : textInput1, 
                  //     email : textInput1, 
                  //     password : textInput2,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    }


    // console.log("[authfirst - screen] props && props.appKeyboardVisible ", props && props.appKeyboardVisible );

    // console.log("[authfirst - screen] imageUpload1 ", imageUpload1);


    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        {objectPopupData1}
        <div className={`first_screen`}> 
            <div className="checkInPageWrapper" style={{ background : '#fff'}}>
                <div className="checkInTopWrapper" >
                    <div className="checkInTopTopBar">
                        <img className="checkInTopTopIcon1" src={`/static/images/ssokdak/Tab2/topBack1.svg`} alt=""
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                // setScreenMode1( 'page002/0/mind-diary')

                                if (intPageStep1 === 1) {
                                    setScreenMode1( 'page000/0/splash');
                                    return;
                                    
                                } else {
                                    if (intPageStep1 === 5) {
                                        setIntPageStep1(3);
                                        return;
                                    }
                                    if (intPageStep1 === 7) {
                                        setIntPageStep1(5);
                                        return;
                                    }
                                    setIntPageStep1(intPageStep1 - 1)
                                }
                            }}
                        />
                    </div>
                </div>

            {
                intPageStep1 >= 1 && 
                intPageStep1 <= 2 && 
                <>
                    <div className="topLabel">
                        { intPageStep1 === 1 && <>{parse(`이메일을<br/>입력해 주세요.`)}</>}
                        { intPageStep1 === 2 && <>{parse(`입력란을 모두<br/>입력해 주세요.`)}</>}
                        
                    </div>
                    {
                        intPageStep1 === 2 &&
                        <div className="inputWrapper0 inputWrapper2">
                            <div className="inputLabel0 inputLabel1">이메일 주소</div>
                        </div>
                    }
                    <div className="inputWrapper0 inputWrapper1">
                        <input 
                        className="emailInput1"
                        value={textInput1} 
                        onChange={e=> {
                            setTextInput1(e.target.value);
                        }} 
                        placeholder="이메일주소를 입력해주세요."
                        onFocus={e=> {
                            setBoolInputFocused(true);
                        }}
                        onBlur={e=> {   
                            setBoolInputFocused(false);
                            
                        }}
                        />
                    </div>
                    {
                        intPageStep1 === 2 &&
                        <>
                        <div className="inputWrapper0 inputWrapper2">
                            <div className="inputLabel0 inputLabel1">비밀번호</div>
                        </div>
                        <div className="inputWrapper0 inputWrapper1">
                            <input 
                            className="emailInput1"
                            type="password"
                            value={textInput2} 
                            onChange={e=> {
                                setTextInput2(e.target.value);
                            }} 
                            placeholder="비밀번호"
                            onFocus={e=> {
                                setBoolInputFocused(true);
                            }}
                            onBlur={e=> {   
                                setBoolInputFocused(false);
                                
                            }}
                            />
                        </div>
                        <div className="inputWrapper0 inputWrapper3">
                            <div className="inputLabel0 inputLabel2">
                            영문/숫자/특수문자 포함 6~14자
                            </div>
                        </div>
                        <div className="inputWrapper0 inputWrapper2">
                            <div className="inputLabel0 inputLabel1">비밀번호 확인</div>
                        </div>
                        <div className="inputWrapper0 inputWrapper1">
                            <input 
                            className="emailInput1"
                            type="password"
                            value={textInput3} 
                            onChange={e=> {
                                setTextInput3(e.target.value);
                            }} 
                            placeholder="비밀번호"
                            onFocus={e=> {
                                setBoolInputFocused(true);
                            }}
                            onBlur={e=> {   
                                setBoolInputFocused(false);
                                
                            }}
                            />
                        </div>
                        <div className="inputWrapper0 inputWrapper3">
                            <div className="inputLabel0 inputLabel2">
                            영문/숫자/특수문자 포함 6~14자
                            </div>
                        </div>
                        </>

                    }

                    {/* <div className="emailInput0 emailInput1" >
                        <input 
                            value={textInput1} 
                            onChange={e=> {
                                setTextInput1(e.target.value);
                            }} 
                            placeholder="이메일주소를 입력해주세요."
                        />

                        <div className="at_div"></div>

                        <div>naver.com</div>
                    </div> */}
                    {/* <div className=""></div> */}
                </>
            }
            {
                intPageStep1 === 3 &&
                <>

                    <div className="topLabel">
                        { intPageStep1 === 3 && <>{parse(`휴대폰 번호를<br/>입력해 주세요`)}</>}
                        { intPageStep1 === 4 && <>{parse(`문자로 전송된 인증번호를<br/> 입력해 주세요.`)}</>}
                        
                    </div>
                    <div className="inputWrapper0 inputWrapper1">
                        <input 
                        
                        className="emailInput1"
                        value={textInput4} 
                        onChange={e=> {

                            var temp = e.target.value.replace(/[^0-9]+/g, "");
                            if (temp.length > 11) {
                                return;
                            }
                            setTextInput4(temp);
                        }} 
                        placeholder="휴대폰 번호"
                        onFocus={e=> {
                            setBoolInputFocused(true);
                        }}
                        onBlur={e=> {   
                            setBoolInputFocused(false);
                            
                        }}
                        />
                    </div>

                </>
            }
            {
                intPageStep1 === 5 &&
                <>

                    <div className="topLabel">
                        { intPageStep1 === 5 && <>{parse(`쏙닥쏙닥에서 사용할 이름을<br/>입력해 주세요.`)}</>}
                        
                    </div>
                    <div className="inputWrapper0 inputWrapper1">
                        <input 
                        className="emailInput1"
                        value={textInput5} 
                        onChange={e=> {
                            // const regex = /^[ㄱ-ㅎ가-힣a-zA-Z0-9]+$/;
                            // var temp = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z0-9]+/g, "");
                            // setTextInput5(temp);
                            setTextInput5(e.target.value);
                        }} 
                        placeholder="닉네임"
                        maxLength={12}
                        onFocus={e=> {
                            setBoolInputFocused(true);
                        }}
                        onBlur={e=> {   
                            setBoolInputFocused(false);
                            
                        }}
                        />
                        <div 
                            className="checkButton0 checkButton1"
                            style={{

                                color : 
                                (textInput5 !== "" ? `#fff` : `#A6A6A6` )
                                ,
            
                                background : 
                                (textInput5 !== "" ? `#2D2D2D` : `#E2E2E2` )
                                ,
                            }}
                            onClick={e=> {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                handleFindUserExists("nickname", textInput5);
                                return;

                            }}
                        >
                            중복확인
                            {/* 인증하기 */}
                        </div>
                    </div>

                </>
            }

            {
                intPageStep1 === 7 &&
                <>

                    <div className="topLabel">
                        { intPageStep1 === 7 && <>{parse(`커뮤니티에서 사용할<br/>프로필 사진을 업로드 해주세요`)}</>}
                        <div className="profileInitImgWrapper ">
                            <div className="profileInitImgRoundWrapper">
                                <img className="profileUpload1" 
                                src={
                                    imageLink === "" ?
                                    `/static/images/ssokdak/Init/profUpload.png`
                                    :
                                    imageLink
                                } alt=""
                                    onClick={e=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // setScreenMode1( 'page002/0/mind-diary')
                                        imageRef1.current.click();
                                    }}
                                />
                                <input 
                                ref={imageRef1} 
                                type="file" 
                                onChange={(e)=>{
                                    // setImageUpload1(e.target.files[0])


                                    actionImgCompress(e.target.files[0]);
                                }} 

                                // onChange={handleFile}
                                name="photo"
                                accept="image/*"
                                // accept="image/*"
                                // accept=".gif, .jpg, .png"
                                // accept=".png"
                                style={{display : 'none'}}/>
                                <button onClick={upload} style={{display : 'none'}}>Upload</button>
                            </div>
                        </div>
                    </div>

                </>
            }
            {
                            intPageStep1 === 8 &&
                            <>
            
                                <div className="topLabel">
                                    { intPageStep1 === 8 && <>{parse(`성별을<br/>알려주세요.`)}</>}
                                    <div className="genderInitImgWrapper ">
                                        <div className="genderItem">
                                            <img className="img0 img1" 
                                            src={ 
                                                textInput6 === "female" ?
                                                `/static/images/ssokdak/Init/genderFeMaleOn.png` 
                                                :
                                                `/static/images/ssokdak/Init/genderFeMaleOff.png` 
                                            } alt=""
                                            onClick={e=> {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setTextInput6("female")
                                            }}
                                            />
                                        </div>
                                        <div className="genderItem">
                                            <img className="img0 img1" 
                                            src={ 
                                                textInput6 === "male" ?
                                                `/static/images/ssokdak/Init/genderMaleOn.png` 
                                                :
                                                `/static/images/ssokdak/Init/genderMaleOff.png` 
                                            } alt=""
                                            onClick={e=> {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setTextInput6("male")
                                            }}
                                            />
                                        </div>
                                    </div>
                                </div>
            
                            </>
                        }

                        {
                            intPageStep1 === 8 &&
                            textInput6 === "male" &&
                            <> 
                            
                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: intPageStep1 !== 8 ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          
                          <div className="centerPopupView">
                                <img className="image0 image1" 
                                src={`/static/images/ssokdak/Init/femaleService.png`} alt=""
                                style={{ marginLeft : 'auto'}}
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                />
                                <div className="text0 text1">쏙닥쏙닥은 여성들을 위한<br/>서비스에요.</div>
                                <div className="text0 text2">남성분이 사용하기<br/>적합하지 않을 수 있어요. 괜찮으신가요?</div>
                                <div className="row0 row1">
                                    <div className="button0 button1"
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setTextInput6("female")
                                            // setScreenMode1('page000/0/splash')
                                        }}
                                >닫기</div>
                                    <div className="button0 button2"
                                onClick={e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setTextInput6("male")
                                    setIntPageStep1(9);
                                }}
                                >괜찮아요!</div>
                                </div>
                            </div>
                        </div>
                            </>
                        }


                        {
                            (intPageStep1 === 9 || intPageStep1 === 10)&&
                            <>  <div className="topLabel">
                                { intPageStep1 === 9 && <>{parse(`자녀가 있나요?`)}</>}

                                <div className="rowItemOptionSelectorWrapper">
                                    {
                                        Array.from([
                                            "출산 계획중 이에요",
                                            "출산 중이에요",
                                            "자녀가 1-2명 있어요",
                                            "자녀가 3명 이상 이에요",
                                        ]).map((mapItem, mapIndex)=> {
                                            return (
                                                <div className={"rowItemOption0 rowItemOption" + (mapIndex + 1)}
                                                style={{
                                                    color : 
                                                    textInput7 === mapItem ?
                                                    `#fff` : `#000`,
                                                    background : 
                                                    textInput7 === mapItem ?
                                                    `#FF7A7A` : `#fff`,
                                                }}
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setTextInput7(mapItem)
                                                    
                                                }}>
                                                    <img className="img0 img1" 
                                                    src={ 
                                                        textInput7 === mapItem ?
                                                        `/static/images/ssokdak/Init/iconWhiteCheck.svg` 
                                                        :
                                                        `/static/images/ssokdak/Init/iconWhiteCheck.svg` 
                                                    } alt=""
                                                    onClick={e=> {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setTextInput7(mapItem)
                                                    }}
                                                    />
                                                    <div className="text0 text1" 
                                                    onClick={e=> {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setTextInput7(mapItem)
                                                        
                                                    }}>
                                                    {mapItem}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            </>
                        }

                        {
                            intPageStep1 === 10 &&
                            <> 
                            
                        <div 
                        className="bottomSheet0 bottomSheetForEmotionCard" 
                        style={{
                          height: intPageStep1 !== 10 ? 0 : `100vh`,
                          transition: `all 0.5s ease-out`
                        }}
                        >
                          <div className="blurView"></div>
                          <div className="contentView"> 
                            <div className="row0 row1"
                            >
                              <img className="closeBtn" 
                              src={`/static/images/ssokdak/Tab2/topClose1.svg`} alt=""
                              style={{ marginLeft : 'auto'}}
                              onClick={e=> {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setIntPageStep1(intPageStep1 - 1)
                              }}
                              />
                            </div>
                            {
                              intPageStep1 === 10 &&
                              
                            <div className="bottomSheetEmoWrapper">
                                <div className="initAgreeWrapper ">
                                    <div className="initAgreeRowA">
                                        <img className="checkIcon0" 
                                        src={
                                            boolCheckTermA === true ?
                                            `/static/images/ssokdak/Init/pinkCheckAll.svg`
                                            :
                                            `/static/images/ssokdak/Init/grayCheckAll.svg`
                                        } alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (boolCheckTerm1 === false || boolCheckTerm2 === false || boolCheckTerm3 === false ) {
                                                setBoolCheckTerm1(true);
                                                setBoolCheckTerm2(true);
                                                setBoolCheckTerm3(true);
                                            } else {

                                                setBoolCheckTerm1(false);
                                                setBoolCheckTerm2(false);
                                                setBoolCheckTerm3(false);
                                            }
                                        }}
                                        />
                                        <div className="text0 text1">약관에 모두 동의</div>
                                    </div>
                                    <div className="initAgreeRowB">
                                        <img className="checkIcon0" 
                                        src={
                                            boolCheckTerm1 === true ?
                                            `/static/images/ssokdak/Init/pinkCheck.svg`
                                            :
                                            `/static/images/ssokdak/Init/grayCheck.svg`
                                        } alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setBoolCheckTerm1(!boolCheckTerm1)
                                        }}
                                        />
                                        <div className="text0 text1">쏙닥쏙닥 서비스 필수 항목 모두 동의</div>
                                        <img className="arrowIcon0" 
                                        src={`/static/images/ssokdak/Init/grayRight.svg`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        />
                                    </div>
                                    <div className="initAgreeRowB">
                                        <img className="checkIcon0" 
                                        src={
                                            boolCheckTerm2 === true ?
                                            `/static/images/ssokdak/Init/pinkCheck.svg`
                                            :
                                            `/static/images/ssokdak/Init/grayCheck.svg`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setBoolCheckTerm2(!boolCheckTerm2)
                                        }}
                                        />
                                        <div className="text0 text1">맞춤형 서비스 안내 동의(선택)</div>
                                        <img className="arrowIcon0" 
                                        src={`/static/images/ssokdak/Init/grayRight.svg`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        />
                                    </div>
                                    <div className="initAgreeRowB">
                                        <img className="checkIcon0" 
                                        src={
                                            boolCheckTerm3 === true ?
                                            `/static/images/ssokdak/Init/pinkCheck.svg`
                                            :
                                            `/static/images/ssokdak/Init/grayCheck.svg`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            setBoolCheckTerm3(!boolCheckTerm3)
                                        }}
                                        />
                                        <div className="text0 text1">마케팅 정보수신 동의(선택)</div>
                                        <img className="arrowIcon0" 
                                        src={`/static/images/ssokdak/Init/grayRight.svg`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        />
                                    </div>

                                </div>
                            </div>
                            }
                          </div>
                        </div>
                            </>
                        }

                {
                            intPageStep1 === 11 &&
                            <div className="completeWrapper">
                                <div className="text0 text1">회원가입 완료!</div>
                                <div className="text0 text2">
                                {/* {textInput5}님<br/> */}
                                    환영합니다!</div>
                                <img className="image0 image1" 
                                        src={`/static/images/ssokdak/Init/competeImg.png`} alt=""
                                        style={{ marginLeft : 'auto'}}
                                        onClick={e=> {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        />
                            </div>
                }

                {
                     (intPageStep1 === 8 && textInput6 === "male") ?
                     <>
                     </>
                     :
                    <>
                    {
                        (props && props.appKeyboardVisible === "visible" || boolInputFocused === true) ?
                        <>
                        </>
                        :

                        <div 
                        id="start_button0"
                        className="start_button0 start_button1"
                        style={{ 
                            color : 
                            intPageStep1 === 1 ? 
                            (textInput1 !== "" ? `#ffffff` : `#A6A6A6` )
                            :
                            intPageStep1 === 2 ? 
                            ((textInput1 !== ""  && textInput2 !== "" && textInput3 !== "" ) ? `#ffffff` : `#A6A6A6` )
                            :
                            intPageStep1 === 3 ? 
                            ((textInput4!== "" ) ? `#ffffff` : `#A6A6A6` )
                            :
                            intPageStep1 === 5 ? 
                            ((boolCheck1 === true ) ? `#ffffff` : `#A6A6A6` )
                                
                            :
                            intPageStep1 === 7 ? 
                            ((imageLink !== "") ?`#ffffff` : `#A6A6A6` )
                                      
                                
                           :
                           intPageStep1 === 8 ? 
                           ((textInput6 !== "") ? `#ffffff` : `#A6A6A6` )
                                          
                                
                           :
                           intPageStep1 === 9 ? 
                           ((textInput7 !== "") ?  `#ffffff` : `#A6A6A6` )
                            
                           :
                           intPageStep1 === 10 ? 
                           ((boolCheckTerm1 !== false) ? `#ffffff` : `#A6A6A6` )
                           :
   
                           intPageStep1 === 11 ? 
                           (true ? `#ffffff` : `#A6A6A6` )
                                          
                            :`#A6A6A6`,
        
        
        
                            background : 
                            intPageStep1 === 1 ?  
                            (textInput1 !== "" ? `#FF7A7A` : `#E2E2E2` )
                            :
                            intPageStep1 === 2 ? 
                                ((textInput1 !== ""  && textInput2 !== "" && textInput3 !== "" ) ? `#FF7A7A` : `#E2E2E2` )
                            :
                            intPageStep1 === 3 ? 
                                ((textInput4 !== "" ) ? `#FF7A7A` : `#E2E2E2` )
                                :
                                intPageStep1 === 5 ? 
                                ((boolCheck1 === true ) ? `#FF7A7A` : `#E2E2E2` )
                                
                                :
                                intPageStep1 === 7 ? 
                                ((imageLink !== "") ? `#FF7A7A` : `#E2E2E2` )
                                     
                                
                           :
                           intPageStep1 === 8 ? 
                           ((textInput6 !== "") ? `#FF7A7A` : `#E2E2E2` )
                                          
                                
                           :
                           intPageStep1 === 9 ? 
                           ((textInput7 !== "") ? `#FF7A7A` : `#E2E2E2` )
                                    
                           :
                           intPageStep1 === 10 ? 
                           ((boolCheckTerm1 !== false) ? `#FF7A7A` : `#E2E2E2` ) :
                           intPageStep1 === 11 ? 
                           (true ? `#FF7A7A` : `#E2E2E2` )
                                          
                            :`#E2E2E2`
                        }}
                        onClick={e=> {
                            e.preventDefault();
                            e.stopPropagation();
                           // step 1 : 이메일
                           // step 2 : 비밀번호
                           // step 3 : 휴대폰 번호 입력
                           // step 4 : 휴대폰 인증번호 입력 (skip) - 금액 든다.
                           // step 5 : 닉네임
                           // step 6 : - (없음)
                           // step 7 : 프로필 사진
                           // step 8 : 성별 (남/여에 따른 처리)
                           // step 9 : 자녀 유무
                           // step 10 : 약관 동의
                           // step 11 : 환영합니다.
                            if (intPageStep1 === 1) {
                               // 이메일 존재여부
                               // 이메일 형식체크
                               var email =  textInput1
                               var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
   
                               if (email === "") {
   
                                   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>입력란을 확인해주세요.</div>)
                                   return;
                               }
                               if(exptext.test(email)===false){
                                   // toast.error("이메일형식이 올바르지 않습니다.");
   
                                   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>이메일형식이 올바르지 않습니다.</div>)
                                   return false;
                               } else {
   
                                   handleFindUserExists("email", textInput1, 1);
                                   return;
                               }
                                   
                               
                            }
                            if (intPageStep1 === 2) {
                               // 비밀번호 형식체크
                               // Min 1 uppercase letter.
                               // Min 1 lowercase letter.
                               // Min 1 special character.
                               // Min 1 number.
                               // Min 8 characters.
                               // Max 30 characters.
                               // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
   
                               var password =  textInput2;
                               if (textInput2 === "" || textInput3 === "") {
   
                                   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>입력란을 확인해주세요.</div>)
                                   return;
                               }
                               if (textInput2 !== textInput3) {
                                   
                                   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>비밀번호가 같지 않습니다.</div>)
                                   return;
                               }
                               var passwordexp = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,14}$/;
                               if(passwordexp.test(password)===false) {
                                   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>비밀번호 형식을 확인해주세요.</div>)
                                   return;
                               } else {
                                   setIntPageStep1(3);
                                   return;
                               }
                            }
                            if (intPageStep1 === 3) {
                               setIntPageStep1(5);
                               return;
                            }
                            if (intPageStep1 === 4) {
                               setIntPageStep1(5);
                               return;
                            }
                            if (intPageStep1 === 5) {
                               setIntPageStep1(7);
                               return;
                           }
                           if (intPageStep1 === 7) {
                               if (imageLink !== "") {
                                   setIntPageStep1(8);
                               } else {

                                setIntPageStep1(8);
                                //    setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ffa8a8`}}>이미지를 업로드해주세요.</div>)
                                   return;
                               }
                           }
                           if (intPageStep1 === 8) {
                               if (textInput6 === "female") {
                                   setIntPageStep1(9);
                                   return;
                               }
                           }
       
                           if (intPageStep1 === 9) {
                               if (textInput7 !== "") {
                                
                                   handleSignUp();
                                   setIntPageStep1(11);
                                   return;
                               }
                           }
       
                           if (intPageStep1 === 10) {
   
                               if (boolCheckTerm1 !== false) {
                                //    handleSignUp();
                                //    setIntPageStep1(11);
                                   return;
                               }
                           }
       
                           if (intPageStep1 === 11) {
                               
                               setScreenMode1( 'page000/0/login');
                           }
   
                           if (intPageStep1 >= 11) {
                               return;
                           }
       
                            setIntPageStep1(intPageStep1 + 1);
                        }}
                        >
                            다음
                        </div>
                    }
                    </>
     
                }

            </div>



            <div className="" style={{ marginBottom : 100 }}></div>
        </div>
      </>
      
    );
}
