import React, { Component, useEffect, useState, useContext, useRef } from "react";

import parse from "html-react-parser";
import "./a04.css";
import {useSelector, useDispatch} from 'react-redux'
import fireDb from "../../../firebase";
import Firebase from 'firebase';
import moment from "moment";

import { toast } from "react-toastify";


import { SCREENMODE_KEY } from "../../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../../assets/appColors";
import appImages from "../../../assets/appImages";
import appPages from "../../../assets/appPages";
import NavigationTop from "../../NavigationTop/NavigationTop";

import { QRCodeCanvas } from "qrcode.react";

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 ='page005/2/setting-mypage';
export default function ThisComponent(props) {

  const [url, setUrl] = useState("");

  const downloadQRCode = (e) => {
    e.preventDefault();
    setUrl("");
  };

    const [objectPopupData1, setObjectPopupData1] = useState(null);

    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(3);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("01011112222");
    const [textInput4 , setTextInput4] = useState("1234");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE

    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");

    const [textInput21 , setTextInput21] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);
    const [boolPasswordShow3, setBoolPasswordShow3] = useState(false);
    const [boolPasswordShow4, setBoolPasswordShow4] = useState(false);
    
    

    const [messageFromDevice0, setmessageFromDevice0] = useState("");
    const [blockSystem, setBlockSystem] = useState(false);
    const [fbContactKeyName, setFbContactKeyName] = useState("");

    const [boolCheckA, setBoolCheckA] = useState(false);
    const [boolCheck1, setBoolCheck1] = useState(false);
    const [boolCheck2, setBoolCheck2] = useState(false);
    const [boolCheck3, setBoolCheck3] = useState(false);
    const [boolCheck4, setBoolCheck4] = useState(false);
    const [boolCheck5, setBoolCheck5] = useState(false);

    const imageRef1 = useRef();

    const [imageUpload1, setImageUpload1] = useState('');
    const [imageLink, setImageLink] = useState('');

    const [keyname, setKeyname] = useState("")
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [objectUserData, setObjectUserData] = useState(null);
    const [objectArrayCardData1, setObjectArrayCardData1] = useState([]);
    const [objectArrayHistoryData1, setObjectArrayHistoryData1] = useState([]);
    const [objectArrayFolderData1, setObjectArrayFolderData1] = useState([]);
    const [objectArrayGameData1, setObjectArrayGameData1] = useState([]);
  
  
    const [currentFolderKeyname, setCurrentFolderKeyname] =  useState(null);
    const [currentFolderData, setCurrentFolderData] =  useState(null);
    const [currentCardsData, setCurrentCardsData] =  useState([]);
  
    const [currentHistoryKeyname, setCurrentHistoryKeyname] =  useState(null);
    const [currentHistoryData, setCurrentHistoryData] =  useState(null);
  
    const [allCardsData, setAllCardsData] =   useState([]);

    const [boolPopup1, setBoolPopup1] =  useState(false);
    

    const upload = ()=>{

      if (viewScreenMode.mode !== pageCode1) {
        return;
      }

      var returnName1 = `${imageUpload1.name}_${moment().format("YYYYMMDD_HHmmss")}`;
      if(imageUpload1 == null)
        return;
        const uploadTask =  fireDb.storage().ref(`/images/${returnName1}`).put(imageUpload1)
        uploadTask
        .on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          fireDb.storage().ref('images').child(returnName1).getDownloadURL()
           .then(fireBaseUrl => {
            //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            console.log("fireBaseUrl", fireBaseUrl)
            setImageLink(fireBaseUrl);
           })
        }
      );

    }
    useEffect(()=> {
      upload();
    },[imageUpload1])

    useEffect(()=> {
      setStepIndex(3);
    },[])

    const qrcode = (
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={300}
        bgColor={"#00ff00"}
        level={"H"}
      />
    );
    function handleUserUpdate() {


      if (viewScreenMode.mode !== pageCode1) {
        return;
      }

      console.log("handleUserUpdate - 0", textInput11)

      Firebase.database().ref()
            .child("accounts")
            .orderByChild("username")
            .equalTo(textInput11)
            .once("value", (snapshot) => {

                  console.log("handleUserUpdate - 1", snapshot)

                if (snapshot.val()) {
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {

                      setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>해당 아이디 사용중입니다.</div>)
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                        if (data[keyName].status == "YES") {
                            
                        } else {
                            // setBlockSystem(true);
                        }
                    } else {
                        //조건 분기 필요

                    }

                }  else {
                  
                  console.log("handleUserUpdate - 2", )
                  fireDb.database().ref().child("accounts").child(keyname).update(
                    {
                      check_agree1 : boolCheck1,
                      check_agree2 : boolCheck2,
                      check_agree3 : boolCheck3,
                      check_agree4 : boolCheck4,
                      nation : textInput1,  //"in" 내국인, "out" 외국인
                      // 지금수정중
                      parent_name : textInput2,
                      contact_number : textInput3,
                      contact_secret : textInput4,
                      profile_image : imageLink,
                      username : textInput11,
                      nickname : textInput12,
                      password : textInput13,
                      email : textInput15,
                      gender : textInput16, // "male", "female"
                      usertype : textInput17,
                      birthdate : textInput18,
                      grade : textInput19

                      // account : textInput1, 
                      // email : textInput1, 
                      // password : textInput2,
                    }
                    , (err) => {
                    if (err) {
                      toast.error(err);
                      console.log(err);
                    } else {
                      toast.success("Signup  Successfully");

                      setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#1165F5`}}> 회원가입되었습니다.</div>)

                      setTimeout(()=> {
                          const pageCodeGo1 =  "page000/0/login";
                          setScreenMode1(pageCodeGo1)
                      }, 1500)

                      alert("")
                      
                    }
                  });
                }
            });
    
    }


    function handleFindUserExists(paramColumnName, paramValue) {

      // var email =  textInput1
      // var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

      //   if(exptext.test(email)==false){
      //     toast.error("이메일형식이 올바르지 않습니다.");
      //     return false;
      // }

      console.log("handleFindUserExists - textInput - 0", paramValue)

      Firebase.database().ref()
            .child("accounts")
            .orderByChild(paramColumnName)
            .equalTo( paramValue)
            .once("value", (snapshot) => {

                console.log("handleFindUserExists - textInput - 1", snapshot)

                if (snapshot.val()) {
                    const data = snapshot.val();
                    const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                    if (keyName) {

                         console.log("handleFindUserExists - textInput - 2", snapshot)
                        // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                        // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                        setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>해당 {paramColumnName.toUpperCase()} 사용중입니다.</div>)


                        if (data[keyName].status == "YES") {
                            
                        } else {
                            // setBlockSystem(true);
                        }
                    } else {

                      console.log("handleFindUserExists - textInput - 3", snapshot)
                        //조건 분기 필요

                    }

                }  else {
                  console.log("handleFindUserExists - textInput - 4", snapshot)
                  
                  setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }}  style={{ color : `#1165F5`}}>해당 {paramColumnName.toUpperCase()} 사용가능합니다.</div>)

                  // fireDb.database().ref().child("accounts").push(
                  //   {
                  //     account : textInput1, 
                  //     email : textInput1, 
                  //     password : textInput2,
                  //   }
                  //   , (err) => {
                  //   if (err) {
                  //     toast.error(err);
                  //     console.log(err);
                  //   } else {
                  //     toast.success("Signup  Successfully");
                  //     alert("")
                      
                  //   }
                  // });
                }
            });
    
    }




      

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      // 'page001/0/home'
      // console.log("viewScreenMode.mode", viewScreenMode.mode)

      // top_nav_display_visible : false,
      // top_nav_back_enabled : true,
      // top_nav_more_enabled : true,
      // top_nav_star_enabled : false,
      // top_nav_search_enabled : false,
      // top_nav_other_function : '',
      if (viewScreenMode && viewScreenMode.mode) {
        // console.log()
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }
      setStepIndex(3);


      if (viewScreenMode.mode !== pageCode1) {
        return;
      }


    // console.log("currentFolderItem - mapItem.id", mapItem.id)
    // window.localStorage.setItem("currentHistoryItem",mapItem.id)
    const currentFolderItem =  window.localStorage.getItem("currentFolderItem");
    const currentHistoryItem =  window.localStorage.getItem("currentHistoryItem");

    // console.log("currentFolderItem", currentFolderItem);
    
      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")
    var arrayCardData1 = [];
    var arrayFolderData1 = [];
    var arrayGameData1 = [];
    if (true && true) {
      const parseAuthStore1 = JSON.parse(authStore1)
      console.log("parseAuthStore1", parseAuthStore1);

      Firebase.database().ref()
          .child("accounts")
          .orderByChild("username")
          .equalTo(authStoreUserName)
          .once("value", (snapshot) => {
              if (snapshot.val()) {
                  const data = snapshot.val();

                  const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
                  setKeyname(keyName);
                  if (keyName) {
                      // Firebase.database().ref().child("accounts").child(`/${keyName}`).update({"recent_date": moment().format("YYYY-MM-DD HH:mm:ss")});
                      // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)

                      
                        // window.localStorage.setItem("authStore",  JSON.stringify(data[keyName]));
                        window.localStorage.setItem("authStoreKeyName",  keyName);
                        
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                      setObjectUserData(data[keyName]);
                        props && props.handleUser(keyName, data[keyName]);

                      if (data[keyName] && data[keyName]) {
                        
                        setBoolCheck1(data[keyName].check_agree1)
                        setBoolCheck2(data[keyName].check_agree2)
                        setBoolCheck3(data[keyName].check_agree3)
                        setBoolCheck4(data[keyName].check_agree4)
                        setTextInput1(data[keyName].nation)
                        setTextInput2(data[keyName].parent_name)
                        setTextInput3(data[keyName].contact_number)
                        setTextInput4(data[keyName].contact_secret)
                        setTextInput11(data[keyName].username)
                        setTextInput12(data[keyName].nickname)
                        // setTextInput13(data[keyName].password)
                        setTextInput15(data[keyName].email)
                        setTextInput16(data[keyName].gender)
                        setTextInput17(data[keyName].usertype)
                        setTextInput17(data[keyName].usertype)
                        setTextInput18(data[keyName].birthdate)
                        setTextInput19(data[keyName].grade)

                        
                      // nation : textInput1,  //"in" 내국인, "out" 외국인
                      // // 지금수정중
                      // parent_name : textInput2,
                      // contact_number : textInput3,
                      // contact_secret : textInput4,
                      // profile_image : imageLink,
                      // username : textInput11,
                      // nickname : textInput12,
                      // password : textInput13,
                      // email : textInput15,
                      // gender : textInput16, // "male", "female"
                      // usertype : textInput17,
                      // birthdate : textInput18,
                      // grade : textInput19


                      }
                      // setObjectArrayCardData1
                      // setObjectArrayFolderData1
                      // setObjectArrayGameData1
                      if (data[keyName].history &&  Object.values(data[keyName].history) &&  Object.values(data[keyName].history).length > 0) {

                        console.log("data[keyName].history", Object.values(data[keyName].history));
                        setObjectArrayHistoryData1([... Object.values(data[keyName].history)])

                        var historys = Object.values( data[keyName].history) ;
                        if (historys && historys.length > 0 ) {
                          const findIndex1 = historys.findIndex((findItem, findIndex)=> {
                            if (findItem.id == currentHistoryItem) {
                              return (
                                findItem
                              )
                            }
                          })

                          if (findIndex1 > -1 ) {
                            setCurrentHistoryKeyname(Object.keys(data[keyName].history)[findIndex1]);
                            setCurrentHistoryData(historys[findIndex1])

                            // if (folders[findIndex1] && folders[findIndex1].cards) {
                            //   // console.log(" == folders[findIndex1].cards - Object.values()",Object.values( historys[findIndex1].cards));
                            //   // var cards = Object.values( historys[findIndex1].cards);
                            //   // setCurrentCardsData(cards);

                            // }

                          }else {
                            // 없음
                          }
                        }

                      } else {
                        setObjectArrayHistoryData1([])
                      }
                      if (data[keyName].folders) {
                        
                        console.log("data[keyName].folders - Object.values()",Object.values( data[keyName].folders));
                        if (Object.values( data[keyName].folders) && Object.values( data[keyName].folders).length > 0)
                        setObjectArrayFolderData1(Object.values( data[keyName].folders))

                        var folders = Object.values( data[keyName].folders) ;
                        if (folders && folders.length > 0 ) {
                          const findIndex1 = folders.findIndex((findItem, findIndex)=> {
                            if (findItem.id == currentFolderItem) {
                              return (
                                findItem
                              )
                            }
                          })

                          if (findIndex1 > -1 ) {
                            setCurrentFolderKeyname(Object.keys(data[keyName].folders)[findIndex1]);
                            setCurrentFolderData(folders[findIndex1])
                            props && props.handleFolder(Object.keys(data[keyName].folders)[findIndex1], folders[findIndex1], Object.keys(data[keyName].folders)[findIndex1].id);

                            if (folders[findIndex1] && folders[findIndex1].cards) {
                              console.log(" == folders[findIndex1].cards - Object.values()",Object.values( folders[findIndex1].cards));
                              var cards = Object.values( folders[findIndex1].cards);
                              setCurrentCardsData(cards);

                            }

                          }else {
                            // 없음
                          }

                          var tempAllCards = [];
                          for(var i=0;i<folders.length;i++) {
                            
                            if (folders[i].cards && Object.values( folders[i].cards) &&  Object.values( folders[i].cards)) {
                              tempAllCards = [...tempAllCards, ...Object.values( folders[i].cards)];
                            }
                          }

                          setAllCardsData(tempAllCards)

                        }
                        
                      }else {
                        setObjectArrayFolderData1([])
                      }


                      if (data[keyName].games) {
                        setObjectArrayGameData1([...data[keyName].games])
                      }else {
                        setObjectArrayGameData1([])
                      }
                      // if (data[keyName].status == "YES") {
                      // } else {
                      // }
                  } else {

                  }

              }  else {
                  // alert("존재하지 않는 회원입니다.");
              }
          });



    }









    }, [useSelector(state => state)])


    useEffect(()=> {
      if(objectPopupData1 != null) {
        setTimeout(()=> {
          setObjectPopupData1(null);
        }, 3000)
      }
    }, [objectPopupData1])

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    
    // 렌더, return-render,
    return (
      <>
        {objectPopupData1}
        <div className={`a04_c`}>
          <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
          
          <div className="" style={{ height : 50 }}></div>

          {
            stepIndex == 1 &&
            <div className="step_content_container0 step_content_container1">
              <div className="term_text0 term_text1">쏙닥쏙닥 앱이용을 위해 아래 약관에 동의해 주세요!</div>
              <div className="hl"></div>
              <div className="textinput_form_check_row0">
                <div className="col50">
                  {
                    boolCheck1 == false &&
                    <img 
                        src={`/static/icons/000/icon_check0.png`}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setBoolCheck1(true)
                            
                          }}
                        />
                  }
                  {
                    boolCheck1 == true &&
                    <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                    
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck1(false)
                      
                    }}>

                      </div>
                  }
                      <div 
                        className="text1 "
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolCheck1(true)
                        }}
                      >일반 전체 동의</div>
                </div>
                <div className="col50">
                {
                    boolCheck2 == false &&
                  <img 
                      src={`/static/icons/000/icon_check0.png`}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolCheck2(true)
                          
                        }}
                      />
                  }
                      {
                        boolCheck2 == true &&
                        <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                        
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolCheck2(false)
                          
                        }}>
    
                          </div>
                      }
                      <div 
                        className="text1 "
                        onClick={e=> {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolCheck2(true)
                        }}
                      >14세 미만 미성년자 동의</div>
                </div>

              </div>
              <div className="hl"></div>
              <div className="textinput_form_check_row0">
                <div className="col100">
                  
                {
                  boolCheck3 == false &&
                  <img 
                  src={`/static/icons/000/icon_check0.png`}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck3(true)
                      
                    }}
                  />
                  }
                  {
                    boolCheck3 == true &&

                    <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                    
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck3(false)
                      
                    }}>
                      </div>
                  }
                  <div 
                    className="text1 "
                    onClick={e=> {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck3(true)
                    }}
                  >
                    (필수) 쏙닥쏙닥 서비스 이용약관
                  </div>
                  
                  <div className="more_wrapper0 more_wrapper1"
                    style={{
                      color : boolMoreInfoOpen1 == true ? `#181818` : `#545963`
                    }}
                  >
                    더보기
                    <img 
                    src={
                      boolMoreInfoOpen1 == true ?
                      `/static/icons/000/icon_more_arrow1.png`
                      :
                      `/static/icons/000/icon_more_arrow0.png`
                    }
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setBoolMoreInfoOpen1(!boolMoreInfoOpen1)
                      }}
                    />
                  </div>
                    
                </div>

              </div>

              {
                boolMoreInfoOpen1 == true &&
                <div className="term_textarea0 term_textarea1">
                {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                </div>
                }
                <div className="hl"></div>
                <div className="textinput_form_check_row0">
                  <div className="col100">

                  {
                        boolCheck4 == false &&
                        <img 
                        src={`/static/icons/000/icon_check0.png`}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setBoolCheck4(true)
                            
                          }}
                        />
                  }
                  {
                    boolCheck4 == true &&
                    <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                    
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck4(false)
                      
                    }}>

                      </div>
                  }
                    <div 
                      className="text1 "
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();

                        setBoolCheck4(true)
                      }}
                    >(필수) 개인정보 수집 및 이용동의
                    </div>
                    
                    <div className="more_wrapper0 more_wrapper1"
                      style={{
                        color : boolMoreInfoOpen2 == true ? `#181818` : `#545963`
                      }}
                    >
                      더보기
                      <img 
                      src={
                        boolMoreInfoOpen2 == true ?
                        `/static/icons/000/icon_more_arrow1.png`
                        :
                        `/static/icons/000/icon_more_arrow0.png`
                      }
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setBoolMoreInfoOpen2(!boolMoreInfoOpen2)
                        }}
                      />
                    </div>
                      
                  </div>
  
                </div>
  
                {
                  boolMoreInfoOpen2 == true &&
                  <div className="term_textarea0 term_textarea1">
                  {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
                  </div>
                  }

                <div className="hl"></div>
                
                <div className="term_text0 term_text2">
                  *  위 약관에 동의하셔야 회원가입이 가능 합니다 <br/>
                  *  14세 미만의 아동은 보호자(법정대리인)의 동의 및 인증이 
                       반드시  필요합니다 
                </div>
                <div className="submit_button0 submit_button1"
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    if (boolCheck1 == true 
                      && boolCheck2 == true 
                      &&boolCheck3 == true  
                      &&boolCheck4 == true 
                      )
                     {

                      setStepIndex(2);
                    } else {
                      toast.error("확인이 필요합니다.")

                    }
                  }}
                >다음</div>
            </div>
          }
          {
            stepIndex == 2 &&
            <div className="step_content_container0 step_content_container2">
            <div className="textinput_form_check_row0">
              <div className="col100">

              {
                        boolCheck4 == false &&
                        <img 
                        src={`/static/icons/000/icon_check0.png`}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setBoolCheck4(true)
                            
                          }}
                        />
              }

{
                    boolCheck4 == true &&
                    <div style={{ width : `24px`, height : `24px`, borderRadius : `4px`, background : `#1165F5`}}
                    
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolCheck4(false)
                      
                    }}>

                      </div>
                  }

                <div 
                  className="text1 "
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >보호자동의약관
                </div>
                
                <div className="more_wrapper0 more_wrapper1"
                  style={{
                    color : boolMoreInfoOpen3 == true ? `#181818` : `#545963`
                  }}
                >
                  더보기
                  <img 
                  src={
                    boolMoreInfoOpen3 == true ?
                    `/static/icons/000/icon_more_arrow1.png`
                    :
                    `/static/icons/000/icon_more_arrow0.png`
                  }
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setBoolMoreInfoOpen3(!boolMoreInfoOpen3)
                    }}
                  />
                </div>
                  
              </div>

            </div>

            {
              boolMoreInfoOpen3 == true &&
              <div className="term_textarea0 term_textarea1">
              {`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
{`쏙닥쏙닥 앱 (이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 
약관은 다양한 쏙닥쏙닥앱의 이용과 관련하여 서비스를 
제공하는 쏙닥쏙닥 앱과 이를 이용하는 서비스 회원(이하 
‘회원’)또는 비회원과의 관계를 설명하며, 쏙닥쏙닥 여러분의 
서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고`}
              </div>
              }
              <div className="hl"></div>

              <div className="input_label0 input_label1">
              &nbsp;&nbsp;국적
              </div>
              <div className="tab2_wrapper0 tab_wrapper1">
                <div className="tab_item0 tab_item1"
                  style={{ 
                    color : textInput1 == "in" ?  `#FFFFFF` : `#212529`,
                    fontWeight : textInput1 == "in" ?  `700` : `400`,
                    background : textInput1 == "in" ?  `#181818` : `#FFFFFF`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput1("in")
                  }}
                >내국인</div>
                <div className="tab_item0 tab_item2"
                  style={{ 
                    color : textInput1 == "out" ?  `#FFFFFF` : `#212529`,
                    fontWeight : textInput1 == "out" ?  `700` : `400`,
                    background : textInput1 == "out" ?  `#181818` : `#FFFFFF`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput1("out")
                  }}
                  >외국인</div>
              </div>
              <div className="input_label0 input_label2">
              &nbsp;&nbsp;보호자 성명
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container1"
                style={{ background : `#fff`}}
              >
            <input 
              value={textInput2}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput2(e.target.value);
              }}
              placeholder={`부모님의 이름을 입력하세요`}
            />
            {
              textInput2.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput2("");
              }}
              />
            }
          </div>
              <div className="input_label0 input_label2">
              &nbsp;&nbsp;연락처
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>

                      <input 
                    value={textInput3}
                    onChange={e=> {
                      // e.preventDefault();
                      // e.stopPropagation();
                      setTextInput3(e.target.value);
                    }}
                    placeholder={`번호를 입력하세요(“-”제외)`}
                  />
                  {
                    textInput3.length > 0 &&
                    <img src={appImages._000_2} 
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTextInput3("");
                    }}
                    />
                  }
                </div>
                <div className="button0">인증번호전송</div>
            </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              style={{ marginTop: 12}}
              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>

                <input 
              value={textInput4}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput4(e.target.value);
              }}
              placeholder={`인증코드를 입력하세요`}
            />
            {
              textInput4.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput4("");
              }}
              />
            }
                </div>
                <div className="button0">인증번호확인</div>
          </div>





              <div className="submit_button0 submit_button1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                  if (textInput2 == "") {
                    alert("보호자 성명을 입력하세요");
                  } else {
                    setStepIndex(3);
                  }


                }}
              >다음</div>
            </div>
          }
          {
            stepIndex == 3 &&
            <div className="step_content_container0 step_content_container3">
              <div>
                  <div className="circleimage_wrapper" 
                    style={{ 
                      background: imageUpload1 == '' ? `rgba(33, 37, 41, 0.7)` : `transparent`,
                      border : imageUpload1 == '' ? `none` : `1px solid #aeaeae`,
                    }}
                  >
                    {
                      imageUpload1 == '' &&
                      <>
                      <div className="circle0" onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        imageRef1.current.click();

                      }}>
                        <img className="circleimage0" src={`/static/icons/000/icon_camera.png`} alt=""/>
                        <div className="circledesc0">본인의 사진을<br/>등록해보세요!</div>
                      </div>
                      <input ref={imageRef1} 
                        type="file" 
                        onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                        // accept="image/*"
                        accept=".gif, .jpg, .png"
                        // accept=".png"
                        style={{display : 'none'}}/>
                      <button onClick={upload} style={{display : 'none'}}>Upload</button>
                      </>
                    }
                    {
                      imageUpload1 != '' &&
                      <>
                      <img className="full_image" src={imageLink} alt=""/>
                      <input ref={imageRef1} 
                      type="file" 
                      onChange={(e)=>{setImageUpload1(e.target.files[0])}} 
                      // accept="image/*"
                      // accept=".gif, .jpg, .png"
                      accept=".png"
                      style={{display : 'none'}}/>
                      <button onClick={upload} style={{display : 'none'}}>Upload</button>
                      </>
                    }
                    <div>
                    </div>
                  </div>
                </div>
              <div className="input_label0 input_label1">
              &nbsp;&nbsp;아이디입력
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              style={{ marginTop: 12}}
              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>

                <input 
              value={textInput11}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput11(e.target.value);
              }}
              placeholder={`영문+숫자 1~10자리`}

            />
            {
              textInput11.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput11("");
              }}
              />
            }
                </div>
                <div className="button0" 
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    handleFindUserExists("username", textInput11);
                  }}
                >중복확인</div>
          </div>
              <div className="input_label0 input_label2">
              &nbsp;&nbsp;닉네임입력
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              style={{ marginTop: 12}}
              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>

                <input 
              value={textInput12}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput12(e.target.value);
              }}
              placeholder={`영문+숫자 1~10자리`}
              maxLength={10}
            />
            {
              textInput12.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput12("");
              }}
              />
            }
                </div>
                <div className="button0" 
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    handleFindUserExists("nickname", textInput12);
                  }}
                >중복확인</div>
          </div>


          <div className="input_label0 input_label2">
              &nbsp;&nbsp;비밀번호입력
              </div>
          <div className="textinput_form_container0 textinput_form_container2" 
                style={{ background : `#fff`}}>
            <input 
              type={boolPasswordShow1 == true ? `text` : `password`}
              value={textInput13}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput13(e.target.value);
              }}
              placeholder={`패스워드를 입력하세요`}
            />
            {/* 
            {
              textInput2.length > 0 &&
              <img src={appImages._000_2} />
            } 
            */}
            {
              // textInput2.length > 0 &&
              // boolPasswordShow1 == false &&
              <img src={`/static/icons/000/icon_eye.png`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPasswordShow1(!boolPasswordShow1)
                }}
              />
            }
          </div>


          <div className="input_label0 input_label2">
              &nbsp;&nbsp;비밀번호 재입력
              </div>
          <div className="textinput_form_container0 textinput_form_container2" 
                style={{ background : `#fff`}}>
            <input 
              type={boolPasswordShow2 == true ? `text` : `password`}
              value={textInput14}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput14(e.target.value);
              }}
              placeholder={`패스워드를 입력하세요`}
            />
            {/* 
            {
              textInput2.length > 0 &&
              <img src={appImages._000_2} />
            } 
            */}
            {
              // textInput2.length > 0 &&
              // boolPasswordShow1 == false &&
              <img src={`/static/icons/000/icon_eye.png`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPasswordShow2(!boolPasswordShow2)
                }}
              />
            }
          </div>


          <div className="input_label0 input_label2">
              &nbsp;&nbsp;비밀번호 복구 코드 입력
              </div>
          <div className="textinput_form_container0 textinput_form_container2" 
                style={{ background : `#fff`}}>
            <input 
              type={boolPasswordShow3 == true ? `text` : `password`}
              value={textInput21}
              maxLength={4}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput21(e.target.value);
              }}
              placeholder={`기억하기쉬운 코드 숫자 4자리를 입력하세요`}
            />
            {/* 
            {
              textInput2.length > 0 &&
              <img src={appImages._000_2} />
            } 
            */}
            {
              // textInput2.length > 0 &&
              // boolPasswordShow1 == false &&
              <img src={`/static/icons/000/icon_eye.png`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setBoolPasswordShow3(!boolPasswordShow3)
                }}
              />
            }
          </div>


          <div className="input_label0 input_label2">
              &nbsp;&nbsp;이메일 입력
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              style={{ marginTop: 12}}
              >
                <div className="input_wrapper0 " 
                style={{ background : `#fff`}}>
                <input 
              value={textInput15}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput15(e.target.value);
              }}
              placeholder={`사용중인 이메일 입력`}

            />
            {
              textInput15.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput15("");
              }}
              />
            }
                </div>
                <div className="button0" 
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();

                    handleFindUserExists("email", textInput15);
                  }}
                >중복확인</div>
          </div>

          <div className="input_label0 input_label2">
              &nbsp;&nbsp;성별
              </div>
              <div className="tab2_wrapper0 tab_wrapper1" style={{ minHeight : 50 }}>
                <div className="tab_item0 tab_item1"
                  style={{ 
                    color : textInput16 == "male" ?  `#FFFFFF` : `#212529`,
                    fontWeight : textInput16 == "male" ?  `700` : `400`,
                    background : textInput16 == "male" ?  `#181818` : `#FFFFFF`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput16("male")
                  }}
                >남성</div>
                <div className="tab_item0 tab_item2"
                  style={{ 
                    color : textInput16 == "female" ?  `#FFFFFF` : `#212529`,
                    fontWeight : textInput16 == "female" ?  `700` : `400`,
                    background : textInput16 == "female" ?  `#181818` : `#FFFFFF`,
                  }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput16("female")
                  }}
                  >여성</div>
              </div>
          {/* <div className="input_label0 input_label2">
              &nbsp;&nbsp;회원구분 선택
              </div>
          <div className="textinput_form_container0 textinput_form_container1"
                style={{ background : `#fff`}}>
            <input 
              value={textInput17}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput17(e.target.value);
              }}
              placeholder={`회원구분`}
            />
            {
              textInput17.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput17("");
              }}
              />
            }
          </div> */}

          {/* <div className="input_label0 input_label2">
              <div className="col50">
              &nbsp;&nbsp;생년월일입력
                </div>
              <div className="col50">
              &nbsp;&nbsp;학년입력
                </div>
              </div>
              <div 
              className="textinput_form_container0 textinput_form_container_phone0"
              style={{ marginTop: 12}}
              >
              <div className="input_wrapper50 " 
              style={{ background : `#fff`}}>

              <input 
            value={textInput18}
            onChange={e=> {
              // e.preventDefault();
              // e.stopPropagation();
              setTextInput18(e.target.value);
            }}
            placeholder={`20010422`}
          />
          {
            textInput18.length > 0 &&
            <img src={appImages._000_2} 
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setTextInput18("");
            }}
            />
          }
              </div>
                <div className="input_wrapper50 " 
                style={{ background : `#fff`}}>

                <input 
              value={textInput19}
              onChange={e=> {
                // e.preventDefault();
                // e.stopPropagation();
                setTextInput19(e.target.value);
              }}
              placeholder={`학년입력`}
            />
            {
              textInput19.length > 0 &&
              <img src={appImages._000_2} 
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTextInput19("");
              }}
              />
            }
                </div>
          </div> */}
              <div className="submit_button0 submit_button1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  // setStepIndex(3);
                  console.log("test1")
                  // if (
                  //   textInput11 == ""  ||
                  //   textInput12 == ""  ||
                  //   textInput13 == ""  ||
                  //   textInput14 == ""  ||
                  //   textInput15 == ""  ||
                  //   textInput16 == ""  ||
                  //   textInput17 == ""  ||
                  //   textInput18 == ""  ||
                  //   textInput19 == ""  

                  // ) {
                  //   setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>빈 항목이 있습니다.</div>)
                  //   return;
                  // }

                  console.log("test2")
                  if (textInput13 != textInput14) {
                    
                    setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>비밀번호가 같지 않습니다.</div>)
                    return;
                  } 


                  console.log("test3")
                  var email =  textInput15
                  var exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

                  if(exptext.test(email)==false){

                    setObjectPopupData1(<div className="global_popup"  onClick={e=> {e.preventDefault(); e.stopPropagation();setObjectPopupData1(null); }} style={{ color : `#ff0000`}}>이메일형식이 올바르지 않습니다.</div>)
                    // toast.error("이메일형식이 올바르지 않습니다.");
                    return false;
                  }
                  
                  // console.log("test4")

                  handleUserUpdate();


                }}
              >수정</div>

            </div>
          }
































        </div>
      </>
      
    );

}
