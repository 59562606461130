import React, { Component, useEffect, useState, useContext } from "react";

import parse from "html-react-parser";
import fireDb from "../../firebase";
import Firebase from 'firebase';
import moment from "moment";
import "./s01.css";
import {useSelector, useDispatch} from 'react-redux'
import { SCREENMODE_KEY } from "../../redux/screenModeRedux/screenMode.reducer";
import { setScreenMode } from "../../redux/screenModeRedux/screenMode.actions";
import appColors from "../../assets/appColors";
import appImages from "../../assets/appImages";
import appPages from "../../assets/appPages";
import NavigationTop from "../NavigationTop/NavigationTop";;

const pageCodeName0 = ""
const pageCodeName1 = ""
const pageCodeName2 = ""
const pageCodeName3 = ""
const pageCodeName4 = ""
const pageCodeName5 = ""
 
// <div className={``}></div>
//  'page000/1/register',
const pageCode1 ='page088/0/search';
export default function ThisComponent(props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [selectedPageObject, setSelectedPageObject] = useState(appPages.pageCodeInfoArr[0]);
    const [stepIndex, setStepIndex] = useState(2);
    const [boolMoreInfoOpen1 , setBoolMoreInfoOpen1] = useState(false);
    const [boolMoreInfoOpen2 , setBoolMoreInfoOpen2] = useState(false);
    const [boolMoreInfoOpen3 , setBoolMoreInfoOpen3] = useState(false);

    // STEP 2 USAGE
    const [textInput1 , setTextInput1] = useState("in");
    const [textInput2 , setTextInput2] = useState("");
    const [textInput3 , setTextInput3] = useState("");
    const [textInput4 , setTextInput4] = useState("");
    const [textInput5 , setTextInput5] = useState("");
    const [textInput6 , setTextInput6] = useState("");
    const [textInput7 , setTextInput7] = useState("");
    const [textInput8 , setTextInput8] = useState("");

    // STEP 3 USAGE
    const [textInput11 , setTextInput11] = useState("");
    const [textInput12 , setTextInput12] = useState("");
    const [textInput13 , setTextInput13] = useState("");
    const [textInput14 , setTextInput14] = useState("");
    const [textInput15 , setTextInput15] = useState("");
    const [textInput16 , setTextInput16] = useState("male");
    const [textInput17 , setTextInput17] = useState("");
    const [textInput18 , setTextInput18] = useState("");
    const [textInput19 , setTextInput19] = useState("");

    const [boolPasswordShow1, setBoolPasswordShow1] = useState(false);
    const [boolPasswordShow2, setBoolPasswordShow2] = useState(false);

    const [boolPopup1, setBoolPopup1] =  useState(false);

    const [objectUserKeyname, setObjectUserKeyname] = useState("");
    const [objectUserData, setObjectUserData] = useState(null);


    const [boolSaveList1, setBoolSaveList1] = useState(false);
    const [arraySearchText, setArraySearchText] = useState([]);
    const [objectSearchText1, setObjectSearchText1] = useState("");
    const [objectSearchText2, setObjectSearchText2] = useState("");
    const [objectSearchText3, setObjectSearchText3] = useState("");

    let dispatch = useDispatch()
    // view data from store
    let viewScreenMode = useSelector((state) =>{
      return state[SCREENMODE_KEY]
    })

    let setScreenMode1 = (param) => {
      dispatch(setScreenMode(param))
    }

    // if (viewScreenMode.mode != "popup/mypage/notice") {
    //     return <></>
    // }
    useEffect(()=> {
      if (viewScreenMode && viewScreenMode.mode) {
        const arr1 = appPages.pageCodeInfoArr;
        const selectedPageIndex = arr1 && arr1.findIndex((arrItem, arrIndex)=> {
            if (arrItem && arrItem.page_code === viewScreenMode.mode) {
              return arrItem
            }
        })

        if (selectedPageIndex > -1) {
          setSelectedPageObject(arr1[selectedPageIndex])
        }
      }
      getUserData();

    }, [useSelector(state => state)])

    function getUserData() {

      const authStoreKeyName = localStorage.getItem("authStoreKeyName");
      const authStoreUserName = localStorage.getItem("authStoreUserName");
      const authStore1 = localStorage.getItem("authStore")

      Firebase.database().ref()
      .child("accounts")
      .orderByChild("username")
      .equalTo(authStoreUserName)
      .once("value", (snapshot) => {
          if (snapshot.val()) {
              const data = snapshot.val();
              const keyName =  Object.keys(snapshot.val()) && Object.keys(snapshot.val())[0]
              if (keyName) { 
                  window.localStorage.setItem("authStoreKeyName",  keyName);
                  
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                  window.localStorage.setItem("authStoreKeyName",  keyName);
                  
                            window.localStorage.setItem("authStoreUserName",  data[keyName].username);
                            window.localStorage.setItem("authStorePassword",  data[keyName].password);
                  setObjectUserData(data[keyName]);
                  setObjectUserKeyname(keyName);

                  if (data[keyName] && data[keyName].check_search1 && data[keyName].check_search1 == true) {
                    setBoolSaveList1(true)
                  } else {
                    setBoolSaveList1(false)
                  }

                  if (data[keyName] && data[keyName].history_search ) {

                    setArraySearchText(Object.values(data[keyName].history_search));
                    
                    // setTextSearchText1(data[keyName].history_search1)
                    // check_search1
                    // history_search
                    // Firebase.database().ref().child("accounts").child(`/${keyName}/date`).push(`${moment().format("YYYY-MM-DD HH:mm:ss")}`)
                    
                  }

                  props && props.handleUser(keyName, data[keyName]);
               
              } else {

              }

          }  else {
              // alert("존재하지 않는 회원입니다.");
          }
      });


    }

    if (viewScreenMode.mode !== pageCode1) {
      return <></>
    }

    return (
      <>
        <div className={`a04_c`}>
          <div className="top_nav_for_search">
            <img 
              className={`image1_0 image1_1`} 
              src={appImages._000_1} 
              onClick={e=> {
                e.preventDefault();
                e.stopPropagation();
                setScreenMode1('page001/0/home');
              }}
              />
          </div>
            <NavigationTop callBack1={e=> {setBoolPopup1(true)}} callBack2={null} callBack3={null} {...props}  />
            <div className="searchbar_container0 top_search_area0">
                
                <div 
                className="textinput_form_container0 textinput_form_container_phone0"
                >
                  {/* <div className=" search_btn1">카드
                  </div> */}
                  <div className=" search_btn2"  >
                      <input 
                      value={textInput3}
                      onChange={e=> {
                        // e.preventDefault();
                        // e.stopPropagation();
                        setTextInput3(e.target.value);
                      }}
                      placeholder={`검색어를 입력하세요`}
                    />
                    {
                      textInput3.length > 0 &&
                      <img src={appImages._000_2} 
                      style={{ marginTop: 4, }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTextInput3("");
                      }}
                      />
                    }
                  </div>
              </div>
              <div className="search_row0">
                <div className="search_btn3" style={{ }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    // 

                    
                    Firebase.database()
                    .ref()
                    .child("accounts").child(`/${objectUserKeyname}/history_search`)
                    .push(
                      {
                        text : textInput3,
                        date : moment().format("YYYY-MM-DD HH:mm:ss")
                      }
                    )
                    
                    Firebase.database()
                    .ref()
                    .child("accounts").child(`/${objectUserKeyname}/recent_search`)
                    .update({
                      text : textInput3,
                      date : moment().format("YYYY-MM-DD HH:mm:ss")
                    }
                    )
                    setScreenMode1( 'page088/2/search-result');


                  }}
                >검색하기</div>
                <div className="search_btn4" style={{ }}
                  onClick={e=> {
                    e.preventDefault();
                    e.stopPropagation();
                    setTextInput3("");
                    setScreenMode1("page001/0/home");
                  }}
                  >취소하기</div>

              </div>
            </div>
              
              {
                boolSaveList1 == true &&
                <>
                <div className="search_label0" style={{ }}>최근 검색어</div>
                {
                // Array.from(["세계사", "미분과 적분", "우주과학(양자물리학.."])
                arraySearchText
                .map((mapItem, mapIndex)=> {
                  return (
                    <div className="search_row0" style={{ }}>
                      <div className="text0 text1"
                      onClick={e=> {
                        e.preventDefault();
                        e.stopPropagation();
                        Firebase.database()
                        .ref()
                        .child("accounts").child(`/${objectUserKeyname}/history_search`)
                        .push(
                          {
                            text : mapItem.text,
                            date : moment().format("YYYY-MM-DD HH:mm:ss")
                          }
                        )
                        
                        Firebase.database()
                        .ref()
                        .child("accounts").child(`/${objectUserKeyname}/recent_search`)
                        .update({
                          text : mapItem.text,
                          date : moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                        )
                        setScreenMode1( 'page088/2/search-result');
    
    
                      }}
                      >
                        {/* {mapItem} */}
                        {mapItem.text}
                      </div>
                      <div className="text0 text2">
                        
                      {moment(mapItem.date, "YYYY-MM-DD HH:mm:ss").format("MM-DD")}
                      </div>
                      {/* <div className="close0 close1">
                        <img src={`/static/icons/054/icon_delete.png`}/>
                      </div> */}
                    </div>
                  )
                })
                }
                </>
              }
            <div className="search_row0" style={{ }}>
              <div className="bottom_button0 buttom_button1"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();
                  
                    // check_search1
                    // history_search
                    // Firebase.database().ref().child("accounts").child(`/${keyName}`)
                    Firebase.database()
                      .ref()
                      .child("accounts").child(`/${objectUserKeyname}`)
                      .update({"check_search1": !boolSaveList1 });

                    setBoolSaveList1(!boolSaveList1);

                }}
              >
                {boolSaveList1 == true && <>저장기능끄기</>}
                {boolSaveList1 == false && <>저장기능켜기</>}
              </div>
              <div className="bottom_button0 buttom_button2"
                onClick={e=> {
                  e.preventDefault();
                  e.stopPropagation();

                  
                    // check_search1
                    // history_search
                  Firebase.database()
                  .ref()
                  .child("accounts").child(`/${objectUserKeyname}/history_search`)
                  .remove();

                  setArraySearchText([]);
                }}
              >전체삭제</div>
            </div>

            

            <style>
              {`
              .top_nav_for_search {
                width : 100%;
                height : 56px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                background-color : #fff;

              }
              .top_nav_for_search img {
                width : 24px;
                height : 24px;
                object-fit : contain;

                margin-top: 20px;
                margin-left: 12px;

              }
              `}
            </style>




        </div>
      </>
      
    );
}


{/* <div className="" style={{ }}>검색하기</div> */}